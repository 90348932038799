import React, { useCallback } from 'react';
import { UDFormComponentProps } from '../types';
import { FieldProps, useFormikContext } from 'formik';
import UDFormFieldWrapper from '../../wrapper';
import UDTimeInput, { UDTimeInputProps } from './component/time';

export type UDFormTimeInputProps = UDFormComponentProps &
  Omit<UDTimeInputProps, 'hasError' | 'valid' | 'onChange'>;

const UDFormTimeInput = (props: UDFormTimeInputProps) => {
  const { name, label, labelProps, containerProps, required, ...otherProps } =
    props;
  const { setFieldValue } = useFormikContext();
  const onChange = useCallback(
    (date, e) => {
      setFieldValue(name, date);
    },
    [name],
  );
  return (
    <UDFormFieldWrapper
      name={name}
      containerProps={containerProps}
      label={label}
      labelProps={labelProps}
      required={required}>
      {({ field, meta }: FieldProps<any>) => {
        return (
          <UDTimeInput
            {...field}
            onChange={onChange}
            hasError={!!meta.error}
            valid={!meta.error && meta.touched && meta.value}
            {...otherProps}
          />
        );
      }}
    </UDFormFieldWrapper>
  );
};

export default UDFormTimeInput;
