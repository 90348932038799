import styled from '@emotion/styled';
import { UDCreatableSelect, UDSelect } from '../../../ud-form/components/select/component';

const BorderedSelect = styled(UDSelect)`
  border: 1px solid #F4F4F5;
  border-radius: 8px;
` as typeof UDSelect;

export const BorderedCreatableSelect = styled(UDCreatableSelect)`
  border: 1px solid #F4F4F5;
  border-radius: 8px;
` as typeof UDCreatableSelect;

export default BorderedSelect;
