import styled from '@emotion/styled';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleActiveOverlay } from '../../../store/overlay';
import { selectOverlayState } from '../../../store/selectors';
import Block from '../components/block';
import Text from '../components/text';
import _ from "lodash";

export function Dashboard() {
  const dispatch = useDispatch()
  const { activeOverlay, category } = useSelector(selectOverlayState)
  
  const baseCallback = (newState: number | null) => {
    dispatch(toggleActiveOverlay(newState))
  }
  
  const templates = useMemo(() => {
    if (!category) return
    return _(category.templates).toPairs().orderBy((value) => {return value[ 1 ].id}).value().map(value => ({
      name: value[ 0 ],
      id: +value[ 1 ].id
    }))
  }, [category])
  
  const listItems: ItemType[] | undefined = templates?.map(({ name, id }) => {
    return {
      callback: baseCallback,
      text: name,
      state: id
    }
  })
  
  listItems?.push({ callback: baseCallback, text: 'Выкл.', state: null })
  
  const items = listItems?.map((item) => {
    const onClick = () => {
      item.callback(item.state)
    }
    
    return (
      <Item key={item.state} active={activeOverlay === item.state} onClick={onClick} className={'flex-center'}>
        <Text color={'#FFF'} size={'medium'}>{item.text}</Text>
      </Item>
    )
  })
  
  return <Block className={'d-flex flex-column'} headerText={'Табло'}>
    <DashboardList className={'d-flex'}>
      {items}
    </DashboardList>
  </Block>;
}

type ItemType = { callback: (newState: number | null) => void, text: string, state: number | null };

const DashboardList = styled.div`
  gap: 8px;
  flex-wrap: wrap;
`

const Item = styled.div<{ active: boolean }>`
  padding-inline: 14px;
  padding-block: 4px;
  background: ${({ active }) => active ? '#D9B244' : '#686a79'};
`
