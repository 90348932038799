import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
  selectActiveCategory,
  selectActiveTemplate,
  selectFabricManager,
  selectPreviewState
} from "../../../../store/selectors";
import { setDataForCategory, setPreview } from "../../../../store/templateEditor";
import { useOrganizer } from "../../../../../organizer/ui/hooks/useOrganizer";
import history from "../../../../../ud-ui/components/fabric-canvas/addons/history";
import { GridLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import { Pages } from "../../../../../navigation/domain/enums/pages";
import Canvas from "./canvas";
import _ from 'lodash';
import { supabaseTemplate, template } from "../../../../../ud-ui/components/fabric-canvas/types";
import { Button, Container, Header, MainBlock, RightBlock, TemplateName } from "./styles";
import useLocalStorage from "../../../hooks/useLocalStorage";
import { Zoom } from "./zoom";

function Content() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const category = useSelector(selectActiveCategory)
  const activeTemplate = useSelector(selectActiveTemplate)
  const {
    template: templateBeforePreview,
  } = useSelector(selectPreviewState)
  const { info } = useOrganizer()
  const [isSaveButtonAvailable, setAvailableSaveButton] = useState<boolean>(false)
  const [savedTemplate, setSavedTemplate] = useState<template>()
  const [savedResolution, setSavedResolution] = useState<{ width: number, height: number }>()
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const manager = useSelector(selectFabricManager)
  const { setDataToLocalStorage: setData, getDataFromLocalStorage: getData } = useLocalStorage()
  
  setData({
    categoryId: category?.id,
    templateName: activeTemplate,
    userId: info?.id,
    resolution: category?.resolution,
    template: category?.templates[ activeTemplate ?? '' ],
  })
  
  useEffect(() => {
    const historySubscriber = {
      update: (state: supabaseTemplate) => {
        if (!manager) return
        if (!savedTemplate) return;
        const isEqual = _.isEqual(state, savedTemplate)
        setAvailableSaveButton(!isEqual)
      }
    };
    
    manager?.resolution.subscribe((resolution) => {
      if (!manager) return
      if (!resolution) return;
      const isEqual = _.isEqual(resolution, savedResolution)
      setAvailableSaveButton(!isEqual)
    })
    
    history.onChangeState.subscribe(historySubscriber)
    
    return () => {
      history.onChangeState.unsubscribe(historySubscriber)
    }
  }, [manager, savedResolution, savedTemplate])
  
  useEffect(() => {
    if (!manager) return
    const { template, resolution } = getData()
    setSavedResolution(category?.resolution ?? resolution ?? manager.resolution.value)
    
    if (templateBeforePreview) {
      const categoryTemplate = category?.templates[ activeTemplate ?? '' ]
      const isEqual = _.isEqual(categoryTemplate ?? template, manager.getTemplate(true))
      setAvailableSaveButton(!isEqual)
      setSavedTemplate(categoryTemplate ?? template)
    } else {
      setSavedTemplate(manager.getTemplate(true))
    }
    
    setIsLoaded(true)
    
    // eslint-disable-next-line
  }, [activeTemplate, category?.templates, dispatch, manager])
  
  const onClickSave = useCallback(() => {
    if (!manager) {return}
    
    history.disableHistory()
    setAvailableSaveButton(false)
    
    const { categoryId, userId, templateName } = getData()
    if (!categoryId && !category?.name) return;
    if (!userId && !info?.id) return;
    if (!templateName && !activeTemplate) return;
    
    setData({
      template: manager.getTemplate(true),
      resolution: manager.resolution.value
    })
    
    manager.saveSupabase({
      rowId: (category?.id ?? categoryId) as number,
      templateName: (activeTemplate ?? templateName) as string
    }).then(() => {
      history.saveState()
      setSavedTemplate(manager.getTemplate(true))
      setSavedResolution(manager.resolution.value)
      setAvailableSaveButton(false)
      history.activeHistory()
    })
    
    dispatch(setDataForCategory({
      categoryId: (category?.id ?? categoryId) as number,
      templateName: (activeTemplate ?? templateName) as string,
      template: manager.getTemplate(true),
      resolution: manager.resolution.value,
    }))
    
    // eslint-disable-next-line
  }, [activeTemplate, category?.name, dispatch, info?.id, manager]);
  
  const onGoPreview = useCallback(() => {
    if (!manager) {return}
    
    history.disableHistory()
    manager.canvas.discardActiveObject()
    const historyState = history.getHistory()
    const curState = manager.getTemplate(true)
    dispatch(setPreview({
      curHistoryIndex: historyState.index,
      history: historyState.history,
      template: curState,
      resolution: manager.resolution.value,
    }))
    
    navigate(Pages.TEMPLATE.PREVIEW)
  }, [dispatch, manager, navigate]);
  
  return (
    <MainBlock className={'flex-center flex-column h-100 w-100'}>
      {!isLoaded && <GridLoader style={{ position: 'absolute' }}/>}
      <div style={{ visibility: isLoaded ? 'visible' : 'hidden' }}>
        <Header>
          <div>
            <TemplateName>
              {activeTemplate ?? 'Неизвестный шаблон'}
            </TemplateName>
          </div>
          <RightBlock>
            <Button onClick={onGoPreview} color={'#FFF'}>Предосмотр</Button>
            <Button disabled={!isSaveButtonAvailable} onClick={onClickSave} color={'#F5D956'}>Сохранить</Button>
          </RightBlock>
        </Header>
        <Container>
          <Canvas/>
          <Zoom/>
        </Container>
      </div>
    </MainBlock>
  );
}

export default Content;
