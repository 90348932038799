import React from 'react';
import ChampionshipTeams from '../components/championship-teams/championship-teams';
import { Route, Routes } from 'react-router-dom';
import TeamPlayersPage from './team-players-page';

type ChampionshipTeamsPageProps = {
  championshipId: number;
};

const ChampionshipTeamsPage = ({ championshipId }: ChampionshipTeamsPageProps) => {
  return (
    <Routes>
      <Route path=":teamId" element={<TeamPlayersPage />} />
      <Route path="*" element={<ChampionshipTeams championshipId={championshipId} />} />
    </Routes>
  );
};

export default ChampionshipTeamsPage;
