import { createSlice } from '@reduxjs/toolkit';
import { Object } from "fabric/fabric-impl";
import Interactive from "../../ud-ui/components/fabric-canvas/canvases/interactive";
import { Category } from "../domain/interface/category";
import { supabaseTemplate, template } from "../../ud-ui/components/fabric-canvas/types";

export type TemplateEditor = {
  activeTemplateName?: string;
  activeCategoryId?: number
  fabricManager?: Interactive
  activeObjects: Object[]
  categories?: Category[]
  activeModal?: string
  previewState: {
    curHistoryIndex?: number
    history?: template[],
    template?: template
    resolution?: { width: number, height: number }
  }
};

const initialState: TemplateEditor = {
  activeTemplateName: undefined,
  activeObjects: [],
  activeModal: undefined,
  previewState: {
    history: [],
    template: undefined,
    curHistoryIndex: undefined,
    resolution: undefined,
  }
};

export const templateEditorSlice = createSlice({
  name: 'templateEditor',
  initialState,
  reducers: {
    setTemplate(state, action: { payload: { templateName?: string } }) {
      state.activeTemplateName = action.payload.templateName;
    },
    setFabricManager(state, action) {
      state.fabricManager = action.payload;
    },
    setActiveObjects(state, action) {
      state.activeObjects = action.payload;
    },
    setActiveCategory(state, action: { payload: number | undefined }) {
      state.activeCategoryId = action.payload;
    },
    setCategories(state, action) {
      state.categories = action.payload;
    },
    setDataForCategory(state, action: {
      payload: {
        categoryId: number,
        templateName: string,
        template: template,
        resolution: { width: number, height: number }
      }
    }) {
      if (!state) return
      if (!state.categories) return
      state.categories.forEach(category => {
        if (category.id !== action.payload.categoryId) return
        category.templates[ action.payload.templateName ] = { ...category.templates[ action.payload.templateName ], ...action.payload.template } as any
        category.resolution = action.payload.resolution
      })
    },
    setPreview(state, action: {
      payload: {
        curHistoryIndex?: number
        history?: template[],
        template?: template,
        resolution?: { width: number, height: number },
      }
    }) {
      state.previewState.template = action.payload.template as any
      state.previewState.history = action.payload.history as any
      state.previewState.curHistoryIndex = action.payload.curHistoryIndex
      state.previewState.resolution = action.payload.resolution
    },
    updateCategory(state, action: {
      payload: {
        name: string,
        templates?: { [ key: string ]: supabaseTemplate }
        resolution?: { width: number, height: number }
      }
    }) {
      if (!state) return
      if (!state.categories) return
      state.categories.forEach(category => {
        if (category.name !== action.payload.name) return
        category.templates = { ...action.payload.templates } as any
        category.resolution = action.payload.resolution as any
      })
    },
    setActiveModal(state, action: { payload: string | undefined }) {
      state.activeModal = action.payload
    }
  },
});

export const {
  setActiveObjects,
  setTemplate,
  setFabricManager,
  setActiveCategory,
  setCategories,
  setDataForCategory,
  setPreview,
  updateCategory,
  setActiveModal,
} = templateEditorSlice.actions
