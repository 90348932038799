import { createSlice } from '@reduxjs/toolkit';
import { Event } from '../domain/interfaces/events';
import { GameInfoMapped } from '../domain/interfaces/gameInfoMapped';
import { TemplatesRow } from "../../core/domain/interfaces/receivedData";

export type OverlaySliceState = {
  gameInfo?: GameInfoMapped
  category?: TemplatesRow
  score: [number, number]
  fouls: [number, number]
  period: number
  events: Event[]
  activeOverlay: number | null
  timer: {
    passedTime: number
    isActive: boolean,
    startTime: number | null
    lastChange: number | null
  },
};

const initialState: OverlaySliceState = {
  category: undefined,
  gameInfo: undefined,
  score: [0, 0],
  fouls: [0, 0],
  period: 1,
  events: [],
  activeOverlay: null,
  timer: {
    passedTime: 0,
    isActive: false,
    startTime: null,
    lastChange: null,
  },
};

export const overlaySlice = createSlice({
  name: 'overlay',
  initialState,
  reducers: {
    setGameInfo(state, action: { payload: GameInfoMapped }) {
      state.gameInfo = action.payload;
    },
    setScore(state, action: { payload: [number, number] }) {
      state.score = action.payload;
      
      if (!state.gameInfo) return
      if (!state.gameInfo.teams) return
      if (!state.gameInfo.teams.firstTeam) return
      if (!state.gameInfo.teams.secondTeam) return
      state.gameInfo.teams.firstTeam.score = action.payload[ 0 ];
      state.gameInfo.teams.secondTeam.score = action.payload[ 1 ];
    },
    setFouls(state, action: { payload: [number, number] }) {
      state.fouls = action.payload;
      
      if (!state.gameInfo) return
      if (!state.gameInfo.teams) return
      if (!state.gameInfo.teams.firstTeam) return
      if (!state.gameInfo.teams.secondTeam) return
      state.gameInfo.teams.firstTeam.fouls = action.payload[ 0 ];
      state.gameInfo.teams.secondTeam.fouls = action.payload[ 1 ];
    },
    setEvents(state, action: { payload: Event[] }) {
      state.events = action.payload;
      
      const lastEvent = action.payload[ action.payload.length - 1 ]
      if (!lastEvent) return
      if (!lastEvent.players?.length) return
      if (!lastEvent.players[ 0 ]) return
      if (!state.gameInfo) return
      if (!state.gameInfo.teams) return
      if (!state.gameInfo.teams.firstTeam) return
      if (!state.gameInfo.teams.secondTeam) return
      const allMembers = state.gameInfo.teams.firstTeam.members?.concat(state.gameInfo.teams.secondTeam.members ?? []) ?? []
      const member = allMembers.find((member) => member.id === lastEvent.players[ 0 ].id)
      const teamIndex = state.gameInfo.teams.firstTeam.id === lastEvent.teamId ? 0 : 1
      if (!member) return
      
      switch (lastEvent.type) {
        case 'GOAL':
        case 'PENALTY_GOAL':
        case 'PASS_AND_GOAL':
          member.qtyGoalsMatch += 1
          const team = state.gameInfo.teams[ teamIndex === 0 ? 'firstTeam' : 'secondTeam' ];
          if (team && team.score) {
            team.score += 1;
          }
          state.score[ teamIndex ] += 1
          break
        case'RED_CARD':
          member.qtyRedCardsMatch += 1
          break
        case 'YELLOW_CARD':
          member.qtyYellowCardsMatch += 1
          break
        case 'FOUL':
          state.fouls[ teamIndex ] += 1
          break
      }
    },
    setActiveOverlay(state, action: { payload: number | null }) {
      state.activeOverlay = action.payload;
    },
    toggleActiveOverlay(state, action: { payload: number | null }) {
      state.activeOverlay = state.activeOverlay === action.payload ? null : action.payload;
    },
    toggleTimer(state) {
      const isActive = !state.timer.isActive
      state.timer.isActive = isActive
      if (isActive) {
        state.timer.startTime = Date.now()
      } else {
        if (!state.timer.startTime) return
        state.timer.passedTime += Date.now() - state.timer.startTime
        state.timer.startTime = null
      }
    },
    setTimer(state, action: { payload: number }) {
      state.timer.startTime = Date.now()
      state.timer.passedTime = action.payload > 0 ? action.payload : 0
    },
    clearLastChange(state) {
      state.timer.lastChange = null
    },
    addTimer(state, action: { payload: number }) {
      state.timer.lastChange = (state.timer.lastChange ?? 0) + action.payload
      
      const isActive = state.timer.isActive
      if (isActive) {
        if (!state.timer.startTime) return
        const now = Date.now()
        state.timer.passedTime += now - state.timer.startTime
        state.timer.startTime = now
      }
      
      let passedTime = state.timer.passedTime + action.payload;
      state.timer.passedTime = passedTime > 0 ? passedTime : 0
    },
    setPeriod(state, action: { payload: number }) {
      state.period = action.payload
    },
    setStatsForMember(state, action: {
      payload: { id: number, stats: { qtyGoalsMatch: number, qtyYellowCardsMatch: number, qtyRedCardsMatch: number } }
    }) {
      if (!state.gameInfo) return
      const allMembers = state.gameInfo.teams?.firstTeam?.members?.concat(state.gameInfo.teams?.secondTeam?.members ?? []) ?? []
      const member = allMembers.find((member) => member.id === action.payload.id)
      if (!member) return
      member.qtyGoalsMatch = action.payload.stats.qtyGoalsMatch
      member.qtyYellowCardsMatch = action.payload.stats.qtyYellowCardsMatch
      member.qtyRedCardsMatch = action.payload.stats.qtyRedCardsMatch
    },
    setCategory(state, action: { payload: { category?: TemplatesRow } }) {
      state.category = action.payload.category as any
    },
  },
});

export const {
  setGameInfo,
  setScore,
  setEvents,
  setActiveOverlay,
  toggleActiveOverlay,
  toggleTimer,
  setTimer,
  addTimer,
  setFouls,
  setPeriod,
  clearLastChange,
  setStatsForMember,
  setCategory,
} = overlaySlice.actions
