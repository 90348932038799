import React from 'react';
import { iconsMap } from './map';

export type UDIconProps = {
  name: keyof typeof iconsMap;
  componentProps?: React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  >;
};

const UDIcon = (props: UDIconProps) => {
  return <img src={iconsMap[props.name]} {...props.componentProps} />;
};

export default UDIcon;
