import { ITokenStorage } from 'modules/core/domain/interfaces/ITokenStorage';
import { TokenStorage } from 'modules/core/tokenStorage';
import { AuthRequestAuthCodePayload } from '../interfaces/AuthRequestAuthCodePayload';
import { AuthSignInPayload } from '../interfaces/AuthSignInPayload';
import { AuthCheckResponse, AuthSignInResponse } from '../interfaces/AuthSignInResponse';
import { AuthSignUpPayload } from '../interfaces/AuthSignUpPayload';
import authResource from '../resources/authResource';
import { AuthSignUpResponse } from '../interfaces/AuthSignUpResponse';

class AuthService {
  constructor(private readonly tokenStorage: ITokenStorage) {}

  public async check(): Promise<AuthCheckResponse | null> {
    const previousToken = this.getToken();
    if (!previousToken) {
      return null;
    }

    this.saveToken(previousToken);

    try {
      const [user, availableTournaments] = await Promise.all([
        authResource.check(),
        authResource.getAvailableTournaments()
      ]);

      return {
        user,
        availableTournaments,
      };
    } catch (error) {
      console.error('Check auth error:', error);
      return null;
    }
  }

  public async requestAuthCode(data: AuthRequestAuthCodePayload): Promise<void> {
    await authResource.requestAuthCode(data);
  }

  public async signIn(data: AuthSignInPayload): Promise<AuthSignInResponse['user']> {
    const response = await authResource.signIn(data);
    this.saveToken(response.token);
    return response.user;
  }

  public signOut() {
    this.removeToken();
    return null;
  }

  public async signUp(data: AuthSignUpPayload): Promise<AuthSignUpResponse> {
    return await authResource.signUp(data);
  }

  private saveToken(token: string): void {
    this.tokenStorage.saveToken(token);

    authResource.resource.setHeaders({
      Authorization: `Bearer ${token}`,
    });
  }

  private getToken() {
    return this.tokenStorage.getToken();
  }

  private removeToken() {
    this.tokenStorage.removeToken();
  }
}

const authService = new AuthService(new TokenStorage());
export default authService;
