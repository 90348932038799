import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

export const playersCommonStoreSelector = (state: RootState) => state.players;

export const playersIsGettingPlayersSelector = createSelector(
  playersCommonStoreSelector,
  (state) => state.isGettingPlayers
);
export const playersIsLoadingSelector = createSelector(
  playersCommonStoreSelector,
  (state) => state.isLoading
);
export const playersInfoSelector = createSelector(
  playersCommonStoreSelector,
  (state) => state.info
);
