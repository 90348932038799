import UDFormInput from '../../../../ud-form/components/input';
import { Formik } from 'formik';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createTeam } from '../../../../organizer/store/actions';
import UDButton from '../../../../ud-ui/components/button';
import { useNavigate } from 'react-router-dom';
import { Pages } from '../../../../navigation/domain/enums/pages';
import { organizerIsTeamCreatingSelector } from '../../../../organizer/store/selectors';
import { AppDispatch } from '../../../../../store/store';
import UDFormDropZone from 'modules/ud-form/components/dropzone/index';
import { tourneysCommonStoreImagesSelector } from 'modules/tourneys/store/common/selectors';

type CreateTeamFormProps = {
  tournamentId: number;
};

const CreateTeamForm = ({ tournamentId }: CreateTeamFormProps) => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const isLoading = useSelector(organizerIsTeamCreatingSelector);

  const handleCancelClick = useCallback(() => {
    navigate(Pages.TEAMS.INDEX, { replace: true });
  }, [navigate]);

  const handleSubmit = useCallback(
    (values) => {
      dispatch(createTeam(values)).then(() => {
        handleCancelClick();
      });
    },
    [dispatch, handleCancelClick]
  );

  const initialValues = {
    tournamentId,
    name: '',
  };
  const images = useSelector(tourneysCommonStoreImagesSelector);

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {(formProps) => (
        <form
          className="d-flex flex-column justify-content-between h-100"
          onSubmit={formProps.handleSubmit}
        >
          <input
            type="hidden"
            name="tournamentId"
            value={formProps.initialValues.tournamentId}
          />

          <div className="row align-items-start gx-4">
            <UDFormInput
              name="name"
              label="Название команды"
              placeholder="Введите название"
              containerProps={{ className: 'col-6' }}
            />

            <UDFormDropZone
              name="logo"
              label="Логотип команды"
              value={images}
              isCover={true}
              containerProps={{ className: 'col-6' }}
              formProps={{ className: 'bgc-SurfaceWhite br-8 p-6' }}
            />
          </div>

          <div className="d-flex justify-content-end mb-2">
            <UDButton
              type="button"
              variant="secondary"
              disabled={isLoading}
              onClick={handleCancelClick}
              className="mr-4"
            >
              Отменить
            </UDButton>

            <UDButton
              type="submit"
              variant="primary"
              disabled={isLoading}
              loading={isLoading}
            >
              Сохранить
            </UDButton>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default CreateTeamForm;
