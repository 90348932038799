import styled from '@emotion/styled';
import defaultLogo from './default-team-logo.png';

type TeamLogoContainerProps = {
  size?: number;
};


export const TeamLogoContainer = styled.div`
  width: ${(props: TeamLogoContainerProps) => props.size ?? 40}px;
  height: ${(props: TeamLogoContainerProps) => props.size ?? 40}px;

  display: flex;
  position: relative;
  border: 1px solid #EAEAEC;
  border-radius: 8px;
  justify-content: center;
  padding: 4px;
`;

type TeamLogoProps = {
  logoUrl?: string;
};

export const TeamLogo = styled.div`
  background-image: ${(props: TeamLogoProps) => `url('${props.logoUrl || defaultLogo}')`};
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
`;
