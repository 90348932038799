import { SupabaseClient } from "@supabase/supabase-js";

export default class OrganizerLogoBacking {
  constructor(private supabase: SupabaseClient) {}
  
  async get({ tournamentId }: { tournamentId: number }) {
    const { data, error } = await this.supabase
      .from("organizer_logo_backing")
      .select()
      .eq("tournamentId", tournamentId)
      .single();
    
    if (error) {
      console.error(error)
      return null
    }
    
    return data;
  }
  
  async update({ tournamentId, type }: { tournamentId: number, type: number }) {
    const { error } = await this.supabase
      .from("organizer_logo_backing")
      .upsert({ tournamentId, type }, { onConflict: 'tournamentId' })
    
    if (error) {
      console.error(error)
      return false
    }
    
    return true
  }
}
