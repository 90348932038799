import { css } from "@emotion/react";

export const modalInputStyle = css`
  caret-color: #D9B244;
  background-color: #F2F2F2;
  border: 1px solid transparent;
  max-height: 36px !important;
`

export const modalButton = css`
  width: 130px;
  min-width: 130px;
  height: 100%;
  padding: 8px 16px 8px 16px;
  gap: 10px;
  border-radius: 8px;
  
  font-family: SF Pro Display, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  
  color: #1A1A29;
  background-color: #F5D956;
  
  &:disabled {
    background-color: #E2E3E4;
    color: #686A79
  }
`

export const modalText = css`
  font-family: SF Pro Display, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #000000
`

export const modalError = css`
  font-family: SF Pro Display, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #FF6E6E;
`
