import React from 'react';

type IProps = {
  width?: number;
  height?: number;
  color?: string;
};

const NewTrashIcon = ({ width, height, color = 'white' }: IProps) => {
  return (
    <svg width={width ?? 24} height={height ?? 24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.1111 7.2V6.56C15.1111 5.66392 15.1111 5.21587 14.9416 4.87362C14.7924 4.57256 14.5545 4.32779 14.2618 4.17439C13.929 4 13.4934 4 12.6222 4H11.3778C10.5066 4 10.071 4 9.73824 4.17439C9.44554 4.32779 9.20757 4.57256 9.05843 4.87362C8.88889 5.21587 8.88889 5.66392 8.88889 6.56V7.2M10.4444 11.6V15.6M13.5556 11.6V15.6M5 7.2H19M17.4444 7.2V16.16C17.4444 17.5041 17.4444 18.1762 17.1901 18.6896C16.9664 19.1412 16.6095 19.5083 16.1704 19.7384C15.6713 20 15.0179 20 13.7111 20H10.2889C8.9821 20 8.32871 20 7.82958 19.7384C7.39053 19.5083 7.03358 19.1412 6.80987 18.6896C6.55556 18.1762 6.55556 17.5041 6.55556 16.16V7.2"
        stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  
  );
};

export default NewTrashIcon;
