import { useCallback, useMemo } from "react";
import { useOrganizer } from "../../../../../organizer/ui/hooks/useOrganizer";
import { useDispatch, useSelector } from "react-redux";
import { selectActiveCategoryId, selectCategories } from "../../../../store/selectors";
import supabase from "../../../../../core/supabase/supabase";
import { setActiveCategory, setCategories as setCategoriesToStore } from "../../../../store/templateEditor";
import useUniversalModal, { IUNModalProps } from "../useUniversalModal";

function useDeleteModal() {
  const dispatch = useDispatch()
  const { info } = useOrganizer()
  const categoryId = useSelector(selectActiveCategoryId)
  const categories = useSelector(selectCategories)
  
  const onSubmit = useCallback(() => {
    if (!info) return
    if (!categoryId) return
    supabase.overlayTemplates.deleteRow({ rowId: categoryId });
    dispatch(setCategoriesToStore(categories?.filter(category => category.id !== categoryId)));
    dispatch(setActiveCategory(undefined));
  }, [categories, categoryId, dispatch, info]);
  
  const data: IUNModalProps = useMemo(() => {
    return {
      validator: () => ({}),
      initialValue: {},
      headerText: 'Удаление шаблона',
      contentText: 'Вы уверены, что хотите удалить этот шаблон? Это действие нельзя отменить.',
      buttonText: 'Удалить',
      onSubmit,
      onClose: () => dispatch(setActiveCategory(undefined)),
    }
  }, [dispatch, onSubmit]);
  
  const { modal, close, open } = useUniversalModal(data)
  
  return { modal, close, open }
}

export default useDeleteModal;
