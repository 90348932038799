/** @jsxImportSource @emotion/react */
import React from 'react';
import { useTranslation } from "react-i18next";
import HeaderItem from "../header/elements/headerItem";
import { copyIconCss, CustomField, CustomTextarea, disableColor, tooltipCss } from "./styles";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectOrganizerById } from "../../../store/selectors";
import { Organizer } from "../../../domain/interfaces/Organizer";
import { history } from "../../../../../store/store";
import UDText from "../../../../ud-ui/components/text";
import CopyIcon from "../../../../ud-ui/components/icon/copy-icon";
import Tooltip from "../../../../ud-form/components/tooltip";
import { Pages } from "../../../../navigation/domain/enums/pages";
import PlansBlock from "./components/PlansBlock";
import { toast } from "react-toastify";

function OrganizerInfo() {
  const { id } = useParams();
  const organizer = useSelector(selectOrganizerById((+(id ?? 0)))) as Organizer;
  if (!organizer) history.push(Pages.ORGANIZERS.INDEX);
  const { t } = useTranslation();
  
  const onClickCopy = (text: string) => {
    navigator.clipboard.writeText(text).then(_ => {
      toast.success('Сохранено в буфер обмена!');
    });
  }
  
  return (
    <div className="row g-2">
      <div className="col-6">
        <HeaderItem
          header={{ text: "Название организатора", type: 'body' }}
          className={'mb-4'}
        >
          <CustomField>
            <UDText type={'subhead'}>{organizer?.name}</UDText>
            {organizer?.name && (
              <Tooltip css={tooltipCss} text={'Скопировать'}>
                <CopyIcon onClick={()=>onClickCopy(organizer?.name ?? '')} css={copyIconCss}/>
              </Tooltip>
            )}
          </CustomField>
        </HeaderItem>
        <HeaderItem
          header={{ text: "Тип спорта", type: 'body' }}
          className={'mb-4'}
        >
          <CustomField>
            <UDText type={'subhead'}>{t(`sportType.${organizer?.sportType}`)}</UDText>
          </CustomField>
        </HeaderItem>
        <HeaderItem
          header={{ text: 'Email', type: 'body' }}
          className={'mb-4'}
        >
          <CustomField>
            <UDText type={'subhead'}>{organizer?.email}</UDText>
            {organizer?.email && (
              <Tooltip css={tooltipCss} text={'Скопировать'}>
                <CopyIcon onClick={()=>onClickCopy(organizer?.email ?? '')} css={copyIconCss}/>
              </Tooltip>
            )}
          </CustomField>
        </HeaderItem>
        <HeaderItem
          header={{ text: "Телефон", type: 'body' }}
          className={'mb-4'}
        >
          <CustomField>
            <UDText type={'subhead'}>{organizer?.phone}</UDText>
            {organizer?.phone && (
              <Tooltip css={tooltipCss} text={'Скопировать'}>
                <CopyIcon onClick={()=>onClickCopy(organizer?.phone ?? '')} css={copyIconCss}/>
              </Tooltip>
            )}
          </CustomField>
        </HeaderItem>
        <HeaderItem
          header={{ text: "Город", type: 'body' }}
          className={'mb-4'}
        >
          <CustomField>
            <UDText type={'subhead'}>{organizer?.address.city}</UDText>
          </CustomField>
        </HeaderItem>
        <HeaderItem
          header={{ text: "Описание", type: 'body' }}
          className={'mb-4'}
        >
          <CustomTextarea
            css={disableColor}
            readOnly
            rows={4}
            value={organizer?.description}
          />
        </HeaderItem>
      </div>
      <div className="col-6">
        <HeaderItem header={{ text: 'Информация о подписке организатора', type: 'body' }}>
          <PlansBlock/>
        </HeaderItem>
      </div>
    </div>
  );
}

export default OrganizerInfo;
