import React from 'react';

interface IProps {
  width?: number;
  height?: number;
  color?: string;
}

export default function ArrowLeftIcon(props: IProps) {
  const {
    width,
    height,
    color = '#000',
    ...rest
  } = props;
  
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width ?? 20} height={height ?? 20} viewBox="0 0 20 20" fill="none" {...rest}>
      <path d="M8.33333 15.8337L2.5 10.0003M2.5 10.0003L8.33333 4.16699M2.5 10.0003L17.5 10.0003" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}
