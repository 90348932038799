import styled from "@emotion/styled";
import _ from "lodash";
import { css, Theme } from "@emotion/react";

export const MainBlock = styled.div`
  display: flex;
  flex-direction: column;
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  
  margin-bottom: 12px;
`

export const IconWrapper = styled.div`
  color: ${({ theme }) => theme.colors.Black24};
  transition: color 0.2s ease-in-out;
  cursor: pointer;
  
  &:hover {
    color: ${({ theme }) => theme.colors.AccentNapplesYellow};
  }
`

export const InputCss = (theme: Theme) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  
  font: ${_(theme.fonts[ "caption-1" ]).values().value().join(' ')};
  
  padding: 0;
  
  width: 66px !important;
  height: 36px;
  border-radius: 8px;
  
  background: ${theme.colors.GrayF2};
`

export const Inputs = styled.div`
  display: flex;
  flex-direction: row;
  gap: 33px;
  
  text-align: center;
`
