import React from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store/store';
import { authSignOut } from 'modules/auth/store/actions';
import UDMenuLogout from './components/logout';
import UDMenuMainNavigation from './components/main-navigation';
import UDMenuSystemNavigation from './components/system-navigation';
import * as S from './styles';
import { Organizer } from 'modules/organizer/domain/interfaces/Organizer';
import { resetOrganizer } from '../../../organizer/store/actions';

type UDMenuProps = {
  isOrganizerLoading: boolean;
  organizerInfo: Organizer | null;
};

const UDMenu = (props: UDMenuProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const onLogoutClicked = () => {
    dispatch(authSignOut());
    dispatch(resetOrganizer());
  };

  return (
    <S.MenuContainer className="d-flex flex-column pb-10">
      <S.MenuLogo className="ml-10 mb-5 d-flex align-items-center" />
      <UDMenuMainNavigation
        isOrganizerLoading={props.isOrganizerLoading}
        organizerInfo={props.organizerInfo}
      />
      <UDMenuSystemNavigation className="mt-auto mb-13" />
      <div className="mt-auto mb-13"></div>
      <UDMenuLogout
        isOrganizerLoading={props.isOrganizerLoading}
        organizerInfo={props.organizerInfo}
        onLogoutClick={onLogoutClicked}
      />
    </S.MenuContainer>
  );
};

export default UDMenu;
