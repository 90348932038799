import styled from "@emotion/styled";

export type ActiveProps = {
  active: boolean
}

export type TextProps = {
  size: 'small' | 'medium'
}


export const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;

  height: 100%;
`

export const BaseText = styled.span<TextProps>`
  color: #1A1A29;
  font-family: SF Pro Text, sans-serif;
  font-weight: ${props => (props.size === 'small' ? '400' : '600')};
  font-size: ${props => (props.size === 'small' ? '15px' : '17px')};

  display: flex;
  align-items: center;
  height: 22px;
`

export const TemplatesList = styled.div`
  background: white;
  border-radius: 0 0 8px 8px;

  display: flex;
  flex-direction: column;
  min-width: 260px;

  font-size: 15px;
  color: #1A1A29;

  padding: 8px 0;
  
  max-height: 400px;
  overflow-y: auto;
  
  &::-webkit-scrollbar {
    width: 4px;
    background-color: #fcfcfc;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: #2a2929;
    border-radius: 8px;
  }
`

export const HeaderTemplate = styled(BaseText)`
  background: #EAEAEC;
  height: 38px;
  border-radius: 8px 8px 0 0;
  padding-left: 16px;
`

export const Item = styled(BaseText)<ActiveProps>`
  border-radius: 8px;
  background: ${props => (props.active && '#9093AA')};
  color: ${props => (props.active && 'white')};
  height: ${props => (props.active && 'unset')};
  padding: ${props => (props.active ? '8px 10px 8px 16px' : '0 10px 0 16px')};
  line-height: 22px;
  cursor: pointer;
  margin: ${props => (props.active ? 'unset' : '8px 0')};
`

export const RightPanel = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  gap: 8px;
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  background: white;
  border-radius: 8px;
  width: 100%;

  padding: 8px 16px;
`

export const BarItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const Tools = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
`

export const Divider = styled.div`
  border-top: 1px solid #EAEAEC
`

export const Button = styled.button`
  border: none;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
`

export const CanvasWrapper = styled.div`
  height: 100%;
  width: 100%;
  background: white;
  border-radius: 8px;
  flex-grow: 1;
  aspect-ratio: 16 / 9;
  max-height: 450px;

  display: flex;
  align-items: center;
  justify-content: center;
`
