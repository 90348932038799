import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import UDSelect from 'modules/ud-form/components/select/component';
import debounce from 'modules/utils/debounce';
import UDInput from 'modules/ud-form/components/input/component';
import UDText from 'modules/ud-ui/components/text';

type Props = {
  className?: string;
  filters: any;
  years: number[];
  onFilter: (changes: any) => any;
};

const MainComponentsTourneysFilters = (props: Props) => {
  const { filters, years, onFilter, className } = props;
  const [year, setYear] = useState(filters.year);
  const [resultsOnPage, setResultsOnPage] = useState(filters.per_page);

  const yearsOptions = useMemo(() => {
    const options: { label: string; value: number | null }[] = [{
      label: 'За все время',
      value: null,
    }];

    years.forEach((year) => {
      options.push({
        label: `${year}`,
        value: year,
      });
    });

    return options;
  }, [years]);

  const defaultValue = useMemo(() => {
    return yearsOptions.find((item) => item.value === year)
  }, [year, yearsOptions]);

  const handleChangeSearch = useMemo(() => debounce(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onFilter({
        ...filters,
        search: e.target.value,
        page: 1,
      });
    }, 300),
    [filters, onFilter],
  );

  const handleChangeYear = useCallback((option) => {
      onFilter({
        ...filters,
        year: option.value,
        page: 1,
      });

      setYear(option.value);
    },
    [filters, onFilter],
  );

  const handleChangeResultsOnPage = useMemo(() => (e: React.ChangeEvent<HTMLInputElement>) => {
      debounce(() => {
        onFilter({
          ...filters,
          per_page: +e.target.value,
        });
      }, 300)();

      setResultsOnPage(+e.target.value);
    },
    [filters, onFilter],
  );

  return (
    <div className={classNames('container py-6', className)}>
      <div className="row gx-2">
        <div className="col-9">
          <div className="row gx-2 flex-center">
            <div className="col-4">
              <UDSelect
                options={yearsOptions}
                value={defaultValue}
                onChange={handleChangeYear}
              />
            </div>
            <div className="col-8">
              <UDInput
                defaultValue={filters.search}
                placeholder="Поиск по турнирам"
                onChange={handleChangeSearch}
                iconProps={{
                  position: 'icon-left',
                  name: 'search',
                  size: 20,
                  componentProps: { className: 'color-SurfaceRomanSilver30' },
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-3 d-flex align-items-center">
          <UDText
            type="subhead"
            className="color-SurfaceRomanSilver30 mr-2 ml-auto white-space-nowrap">
            На странице:
          </UDText>
          <UDInput
            type="number"
            wrapperProps={{ className: 'w-30' }}
            min={0}
            value={resultsOnPage}
            onChange={handleChangeResultsOnPage}
          />
        </div>
      </div>
    </div>
  );
};

export default MainComponentsTourneysFilters;
