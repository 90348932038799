import { batch } from 'react-redux';
import { createAction, createAsyncThunk, Update } from '@reduxjs/toolkit';
import debounce from 'modules/utils/debounce';
import { generateUUID } from 'modules/utils/uuid';
import { TourneyImageEntity } from 'modules/tourneys/ui/components/tourneys-images-upload';
import { TourneyFile } from '../../domain/interfaces/TourneyFile';

const PREFIX = 'tourneys/common';

export const addUploadImage = createAction<TourneyImageEntity>(
  `${PREFIX}/addUploadImage`,
);
export const addUploadImages = createAction<TourneyImageEntity[]>(
  `${PREFIX}/addUploadImages`,
);
export const deleteUploadImage = createAction<number | string>(
  `${PREFIX}/deleteUploadImage`,
);
export const updateImageProgress = createAction<Update<TourneyImageEntity>[]>(
  `${PREFIX}/updateImageProgress`,
);
export const updateImage = createAction<Update<TourneyImageEntity>>(
  `${PREFIX}/updateImage`,
);

export const uploadImage = createAsyncThunk<
  void,
  { id: string | number; file: File }
>(`${PREFIX}/uploadFile`, async ({ id, file }, { dispatch, signal }) => {
  const onProgress = debounce((progress: number) => {
    dispatch(
      updateImage({
        id,
        changes: { meta: { progress } },
      }),
    );
  }, 500);
  const createdFile = await uploadSimulate(file, onProgress, signal);
  // dispatch(
  //   updateImage({
  //     id,
  //     changes: {
  //       id: createdFile.id,
  //       preview: createdFile.link,
  //       name: createdFile.name,
  //       remove: () => {
  //         dispatch(deleteUploadImage(createdFile.id));
  //       },
  //     },
  //   }),
  // );
});

export const uploadImages = createAsyncThunk<any, File[]>(
  `${PREFIX}/uploadFiles`,
  async (files, { dispatch }) => {
    const file = files.shift();
    if (file) {
      batch(() => {
        const id = generateUUID();
        const fileUpload = dispatch(uploadImage({ id, file }));
        const fileEntity = {
          id,
          name: file.name,
          preview: URL.createObjectURL(file),
          size: file.size,
          meta: {
            progress: 0,
          },
          remove: () => {
            fileUpload.abort();
            dispatch(deleteUploadImage(id));
          },
        };
        // dispatch(addUploadImage(fileEntity));
        dispatch(uploadImages(files));
      });
    }
  },
);

const uploadSimulate = async (
  file: File,
  onProgress: (progress: number) => any,
  signal: AbortSignal,
): Promise<TourneyFile> => {
  return new Promise((res, rej) => {
    let progress = 0;
    const interval = setInterval(() => {
      if (progress > 100 || signal.aborted) {
        clearInterval(interval);
        if (signal.aborted) {
          return rej('aborted');
        }
        const id = generateUUID();
        return res({
          id,
          link: 'https://images.pexels.com/photos/6826970/pexels-photo-6826970.jpeg?cs=srgb&dl=pexels-jan-kop%C5%99iva-6826970.jpg&fm=jpg',
          name: id,
        });
      }
      onProgress(progress);
      progress = progress + 5;
    }, 1000);
  });
};
