import React from 'react';
import { GroupBase } from 'react-select';
import AsyncSelect, { AsyncProps } from 'react-select/async';
import { IDadataArea } from '../../domain';
import { createDadataRepository } from '../../infra/repositories/dadata-repository.factory';

const dadataRepository = createDadataRepository();
const loadingOptions = async (inputValue: string): Promise<DadataSelectOption[]> => {
  const areas = await dadataRepository.getSuggestedAreas(inputValue);
  return areas.map((area) => ({
    label: area.value,
    value: area,
  }));
};

export type DadataSelectOption = {
  label: string | number | JSX.Element;
  value: IDadataArea | null | undefined;
};

export type DadataAutocompleteProps = Omit<AsyncProps<DadataSelectOption, boolean, GroupBase<DadataSelectOption>>, 'loadOptions'>;

export const DadataAutocomplete = (props: DadataAutocompleteProps) => {
  return (
    <AsyncSelect
      {...props}
      loadOptions={loadingOptions}
    />
  );
};
