import React from 'react';
import { Props as SelectProps, GroupBase,  } from 'react-select';
import { useTranslation } from 'react-i18next';
import { PlayerPosition } from '../../domain/enums/PlayerPosition';
import { SelectOption } from '../../../ud-form/components/select/component';
import BorderedSelect from './bordered-select';

type PositionSelectProps<
  IsMulti extends boolean = boolean,
  Group extends GroupBase<SelectOption> = GroupBase<SelectOption>
> = Omit<
  SelectProps<SelectOption, IsMulti, Group>,
  'hideSelectedOptions' | 'isSearchable' | 'styles' | 'value'
> & {
  value?: string;
};

type ReturningPositionSelect = <
  isMulti extends boolean = false,
  Group extends GroupBase<SelectOption> = GroupBase<SelectOption>
>(
  props: PositionSelectProps<isMulti, Group>,
) => JSX.Element;

const PositionSelect: ReturningPositionSelect = (props) => {
  const { t } = useTranslation();
  const {
    value,
    ...restProps
  } = props;

  const positionOptions = Object
    .values(PlayerPosition)
    .map((position) => ({
      value: position,
      label: t(`player.position.${position}`),
    }));

  const selectedOption = value
    ? positionOptions.find((item) => item.value === value)
    : undefined

  return (
    <BorderedSelect
      {...restProps}
      options={positionOptions}
      value={selectedOption}
    />
  );
};

export default PositionSelect;
