import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { GameDetails, GameDetailsScore } from '../domain/interfaces/GameDetails';
import { GameEventDTO } from '../domain/interfaces/GameEventDTO';
import gameRepository from '../domain/repositories/game-repository';
import { RootState } from '../../../store/store';
import { GamePeriodsSettings, getPeriodsSettings } from '../domain/mappers/match-periods-settings';
import { ChampionshipSettings } from '../../tourneys/domain/interfaces/ChampionshipSettings';

const PREFIX = 'GAMES';

export const fetchGameDetails = createAsyncThunk<GameDetails | null, { gameId: number }>(
  `${PREFIX}/fetchDetails`,
  async (payload, { getState }) => {
    try {
      const state = getState() as RootState;
      const settings = state.tourneys.championship.data?.settings || {} as ChampionshipSettings;
      const periodsSettings = getPeriodsSettings(settings);

      return await gameRepository.fetchGameDetails(payload.gameId, periodsSettings);
    } catch (err) {
      console.error(`${PREFIX}/fetchDetails error:`, err);
      throw err;
    }
  },
);

export type ModifyGameEventData = {
  data: GameEventDTO,
  periodSettings: GamePeriodsSettings
};

export const addGameEvent = createAction<ModifyGameEventData>(`${PREFIX}/addGameEvent`);
export const editGameEvent = createAction<ModifyGameEventData>(`${PREFIX}/editGameEvent`);
export const deleteGameEvent = createAction<number>(`${PREFIX}/deleteGameEvent`);
export const editGameScore = createAction<GameDetailsScore>(`${PREFIX}/editGameScore`);

export const changeGameDetailsVideoUrl = createAction<string>(`${PREFIX}/changeGameDetailsVideoUrl`);

export const saveGameDetails = createAsyncThunk<GameDetails, GameDetails>(
  `${PREFIX}/saveGameDetails`,
  async (payload, { getState }) => {
    try {
      const state = getState() as RootState;
      const settings = state.tourneys.championship.data?.settings || {} as ChampionshipSettings;
      const periodsSettings = getPeriodsSettings(settings);

      const eventsPlayerIds = payload.events
        .reduce<number[]>((results, event) => ([
          ...results,
          ...event.playerIds,
        ]), [])
        .filter(Boolean);

      const oldParticipantIds = payload.participants;
      const newParticipantIds = Array.from(new Set([...oldParticipantIds, ...eventsPlayerIds]));
      const participantsChanged = newParticipantIds.some((id) => !oldParticipantIds.includes(id));

      if (participantsChanged) {
        await gameRepository.updateMatchParticipants(payload.id, newParticipantIds);
      }

      return await gameRepository.updateGameDetails(payload, periodsSettings);
    } catch (err) {
      console.error(`${PREFIX}/saveGameDetails error:`, err);
      throw err;
    }
  },
);
