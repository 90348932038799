import styled from '@emotion/styled';
import done from 'modules/ud-ui/components/icon/icons/done.svg';

export const CustomCheckbox = styled.div`
  position: relative;

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: inherit;
    height: inherit;
  }
`;

export const CheckboxLabel = styled.label`
  cursor: pointer;
  display: block;
  &:focus > div {
    border: 1px solid ${({ theme }) => theme.colors.AccentNapplesYellow};
  }
  &:hover > div {
    background-color: ${({ theme }) => theme.colors.AccentCornsilk};
    border: 1px solid ${({ theme }) => theme.colors.AccentNapplesYellow};
  }
`;

export const CheckboxInput = styled.input`
  & + div {
    display: block;
    width: 24px;
    height: 24px;
    background-color: ${({ theme }) => theme.colors.SurfacePlatinum20};
    border-radius: 4px;
  }
  &:focus + div {
    border: 1px solid ${({ theme }) => theme.colors.AccentNapplesYellow};
  }
  &:checked + div {
    background-color: ${({ theme }) => theme.colors.AccentNapplesYellow};
    &:hover {
      border: none;
    }
    &:after {
      background-image: url(${done});
      background-repeat: no-repeat;
      background-position: center;
      background-size: 14px;
    }
  }
  &:disabled + div {
    background-color: ${({ theme }) => theme.colors.SurfaceCultured10};
  }
`;
