import graphqlResource from '../../../core/graphqlResource';

export interface FeatureDTO {
  data: {
    features: {
      feature: string;
      isEnabled: boolean;
    }[];
  }
}

async function getAvailableFeature(tournamentId: number) {
  const query = `
      query getMyFeatures($id: Int!) {
        features(tournamentId: $id) {
          feature
          isEnabled
        }
      }
    `;
  
  const response = await graphqlResource.query<FeatureDTO>(query, {
    id: tournamentId,
  });
  
  const mappedFeature = {
    MEDIA_MANAGER: false,
    ACTIVE_CHAMPIONSHIPS: false,
    OBS_INTEGRATION: false,
    JOYSTICK: false,
  };
  
  response.data.data.features.forEach((loopFeature) => {
    const feature = loopFeature.feature as keyof typeof mappedFeature;
    mappedFeature[feature] = loopFeature.isEnabled;
  });
  
  return mappedFeature;
}

export default getAvailableFeature;
