import graphqlResource from '../../../core/graphqlResource';
import { ChampionshipStats } from '../interfaces/championshipStats';

export interface ChampionshipStatsDTO {
  data: {
    championshipStatsByPlayer: {
      lines: ChampionshipStats[]
    }
  }
}

async function getChampionshipStats(championshipId: number) {
  const query = `
      query getChampionshipStats($id: Int!) {
        championshipStatsByPlayer(championshipId: $id) {
          lines {
            user { id, name, surname }
            games
            goals
            yellowcards
            redcards
            assists
          }
        }
      }
    `;
  
  const response = await graphqlResource.query<ChampionshipStatsDTO>(query, {
    id: championshipId,
  });
  
  return response.data.data.championshipStatsByPlayer.lines;
}

export default getChampionshipStats;
