import React, { useRef } from 'react';
import classNames from 'classnames';
import { InputWrapper, TextareaInput } from './styles';
import { UDTextareaInputProps } from './types';

type Props = UDTextareaInputProps;

const UDTextareaInput = (props: Props) => {
  const ref = useRef<HTMLTextAreaElement>(null);
  const { hasError, valid, className, wrapperProps, ...othetProps } = props;
  const inputClassName = classNames('input', 'text subhead', props.className, {
    '--error': props.hasError,
  });

  return (
    <InputWrapper
      {...wrapperProps}
      className={classNames(wrapperProps?.className, {
        error: hasError,
        valid,
      })}>
      <TextareaInput {...othetProps} ref={ref} className={inputClassName} />
    </InputWrapper>
  );
};

export default UDTextareaInput;
