import React from 'react';
import TourneysComponentsFormRulesPartWin from './win';
import TourneysComponentsFormRulesPartLose from './lose';
import TourneysComponentsFormRulesPartDraw from './draw';
import TourneysComponentsFormRulesPartGame from './game';
import TourneysComponentsFormRulesPartRanking from './ranking';

const Regulations = () => {
  return (
    <div className="row mt-5 g-2">
      <div className="col-5">
        <div className="b-1 br-8 p-4 h-100">
          <TourneysComponentsFormRulesPartWin />
        </div>
      </div>
      <div className="col-5">
        <div className="b-1 br-8 p-4 h-100">
          <TourneysComponentsFormRulesPartLose />
        </div>
      </div>
      <div className="col-2">
        <div className="b-1 br-8 p-4 h-100">
          <TourneysComponentsFormRulesPartDraw />
        </div>
      </div>
      <div className="col-10">
        <div className="b-1 br-8 p-4 h-100">
          <TourneysComponentsFormRulesPartGame />
        </div>
      </div>
      <div className="col-7">
        <div className="b-1 br-8 p-4 h-100">
          <TourneysComponentsFormRulesPartRanking />
        </div>
      </div>
    </div>
  )
}

export default Regulations;
