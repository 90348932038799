import React from 'react';
import classNames from 'classnames';
import UDText from 'modules/ud-ui/components/text';
import { Label } from './styles';

export type UDFormLabelProps = { error?: string } & React.DetailedHTMLProps<
  React.LabelHTMLAttributes<HTMLLabelElement>,
  HTMLLabelElement
>;

const UDFormLabel = (props: UDFormLabelProps) => {
  const { children, error, className, ...otherProps } = props;
  return (
    <Label {...otherProps} className={classNames('text body', className)}>
      {children}
      {error && (
        <UDText type="subhead" className="color-CaseError">
          {error}
        </UDText>
      )}
    </Label>
  );
};

export default UDFormLabel;
