import { TemplatesRow } from "../interfaces/receivedData";
import { Category } from "../../../template-editor/domain/interface/category";

export const categoryDataMapper = (data: TemplatesRow[] | undefined): Category[] => {
  if (!data) return []
  const userNull: Category[] = []
  const userNotNull: Category[] = []
  
  for (const row of data) {
    if (row.userId === null) {
      if (row.category !== 'ImGame (стандарт)') continue
      userNull.push({ ...row, name: row.category })
    } else {
      userNotNull.push({ ...row, name: row.category })
    }
  }
  
  return userNull.concat(
    userNotNull.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime())
  )
}
