import { Organizer } from "../interfaces/Organizer";
import _ from "lodash";
import { onlyNumber } from "./regx/onlyNumber";

export function filteringByPhone(organizers: Organizer[] | undefined, filter: string | undefined) {
  if (!filter) return organizers
  
  return _.reduce(organizers, (acc, organizer) => {
    const organizerPhone = onlyNumber(organizer?.phone)
    const filterPhone = onlyNumber(filter)
    
    if (organizerPhone.includes(filterPhone)) {
      acc.push(organizer)
      return acc
    }
    
    return acc
  }, [] as Organizer[]);
}
