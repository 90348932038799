import React from 'react';
import styled from "@emotion/styled";

interface IGoalProps {
  data?: {
    teamLogo: string
    leagueLogo: string
    score: number
    minute: number
    lastName: string
  }
}

function Goal(props: IGoalProps) {
  const { score, minute, lastName, leagueLogo, teamLogo } = props.data ?? {}

  return (
    <MainElem className={'d-flex'}>
      <div className={'d-flex flex-column'} style={{ gap: 1 }}>
        <div className={'d-flex flex-row'}>
          <div className={'d-flex flex-row'}>
            <LeagueLogo className={'flex-center'}>
              <img src={leagueLogo} style={{ width: '100%', padding: 4 }} alt={'LeagueLogo'}/>
            </LeagueLogo>
            <Strip width={2}/>
          </div>
          <TeamLogo className={'flex-center'}>
            <img src={teamLogo} alt={'TeamLogo'} style={{ width: '100%', padding: 4 }}/>
          </TeamLogo>
          <Score className={'flex-center'}>
            <span>{score}</span>
          </Score>
        </div>
        <Time className={'px-3'}>мин. {minute}`</Time>
      </div>
      <LastName className={'d-flex align-items-center'}>
        {lastName}
      </LastName>
    </MainElem>
  );
}

export default Goal;

const MainElem = styled.div`
  width: 392px;
  height: 82px;

  position: absolute;
  bottom: 60px;
  left: 30px;
`

const LeagueLogo = styled.div`
  background-color: #F0624A;

  width: 50px;
  height: 60px;
`

const TeamLogo = styled.div`
  background-color: #1A1A29;

  width: 60px;
  height: 60px;
`

const Score = styled.div`
  background: #ffffff;

  width: 50px;
  height: 60px;

  font-family: Big Shoulders Display, sans-serif;
  font-size: 40px;
  font-weight: 900;
  line-height: 26px;
  text-align: center;
`

const Time = styled.div`
  background: #ffffff;

  font-family: Big Shoulders Display, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 21px;
  //text-align: center;

`

const LastName = styled.div`
  background: #F0624A;

  width: 230px;
  height: 60px;
  padding: 20px;

  color: white;
  font-family: Big Shoulders Display, sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 26px;
  text-align: center;
`

const Strip = styled.div<{ width: number }>`
  background-color: #ffffff;
  width: ${props => props.width}px;
  height: 100%;
`
