import supabase from "../../../core/supabase/supabase";
import { GameInfoMapped } from "../interfaces/gameInfoMapped";

export async function updateMembersStats(gameInfo: GameInfoMapped) {
  if (!gameInfo.match) return
  if (!gameInfo.teams) return
  if (!gameInfo.teams.firstTeam) return
  if (!gameInfo.teams.secondTeam) return
  
  const receivedData = await supabase.matchChronology.getRow(gameInfo.match.id)
  const data = receivedData && receivedData.length > 0 ? receivedData[ 0 ] : undefined
  const allMembers = gameInfo.teams.firstTeam.members?.concat(gameInfo.teams.secondTeam.members ?? []) ?? []
  for (const receivedEvent of data?.chronology ?? []) {
    const member = allMembers.find((member) => member.id === receivedEvent.players[ 0 ].id)
    if (!member) continue
    switch (receivedEvent.type) {
      case 'GOAL':
      case 'PENALTY_GOAL':
      case 'PASS_AND_GOAL':
        member.qtyGoalsMatch += 1
        break
      case 'RED_CARD':
        member.qtyRedCardsMatch += 1
        break
      case 'YELLOW_CARD':
        member.qtyYellowCardsMatch += 1
        break
    }
  }
}
