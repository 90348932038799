import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { GameInfoMapped } from '../domain/interfaces/gameInfoMapped';

export const selectOverlayState = (state: RootState) => state.overlay.overlay

export const selectEvents = (state: RootState) => state.overlay.overlay.events
export const selectScore = (state: RootState) => state.overlay.overlay.score
export const selectActiveOverlay = (state: RootState) => state.overlay.overlay.activeOverlay
export const selectGameInfo = (state: RootState): undefined | GameInfoMapped => state.overlay.overlay.gameInfo;
export const selectTimer = (state: RootState) => state.overlay.overlay.timer
export const selectFouls = (state: RootState) => state.overlay.overlay.fouls
export const selectPeriod = (state: RootState) => state.overlay.overlay.period

export const selectOrganizer = (state: RootState) => state.overlay.overlay.gameInfo?.organizer;
export const selectTeam = createSelector(
  [
    (state: RootState) => state.overlay.overlay.gameInfo?.teams,
    (_, teamIndex: number) => teamIndex,
  ],
  // Output selector uses the extracted items, category, and ID
  (teams, teamIndex) => {
    if (teamIndex === 0) {
      return teams?.firstTeam
    } else {
      return teams?.secondTeam
    }
  },
)
