/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect } from 'react';
import { css, SerializedStyles } from "@emotion/react";
import { EmotionJSX } from "@emotion/react/types/jsx-namespace";

type IProps = {
  idleColor?: string
  activeColor?: string
  isActive?: boolean
  isTurnOffWhenClickSomewhere?: boolean,
  child: (props: {
    css: SerializedStyles;
    color: string;
    onMouseEnter: () => void;
    onMouseLeave: () => void
  }) => EmotionJSX.Element
};

const InteractiveToggleIcon = (props: IProps) => {
  const {
    idleColor: initColor = '#242424',
    activeColor = '#F5D956',
    isTurnOffWhenClickSomewhere = true,
    child
  } = props;
  
  const [color, setColor] = React.useState<string>(initColor);
  const [isHover, setHover] = React.useState<boolean>(false);
  const [isActive, setActive] = React.useState<boolean>(false);
  
  function select() {
    setHover(true)
    setColor(activeColor)
  }
  
  function unSelect() {
    setHover(false)
    if (isActive) return
    setColor(initColor)
  }
  
  function activate() {
    setActive(true)
    setColor('#F5D956')
  }
  
  const deactivate = useCallback(() => {
    setActive(false)
    setColor(initColor)
  }, [initColor]);
  
  useEffect(() => {
    const checkClickOnRef = () => {
      if (isHover) {
        isActive ? deactivate() : activate()
      } else {
        if (!isTurnOffWhenClickSomewhere) return
        deactivate()
      }
    };
    
    const keyListener = (event: KeyboardEvent) => {
      if (event.key !== 'Escape') { return; }
      deactivate()
    }
    
    window.addEventListener('click', checkClickOnRef)
    document.addEventListener('keydown', keyListener)
    
    return () => {
      window.removeEventListener('click', checkClickOnRef)
      document.removeEventListener('keydown', keyListener)
    }
  }, [deactivate, initColor, isActive, isHover, isTurnOffWhenClickSomewhere])
  
  return child({
    onMouseEnter: select,
    onMouseLeave: unSelect,
    color: color,
    css: svgCss,
  });
};

export default InteractiveToggleIcon;

const svgCss = css`
  &:focus {
    outline: unset;
  }
`
