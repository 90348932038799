import React, { useCallback } from 'react';
import classNames from 'classnames';
import { Team } from 'modules/teams/domain/interfaces/Team';
import { TournamentBracket } from '../../../../../../../domain/interfaces/TournamentBracket';
import { BracketTeamField } from '../bracket-team-field';
import { StyledBracketContainer, StyledRoundBrackets } from './round-brackets.styles';

type BracketProps = {
  bracket: TournamentBracket;
  teams: Team[];
  className?: string;
  onBracketChanged?: (bracketId: number, teams: [number | null, number | null]) => void;
};

const Bracket = ({ bracket, teams, className, onBracketChanged }: BracketProps) => {
  const { roundId, id: bracketId } = bracket;
  const hasParent = !!bracket.parentBracketIds.length;
  const hasChild = !!bracket.childBracketId;
  const teamMatches = bracket.match?.teamMatches || [];
  const firstSelectedTeamId = teamMatches[0]?.team?.id || null;
  const secondSelectedTeamId = teamMatches[1]?.team?.id || null;

  const handleBracketTeamChanged = useCallback((index: number, teamId: number | null) => {
    if (!onBracketChanged) return;

    if (index === 0) {
      onBracketChanged(bracketId, [teamId, secondSelectedTeamId]);
    }

    if (index === 1) {
      onBracketChanged(bracketId, [firstSelectedTeamId, teamId]);
    }
  }, [bracketId, firstSelectedTeamId, onBracketChanged, secondSelectedTeamId]);

  return (
    <StyledBracketContainer className={classNames(className, {
      hasParent,
      hasChild,
    })}>
      <div className="teams-info">
        <div className="team-info-row">
          <BracketTeamField
            index={0}
            teams={teams}
            roundId={roundId}
            bracketId={bracketId}
            onBracketTeamChange={handleBracketTeamChanged}
          />
        </div>
        <div className="team-info-row">
          <BracketTeamField
            index={1}
            teams={teams}
            roundId={roundId}
            bracketId={bracketId}
            onBracketTeamChange={handleBracketTeamChanged}
          />
        </div>
      </div>
    </StyledBracketContainer>
  );
};

type BracketPairProps = {
  bracketPair: TournamentBracket[];
  teams: Team[];
  onBracketChanged?: (bracketId: number, teams: [number | null, number | null]) => void;
};

const BracketPair = ({ bracketPair, teams, onBracketChanged }: BracketPairProps) => {
  const hasFirstBracket = Boolean(bracketPair[0]);
  const hasSecondBracket = Boolean(bracketPair[1]);

  return (
    <>
      {hasFirstBracket && (
        <Bracket
          className="bracket-match"
          bracket={bracketPair[0]}
          teams={teams}
          onBracketChanged={onBracketChanged}
        />
      )}

      {hasSecondBracket && (
        <Bracket
          className="bracket-match"
          bracket={bracketPair[1]}
          teams={teams}
          onBracketChanged={onBracketChanged}
        />
      )}
    </>
  );
};

type RoundBracketsProps = {
  bracketPairs: Array<TournamentBracket[]>;
  teams: Team[];
  className?: string;
  onBracketChanged?: (bracketId: number, teams: [number | null, number | null]) => void;
};

export const RoundBrackets = ({ bracketPairs, teams, className, onBracketChanged }: RoundBracketsProps) => {
  return (
    <StyledRoundBrackets className={className}>
      {bracketPairs.map((pair) => (
        <BracketPair
          key={`${pair[0].id}-${pair[1]?.id}`}
          bracketPair={pair}
          teams={teams}
          onBracketChanged={onBracketChanged}
        />
      ))}
    </StyledRoundBrackets>
  );
};

