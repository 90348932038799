import React from 'react';
import * as S from './styles';

type TeamLogoProps = {
  url?: string;
  name?: string;
  size?: number;
  className?: string;
};

const TeamLogo = (props: TeamLogoProps) => (
  <S.TeamLogoContainer
    className={props.className}
    size={props.size}
    title={props.name}
  >
    <S.TeamLogo logoUrl={props.url} />
  </S.TeamLogoContainer>
);

export default TeamLogo;
