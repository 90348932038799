import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Location } from 'history';

const RouteAdapter: React.FC = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const adaptedHistory = React.useMemo(
    () => ({
      replace(location: Location) {
        navigate(location, { replace: true, state: location.state });
      },
      push(location: Location) {
        navigate(location, { replace: false, state: location.state });
      },
    }),
    [navigate],
  );

  // @ts-ignore
  return children({ history: adaptedHistory, location });
};

export default RouteAdapter;
