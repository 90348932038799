import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import debounce from 'modules/utils/debounce';
import UDInput from 'modules/ud-form/components/input/component';
import UDText from 'modules/ud-ui/components/text';

type Props = {
  className?: string;
  filters: any;
  teamsPerPage: number;
  onFilter: (changes: any) => any;
};

const MainComponentsTeamFilters = (props: Props) => {
  const { filters, onFilter, className, teamsPerPage } = props;
  const [resultsOnPage, setResultsOnPage] = useState(teamsPerPage);

  const handleChangeSearch = useMemo(
    () =>
      debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        onFilter({
          ...filters,
          search: e.target.value,
          page: 1,
        });
      }, 300),
    [filters, onFilter]
  );

  const handleChangeResultsOnPage = useMemo(
    () => (e: React.ChangeEvent<HTMLInputElement>) => {
      debounce(() => {
        onFilter({
          ...filters,
          perPage: +e.target.value,
        });
      }, 300)();

      setResultsOnPage(+e.target.value);
    },
    [filters, onFilter]
  );

  return (
    <div className={classNames('container py-6', className)}>
      <div className="row gx-2">
        <div className="col-9">
          <div className="row gx-2">
            <div className="col-8">
              <UDInput
                defaultValue={filters.search}
                placeholder="Поиск по команде"
                onChange={handleChangeSearch}
                iconProps={{
                  position: 'icon-left',
                  name: 'search',
                  size: 20,
                  componentProps: { className: 'color-SurfaceRomanSilver30' },
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-3 d-flex align-items-center">
          <UDText
            type="subhead"
            className="color-SurfaceRomanSilver30 mr-2 ml-auto white-space-nowrap"
          >
            На странице:
          </UDText>
          <UDInput
            type="number"
            wrapperProps={{ className: 'w-30' }}
            min={0}
            value={resultsOnPage}
            onChange={handleChangeResultsOnPage}
          />
        </div>
      </div>
    </div>
  );
};

export default MainComponentsTeamFilters;
