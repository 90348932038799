import React from 'react';
import UDFormInput from 'modules/ud-form/components/input';
import UDText from 'modules/ud-ui/components/text';

const TourneysComponentsFormRulesPartDraw = () => {
  return (
    <>
      <UDText type="callout" className="mb-4">
        ⚔️ Ничья
      </UDText>
      <UDFormInput
        name="settings.drawScore"
        placeholder="0 очков"
        containerProps={{ className: 'w-100 mb-4' }}
      />
    </>
  );
};

export default TourneysComponentsFormRulesPartDraw;
