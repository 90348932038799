import { TeamPlayersNumber } from 'shared/types/team-players-number';
import { SymbolicTeamPlayer } from '../types';

export const buildEmptyPlayers = (playersNumber: TeamPlayersNumber): SymbolicTeamPlayer[] => {
  const players: SymbolicTeamPlayer[] = [];

  for (let i = 1; i < playersNumber; i++) {
    players.push({ rank: i });
  }

  return players;
};
