import { addDays, isBefore, parseISO } from 'date-fns';
import { DataMapper } from "modules/core/domain/interfaces/DataMapper";
import { Game } from 'modules/games/domain/interfaces/Game';
import { TourneyStatus } from '../enums/TourneyStatus';
import { Championship } from "../interfaces/Championship";
import { MatchTeam } from "../interfaces/MatchTeam";
import { Tournament } from "../interfaces/Tournament";
import { TournamentDTO } from "../interfaces/TournamentDTO";
import { extractYear } from '../../../../lib/dates';

export type TournamentDataMapperResult = {
  tournament: Tournament;
  years: number[];
};

export class TournamentDataMapper implements DataMapper<TournamentDataMapperResult, TournamentDTO> {
  public decode(encoded: TournamentDTO): TournamentDataMapperResult {
    const tournament = encoded.data.tournament;


    const championships = tournament.championships
      .map((champ) => {
        const games = champ.matches
          ? champ.matches.map((match) => {
              return {
                id: match.id,
                sportType: match.sportType,
                date: new Date(match.date),
                teams: match.teams.map((team) => ({
                  id: team.id,
                  name: team.name,
                  logoUrl: team.emblem?.externalUrl,
                } as MatchTeam))
              } as Game;
            })
          : [];

        const address = {
          addressLine1: champ.address?.addressLine1 || '',
          addressLine2: champ.address?.addressLine2 || '',
          city: champ.address?.city || 'Новосибирск',
          country: champ.address?.country || '',
        };

        const applicationsDeadline = addDays(Date.now(), 3);
        const areApplicationsClosed = champ.endDate && isBefore(new Date(champ.endDate), applicationsDeadline);

        return {
          id: champ.id,
          name: champ.name,
          startDate: champ.startDate,
          endDate: champ.endDate,
          games,
          address,
          status: areApplicationsClosed ? TourneyStatus.CLOSE : TourneyStatus.OPEN,
        } as Championship;
      })
      .sort((a, b) => {
        if (b.startDate && a.startDate) {
          return parseISO(b.startDate).getTime() - parseISO(a.startDate).getTime();
        }

        if (!b.startDate) {
          return -1;
        }

        if (!a.startDate) {
          return 1;
        }

        return 0;
      });

    const years = championships.reduce<number[]>((acc, champ) => {
      const result = [...acc];
      if (champ.startDate) {
        result.push(extractYear(champ.startDate));
      }
      if (champ.endDate) {
        result.push(extractYear(champ.endDate));
      }

      return result;
    }, []);

    return {
      tournament: {
        id: tournament.id,
        name: tournament.name,
        championships,
      } as Tournament,
      years: Array.from(new Set(years)),
    };
  }

  public encode(decoded: TournamentDataMapperResult): TournamentDTO {
    const { tournament } = decoded;

    return {
      data: {
        tournament: {
          id: tournament.id,
          name: tournament.name,
          championships: tournament.championships.map((champ) => ({
            id: champ.id,
            name: champ.name,
            startDate: champ.startDate,
            endDate: champ.endDate,
            matches: champ.matches.map((match) => ({
              id: match.id,
              date: match.date.toISOString(),
              teams: match.teams.map((team) => ({
                id: team.id,
                name: team.name,
                emblem: team.logoUrl ? { externalUrl: team.logoUrl } : undefined,
              })),
            })),
            address: {
              addressLine1: champ.address.addressLine1 ? champ.address.addressLine1 : undefined,
              addressLine2: champ.address.addressLine2 ? champ.address.addressLine2 : undefined,
              city: champ.address.city ? champ.address.city : undefined,
              country: champ.address.country ? champ.address.country : undefined,
            }
          })),
        },
      },
    } as TournamentDTO;
  };

}
