import styled from '@emotion/styled';

export const ModalOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100vh;
  min-width: 100vw;
  background-color: #00000080;
  z-index: 9999;
`;

export const ModalContainer = styled.div`
  position: relative;
  min-width: 400px;
  min-height: 200px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.SurfaceIndependence40};

  & .close {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }
`;
