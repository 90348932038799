import React from 'react';
import UDFormCheckbox from 'modules/ud-form/components/checkbox';
import UDFormInput from 'modules/ud-form/components/input';
import UDText from 'modules/ud-ui/components/text';

const TourneysComponentsFormRulesPartLose = () => {
  return (
    <>
      <UDText type="callout" className="mb-4">
        ❌ Поражение
      </UDText>
      <UDFormInput
        name="settings.lossScore"
        placeholder="0 очков"
        containerProps={{ className: 'w-30 mb-4' }}
      />
      <div className="mb-4 d-flex align-items-center">
        <UDText type="callout" className="mr-auto color-SurfaceRaisinBlack50">
          В дополнительное время
        </UDText>
        <UDFormInput
          name="settings.lossScoreExtraTime"
          placeholder="0 очков"
          containerProps={{ className: 'w-30' }}
        />
      </div>
      <div className="mb-4 d-flex align-items-center">
        <UDText type="callout" className="mr-auto color-SurfaceRaisinBlack50">
          В серии послематчевых пенальти
        </UDText>
        <UDFormInput
          name="settings.lossPenaltySeries"
          placeholder="0 очков"
          containerProps={{ className: 'w-30' }}
        />
      </div>
      <div className="d-flex align-items-center">
        <UDFormCheckbox name="settings.technicalDefeatGoalsCount" />
        <UDText type="callout" className="ml-2 color-SurfaceRaisinBlack50">
          Учёт голов при техническом поражении
        </UDText>
      </div>
    </>
  );
};

export default TourneysComponentsFormRulesPartLose;
