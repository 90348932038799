import { Pattern } from "fabric/fabric-impl";
import { ObservableField } from "../../../../utils/observerField";
import Observable from "../../../../utils/observer";
import { template } from "../types";
import customization from "./customization";
import backgroundElems from "./backgroundElems";
import _ from "lodash";
import SnapToGrid from "./snapToGrid";
import Interactive from "../canvases/interactive";

class History {
  history: ObservableField<template[]> = new ObservableField<template[]>([])
  index: ObservableField<number> = new ObservableField<number>(0)
  manager!: Interactive;
  historyProcessing = false
  
  onChangeState = new Observable()
  lastSavedBackgroundColor: string | Pattern | undefined = '#FFFFFF'
  
  keyCallback = this.hotkeyCallBack.bind(this)
  
  isInit = () => {
    return !!this.manager
  }
  
  loadManager(manager: Interactive) {
    this.manager = manager
    console.debug({ trace: 'history', event: 'manager stored', manager, canvas: manager.canvas })
  }
  
  init() {
    this.history.value = [this.getCurrentState()]
    this.index.value = 1
    this.lastSavedBackgroundColor = '#FFFFFF'
  }
  
  activeHistory() {
    this.manager.canvas.on('object:added', this.saveAction);
    this.manager.canvas.on('object:modified', this.saveAction);
    this.manager.canvas.on('object:removed', this.saveAction);
    this.manager.canvas.on('before:transform', this.saveAction);
    this.manager.canvas.on('before:selection:cleared', this.saveAction);
    this.manager.canvas.on('selection:created', this.saveAction);
    window.addEventListener('keypress', this.keyCallback)
  }
  
  disableHistory() {
    this.manager.canvas.off('object:added', this.saveAction);
    this.manager.canvas.off('object:modified', this.saveAction);
    this.manager.canvas.off('object:removed', this.saveAction);
    this.manager.canvas.off('before:transform', this.saveAction);
    this.manager.canvas.on('before:selection:cleared', this.saveAction);
    this.manager.canvas.on('selection:created', this.saveAction);
    window.removeEventListener('keypress', this.keyCallback)
  }
  
  private hotkeyCallBack(e: KeyboardEvent) {
    if (e.code === 'KeyZ' && e.ctrlKey && e.shiftKey) {
      this.redo()
    } else if (e.code === 'KeyZ' && e.ctrlKey) {
      this.undo()
    }
  }
  
  private saveAction = () => {
    if (this.historyProcessing) return;
    const indexState = this.getIndexState();
    const currentState = this.getCurrentState();
    if (this.eqStates(indexState, currentState)) {return}
    this.saveState();
  }
  
  saveState() {
    const currentState = this.getCurrentState();
    const savedState = this.getIndexState()
    if (this.eqStates(savedState, currentState)) {
      return
    }
    
    this.history.value.length = this.index.value
    this.history.value = [...this.history.value, currentState]
    this.index.value = this.index.value + 1
    this.onChangeState.notify(currentState)
  }
  
  undo() {
    if (this.historyProcessing) return;
    if (this.index.value <= 1) return
    this.historyProcessing = true;
    
    if (this.isIndexMax()) {
      this.saveState()
    }
    
    this.index.value = Math.max(this.index.value - 1, 1)
    let state = this.history.value[ this.index.value - 1 ];
    this.loadState(state);
  }
  
  redo() {
    if (this.historyProcessing) return;
    if (this.index.value >= this.history.value.length) return
    this.historyProcessing = true;
    this.index.value = this.index.value + 1
    const state = this.history.value[ this.index.value - 1 ];
    this.loadState(state);
  }
  
  private loadState(state: template) {
    this.manager.canvas.loadFromJSON(state, () => {
      this.manager.canvas.getObjects().forEach(obj => {
        obj.set({ ...customization.getAllProperties() })
      })
      backgroundElems.drawBorder(this.manager.canvas, this.manager.resolution.value)
      backgroundElems.drawGrid(this.manager.canvas, {
        resolution: this.manager.resolution.value,
        gridStep: SnapToGrid.gridStep
      })
      this.manager.canvas.backgroundColor = this.lastSavedBackgroundColor
      this.manager.canvas.renderAll();
      this.onChangeState.notify(state)
      this.historyProcessing = false;
    });
  }
  
  undoIsActive() {
    return this.index.value > 1
  }
  
  redoIsActive() {
    return this.index.value < this.history.value.length
  }
  
  private eqStates(state1: template, state2: template) {
    return _.isEqual(state1, state2)
  }
  
  private getCurrentState() {
    this.lastSavedBackgroundColor = this.manager.canvas.backgroundColor
    this.manager.canvas.backgroundColor = 'transparent'
    const state = this.manager.canvas.toJSON(['selectable', 'data'])
    this.manager.canvas.backgroundColor = this.lastSavedBackgroundColor
    return state
  }
  
  private isIndexMax() {
    return this.index.value >= this.history.value.length;
  }
  
  private getIndexState() {
    return this.history.value[ this.index.value - 1 ]
  }
  
  clearHistory() {
    const currentState = this.getCurrentState();
    this.history.value = [currentState]
    this.index.value = 1
    this.onChangeState.notify(currentState)
  }
  
  getHistory() {
    return { history: this.history.value, index: this.index.value }
  }
  
  setHistory({ history, index }: { history: template[], index: number }) {
    this.history.value = [...history]
    this.index.value = index
    this.lastSavedBackgroundColor = '#FFFFFF'
  }
}

const history = new History()
export default history
