import { playersIsGettingPlayersSelector, playersIsLoadingSelector, playersInfoSelector } from "modules/teams/store/players/selectors"
import { useSelector } from "react-redux"

export const usePlayers = () => {
  const isGettingPlayers = useSelector(playersIsGettingPlayersSelector);
  const isLoading = useSelector(playersIsLoadingSelector);
  const info = useSelector(playersInfoSelector);

  return {
    isGettingPlayers,
    isLoading,
    info,
  };
}