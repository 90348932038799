import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import gamesReducer from 'modules/games/store';
import teamsReducer from 'modules/teams/store';
import playersReducer from 'modules/teams/store/players';
import { createReduxHistoryContext } from 'redux-first-history';
import authReducer from '../modules/auth/store';
import { saveState } from '../modules/auth/store/persist';
import mainPageReducer from '../modules/main/store';
import organizerReducer from '../modules/organizer/store';
import overlayReducer from '../modules/stream/store';
import tourneysReducer from '../modules/tourneys/store';
import templatesEditorReducer from "../modules/template-editor/store";
import organizersReducer from "../modules/organizers/store";

const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({
    history: createBrowserHistory(),
  });

export const rootReducer = combineReducers({
  router: routerReducer,
  auth: authReducer,
  organizer: organizerReducer,
  mainPage: mainPageReducer,
  tourneys: tourneysReducer,
  games: gamesReducer,
  teams: teamsReducer,
  players: playersReducer,
  overlay: overlayReducer,
  templateEditor: templatesEditorReducer,
  organizers: organizersReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(routerMiddleware),
  devTools: process.env.NODE_ENV !== 'production',
});

store.subscribe(() => {
  saveState(store.getState());
});

export const history = createReduxHistory(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
