interface Subscriber {
  update(data: any): void;
}


export default class Observable {
  private subscribers: Subscriber[] = [];
  
  // Метод для добавления подписчика
  subscribe(subscriber: Subscriber) {
    this.subscribers.push(subscriber);
  }
  
  // Метод для удаления подписчика
  unsubscribe(subscriber: Subscriber) {
    const index = this.subscribers.indexOf(subscriber);
    if (index > -1) {
      this.subscribers.splice(index, 1);
    }
  }
  
  // Метод для уведомления всех подписчиков
  notify(data: any) {
    this.subscribers.forEach((subscriber) => subscriber.update(data));
  }
}
