import { SymbolicTeamPlayer } from '../../../../../../domain';
import { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import {
  PlayerInfoContainer, PlayerInfoPlayerName, PlayerInfoTeamLogo, PlayerInfoTeamLogoContainer, SelectPlayerButton
} from './player-info.styles';

type PlayerInfoProps = {
  player: SymbolicTeamPlayer,
  isRankActive: boolean;
  onSelect?: (rank: number) => void;
};

export const PlayerInfo = ({player, isRankActive, onSelect}: PlayerInfoProps) => {
  const playerName = useMemo(() => {
    if (isRankActive || !player.user) {
      return '';
    }

    let name = player.user.lastName || '';
    if (player.user.firstName) {
      name += ` ${player.user.firstName.slice(0, 1)}.`;
    }

    return name;
  }, [isRankActive, player.user]);

  const handleSelectClick = useCallback(() => {
    if (!onSelect) {
      return;
    }

    onSelect(player.rank);
  }, [player.rank, onSelect]);

  if (!player.user && !isRankActive) {
    return (
      <SelectPlayerButton
        type="button"
        variant="primary"
        onClick={handleSelectClick}
      >+ Выбрать</SelectPlayerButton>
    );
  }

  return (
    <PlayerInfoContainer
      className={classNames({ activated: isRankActive })}
      onClick={handleSelectClick}
    >
      <PlayerInfoTeamLogoContainer>
        {!isRankActive && (
          <PlayerInfoTeamLogo logo={player.teamLogo} />
        )}
      </PlayerInfoTeamLogoContainer>
      <PlayerInfoPlayerName className="text footnote">
        {playerName}
      </PlayerInfoPlayerName>
    </PlayerInfoContainer>
  );
};
