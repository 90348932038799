import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import png from './arrow.png';

export const Breadcrumb = styled(Link)`
  display: block;
  color: white;
  font-family: 'SF Pro Text', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  

  &:not(:last-child) {
    &::after {
      content: url(${png});
      position: relative;
      top: 2px;
      font: inherit;
      padding: 0 14px;
    }
  }

  &.active {
    pointer-events: none;
    color: ${({ theme }) => theme.colors.SurfacePlatinum20};
    font-weight: 400;
  }
`;
