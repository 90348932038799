import styled from '@emotion/styled';
import field from 'modules/ud-ui/components/icon/icons/soccer-field.svg';

export const ListContainer = styled.div`
  &.empty {
    background-image: url(${field});
    background-position: center;
    background-size: cover;
  }

  & > .item {
    max-height: 150px;
  }
`;
