import React from 'react';
import styled from '@emotion/styled';

const ApplicationSkeletonCard = styled.div`
  @keyframes shine {
    to {
      background-position-x: -200%;
    }
  }

  background: #eee;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
  width: 100%;
  height: 84px;
  margin-bottom: 16px;
`;

export const ApplicationsSkeleton = () => {
  const items = new Array(5).fill('');

  return (
    <div>
      {items.map(() => (
        <ApplicationSkeletonCard />
      ))}
    </div>
  )
};
