import classNames from 'classnames';
import React from 'react';
import image from './images/illustration.svg';
import { Container } from './styles';

type Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

const AuthComponentsBillboard = (props: Props) => {
  return (
    <Container
      {...props}
      className={classNames('d-flex justify-content-center', props.className)}>
      <img src={image} className="image" />
    </Container>
  );
};

export default AuthComponentsBillboard;
