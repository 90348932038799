import React from 'react';
import styled from '@emotion/styled';
import { ListContainer } from '../tourneys-list/styles';

const TourneyCardSkeleton = styled.div`
  border-radius: 8px;
  height: 128px;
`;

const PaginationContentSkeleton = styled.div`
  border-radius: 8px;
  width: 216px;
  height: 24px;
`;

type TourneysListSkeletonProps = {
  resultsOnPage?: number;
};

const TourneysListSkeleton = ({ resultsOnPage = 9 }: TourneysListSkeletonProps) => {
  const items = new Array(resultsOnPage).fill('');

  return (
    <ListContainer className="container">
      <div className="row g-1">
        {items.map((item, index) => (
          <div key={index} className="col-4">
            <TourneyCardSkeleton className="skeleton-block" />
          </div>
        ))}
      </div>
    </ListContainer>
  );
}

const PaginationSkeleton = () => {
  return (
    <div className="d-flex justify-content-end px-6 my-8">
      <PaginationContentSkeleton className="skeleton-block" />
    </div>
  );
}

type TourneysBodySkeletonProps = TourneysListSkeletonProps;

export const TourneysBodySkeleton = ({ resultsOnPage }: TourneysBodySkeletonProps) => {
  return (
    <>
      <TourneysListSkeleton resultsOnPage={resultsOnPage} />
      <PaginationSkeleton />
    </>
  );
};
