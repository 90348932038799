export default function categoryNameOnCreateValidator(categoryName?: string, categoryList?: string[]) {
  if (!categoryName || categoryName.length <= 0) {
    return 'Название категории не может быть пустым'
  }
  if (categoryName.length > 30) {
    return 'Название категории слишком длинное'
  }
  if (categoryList && categoryList.includes(categoryName)) {
    return 'Такое название уже существует, придумайте новое.'
  }
  return undefined
}
