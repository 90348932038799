import React from 'react';
import styled from '@emotion/styled';
import { StagesGrid } from './stages-grid';

const StageCardSkeleton = styled.div`
  border-radius: 8px;
  min-height: 157px;
`;

export const StagesGridSkeleton = () => {
  const items = new Array(4).fill('');

  return (
    <StagesGrid>
      {items.map((_, index) => (
        <StageCardSkeleton
          key={index}
          className="skeleton-block"
        />
      ))}
    </StagesGrid>
  );
};
