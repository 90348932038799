import React from 'react';
import classNames from 'classnames';
import TourneyComponentsTourneyCard from 'modules/tourneys/ui/components/tourney-card';
import { TourneyEncoded } from 'modules/tourneys/domain/interfaces/TourneyEncoded';
import { Championship } from 'modules/tourneys/domain/interfaces/Championship';
import MainComponentsEmptyListMessage from '../empty-message';
import * as S from './styles';

type Props = {
  tourneys: Championship[];
  onDetail: (id: TourneyEncoded['id']) => any;
};

const MainComponentsTourneysList = (props: Props) => {
  const { tourneys, onDetail } = props;
  const isEmpty = !tourneys.length;

  return (
    <S.ListContainer
      className={classNames('container', {
        empty: isEmpty,
      })}>
      <div className="row g-1">
        {tourneys.map((tourney) => (
          <div className="col-4" key={tourney.id}>
            <TourneyComponentsTourneyCard
              key={tourney.id}
              tourney={tourney}
              containerProps={{ className: 'item' }}
              onClickCard={onDetail}
            />
          </div>
        ))}
        {isEmpty && <MainComponentsEmptyListMessage />}
      </div>
    </S.ListContainer>
  );
};

export default MainComponentsTourneysList;
