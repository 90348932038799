import React from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import UDInput, { UDInputProps } from '../../input/component';
import { timeHelpers } from './helpers';

export type UDTimeInputProps = Omit<
  ReactDatePickerProps,
  | 'dateFormat'
  | 'customInput'
  | 'placeholderText'
  | 'showTimeSelect'
  | 'showTimeSelectOnly'
  | 'timeCaption'
  | 'dateFormat'
> &
  Pick<UDInputProps, 'hasError' | 'valid'>;

const UDTimeInput = (props: UDTimeInputProps) => {
  const { value, hasError, valid, timeIntervals, ...datePickerProps } = props;
  const parsedValue = value ? timeHelpers.parse(value) : null;

  return (
    <DatePicker
      selected={parsedValue}
      showTimeSelect
      showTimeSelectOnly
      timeIntervals={timeIntervals}
      timeCaption="Время"
      dateFormat="HH:mm"
      placeholderText="чч:мм"
      customInput={<UDInput hasError={hasError} valid={valid} />}
      {...datePickerProps}
      onChange={(date, e) =>
        datePickerProps.onChange(timeHelpers.extract(date), e)
      }
    />
  );
};

export default UDTimeInput;
