import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const disableColor = css`
  &:disabled {
    color: #1A1A29
  }
`
export const tooltipCss = css`
  background-color: #FAECAB;
  padding: 8px 16px;
  width: unset;
  filter: unset;
  border-radius: 4px;
  margin-left: calc(100% + 24px + 5px);
  
  &:before {
    border-right: 13px solid #FAECAB;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    top: 9px;
    left: -13px;
  }
  
  color: #1A1A29;
  font-family: SF Pro Text, sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;

`

export const copyIconCss = css`
  cursor: pointer;
  stroke: #8F8E99;
  
  &:hover {
    stroke: #F5D956
  }
  
  &:active {
    stroke: #8F8E99;
  }
  
  transition: stroke 0.2s ease-in-out;;
`

export const CustomTextarea = styled.textarea`
  color: #1A1A29;
  outline: 0 transparent;
  border: 0 transparent;
  padding: 8px 16px;
  resize: vertical;
  
  &:focus {
    outline: 0 transparent;
    border: 0 transparent;
  }
  
  font-family: SF Pro Display, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  
  border-radius: 8px;
`

export const CustomField = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  
  border-radius: 8px;
  background-color: #FFFFFF;
  padding: 8px 16px;
  
  height: 36px;
`
