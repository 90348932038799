import styled from '@emotion/styled';

export const PlayerContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;

  .player-info-container {
    display: flex;
    align-items: center;
    flex: 1;
    .team-player-item {
      flex: 1;
    }
  }
`;

export const PlayerAdditional = styled.div`
  display: flex;
  align-items: center;
`;
