import { RootState } from "../../../store/store";

export const selectActiveTemplate = (state: RootState) => state.templateEditor.templateEditor.activeTemplateName
export const selectFabricManager = (state: RootState) => state.templateEditor.templateEditor.fabricManager
export const selectActiveObjects = (state: RootState) => state.templateEditor.templateEditor.activeObjects
export const selectActiveCategory = (state: RootState) => state.templateEditor.templateEditor.categories?.find(c => c.id === state.templateEditor.templateEditor.activeCategoryId)
export const selectActiveCategoryId = (state: RootState) => state.templateEditor.templateEditor.activeCategoryId
export const selectCategories = (state: RootState) => state.templateEditor.templateEditor.categories
export const selectPreviewState = (state: RootState) => state.templateEditor.templateEditor.previewState
export const selectActiveModal = (state: RootState) => state.templateEditor.templateEditor.activeModal
