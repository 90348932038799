import CreateStageGroupForm from './create-stage-group-form';
import { StageV2 } from '../../../../../domain/interfaces/Stage';
import StageGroupList from './stage-group-list';
import { useSelector } from 'react-redux';
import { selectSelectedStageGroups } from '../../../../../store/stages';
import { StageType } from '../../../../../domain/enums/StageType';
import { StageBracketsForm } from './stage-brackets';

type StageGridProps = {
  stage: StageV2;
};

const StageGrid = ({ stage }: StageGridProps) => {
  const teamGroups = useSelector(selectSelectedStageGroups);
  const isGroupStage = stage.type === StageType.GROUP;

  if (isGroupStage) {
    return (
      <div className="row gy-2">
        <CreateStageGroupForm stage={stage} />

        <StageGroupList
          groups={teamGroups}
          teams={stage.teams}
        />
      </div>
    );
  }

  return (
    <div className="row gy-2">
      <StageBracketsForm teams={stage.teams} />
    </div>
  )
};

export default StageGrid;
