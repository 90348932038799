import { TeamEditFormPart } from '../../../teams/ui/components/edit-team-form/constants';
import { TourneyEditFormPart } from '../../../tourneys/ui/components/edit-form/constants';

export const Pages = {
  SIGN_IN: '/sign-in',
  SIGN_UP: '/sign-up',
  SELECT_TOURNAMENT: '/select-tournament',
  INDEX: '/',
  ABOUT: '/about',
  POLICY: '/policy',
  TERMS: '/terms',
  SETTINGS: '/settings',
  TEMPLATE: {
    INDEX: '/template',
    CHOOSING: '/template/choosing',
    PREVIEW: '/template/preview',
    EDITOR: '/template/editor',
  },
  NOTIFICATIONS: '/notifications',
  SUPPORT: '/support',
  TOURNEYS: {
    INDEX: '/tourneys',
    CREATE: '/tourneys/create/common',
    EDIT: '/tourneys/:id',
    builders: {
      edit(id: number, hash: string = TourneyEditFormPart.COMMON.hash) {
        return `/tourneys/${id}/${hash}`;
      },
    },
  },
  GAMES: 'games',
  TEAMS: {
    INDEX: '/teams/',
    CREATE: '/teams/create',
    EDIT: '/teams/:id',
    builders: {
      edit: (id: number, hash: string = TeamEditFormPart.COMMON.hash) => `/teams/${id}/${hash}`,
    },
  },
  COVER: '/cover/:matchId',
  ORGANIZERS: {
    INDEX: '/organizers',
    VIEW: '/organizers/:id',
    builders: {
      view: (id: number) => `/organizers/${id}`,
    },
  }
}
