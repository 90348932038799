import Interactive from "../../canvases/interactive";
import singleAlignment from "./single";
import groupAlignment from "./group";

export class Alignment {
  single = singleAlignment
  group = groupAlignment
  
  init(manager: Interactive) {
    this.single.init(manager)
    this.group.init(manager)
  }
}

const alignment = new Alignment()
export default alignment
