import { Resource } from 'modules/core/baseResource';
import httpResource from 'modules/core/httpResource';

class PlayersResource extends Resource {
  constructor() {
    super('users', httpResource);
  }

  public async uploadPhoto(
    photo: File,
    playerId: number,
    tournamentId: number
  ) {
    const formData = new FormData();

    formData.append('image', photo);
    formData.append('tournamentId', `${tournamentId}`);
    const result = await this.child(`${playerId}/photo`).post(formData);

    return result;
  }
}

const playersResource = new PlayersResource();
export default playersResource;
