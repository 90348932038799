import React, { useCallback, useMemo } from 'react';
import styled from '@emotion/styled';
import { Application } from 'modules/applications/domain/interfaces/Application';
import { ApplicationStatus } from 'modules/applications/domain/enums/ApplicationStatus';
import UDButton from 'modules/ud-ui/components/button';
import TeamsComponentsTeamCard from '../../../../teams/ui/components/team-card';

const StyledButton = styled.div`
  display: inline-block;
  padding: 8px 16px;
  box-sizing: border-box;
  border-radius: 8px;
  outline-width: 1px;
  outline-style: solid;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;

  &.correct {
    color: ${({ theme }) => theme.colors.CaseCorrect};
    outline-color: ${({ theme }) => theme.colors.CaseCorrect};
  }

  &.error {
    color: ${({ theme }) => theme.colors.CaseError};
    outline-color: ${({ theme }) => theme.colors.CaseError};
  }
`;

type ApplicationActionsProps = {
  status: ApplicationStatus;
  onApprove: () => void;
  onDecline: () => void;
  isLoading?: boolean;
};

const ApplicationActions = (props: ApplicationActionsProps) => {
  const { status, isLoading } = props;

  if (status === ApplicationStatus.ACCEPTED) {
    return (
      <div className="ml-auto">
        <StyledButton className="text footnote correct">
          Команда принята
        </StyledButton>
      </div>
    );
  }

  if (status === ApplicationStatus.DECLINED) {
    return (
      <StyledButton className="text footnote error">
        Команда отклонена
      </StyledButton>
    );
  }

  return (
    <div className="ml-auto">
      <UDButton
        type="button"
        variant="primary"
        disabled={isLoading}
        onClick={props.onApprove}
        className="footnote mr-4"
      >
        Принять заявку
      </UDButton>
      <UDButton
        type="button"
        variant="secondary"
        className="footnote"
        disabled={isLoading}
        onClick={props.onDecline}
      >
        Отклонить
      </UDButton>
    </div>
  );
};

type Props = {
  application: Application;
  onApprove: (application: Application) => any;
  onDecline: (Application: Application) => any;
  isLoading?: boolean;
};

const ApplicationComponentsApplicationCard = (props: Props) => {
  const { application, onApprove, onDecline, isLoading } = props;

  const approve = useCallback(
    () => onApprove(application),
    [application, onApprove]
  );
  const decline = useCallback(
    () => onDecline(application),
    [application, onDecline]
  );

  const ActionsComponent = useMemo(
    () => (
      <div style={{ width: '45%', textAlign: 'right' }}>
        <ApplicationActions
          isLoading={isLoading}
          status={application.status}
          onApprove={approve}
          onDecline={decline}
        />
      </div>
    ),
    [application.status, approve, decline, isLoading]
  );

  return (
    <TeamsComponentsTeamCard
      team={application.team}
      actions={ActionsComponent}
    />
  );
};

export default ApplicationComponentsApplicationCard;
