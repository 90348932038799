import { Address } from '../domain/interfaces/address';

export function isEmptyAddress(address?: Address | null): boolean {
  if (!address) {
    return true;
  }

  return (
    !address.id
    && !address.country
    && !address.region
    && !address.city
    && !address.addressLine1
    && !address.addressLine2
  );
}
