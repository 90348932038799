import { Canvas, StaticCanvas } from "fabric/fabric-impl";
import supabase from "../../../../core/supabase/supabase";
import { accessToTemplates, loadSupabase, supabaseTemplate, template } from "../types";
import customization from "../addons/customization";
import { ObservableField } from "../../../../utils/observerField";

export default class BaseFabric {
  canvas!: StaticCanvas | Canvas
  
  template?: supabaseTemplate
  resolution: ObservableField<{ width: number, height: number }> = new ObservableField({ width: 1920, height: 1080 })
  deletedObjects?: { background: string }
  cursorPos?: { x: number, y: number }
  
  protected init() {
    this.canvas.on('mouse:move', e => {
      this.cursorPos = e.absolutePointer
    })
  }
  
  dispose() {
    if (!this.canvas) return
    this.canvas.dispose()
  }
  
  clear(safeBackground = false) {
    const backgroundColor = this.canvas.backgroundColor
    this.canvas.clear()
    
    if (!safeBackground) return
    this.canvas.backgroundColor = backgroundColor
    this.canvas.requestRenderAll()
  }
  
  normalizedViewport(offset?: { x?: number, y?: number, zoom?: number }) {
    const savedViewport = this.resolution
    const zoom = Math.min(this.canvas.getHeight() / savedViewport.value.height * (offset?.zoom ?? 1), this.canvas.getWidth() / savedViewport.value.width * (offset?.zoom ?? 1))
    this.canvas.setZoom(zoom * (offset?.zoom ?? 1))
    
    const viewport = this.canvas.viewportTransform
    if (!viewport) return
    viewport[ 4 ] = (this.canvas.getWidth() - (savedViewport.value.width - (offset?.x ?? 0)) * zoom) / 2
    viewport[ 5 ] = (this.canvas.getHeight() - (savedViewport.value.height - (offset?.y ?? 0)) * zoom) / 2
    this.canvas.setViewportTransform(viewport)
    this.canvas.requestRenderAll()
  }
  
  hideBackground() {
    this.deletedObjects = { background: this.canvas.backgroundColor?.toString() ?? 'transparent', }
    this.canvas.backgroundColor = 'transparent'
    this.canvas.requestRenderAll()
  }
  
  showBackground() {
    if (!this.deletedObjects) return
    this.canvas.backgroundColor = this.deletedObjects.background
    this.canvas.requestRenderAll()
  }
  
  loadLocal({ template, safeBackground }: { safeBackground?: boolean, template: template | supabaseTemplate | string }) {
    const oldBackground = this.canvas.backgroundColor
    return new Promise((resolve) => {
      if (!template) return resolve(false)
      this.canvas.loadFromJSON(template ?? '', (() => {
        this.canvas.getObjects().forEach(obj => obj.set({ ...customization.getAllProperties() }));
        if (safeBackground) this.canvas.backgroundColor = oldBackground
        this.canvas.requestRenderAll()
        resolve(true)
      }))
    })
  }
  
  async saveSupabase({ rowId, templateName }: { rowId: number, templateName: string }) {
    this.hideBackground()
    const template = this.canvas.toJSON(['data', 'selectable'])
    this.showBackground()
    const isDefaultCategory = rowId === null
    
    const row = isDefaultCategory
      ? await supabase.overlayTemplates.getDefaultRow()
      : await supabase.overlayTemplates.getRow({ rowId })
    if (row) {
      const oldTemplates = row.templates
      await supabase.overlayTemplates.updateRow(
        {
          rowId: rowId
        }, {
          templates: { ...oldTemplates, [ templateName ]: { ...template, id: oldTemplates[ templateName ].id, createdAt: oldTemplates[ templateName ].createdAt } },
          resolution: this.resolution.value,
        })
    }
  }
  
  getTemplate(hideBackgroundElems = false): template {
    hideBackgroundElems && this.hideBackground()
    const template = this.canvas.toJSON(['selectable', 'data'])
    hideBackgroundElems && this.showBackground()
    return template
  }
  
  setResolution(resolution: { width: number, height: number }) {
    this.resolution.value = resolution
  }
  
  setDimensions({ width, height }: { width: number, height: number }) {
    this.canvas.setDimensions({ width, height })
    this.canvas.requestRenderAll()
  }
  
  getParentSize() {
    const { parentElement } = this.canvas.getElement()
    if (!parentElement) return
    const height = parentElement.clientHeight
    const width = parentElement.clientWidth
    return { width, height }
  }
  
  setParentSize(offset?: { x?: number, y?: number }) {
    const { width, height } = this.getParentSize() ?? {}
    if (!width || !height) return
    this.canvas.setWidth(width + (offset?.x ?? 0))
    this.canvas.setHeight(height + (offset?.y ?? 0))
    this.canvas.requestRenderAll()
  }
  
  render() {
    this.canvas.renderAll()
  }
}
