import React from 'react';
import UDFormInput from 'modules/ud-form/components/input';
import UDText from 'modules/ud-ui/components/text';

const TourneysComponentsFormRulesPartGame = () => {
  return (
    <>
      <UDText type="callout" className="mb-4">
        ⚙️ Игра
      </UDText>
      <div className="row gx-4">
        <div className="col-6">
          <div className="mb-4 d-flex align-items-center">
            <UDText type="callout" className="mr-2 color-SurfaceRaisinBlack50">
              Количество периодов в матче
            </UDText>
            <UDFormInput
              name="settings.gamePeriodsNumber"
              placeholder="0"
              containerProps={{ className: 'w-30 flex-shrink-0 ml-auto' }}
            />
          </div>
          <div className="d-flex align-items-center">
            <UDText type="callout" className="mr-2 color-SurfaceRaisinBlack50">
              Длительность матча
            </UDText>
            <UDFormInput
              name="settings.periodTime"
              placeholder="0 мин"
              containerProps={{ className: 'w-30 flex-shrink-0 ml-auto' }}
            />
          </div>
        </div>
        <div className="col-6">
          <div className="mb-4 d-flex align-items-center">
            <UDText type="callout" className="mr-2 color-SurfaceRaisinBlack50">
              Кол-во периодов дополнительного времени
            </UDText>
            <UDFormInput
              name="settings.gameExtraPeriodsNumber"
              placeholder="0"
              containerProps={{ className: 'w-30 flex-shrink-0 ml-auto' }}
            />
          </div>
          <div className="d-flex align-items-center">
            <UDText type="callout" className="mr-2 color-SurfaceRaisinBlack50">
              Длительность дополнительного периода
            </UDText>
            <UDFormInput
              name="settings.periodExtraTime"
              placeholder="0 мин"
              containerProps={{ className: 'w-30 flex-shrink-0 ml-auto' }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TourneysComponentsFormRulesPartGame;
