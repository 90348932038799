import { Canvas, Group, IText, StaticCanvas, Textbox } from "fabric/fabric-impl";
import { fabric } from "fabric";

enum LastGameResultColors {
  WON = '#57B34F',
  DRAW = '#867D7DFF',
  LOST = '#EF3D3D',
}

export class Replacer {
  private canvas!: StaticCanvas | Canvas;
  
  init(canvas: StaticCanvas) {
    this.canvas = canvas
  }
  
  replaceImage(indicator: string, url: string, opacity = 1, onLoaded?: (img: fabric.Image) => void) {
    const objects = this.getObjects(indicator);
    if (!objects) {return;}
    
    for (const object of objects) {
      this.canvas.remove(object);
      
      fabric.Image.fromURL(url, (img) => {
        if (!object.width) {
          console.warn({ event: 'width not found' });
          return;
        }
        if (!object.height) {
          console.warn({ event: 'height not found' });
          return;
        }
        img.opacity = opacity
        
        img.set({ left: object.left, top: object.top, selectable: false });
        if (!img.width || !img.height) {
          console.warn({ event: 'width or height img not found' });
          return;
        }
        
        const scale = Math.min(object.width * object.getObjectScaling().scaleX / img.width, object.height * object.getObjectScaling().scaleY / img.height);
        img.scale(scale);
        
        const center = object.getCenterPoint()
        img.setPositionByOrigin(center, 'center', 'center');
        img.data = { name: indicator };
        
        this.canvas.add(img);
        this.canvas.renderAll()
        if (!onLoaded) return;
        onLoaded(img)
      })
    }
  }
  
  replaceText(indicator: string, text: string, opacity = 1) {
    const objects = this.getObjects(indicator)
    if (!objects) {return;}
    
    for (const object of objects) {
      if (!object.width || !object.height) {
        console.warn({ event: 'width or height not found' });
        return
      }
      if (object.type !== 'group') return;
      const textboxOld = (object as Group).getObjects()[ 0 ] as Textbox
      this.canvas.remove(object)
      const newTextBox = new fabric.IText(text, {
        left: object.left,
        fontWeight: object?.data?.fontWeight ?? 'normal',
        fontSize: object?.data?.fontSize ?? 20,
        fontFamily: object?.data?.fontFamily ?? 'Arial',
        textAlign: object?.data?.textAlign ?? 'center',
        fill: object?.data?.color ?? textboxOld.fill ?? '#000000FF',
        opacity: opacity,
        data: { name: indicator },
      })
      
      this.adjustText(newTextBox, object as Group)
      switch (object.data.textAlign) {
        case 'left':
          const left = object.getPointByOrigin('left', 'center')
          newTextBox.setPositionByOrigin(left, 'left', 'center')
          break
        case 'right':
          const right = object.getPointByOrigin('right', 'center')
          newTextBox.setPositionByOrigin(right, 'right', 'center')
          break
        case 'center':
          const center = object.getCenterPoint()
          newTextBox.setPositionByOrigin(center, 'center', 'center')
          break
      }
      
      this.canvas.add(newTextBox)
      this.canvas.renderAll();
    }
  }
  
  replaceLastResultGame(indicator: string, results: (keyof typeof LastGameResultColors)[] | undefined, opacity = 1) {
    const objects = this.getObjects(indicator)
    if (!objects) return;
    const notInit = !results
    
    for (const object of objects) {
      this.canvas.remove(object);
      if (notInit) continue;
      const bounding = object.getBoundingRect(true)
      const radius = Math.min(bounding.height, bounding.width / 3) / 2
      const gap = (bounding.width - ((radius * 2) * 3)) / 2
      const circles = []
      for (let i = 0; i < 3; i++) {
        const circle = new fabric.Circle({
          left: bounding.left + (radius * 2) * i + gap * i,
          top: bounding.top,
          radius,
          fill: LastGameResultColors[ results[ i ] ],
          strokeWidth: 1,
          selectable: false,
          data: { name: indicator },
          opacity: opacity,
        })
        circles.push(circle)
        this.canvas.add(circle)
      }
    }
  }
  
  private getObjects(indicator: string) {
    if (!this.canvas) {
      console.error('Canvas not found');
      return;
    }
    const objects = this.canvas.getObjects().filter((obj) => obj?.data?.name === indicator);
    if (!objects.length) {
      console.debug({ event: 'objects not found' });
      return
    }
    return objects;
  }
  
  updateText(indicator: string, text: string) {
    const objects = this.getObjects(indicator)
    if (!objects) return;
    
    for (const object of objects) {
      if (object.type !== 'i-text') continue;
      (object as Textbox).set({ text });
    }
    
    this.canvas.renderAll()
  }
  
  adjustText(textbox: IText, placeholder: Group) {
    if (placeholder.scaleX === undefined || placeholder.scaleY === undefined) return;
    if (placeholder.width === undefined || placeholder.height === undefined) return;
    if (textbox.scaleX === undefined || textbox.scaleY === undefined) return;
    if (textbox.width === undefined || textbox.height === undefined) return;
    const width = placeholder.getScaledWidth()
    const height = placeholder.getScaledHeight()
    if (!textbox.width || !textbox.height) return;
    textbox.set({ width: width, fontSize: height })
    
    if (textbox.width > width || textbox.height > height) {
      const factorScaleX = placeholder.width * placeholder.scaleX / textbox.width
      const factorScaleY = placeholder.height * placeholder.scaleY / textbox.height
      const factorScale = Math.min(factorScaleX, factorScaleY)
      textbox.set({ scaleX: factorScale, scaleY: factorScale })
    }
  }
}
