import React from 'react';

type TrashIconProps = {
  width: number;
  height: number;
  color?: string;
};

const TrashIcon = (props: TrashIconProps) => {
  const {
    width,
    height,
    color = '#FF7061',
  } = props;

  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 14 16"
      fill="none" 
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.75 0.5C3.92275 0.5 3.25 1.17275 3.25 2V2.75H0.25V4.25H1.75V14C1.75 14.8273 2.42275 15.5 3.25 15.5H10.75C11.5773 15.5 12.25 14.8273 12.25 14V4.25H13.75V2.75H10.75V2C10.75 1.17275 10.0773 0.5 9.25 0.5H4.75ZM9.25 2V2.75H4.75V2H9.25ZM3.25 14V4.25H10.75V14H3.25ZM6.25 5.75V12.5H4.75V5.75H6.25ZM9.25 5.75V12.5H7.75V5.75H9.25Z"
        fill={color}
      />
    </svg>
  );
};

export default TrashIcon;