import styled from '@emotion/styled';
import { GameDetailsScore, GameDetailsTeam } from 'modules/games/domain/interfaces/GameDetails';
import { Formik } from 'formik';
import UDButton from 'modules/ud-ui/components/button';
import { TeamScoreCard } from './team-score-card';

const FieldsWrapper = styled.div`
  margin-top: 28px;
`;

export type GameScoreFormProps = {
  teams: [GameDetailsTeam, GameDetailsTeam];
  initialValues?: GameDetailsScore;
  onSubmit: (values: { firstTeamScore: number; secondTeamScore: number; }) => void;
};

export const GameScoreForm = (props: GameScoreFormProps) => {
  const {
    teams,
    initialValues,
    onSubmit,
  } = props;

  return (
    <Formik
      initialValues={initialValues || { firstTeamScore: 0, secondTeamScore: 0 }}
      onSubmit={onSubmit}
    >
      {(formProps) => (
        <form onSubmit={formProps.handleSubmit}>
          <FieldsWrapper>
            {teams.map((team, index) => (
              <TeamScoreCard
                key={index === 0 ? 'firstTeamScore' : 'secondTeamScore'}
                team={team}
                name={index === 0 ? 'firstTeamScore' : 'secondTeamScore'}
                value={index === 0 ? formProps.values.firstTeamScore : formProps.values.secondTeamScore}
              />
            ))}
          </FieldsWrapper>

          <div className='mt-10'>
            <UDButton
              variant="primary"
              type="submit"
              className="w-100 mb-3"
            >
              Сохранить
            </UDButton>
          </div>
        </form>
      )}
    </Formik>
  );
};