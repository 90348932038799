import React from 'react';
import { useSelector } from "react-redux";
import { selectActiveObjects } from "../../../../store/selectors";
import Canvas from "./canvas";
import Figure from "./figure";
import Text from "./text";
import { Group as GroupType, IText } from "fabric/fabric-impl";
import styled from "@emotion/styled";
import Placeholder from "./placeholder";
import Group from "./group";
import Image from "./image";

function RightToolbar() {
  const activeObjects = useSelector(selectActiveObjects)
  // object
  const someSelect = activeObjects.length > 0
  const moreSelect = activeObjects.length > 1
  const singleSelect = activeObjects.length === 1
  
  const isText = singleSelect && (activeObjects[ 0 ].type === 'i-text' || activeObjects[ 0 ].type === 'textbox')
  const isRect = singleSelect && activeObjects[ 0 ].type === 'rect'
  const isCircle = singleSelect && activeObjects[ 0 ].type === 'circle'
  const isFigure = isRect || isCircle
  const isPlaceholder = singleSelect && activeObjects[ 0 ].type === 'group'
  const isImage = singleSelect && activeObjects[ 0 ].type === 'image'
  
  return (
    <MainBlock>
      {!someSelect && <Canvas/>}
      {isFigure && <Figure/>}
      {isText && <Text text={activeObjects[ 0 ] as IText}/>}
      {isPlaceholder && <Placeholder group={activeObjects[ 0 ] as GroupType}/>}
      {moreSelect && <Group/>}
      {isImage && <Image/>}
    </MainBlock>
  );
}

export default RightToolbar;

const MainBlock = styled.div`
  width: 256px;
  min-width: 256px;
  background: #FFFDFF;
  border-left: 1px solid #E2E3E4;
  padding: 8px 8px;
  
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-weight: normal;
  text-align: left;
  letter-spacing: 0.5px;
  
  overflow-y: auto;
  overflow-x: hidden;
`
