/** @jsxImportSource @emotion/react */
import React from 'react';
import styled from "@emotion/styled";
import { SerializedStyles } from "@emotion/react";
import { DivProps } from "../../../../../typings";

interface IProps extends React.PropsWithChildren<DivProps> {
  headerProps?: DivProps & {
    title: string
  }
  contentProps?: { css?: SerializedStyles } & DivProps
}

function ListContainer(props: IProps) {
  const { headerProps, contentProps, children, ...rest } = props
  
  return (
    <MainBlock {...rest}>
      <Header {...headerProps}>{headerProps?.title}</Header>
      <Content {...contentProps}>
        {children}
      </Content>
    </MainBlock>
  );
}

export default ListContainer;

const MainBlock = styled.div`
  height: calc(100% - 70px - 70px);
`

const Header = styled.div`
  padding: 8px;
  gap: 10px;
  border-radius: 8px 8px 0 0;
  background: #2D2D47;
  
  font-family: SF Pro Text, sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: white;
`

const Content = styled.div`
  border-radius: 0 0 8px 8px;
  background: #FFFFFF;
  overflow-y: auto;
  
  &::-webkit-scrollbar {
    width: 7px;
    background-color: transparent;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: #CBCBCB;
    border-radius: 8px;
  }
`
