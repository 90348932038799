import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UDIcon from '../../../../ud-ui/components/icon';
import { setScore } from '../../../store/overlay';
import { selectScore } from '../../../store/selectors';
import Block from '../components/block';
import Counter from '../components/counter';

export function Score() {
  const dispatch = useDispatch()
  const score = useSelector(selectScore);
  
  const setScoreFirstTeam = (num: number) => {
    dispatch(setScore([num, score[1]]))
  }
  
  const setScoreSecondTeam = (num: number) => {
    dispatch(setScore([score[0], num]))
  }
  
  const onClickReset = () => {
    dispatch(setScore([0, 0]))
  }
  
  return <Block className={'flex-center flex-column'} headerText={'Счёт'}>
    <div className={'d-flex'} style={{width: '100%', justifyContent: 'space-between'}}>
      <Counter value={score[0]} setValue={setScoreFirstTeam}/>
      <UDIcon componentProps={{onClick: onClickReset}} name={'cyclic-arrow'}/>
      <Counter value={score[1]} setValue={setScoreSecondTeam}/>
    </div>
  </Block>;
}
