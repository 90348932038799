import supabase from "../../../core/supabase/supabase";

export async function getFouls(matchId: number): Promise<[number, number]> {
  const receivedData = await supabase.matchChronology.getRow(matchId)
  const data = receivedData && receivedData.length > 0 ? receivedData[ 0 ] : undefined
  const chronology = data?.chronology
  let firstTeam = 0
  let secondTeam = 0
  for (const receivedEvent of chronology ?? []) {
    if (receivedEvent.type === 'FOUL') {
      if (receivedEvent.teamId === data?.firstTeamId) {
        firstTeam += 1
      } else {
        secondTeam += 1
      }
    }
  }
  
  return [firstTeam, secondTeam]
}
