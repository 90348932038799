import { useMemo } from 'react';
import { useSelector } from "react-redux";
import { useQuery } from 'react-query';
import { NumberParam, StringParam, useQueryParams, withDefault, } from 'use-query-params';
import { format, parseISO } from 'date-fns';
import { authStoreUserTournamentId } from 'modules/auth/store/selectors';
import tourneysRepository from 'modules/tourneys/domain/repositories/tourneysRepository';
import debounce from 'modules/utils/debounce';

const defaultFilters = {
  search: StringParam,
  page: withDefault(NumberParam, 1),
  per_page: withDefault(NumberParam, 9),
  year: withDefault(NumberParam, null),
};

export const useTourneysList = () => {
  const tournamentId = useSelector(authStoreUserTournamentId);
  const [filters, setFilters] = useQueryParams(defaultFilters);

  const changeFilter = useMemo(() => debounce(
    (changes: { [key: string]: any }) => {
      setFilters({
        ...filters,
        ...changes,
      });
    }, 300),
    [filters, setFilters],
  );

  const { data, isLoading, isFetched } = useQuery(
    ['tourneys', filters],
    async () => {
      if (tournamentId) {
        return await tourneysRepository.fetchChampionships(tournamentId);
      }
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const { tourneys, years, pages } = useMemo(() => {
    if (!isFetched || isLoading) {
      return {
        tourneys: [],
        years: [],
        pages: 0,
      };
    }

    const { tournament, years = [] } = data || {};
    const tourneys = tournament?.championships.filter((champ) => {
      if (!filters.search && !filters.year) {
        return champ;
      }

      const { name, startDate, endDate } = champ;
      const startYear = startDate ? parseInt(format(parseISO(startDate), 'yyyy')) : null;
      const endYear = endDate ? parseInt(format(parseISO(endDate), 'yyyy')) : null;

      const includesSearch = (!filters.search || name.toLowerCase().includes(filters.search.toLowerCase()));
      const includesYear = (
        !filters.year
        || (startYear && endYear && (startYear === filters.year || endYear === filters.year))
        || (startYear && !endYear && startYear <= filters.year)
      );

      return includesSearch && includesYear;
    }) || [];

    const pages = Math.ceil(tourneys.length / filters.per_page);
    const offset = (filters.page - 1) * filters.per_page;
    const pageTourneys = tourneys.slice(offset, offset + filters.per_page);

    return {
      tourneys: pageTourneys,
      years,
      pages,
    };

  }, [data, isLoading, isFetched, filters]);

  return {
    changeFilter,
    filters,
    tourneys,
    years,
    isLoading,
    pages,
  };
};
