import { TeamGroup } from '../../../../../domain/interfaces/TeamGroup';
import StageGroup from './stage-group/stage-group';
import { Team } from '../../../../../../teams/domain/interfaces/Team';

type StageGroupListProps = {
  groups: TeamGroup[];
  teams: Team[];
};

const StageGroupList = ({ groups, teams }: StageGroupListProps) => {
  return (
    <div>
      {groups.map(group => (
        <StageGroup
          key={group.id}
          group={group}
          teams={teams}
        />
      ))}
    </div>
  );
};

export default StageGroupList;
