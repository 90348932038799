import React from 'react';

type CircleAttentionIconProps = {
  width: number;
  height: number;
  color?: string;
};

const CircleAttentionIcon = ({ width, height, color = '#F5D956' }: CircleAttentionIconProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_3614_23730"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width={width}
        height={height}
      >
        <rect width={width} height={height} fill="white"/>
      </mask>
      <g mask="url(#mask0_3614_23730)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2C12 2 6.48 2 12 2ZM11 17V15H13V17H11ZM11 7V13H13V7H11Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default CircleAttentionIcon;
