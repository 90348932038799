import styled from '@emotion/styled';

export const StyledSelectControl = styled.div`
  background-color: transparent;
  border-radius: 8px;
  overflow: hidden;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  cursor: pointer;
  &.focused {
    border-color: transparent;
  }
  &.disabled {
    opacity: 0.5;
    pointer-events:none;
  }
  &.opened {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

export const StyledSelectPlaceholder = styled.div`
  padding-left: 48px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
