export const handleInputInterceptor = async (
  interceptor: (...value: any[]) => any | Promise<any>,
  ...args: any[]
) => {
  const executionResult = interceptor(...args);
  if (executionResult instanceof Promise) {
    const response = await executionResult;
    return response;
  }
  return executionResult;
};
