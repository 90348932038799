import { Canvas, IGroupOptions, IRectOptions, StaticCanvas } from "fabric/fabric-impl";
import SnapToGrid from "./snapToGrid";
import { fabric } from "fabric";
import { myTextOptions } from "../types";
import customization from "./customization";

export default class Elements {
  
  static getLeftTop(canvas: Canvas | StaticCanvas) {
    const viewport = canvas.viewportTransform;
    const zoom = canvas.getZoom()
    const left = SnapToGrid.snapGrid(viewport ? -1 * viewport[ 4 ] / zoom + 20 : 20);
    const top = SnapToGrid.snapGrid(viewport ? -1 * viewport[ 5 ] / zoom + 20 : 20);
    return { left, top }
  }
  
  static createText(text: string, options: myTextOptions) {
    const centerParent = options.onCenterParent?.getCenterPoint()
    
    return new fabric.Text(text, {
      left: centerParent ? centerParent.x : options.left,
      top: centerParent ? centerParent.y : options.top,
      ...customization.getAllProperties(),
      ...options,
    })
  }
  
  static blockWithText(text: string, options?: {
    blockOptions?: IRectOptions,
    textOptions?: myTextOptions,
    groupOptions?: IGroupOptions
  }) {
    const { blockOptions = {}, textOptions = {}, groupOptions = {} } = options ?? {}
    const fontSize = +(textOptions.fontSize ?? 20).toFixed()
    const block = new fabric.Rect({ ...blockOptions, strokeWidth: 0, })
    const textBlock = this.createText(text, {
      ...textOptions,
      fontSize,
      textAlign: 'center',
      onCenterParent: block,
      originX: 'center',
      originY: 'center',
      fontFamily: 'SF Pro Text, sans-serif',
      strokeWidth: 0,
      ...customization.getAllProperties(),
    })
    return new fabric.Group([block, textBlock], { ...groupOptions, strokeWidth: 0, })
  }
  
  static createPlaceholder(canvas: Canvas | StaticCanvas, text: string, name: string, type: string) {
    const { left, top } = this.getLeftTop(canvas)
    
    return this.blockWithText(text, {
      blockOptions: {
        width: 300,
        height: 100,
        strokeWidth: 0,
        fill: 'rgba(230, 230, 230, 0.6)',
        ...customization.getAllProperties(),
      },
      textOptions: {
        fontSize: 20,
        fontFamily: 'Open Sans, sans-serif',
        fill: 'black',
        fontWeight: 'normal',
        textAlign: 'center',
        ...customization.getAllProperties(),
      },
      groupOptions: {
        left,
        top,
        ...customization.getAllProperties(),
        data: { name, fontSize: 20, fontFamily: 'Open Sans, sans-serif', color: '#000000', fontWeight: 'normal', type }
      }
    })
  }
  
  static createTextPlaceholder(canvas: Canvas | StaticCanvas, text: string, name: string, type: string) {
    const { left, top } = this.getLeftTop(canvas)
    
    const textbox = new fabric.Textbox(text, {
      fontSize: 20,
      fontFamily: 'Open Sans, sans-serif',
      fill: 'black',
      fontWeight: 'normal',
      textAlign: 'center',
      originX: 'center',
      originY: 'center',
      strokeWidth: 0,
      ...customization.getAllProperties(),
    });
    
    return new fabric.Group([textbox], {
      backgroundColor: 'rgba(230, 230, 230, 0.6)',
      strokeWidth: 0,
      left,
      top,
      ...customization.getAllProperties(),
      data: {
        name,
        fontSize: 20,
        fontFamily: 'Open Sans, sans-serif',
        color: '#000000',
        fontWeight: 'normal',
        type,
        textAlign: 'center'
      }
    })
  }
}
