import classNames from 'classnames';
import React from 'react';

export type UDTextType =
  | 'title-large'
  | 'title-1'
  | 'title-2'
  | 'title-3'
  | 'headline'
  | 'body'
  | 'callout'
  | 'subhead'
  | 'footnote'
  | 'caption-1'
  | 'caption-2'
  | 'tip';

const componentsMap: Record<UDTextType, any> = {
  'title-large': 'h1',
  'title-1': 'h1',
  'title-2': 'h2',
  'title-3': 'h3',
  headline: 'h1',
  body: 'p',
  callout: 'p',
  subhead: 'p',
  footnote: 'p',
  'caption-1': 'p',
  'caption-2': 'p',
  tip: 'p',
};

type ComponentProps =
  | React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLHeadingElement>,
      HTMLHeadingElement
    >
  | React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLParagraphElement>,
      HTMLParagraphElement
    >;

type Props = {
  type: UDTextType;
  children: React.ReactNode;
} & Omit<ComponentProps, 'children'>;

const UDText = (props: Props) => {
  const { type, children, className, ...otherProps } = props;
  const Component = componentsMap[type];
  return (
    <Component {...otherProps} className={classNames('text', type, className)}>
      {children}
    </Component>
  );
};

export default UDText;
