import { useMemo } from 'react';
import { SymbolicTeamFormation, SymbolicTeamPlayer } from '../../../../../domain';
import { PlayerCardAvatar, PlayerCardContainer } from './player-card.styles';
import { PlayerInfo } from './player-info';

type PlayerCardProps = {
  className?: string;
  formation: SymbolicTeamFormation;
  player: SymbolicTeamPlayer,
  isRankActive: boolean;
  onSelect?: (rank: number) => void;
};

export const PlayerCard = ({ className, player, isRankActive, onSelect }: PlayerCardProps) => {
  const userAvatar = useMemo(() => {
    return isRankActive ? undefined : player.user?.avatar;
  }, [isRankActive, player.user?.avatar]);

  return (
    <div className={className}>
      <PlayerCardContainer>
        <PlayerCardAvatar avatar={userAvatar} />
        <PlayerInfo
          player={player}
          isRankActive={isRankActive}
          onSelect={onSelect}
        />
      </PlayerCardContainer>
    </div>
  );
};
