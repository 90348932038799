import React from 'react';
import { Divider, Image, ImageBlock, InfoContainer, MainBlock, SportType } from "./styles";
import { IOrganizerCard } from "../../../domain/interfaces/OrganizerCard";
import { format } from "date-fns";
import UDText from "../../../../ud-ui/components/text";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { DivProps } from "../../../../../typings";

type IProps = IOrganizerCard & DivProps

function OrganizerCard(props: IProps) {
  const { t } = useTranslation()
  
  const {
    name,
    phone,
    logo = 'https://www.iconexperience.com/_img/o_collection_png/green_dark_grey/512x512/plain/user.png',
    user,
    address,
    createAt,
    sportType,
    ...rest
  } = props
  
  return (
    <MainBlock {...rest}>
      <InfoContainer width={250} gap={8}>
        <ImageBlock>
          <Image src={logo} alt="logo"/>
        </ImageBlock>
        <UDText
          type={'subhead'}
          style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
          className={'bold'}
        >
          {name}
        </UDText>
      </InfoContainer>
      <Divider/>
      <InfoContainer style={{ flexGrow: 1, justifyContent: 'center' }} gap={20}>
        {user.name && <UDText type={'subhead'}>{user?.surname} {user?.name} {user?.middleName}</UDText>}
        {phone && <UDText type={'subhead'}>{phone}</UDText>}
        {address?.city && <UDText type={'subhead'}>{address?.city}</UDText>}
      </InfoContainer>
      <Divider/>
      <InfoContainer width={250} gap={30}>
        <div style={{ display: 'flex', gap: 2 }}>
          <UDText type={'subhead'} style={{ color: '#8F8E99' }}>Создан: </UDText>
          <UDText type={'subhead'} style={{ display: 'flex' }}>
            {format(createAt, 'dd.MM.yyyy')}
          </UDText>
        </div>
        <SportType>
          <UDText
            style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
            type={'subhead'}
          >
            {_.capitalize(t(`sportType.${sportType}`))}
          </UDText>
        </SportType>
      </InfoContainer>
    </MainBlock>
  );
}

export default OrganizerCard;
