import { createAsyncThunk } from '@reduxjs/toolkit';
import { UpsertTournamentApplicationUserDto } from '../../domain/dtos/UpsertTournamentApplicationUser.dto';
import { TournamentUser } from '../../domain/interfaces/TournamentUser';
import playersRepository from '../../domain/repositories/players.repository';
import { UpsertTournamentPlayerApplicationDto } from '../../domain/dtos/UpsertTournamentPlayerAction.dto.';
import { KickFromTeamInputDto } from '../../domain/dtos/KickFromTeamInput.dto';
import playersResource from '../../domain/resources/playersResource';

const PREFIX = 'teams/players';

export const getTeamPlayers = createAsyncThunk<
  TournamentUser[],
  number
>(`${PREFIX}/getTeamPlayers`, async (payload) => {
  try {
    return await playersRepository.getTournamentUsers(payload);
  } catch (err) {
    console.error(`${PREFIX}/getTeamPlayers error:`, err);
    throw err;
  }
});

export const upsertTournamentPlayerApplication = createAsyncThunk<
  TournamentUser,
  UpsertTournamentPlayerApplicationDto
>(`${PREFIX}/upsertTournamentPlayerApplication`, async (payload) => {
  try {
    const {
      tournamentId,
      userId,
      name,
      surname,
      middleName,
      birthDate,
      photo,
      height,
      weight,
      // position,
      teamId,
    } = payload;
    let player: UpsertTournamentApplicationUserDto = {
      name,
      surname,
      birthDate,
    };

    if (middleName) {
      player = { ...player, middleName };
    }

    if (height) {
      player = { ...player, height };
    }

    if (weight) {
      player = { ...player, weight };
    }

    // if (position) {
    //   player = { ...player, position };
    // }

    if (userId) {
      player = { ...player, userId };
    }

    let result = await playersRepository.upsertTournamentApplicationForUser(
      player,
      tournamentId
    );
    const { id: playerId } = result;

    if (photo && photo[0]) {
      await playersResource.uploadPhoto(
        photo[0],
        playerId,
        tournamentId
      );
    }

    if (!userId) {
      await playersRepository.addUserToTeam({
        userId: playerId,
        teamId,
        // position,
      });
    }

    return await playersRepository.getUser(playerId);
  } catch (err) {
    console.error(`${PREFIX}/upsertTournamentPlayerApplication error:`, err);
    throw err;
  }
});

export const deleteTournamentPlayerApplication = createAsyncThunk<
  KickFromTeamInputDto,
  KickFromTeamInputDto
>(`${PREFIX}/deleteTournamentPlayerApplication`, async (payload) => {
  try {
    const { userId, teamId } = payload;

    await playersRepository.kickUserFromTeam({ teamId, userId });
    await playersRepository.deleteTournamentApplicationUser(userId);

    return payload;
  } catch (err) {
    console.error(`${PREFIX}/deleteTournamentPlayerApplication error:`, err);
    throw err;
  }
});

export const fetchSuggestTournamentPlayers = createAsyncThunk<TournamentUser[], { tournamentId: number; nameFilter: string; }>(
  `${PREFIX}/fetchSuggestTournamentPlayers`,
  async (payload) => {
    try {
      return await playersRepository.suggestTournamentPlayers(payload.tournamentId, payload.nameFilter);
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);
