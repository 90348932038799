const floppy = (
  <>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.11111 1V3.64444C4.11111 4.08004 4.11111 4.29784 4.19588 4.46421C4.27045 4.61056 4.38944 4.72955 4.53579 4.80412C4.70216 4.88889 4.91996 4.88889 5.35556 4.88889H10.6444C11.08 4.88889 11.2978 4.88889 11.4642 4.80412C11.6106 4.72955 11.7295 4.61056 11.8041 4.46421C11.8889 4.29784 11.8889 4.08004 11.8889 3.64444V1.77778M11.8889 15V10.0222C11.8889 9.58663 11.8889 9.36883 11.8041 9.20245C11.7295 9.0561 11.6106 8.93712 11.4642 8.86255C11.2978 8.77778 11.08 8.77778 10.6444 8.77778H5.35556C4.91996 8.77778 4.70216 8.77778 4.53579 8.86255C4.38944 8.93712 4.27045 9.0561 4.19588 9.20245C4.11111 9.36883 4.11111 9.58663 4.11111 10.0222V15M15 5.91982V11.2667C15 12.5735 15 13.2269 14.7457 13.726C14.522 14.165 14.165 14.522 13.726 14.7457C13.2269 15 12.5735 15 11.2667 15H4.73333C3.42654 15 2.77315 15 2.27402 14.7457C1.83498 14.522 1.47802 14.165 1.25432 13.726C1 13.2269 1 12.5735 1 11.2667V4.73333C1 3.42654 1 2.77315 1.25432 2.27402C1.47802 1.83498 1.83498 1.47802 2.27402 1.25432C2.77315 1 3.42654 1 4.73333 1H10.0802C10.4607 1 10.6509 1 10.8299 1.04298C10.9886 1.08109 11.1404 1.14394 11.2796 1.22923C11.4365 1.32542 11.5711 1.45994 11.8401 1.72898L14.271 4.15991C14.5401 4.42895 14.6746 4.56346 14.7708 4.72045C14.8561 4.85962 14.9189 5.01136 14.957 5.17008C15 5.34911 15 5.53935 15 5.91982Z"
        stroke="#1A1A29" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </>
);

export default floppy
