import axios, { AxiosError } from 'axios'
import { Resource } from 'modules/core/baseResource';
import httpResource from 'modules/core/httpResource';
import { AuthRequestAuthCodePayload } from '../interfaces/AuthRequestAuthCodePayload';
import { AuthRestorePayload } from '../interfaces/AuthRestorePayload';
import { AuthSignInPayload } from '../interfaces/AuthSignInPayload';
import { AuthSignInResponse } from '../interfaces/AuthSignInResponse';
import { AuthSignUpPayload } from '../interfaces/AuthSignUpPayload';
import { AuthSignUpErrorResponse, AuthSignUpResponse } from '../interfaces/AuthSignUpResponse';
import { AuthAvailableTournament } from '../interfaces/AuthAvailableTournament';

interface AvailableTournamentDTO {
  id: number,
  name: string;
  sport_type: string;
  slug?: string;
  url?: string;
}

class AuthResource extends Resource {
  constructor() {
    super('users', httpResource);
  }

  public async requestAuthCode(data: AuthRequestAuthCodePayload): Promise<void>{
    const response = await this.child('get-temp-password').post(data);
  }

  public signIn(data: AuthSignInPayload): Promise<AuthSignInResponse> {
    return this.absolute('login_check').post(data);
  }

  public async signUp(data: AuthSignUpPayload): Promise<AuthSignUpResponse> {
    const {
      firstName: name,
      lastName: surname,
      middleName,
      phone,
    } = data;

    return this.child('get-temp-password').post({
      name,
      surname,
      middleName,
      phone,
      roles: ['ROLE_TOURNAMENT_ADMIN'],
    }, {
      validateStatus: (status) => status < 500,
    });
  }

  public restore(data: AuthRestorePayload) {
    return this.child('restore').post(data);
  }

  public check(): Promise<AuthSignInResponse['user']> {
    return this.child('current').get();
  }

  public async getAvailableTournaments(): Promise<AuthAvailableTournament[]> {
    const tournaments = await this.absolute('/tournaments').get<AvailableTournamentDTO[]>();

    return tournaments.map((item) => ({
      id: item.id,
      name: item.name,
      sportType: item.sport_type,
    }));
  }

  public async getTournamentId(): Promise<number | null> {
    const tournaments = await this.getAvailableTournaments();

    return tournaments[0].id;
  }
}

const authResource = new AuthResource();
export default authResource;
