import React, { useCallback } from 'react';
import ApplicationComponentsApplicationCard from 'modules/applications/ui/components/card';
import { useDispatch, useSelector } from 'react-redux';
import {
  championshipApplicationsDataSelector,
  championshipDataSelector,
  championshipTeamsIsLoadedSelector,
  championshipTeamsIsLoadingSelector,
  updatingApplicationTeamIdSelector
} from '../../../../../store/championship/selectors';
import { Application } from '../../../../../../applications/domain/interfaces/Application';
import {
  acceptApplication, declineApplication, fetchChampionshipTeams
} from '../../../../../store/championship/actions';
import { ApplicationsSkeleton } from './applications-skeleton';

const TourneysComponentsEditFormPartApplications = () => {
  const dispatch = useDispatch();
  const championship = useSelector(championshipDataSelector);
  const isLoading = useSelector(championshipTeamsIsLoadingSelector);
  const isLoaded = useSelector(championshipTeamsIsLoadedSelector);
  const applications = useSelector(championshipApplicationsDataSelector);
  const updatingApplicationTeamId = useSelector(updatingApplicationTeamIdSelector);

  const onApprove = useCallback((application: Application) => {
    if (!championship) {
      return;
    }

    dispatch(acceptApplication({
      championshipId: application.championshipId,
      teamId: application.teamId,
      userIds: application.userIds,
    }));
  }, [dispatch, championship]);

  const onDecline = useCallback((application: Application) => {
    if (!championship) {
      return;
    }

    dispatch(declineApplication({
      championshipId: application.championshipId,
      teamId: application.teamId,
      userIds: application.userIds,
    }));
  }, [dispatch, championship]);

  if (!isLoaded && !isLoading && championship) {
    dispatch(fetchChampionshipTeams(championship.id));
  }

  if (isLoading || !championship) {
    return (
      <div className="container pt-6">
        <ApplicationsSkeleton />
      </div>
    );
  }

  if (!applications.length) {
    return (
      <div className="container pt-6">
        <div className="text subhead bold mb-4">Данный раздел пуст.</div>
        <div className="text subhead">
          Команды еще не отправляли заявки.
        </div>
      </div>
    );
  }

  return (
    <div className="container pt-6">
      {applications.map((application) => (
        <ApplicationComponentsApplicationCard
          key={`${application.championshipId}-${application.teamId}`}
          isLoading={application.teamId === updatingApplicationTeamId}
          application={application}
          onApprove={onApprove}
          onDecline={onDecline}
        />
      ))}
    </div>
  );
};

export default TourneysComponentsEditFormPartApplications;
