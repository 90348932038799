import styled from '@emotion/styled';

export const CardContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.SurfaceWhite};
  border-radius: 8px;
`;

export const PlayerCardHeaderRow = styled.div`
  padding: 16px;
  background-color: ${({ theme }) => theme.colors.SurfaceWhite};
  border-radius: 8px;
  box-shadow: 0px 0px 1px rgba(26, 26, 41, 0.08), 0px 8px 16px rgba(26, 26, 41, 0.08);

  & .player-avatar {
    flex-shrink: 0;
    object-fit: contain;
    margin-right: 18px
  }

  & .player-card {
    display: block;
    .player-column {
      display: flex;
      align-items: center;
    }
  }
`;