import { Tab } from 'modules/ud-ui/components/tabs';

type EditFormTabKey =
  | 'COMMON'
  | 'RULES'
  | 'STEPS'
  | 'TEAMS'
  // | 'GAMES'
  // | 'APPLICATIONS'
  | 'SYMBOLIC_TEAMS'
  // | 'RESULTS';

export const TourneyEditFormPart: Record<EditFormTabKey, Tab> = {
  COMMON: { hash: 'common', label: 'Основные данные' },
  RULES: { hash: 'rules', label: 'Условия' },
  // APPLICATIONS: { hash: 'applications', label: 'Заявки' },
  TEAMS: { hash: 'teams', label: 'Команды' },
  STEPS: { hash: 'steps', label: 'Этапы' },
  // GAMES: { hash: 'games', label: 'Матчи' },
  SYMBOLIC_TEAMS: { hash: 'symbolic-teams', label: 'Сборные' },
};

export const defaultTab = TourneyEditFormPart.COMMON;
