import graphqlResource from "../../../../../../../core/graphqlResource";

interface LastGameResultDTO {
  data: {
    matchPreview: {
      championshipTable: {
        rows: {
          position: number;
          team: {
            id: number;
            name: string;
          };
          gamesPlayed: number;
          gamesWon: number;
          gamesDraw: number;
          gamesLost: number;
          goalsWon: number;
          goalsLost: number;
          championshipScore: number;
          lastGamesResults: {
            matchId: number;
            result: string;
          }[];
        }[];
      };
    };
  };
}

export async function getLastGameResult(matchId: number) {
  const query = `
      query getChampionshipMatchPreview($matchId: Int!) {
        matchPreview(matchId: $matchId) {
          championshipTable {
            rows {
              position
              team {
                id
                name
              }
              gamesPlayed
              gamesWon
              gamesDraw
              gamesLost
              goalsWon
              goalsLost
              championshipScore
              lastGamesResults {
                matchId
                result
              }
            }
          }
        }
      }
    `;

  const response = await graphqlResource.query<LastGameResultDTO>(query, { matchId});
  return response.data.data.matchPreview.championshipTable.rows
}
