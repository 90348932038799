import { useSelector } from 'react-redux';
import { gameDetailsIsLoadingSelector, gameDetailsDataSelector } from '../../store/selectors'

export const useGameDetails = () => {
  const isLoading = useSelector(gameDetailsIsLoadingSelector);
  const data = useSelector(gameDetailsDataSelector);

  return {
    isLoading,
    data,
  };
};
