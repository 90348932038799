import React from 'react';
import { useTranslation } from 'react-i18next';
import { EmptyPlayersMessageContainer } from './empty-players.message.styles';

export const EmptyPlayersMessage = () => {
  const { t } = useTranslation();

  return (
    <EmptyPlayersMessageContainer>
      {t('teams.noPlayers')}
    </EmptyPlayersMessageContainer>
  );
};
