import classNames from 'classnames';
import React from 'react';
import UDText from '../text';

type Props = {
  className?: string;
};

const UDCopyright = (props: Props) => {
  return (
    <UDText
      type="callout"
      className={classNames('color-SurfaceRomanSilver30', props.className)}>
      {`© Roowix, ${new Date().getFullYear()}`}
    </UDText>
  );
};

export default UDCopyright;
