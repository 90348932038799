import styled from '@emotion/styled';

export const GameResultHeader = styled.div`
  background: #FFFFFF;
  border-radius: 8px;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  min-height: 112px;
  display: flex;
  justify-content: center;
  // align-items: center;

  padding-top: 10px;
  padding-bottom: 26px;

  &.is-loading {
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
  }

  @keyframes shine {
    to {
      background-position-x: -200%;
    }
  }

  .game-score {
    margin: 0 60px;
    font-weight: 700;
    font-size: 42px;
    line-height: 26px;
  }
`;

export const GameTeamInfo = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;

  .team-name {
    font-size: 17px;
    margin-left: 35px;
  }

  &.right-logo {
    .team-name {
      margin-left: 0;
      margin-right: 35px;
    }
  }
`;

export const EditScoreWrapper = styled.div`
  text-align: right;
`;

export const ScoreWrapper = styled.div`
  padding-right: 28px;
  padding-top: 4px;
`;

export const EditScoreButton = styled.div`
  display: inline-block;
  cursor: pointer;
`;