import React from 'react';
import { TeamInfo } from 'modules/teams/domain/interfaces/Team';
import { TeamPlayer } from 'modules/teams/domain/interfaces/TeamPlayer';
import UDCheckbox from 'modules/ud-form/components/checkbox/component';
import TeamCardPlayer from 'modules/teams/ui/components/team-card/team-card-player';
import TeamLogo from 'modules/ud-ui/components/team-logo';
import { SelectedPlayer, SymbolicTeamFormation } from '../../../../../domain';
import { PlayerRank } from './player-rank';
import { PlayerAdditional, PlayerContainer } from './player.styles';

type PlayerProps = {
  formation: SymbolicTeamFormation;
  team: TeamInfo;
  player: TeamPlayer;
  selectedPlayers: SelectedPlayer[];
  onPlayerSelect?: (player: TeamPlayer, team: TeamInfo) => void;
};

export const Player = (props: PlayerProps) => {
  const {
    formation,
    team,
    player,
    selectedPlayers,
    onPlayerSelect,
  } = props;

  const currentSelectedPlayer = selectedPlayers.find((item) => item.userId === player.userId);
  const isSelected = !!currentSelectedPlayer;

  return (
    <PlayerContainer>
      <div>
        <UDCheckbox
          checked={isSelected}
          onChange={() => onPlayerSelect && onPlayerSelect(player, team)}
        />
      </div>
      <div className="player-info-container">
        <TeamCardPlayer
          className="team-player-item"
          key={player.id}
          player={player}
        />
        <PlayerAdditional>
          {currentSelectedPlayer && (
            <PlayerRank
              className="mr-2"
              formation={formation}
              rank={currentSelectedPlayer.rank}
            />
          )}
          <TeamLogo
            className="team-logo mr-2"
            url={team.logo?.url}
            name={team.name}
            size={42}
          />
        </PlayerAdditional>
      </div>
    </PlayerContainer>
  );
};
