import React from 'react';
import { TeamPlayer } from 'modules/teams/domain/interfaces/TeamPlayer';
import { TeamInfo } from 'modules/teams/domain/interfaces/Team';
import { SelectedPlayer, SymbolicTeamFormation } from '../../../../domain';
import { EmptyPlayersMessage } from './empty-palyers-message';
import { Player } from './player';
import { PayersListContent, PayersListWrapper } from './players-list.styles';

type PlayersListProps = {
  formation: SymbolicTeamFormation;
  allPlayers: TeamPlayer[];
  selectedPlayers: SelectedPlayer[];
  onPlayerSelect?: (player: TeamPlayer, team: TeamInfo) => void;
};

export const PlayersList = ({ formation, allPlayers, selectedPlayers, onPlayerSelect }: PlayersListProps) => {
  return (
    <PayersListWrapper>
      <PayersListContent>
        {!allPlayers.length && (<EmptyPlayersMessage />)}
        {allPlayers.map((player) => (
          <Player
            key={player.userId}
            team={player.team!}
            formation={formation}
            player={player}
            selectedPlayers={selectedPlayers}
            onPlayerSelect={onPlayerSelect}
          />
        ))}
      </PayersListContent>
    </PayersListWrapper>
  );
};
