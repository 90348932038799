import getGameInfo from "../repositories/MatchById";
import { gameInfoDataMapper } from "../mappers/gameInfoDataMapper";
import getChampionshipStats from "../repositories/championshipStatsByPlayer";
import { updateMembersStats } from "./updateMembersStats";

export async function getMappedGameInfo(matchId: number) {
  const rawGameInfo = await getGameInfo(matchId)
  if (!rawGameInfo) return undefined
  if (!rawGameInfo.championship) return undefined
  const championshipStats = await getChampionshipStats(rawGameInfo.championship.id)
  const gameInfoMapped = gameInfoDataMapper(rawGameInfo, championshipStats)
  await updateMembersStats(gameInfoMapped)
  return gameInfoMapped
}
