import styled from '@emotion/styled';
import UDButton from 'modules/ud-ui/components/button';
import teamDefaultAvatar from 'modules/ud-ui/components/team-logo/default-team-logo.png';

export const SelectPlayerButton = styled(UDButton)`
  width: 100%;
  height: 26px;
  line-height: 9px !important;
  border-radius: 4px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25), 0px -4px 16px rgba(0, 0, 0, 0.25);
`;

export const PlayerInfoContainer = styled.div`
  box-shadow: 0 4px 4px rgb(0 0 0 / 25%), 0px -4px 16px rgb(0 0 0 / 25%);
  background: ${({ theme }) => theme.colors.SurfaceIndependence40};
  cursor: pointer;
  width: 95%;
  height: 26px;
  border-radius: 4px;
  position: relative;
  display: flex;
  align-items: center;
  
  &.activated {
    cursor: auto;
  }
`;

export const PlayerInfoTeamLogoContainer = styled.div`
  background: ${({ theme }) => theme.colors.SurfaceWhite};
  border-radius: 4px 0 0 4px;
  padding: 3px;
  width: 26px;
  height: 26px;
  position: relative;
`;

type PlayerInfoTeamLogoProps = {
  logo?: string;
};

export const PlayerInfoTeamLogo = styled.div<PlayerInfoTeamLogoProps>`
  background-image: url(${props => props.logo || teamDefaultAvatar});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
`;

export const PlayerInfoPlayerName = styled.div`
  background: #2D2D47;
  color: ${({ theme }) => theme.colors.SurfaceWhite};
  width: 100%;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
