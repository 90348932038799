import React from 'react';
import { useTranslation } from 'react-i18next';
import { TeamPlayer } from 'modules/teams/domain/interfaces/TeamPlayer';
import { TeamPlayerContainer, TeamPlayerAvatar } from './styles';

type TeamCardPlayerProps = {
  player: TeamPlayer;
  className?: string;
};

const TeamCardPlayer = (props: TeamCardPlayerProps) => {
  const { player, className } = props;
  const { t } = useTranslation();

  return (
    <TeamPlayerContainer className={className}>
      <TeamPlayerAvatar
        url={player.avatar}
        size={36}
      />
      <div className="player-info">
        <div className="player-name">{player.firstName}{'\u00A0'}{player.lastName}</div>
        <div className="player-position">#{player.number} {t(`player.position.${player.position}`)}</div>
      </div>
    </TeamPlayerContainer>
  );
};

export default TeamCardPlayer;
