import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { PlayerCardHeaderRow } from './styles';
import UDText from 'modules/ud-ui/components/text';
import { TeamPlayerAvatar } from '../../../team-card/styles';
import { TournamentUser } from '../../../../../domain/interfaces/TournamentUser';
import UDButton from 'modules/ud-ui/components/button';
import { format, differenceInYears } from 'date-fns';
import { deleteTournamentPlayerApplication } from '../../../../../store/players/actions';
import { DivProps } from "../../../../../../../typings";

type PlayerCardHeaderProps = {
  teamId: number;
  player: TournamentUser;
  containerProps?: Omit<DivProps, 'children'>;
  index: number;
  setPlayerId: Dispatch<SetStateAction<number>>;
  setShowForm: Dispatch<SetStateAction<boolean>>;
};

const defaultAvatar =
  'https://www.iconexperience.com/_img/o_collection_png/green_dark_grey/512x512/plain/user.png';

const PlayerCardHeader = (props: PlayerCardHeaderProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { teamId, player, containerProps, index, setPlayerId, setShowForm } =
    props;

  const name = [player.surname, player.name, player.middleName]
    .filter(Boolean)
    .join(' ');
  // const positions = {
  //   goalkeeper: 'Вратарь',
  //   defender: 'Защитник',
  //   midfielder: 'Полузащитник',
  //   forward: 'Нападающий',
  // };
  const birthDate = player.birthDate ? new Date(player.birthDate) : null;
  const birthDateString = birthDate ? format(birthDate, 'dd.MM.yyyy') : '';
  const years = birthDate ? differenceInYears(new Date(), birthDate) : null;
  const yearsString = years ? t('ages', { count: years }) : '';
  const fullBirthDate =
    [birthDateString, yearsString].filter(Boolean).join(', ') || '-';
  const handleDelete = useCallback(() => {
    dispatch(
      deleteTournamentPlayerApplication({
        userId: player.id,
        teamId,
      })
    );
  }, [dispatch, teamId, player.id]);

  return (
    <PlayerCardHeaderRow
      {...containerProps}
      className={classNames(containerProps?.className)}
      onClick={() => {}}
    >
      <div className="player-card">
        <div className="row">
          {index !== undefined ? (
            <UDText type="subhead" className="player-column bold col-1">
              {index + 1}
            </UDText>
          ) : (
            ''
          )}

          <div className="player-column col-4">
            <TeamPlayerAvatar
              url={
                (player.tournamentAvatars?.length &&
                  player.tournamentAvatars[player.tournamentAvatars.length - 1]
                    .image.externalUrl) ||
                defaultAvatar
              }
              className="player-avatar"
              size={40}
              square
            />
            <UDText type="subhead" className="bold">
              {name}
            </UDText>
          </div>

          {/* <UDText type="subhead" className="player-column col-2">
            {player.position ? positions[player.position] : '-'}
          </UDText> */}

          <UDText
            type="subhead"
            className="player-column col-2"
            style={{ flexBasis: '15%' }}
          >
            {fullBirthDate}
          </UDText>

          <div className="player-column col-5 d-flex justify-content-end">
            <UDButton
              className="d-flex align-items-center mr-3"
              variant="primary"
              onClick={() => {
                setPlayerId(player.id);
                setShowForm(true);
              }}
            >
              <span>Редактировать</span>
            </UDButton>

            <UDButton
              className="d-flex align-items-center"
              variant="secondary"
              onClick={handleDelete}
            >
              <span>Исключить</span>
            </UDButton>
          </div>
        </div>
      </div>
    </PlayerCardHeaderRow>
  );
};

export default PlayerCardHeader;
