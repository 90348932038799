const alignTop = (<>
  <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_11652_13712)">
      <rect width="1" height="16" rx="0.5" transform="matrix(1.19249e-08 1 1 -1.19249e-08 0.666748 0)" fill="#007AFF"/>
      <path
        d="M7.35681 3C6.18075 3 5.25855 4.00984 5.36503 5.18107L6.00139 12.1811C6.09504 13.2112 6.95877 14 7.99318 14L9.34032 14C10.3747 14 11.2385 13.2112 11.3321 12.1811L11.9685 5.18107C12.0749 4.00984 11.1527 3 9.97668 3L7.35681 3Z"
        fill="#007AFF"/>
    </g>
    <defs>
      <clipPath id="clip0_11652_13712">
        <rect width="14" height="16" fill="white" transform="matrix(1.19249e-08 1 1 -1.19249e-08 0.666748 0)"/>
      </clipPath>
    </defs>
  </svg>
</>)

export default alignTop
