const alignCenterVertical = (<>
  <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="6.5" width="1" height="16" rx="0.5" fill="#007AFF"/>
    <path
      d="M0.5 7C0.5 5.89543 1.39543 5 2.5 5L11.5 5C12.6046 5 13.5 5.89543 13.5 7V9C13.5 10.1046 12.6046 11 11.5 11L2.5 11C1.39543 11 0.5 10.1046 0.5 9L0.5 7Z"
      fill="#007AFF"/>
  </svg>
</>)

export default alignCenterVertical
