import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { Championship } from 'modules/tourneys/domain/interfaces/Championship';
import { useOrganizer } from '../../../../organizer/ui/hooks/useOrganizer';
import { usePlayers } from '../../../../organizer/ui/hooks/usePlayers';
import EditTeamForm from './edit-team-form';
import { Team } from 'modules/teams/domain/interfaces/Team';
import TeamsComponentsFormPlayersListPart from '../form-parts/players/players-list';
import { getTeamPlayers } from 'modules/teams/store/players/actions';
// import TeamsComponentsFormAdminsListPart from '../form-parts/admins/admins-list';

type Props = {
  team: Team;
  onSubmit?: (data: Championship) => any;
};

const TeamComponentsEditForm = (props: Props) => {
  const dispatch = useDispatch();
  const { info, isLoading } = useOrganizer();
  const { team } = props;

  useEffect(() => {
    if (info && !isLoading && team) {
      dispatch(getTeamPlayers(team.id));
    }
  }, [dispatch, isLoading, info, team]);

  const { info: players, isGettingPlayers } = usePlayers();

  if (isLoading || isGettingPlayers) {
    return null;
  }

  return (
    <Routes>
      <Route
        path="players"
        element={
          <TeamsComponentsFormPlayersListPart
            tournamentId={info!.id}
            teamId={team.id}
            players={players}
          />
        }
      />
      {/* <Route
        path="admins"
        element={
          <TeamsComponentsFormAdminsListPart
            tournamentId={info!.id}
            teamId={team.id}
          />
        }
      /> */}
      {info && (
        <Route
          path="*"
          element={<EditTeamForm tournamentId={info.id} team={props.team} />}
        />
      )}
    </Routes>
  );
};

export default TeamComponentsEditForm;
