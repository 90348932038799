import React, { HTMLProps, useCallback } from 'react';
import classNames from 'classnames';
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone';
import * as S from './styles';
import UDText from '../../../../../ud-ui/components/text';
import UDButton from '../../../../../ud-ui/components/button';

export type UDDropZoneProps = {
  onChange?: (...event: any[]) => void;
  onGetImg?: (img: any | null) => void;
  value: any[];
  isError?: boolean;
  dropZoneOptions?: Parameters<typeof useDropzone>[0];
} & Omit<HTMLProps<HTMLInputElement>, 'value'>;

const UDDropZone = (props: UDDropZoneProps) => {
  const {
    onChange,
    onGetImg,
    isError,
    value: files = [],
    dropZoneOptions,
    ...otherProps
  } = props;
  const maxFiles = dropZoneOptions?.maxFiles;
  const onDrop = useCallback(
    (
      acceptedFiles: File[],
      fileRejections: FileRejection[],
      event: DropEvent
    ) => {
      const couldAcceptMore =
        maxFiles !== undefined
          ? files.length + acceptedFiles.length <= maxFiles
          : true;
      if (onChange && couldAcceptMore) {
        onChange(acceptedFiles);
      }
      const path = acceptedFiles.map((file) => URL.createObjectURL(file));

      if (onGetImg) {
        onGetImg({
          url: path,
          size: acceptedFiles[0].size,
          name: acceptedFiles[0].name,
        });
      }
    },
    [onChange, files]
  );
  const {
    isFocused,
    isDragActive,
    acceptedFiles,
    getRootProps,
    getInputProps,
  } = useDropzone({
    ...dropZoneOptions,
    onDrop,
  });
  return (
    <S.Container
      className={classNames({
        error: !!isError,
        focused: isFocused,
        active: isDragActive,
      })}
      {...getRootProps()}
    >
      <input {...getInputProps()} {...otherProps} type="file" hidden />
      <UDText type="footnote" className="color-SurfaceRaisinBlack50 mb-2">
        Перетащите сюда изображения
      </UDText>
      <UDText type="footnote" className="color-SurfaceRomanSilver30 mb-2">
        или
      </UDText>
      <UDButton variant="secondary" type="button">
        Выберите файлы
      </UDButton>
    </S.Container>
  );
};

export default UDDropZone;
