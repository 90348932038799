import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { Game } from 'modules/games/domain/interfaces/Game';

export const groupGamesByDate = (games: Game[]) => {
  return games.reduce((acc, game, index) => {
    const groupDateKey = format(new Date(game.date), 'd MMM, eee', { locale: ru });
    if (!acc[groupDateKey]) {
      acc[groupDateKey] = [];
    }
    acc[groupDateKey].push({ index, game });
    return acc;
  }, {} as Record<string, { index: number; game: Game }[]>);
};
