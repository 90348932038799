import { format } from 'date-fns';
import ru from 'date-fns/locale/ru';
import { ChampionshipStats } from '../interfaces/championshipStats';
import { IGameInfo } from '../interfaces/gameInfo';
import { Championship, GameInfoMapped, Match, Members, Organizer, Team, Teams } from '../interfaces/gameInfoMapped';

export function gameInfoDataMapper(gameInfo: IGameInfo, champStats: ChampionshipStats[]): GameInfoMapped {
  return {
    match: extractMatch(gameInfo),
    teams: extractTeams(gameInfo, champStats),
    organizer: extractOrganizer(gameInfo),
    championship: extractChampionship(gameInfo),
  };
}

function extractMatch(gameInfo: IGameInfo): Match {
  const date = gameInfo?.date ? new Date(gameInfo?.date) : undefined
  
  return {
    id: gameInfo.id,
    dateInfo: {
      fullDate: gameInfo.date,
      date: date ? format(date, 'd MMMM', { locale: ru }) : 'Неизвестная дата',
      time: date ? format(date, 'HH:mm') : 'Неизвестное время',
    },
    address: {
      city: gameInfo?.tournament?.address?.city?.split(' ')[ 1 ] ?? 'Неизвестный город',
      country: gameInfo?.tournament?.address?.country,
      region: gameInfo?.tournament?.address?.region,
    },
  }
}

function extractTeams(gameInfo: IGameInfo, champStats: ChampionshipStats[]): Teams {
  return {
    firstTeam: extractTeam(gameInfo, champStats, 0),
    secondTeam: extractTeam(gameInfo, champStats, 1),
  }
}

function extractTeam(gameInfo: IGameInfo, champStats: ChampionshipStats[], teamIndex: number): Team | undefined {
  if (!gameInfo.teamMatches) return undefined
  
  const id = gameInfo.teamMatches[ teamIndex ].team.id
  const lastGameResult = gameInfo.championship?.championshipTable.rows.find((row) => row.team.id === id)?.lastGamesResults
  
  return {
    id: gameInfo.teamMatches[ teamIndex ]?.team?.id,
    name: gameInfo.teamMatches[ teamIndex ]?.team?.name,
    logo: gameInfo.teamMatches[ teamIndex ]?.team?.emblem?.externalUrl,
    lastGamesResults: lastGameResult ?? [],
    members: extractMembers(gameInfo, champStats, teamIndex),
    score: 0,
    fouls: 0,
  }
}

function extractMembers(gameInfo: IGameInfo, champStats: ChampionshipStats[], teamIndex: number): Members[] {
  if (!gameInfo.teamMatches) return []
  
  const championshipId = gameInfo.championship?.id
  const members = gameInfo.teamMatches[ teamIndex ].team.championshipApplications
  const filteredMembers: Members[] = []
  
  for (const member of members) {
    if (member.championship.id !== championshipId) continue
    if (member.state !== 'ACCEPTED') continue
    filteredMembers.push({
      id: member.user.id,
      name: member.user.name,
      surname: member.user.surname,
      middleName: member.user.middleName ?? '',
      photo: getPhoto(gameInfo, member.user.id, teamIndex),
      position: member.position,
      number: member.number,
      isInvolved: isInvolved(gameInfo, member.user.id),
      qtyGoalsMatch: 0,
      qtyYellowCardsMatch: 0,
      qtyRedCardsMatch: 0,
      qtyFoulsMatch: 0,
      ...getStats(champStats, member.user.id),
    })
  }
  
  return filteredMembers
}

function isInvolved(gameInfo: IGameInfo, memberId: number) {
  const member = gameInfo.participants?.find((participant) => participant.user.id === memberId)
  if (!member) return false
  return member.isInvolved
}

function getPhoto(gameInfo: IGameInfo, memberId: number, teamIndex: number) {
  if (!gameInfo.teamMatches) return 'https://www.iconexperience.com/_img/o_collection_png/green_dark_grey/512x512/plain/user.png'
  const member = gameInfo.teamMatches[ teamIndex ].team.members.find((member) => member.user.id === memberId)
  if (!member) return 'https://www.iconexperience.com/_img/o_collection_png/green_dark_grey/512x512/plain/user.png'
  return member.user.photo?.url ?? 'https://www.iconexperience.com/_img/o_collection_png/green_dark_grey/512x512/plain/user.png'
}

function getStats(champStats: ChampionshipStats[], memberId: number): {
  qtyYellowCardsChampionship: number, qtyRedCardsChampionship: number, qtyGoalsChampionship: number
} {
  const stats = champStats
  if (!stats) return { qtyYellowCardsChampionship: 0, qtyRedCardsChampionship: 0, qtyGoalsChampionship: 0 }
  const memberStats = stats.find((stat) => stat.user.id === memberId)
  if (!memberStats) return { qtyYellowCardsChampionship: 0, qtyRedCardsChampionship: 0, qtyGoalsChampionship: 0 }
  return {
    qtyYellowCardsChampionship: memberStats.yellowcards,
    qtyRedCardsChampionship: memberStats.redcards,
    qtyGoalsChampionship: memberStats.goals
  }
}

function extractOrganizer(gameInfo: IGameInfo): Organizer | undefined {
  if (!gameInfo.tournament) return undefined
  
  return {
    name: gameInfo.tournament?.name ?? 'Неизвестно',
    logo: gameInfo.tournament?.images?.[ 0 ]?.externalUrl,
    id: gameInfo.tournament?.id,
  }
}

function extractChampionship(gameInfo: IGameInfo): Championship | undefined {
  if (!gameInfo.championship) return undefined
  
  const overlay = gameInfo.championship.settings.find((setting) => setting.name === 'overlay_id')?.value
  
  return {
    id: gameInfo.championship.id,
    name: gameInfo.championship.name,
    settings: gameInfo.championship.settings,
    overlayId: overlay ? +overlay : null,
  }
}
