import { createSlice } from '@reduxjs/toolkit';
import {
  addUploadImage,
  addUploadImages,
  deleteUploadImage,
  updateImage,
  updateImageProgress,
  uploadImage,
  uploadImages,
} from './actions';
import { tourneysImageEntitiesAdapter } from './adapters';

const slice = createSlice({
  name: 'tourneys/common',
  initialState: { images: tourneysImageEntitiesAdapter.getInitialState({}) },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addUploadImage, (state, { payload }) => {
      tourneysImageEntitiesAdapter.addOne(state.images, payload);
    });
    builder.addCase(addUploadImages, (state, { payload }) => {
      tourneysImageEntitiesAdapter.addMany(state.images, payload);
    });
    builder.addCase(deleteUploadImage, (state, { payload }) => {
      tourneysImageEntitiesAdapter.removeOne(state.images, payload);
    });
    builder.addCase(updateImageProgress, (state, { payload }) => {
      tourneysImageEntitiesAdapter.updateMany(state.images, payload);
    });
    builder.addCase(updateImage, (state, { payload }) => {
      tourneysImageEntitiesAdapter.updateOne(state.images, payload);
    });
    builder.addCase(uploadImage.fulfilled, (state, { payload }) => {});
    builder.addCase(uploadImages.fulfilled, (state, { payload }) => {});
  },
});

const tourneysCommonReducer = slice.reducer;
export default tourneysCommonReducer;
