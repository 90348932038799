import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const MainBlock = styled.div`
  display: flex;
  flex-direction: column;
  
  width: 48px;
  padding: 8px;
  gap: 8px;
  border-right: 1px solid #E2E3E4;
  background: #FFFDFF;
`
const Button = styled.button`
  border: none;
  background: none;
  cursor: pointer;
`
export const IconButton = styled(Button)`
  max-width: 32px;
  max-height: 32px;
  
  &:focus-visible {
    outline: unset;
  }
`
export const ModalButton = styled(Button)`
  display: flex;
  align-items: center;
  padding-inline: 20px;
  min-height: 24px;
  padding-block: 8px;
  gap: 8px;
  
  &:hover {
    background: #FFFAE2;
  }
`
export const tooltipStyles = css`
  width: unset;
  padding-block: 8px;
  padding-inline: 12px;
  margin-top: 3px;
  
  font-family: Inter, sans-serif;
  font-size: 10px;
  font-weight: 400;
  line-height: 12.1px;
  text-align: left;
  
  &:before {
    top: 6px;
    left: -4px;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-right: 5px solid #FFF;
  }
`
