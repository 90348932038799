import React, { Dispatch, SetStateAction, useCallback } from 'react';
import UDFormInput from 'modules/ud-form/components/input';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import UDButton from 'modules/ud-ui/components/button';
import UDFormDropZone from 'modules/ud-form/components/dropzone/index';
import { tourneysCommonStoreImagesSelector } from 'modules/tourneys/store/common/selectors';
// import UDFormSelect from 'modules/ud-form/components/select';
import { TeamPlayerForm } from '../../team-card/styles';
import { upsertTournamentPlayerApplication } from '../../../../store/players/actions';
import { TournamentUser } from '../../../../domain/interfaces/TournamentUser';
import { usePlayers } from 'modules/organizer/ui/hooks/usePlayers';
import { birthDateFormatter } from 'modules/ud-form/components/input/formatters/birthDateFormatter';
import BorderedInput from '../../bordered-input';
import * as yup from 'yup';
import { format } from 'date-fns';
import { AppDispatch } from '../../../../../../store/store';

type UpsertTeamFormProps = {
  tournamentId: number;
  teamId: number;
  playerId: number;
  setShowForm: Dispatch<SetStateAction<boolean>>;
  setPlayerId: Dispatch<SetStateAction<number>>;
};

const UpsertPlayerForm = (props: UpsertTeamFormProps) => {
  const dispatch: AppDispatch = useDispatch();
  const { tournamentId, teamId, playerId, setShowForm, setPlayerId } = props;
  const { info: players, isLoading } = usePlayers();

  let player: TournamentUser | undefined;

  if (playerId) {
    player = players.find((player) => player.id === playerId);
  }

  const handleSubmit = useCallback(
    (values) => {
      dispatch(
        upsertTournamentPlayerApplication({
          userId: player?.id,
          teamId,
          tournamentId,
          name: values.name,
          surname: values.surname,
          middleName: values.middleName,
          birthDate: values.birthDate,
          height: +values.height,
          weight: +values.weight,
          photo: values.photo,
          // position: values.position.value,
        })
      ).then(() => {
        setShowForm(false);
      });
    },
    [dispatch, teamId, tournamentId, player?.id, setShowForm]
  );
  // const positions = ['Вратарь', 'Защитник', 'Полузащитник', 'Нападающий'];
  // const positionsEng = ['goalkeeper', 'defender', 'midfielder', 'forward'];

  const teamPlayerValidator = yup.object().shape({
    name: yup.string().required('Обязательное поле'),
    surname: yup.string().required('Обязательное поле'),
    birthDate: yup
      .string()
      .matches(
        /^(\d{2})\.(\d{2})\.(\d{4})$/,
        'Дата рождения должна быть в формате: число.месяц.год'
      )
      .required('Обязательное поле'),
  });

  const initialValues = player
    ? {
        surname: player.surname,
        name: player.name,
        middleName: player.middleName,
        birthDate: format(new Date(player.birthDate), 'dd.MM.yyyy'),
        // position: {
        //   label: positions.find(
        //     (position, index) =>
        //       player && positionsEng[index] === player.position
        //   ),
        //   value: player.position,
        // },
        height: player.height,
        weight: player.weight,
        photo: player.tournamentAvatars?.length
          ? player.tournamentAvatars[player.tournamentAvatars.length - 1].image
          : [],
      }
    : {
        surname: '',
        name: '',
        middleName: '',
        birthDate: '',
        // position: undefined,
        height: '',
        weight: '',
      };
  const images = useSelector(tourneysCommonStoreImagesSelector);
  // const typeOptions = Array(4)
  //   .fill('')
  //   .map((_, index) => ({
  //     label: positions[index],
  //     value: positionsEng[index],
  //   }));

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={teamPlayerValidator}
      onSubmit={handleSubmit}
    >
      {(formProps) => (
        <form
          className="d-flex flex-column justify-content-between h-100"
          onSubmit={formProps.handleSubmit}
        >
          <TeamPlayerForm>
            <div className="row">
              <div className="player-form col-6">
                <UDFormInput
                  name="surname"
                  label="Фамилия"
                  placeholder="Введите фамилию"
                />

                <div className="player-data">
                  <UDFormInput
                    name="name"
                    label="Имя"
                    placeholder="Введите имя"
                  />

                  <UDFormInput
                    name="middleName"
                    label="Отчество"
                    placeholder="Введите отчество"
                  />
                </div>

                <div className="player-data">
                  <UDFormInput
                    name="birthDate"
                    label="Дата рождения"
                    placeholder="Укажите дату рождения"
                    mask="дд.мм.гггг"
                    formatter={birthDateFormatter}
                    inputComponent={BorderedInput}
                  />

                  {/* <UDFormSelect
                    name="position"
                    label="Амплуа"
                    placeholder="Укажите амплуа"
                    options={typeOptions}
                  /> */}
                </div>

                <div className="player-data">
                  <UDFormInput
                    name="height"
                    label="Рост игрока"
                    placeholder="Укажите рост"
                  />

                  <UDFormInput
                    name="weight"
                    label="Вес игрока"
                    placeholder="Укажите вес"
                  />
                </div>
              </div>

              <div className="player-form col-6">
                <UDFormDropZone
                  name="photo"
                  label="Фото игрока"
                  value={
                    player && player.tournamentAvatars?.length
                      ? [
                          player.tournamentAvatars[
                            player.tournamentAvatars.length - 1
                          ].image,
                        ]
                      : images
                  }
                  isCover={false}
                  formProps={{ className: 'bgc-SurfaceWhite br-8 p-6' }}
                />
              </div>
            </div>

            <div className="form-buttons">
              <UDButton
                type="button"
                variant="secondary"
                disabled={isLoading}
                onClick={() => {
                  setShowForm(false);
                  setPlayerId(-1);
                }}
                className="mr-4"
              >
                Отменить
              </UDButton>

              <UDButton
                type="submit"
                variant="primary"
                disabled={isLoading}
                loading={isLoading}
              >
                Сохранить
              </UDButton>
            </div>
          </TeamPlayerForm>
        </form>
      )}
    </Formik>
  );
};

export default UpsertPlayerForm;
