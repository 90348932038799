import { useEffect, useState } from "react";
import { Organizer } from "../../domain/interfaces/Organizer";
import organizersRepository from "../../domain/repositories/organizatorRepository";

export default function useOrganizers() {
  const [organizers, setOrganizers] = useState<Organizer[]>()
  const [isLoad, setIsLoad] = useState(false)
  useEffect(() => {
    organizersRepository.fetchOrganizers().then((organizers) => {
      setIsLoad(true)
      if (!organizers) return
      setOrganizers(organizers)
    })
  }, [])
  
  return { organizers, isLoad }
}
