import React, { useState } from 'react';
import classNames from 'classnames';
import UDColorableIcon from 'modules/ud-ui/components/icon/colorable-icon';
import { UDCommonInputProps } from './types';
import { PasswordInput, InputWrapper, IconContainer } from './styles';

export type UDPasswordInputProps = UDCommonInputProps;

// TODO: need refactoring
const UDPasswordInput = (props: UDPasswordInputProps) => {
  const { hasError, valid, iconProps, className, wrapperProps, ...othetProps } =
    props;
  const [masked, setMasked] = useState(true);
  const inputClassName = classNames(
    'input',
    'text subhead',
    props.className,
    {
      '--error': props.hasError,
      masked,
    },
    'icon-right',
  );
  const toggleMask = (e: React.MouseEvent<SVGElement, MouseEvent>) =>
    setMasked((prev) => !prev);
  return (
    <InputWrapper
      {...wrapperProps}
      className={classNames(
        {
          error: hasError,
          valid,
        },
        wrapperProps?.className,
      )}>
      {/* @ts-ignore */}
      <PasswordInput
        {...othetProps}
        // @ts-ignore
        onChange={(value) =>
          props?.onChange &&
          // @ts-ignore
          props.onChange({ target: { value, name: props.name || '' } })
        }
        className={inputClassName}
        mask="&#x25A0;"
        show={!masked}
      />
      <IconContainer className="icon-right">
        <UDColorableIcon
          name={masked ? 'eye-closed' : 'eye-opened'}
          componentProps={{ onClick: toggleMask }}
        />
      </IconContainer>
    </InputWrapper>
  );
};

export default UDPasswordInput;
