import React from 'react';
import { useOrganizer } from '../../../organizer/ui/hooks/useOrganizer';
import UDHeaderTitle from '../../../ud-ui/components/header-title';
import UDMainLayout from '../../../ud-ui/layout/main';
import CreateTeamForm from '../components/create-team-form/create-team-form';

const TeamsCreatePage = () => {
  const { info } = useOrganizer();

  return (
    <UDMainLayout
      headerContent={
        <>
          <UDHeaderTitle>Добавление команды</UDHeaderTitle>
        </>
      }
    >
      <div className="container h-100 pt-6">
        {info && (
          <div className="h-100">
            <CreateTeamForm tournamentId={info.id} />
          </div>
        )}
      </div>
    </UDMainLayout>
  );
};

export default TeamsCreatePage;
