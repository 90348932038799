import { useCallback, useState } from 'react';
import UDModal, { UDModalProps } from '.';

export const useModal = (props: { defaultShow: boolean }) => {
  const [shown, setShown] = useState(props.defaultShow);

  const close = useCallback(() => {
    setShown(false);
  }, []);

  const open = useCallback(() => {
    setShown(true);
  }, []);

  const Modal = (modalProps: Omit<UDModalProps, 'show'>) => {
    const { onClose, ...otherProps } = modalProps;

    const handleClose = useCallback((event: React.MouseEvent<HTMLImageElement>) => {
      if (onClose) {
        onClose(event);
      }

      close();
    }, [onClose]);

    return (
      <UDModal {...otherProps} show={shown} onClose={handleClose} />
    );
  };

  return { close, open, Modal };
};
