import { Tab } from 'modules/ud-ui/components/tabs';

type EditFormTabKey = 'COMMON' | 'PLAYERS' /* | 'ADMINS' */;

export const TeamEditFormPart: Record<EditFormTabKey, Tab> = {
  COMMON: { hash: 'common', label: 'Основные данные' },
  PLAYERS: { hash: 'players', label: 'Игроки' },
  // ADMINS: { hash: 'admins', label: 'Администраторы' },
};

export const defaultTab = TeamEditFormPart.COMMON;
