/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import UDIcon from '../../../../../../ud-ui/components/icon';
import { ChampionshipStateData } from '../../../../../store/championship';
import { selectedStageSelector } from '../../../../../store/stages';
import CanvasComponent from "./components/canvasFabric";
import {
  BaseText,
  Button,
  CanvasWrapper,
  Container,
  Divider,
  Header,
  HeaderTemplate,
  Item,
  RightPanel,
  TemplatesList,
  Tools,
  BarItem
} from './components/simpleComponents';
import { css } from "@emotion/react";
import pattern from '../../../../../../template-editor/domain/images/circlePattern.svg';
import _ from "lodash";
import supabase from "../../../../../../core/supabase/supabase";
import { useOrganizer } from "../../../../../../organizer/ui/hooks/useOrganizer";
import { TemplatesRow } from "../../../../../../core/domain/interfaces/receivedData";

type TemplateProps = {
  gameId: number
  championship: ChampionshipStateData
}

function Templates({ gameId, championship }: TemplateProps) {
  const { info } = useOrganizer()
  const [category, setCategory] = useState<TemplatesRow>()
  const [activeItem, setActiveItem] = useState<string>()
  const templates = useMemo(() => {
    if (!category) return
    const templates = _(category?.templates).toPairs().orderBy((value) => {return value[ 1 ].id}).value().map(value => value[ 0 ])
    setActiveItem(templates[ 0 ])
    return templates
  }, [category])
  
  useEffect(() => {
    if (!info) return
    if (category) return;
    const categoryName = championship.settings.overlayId?.toString() === "undefined" ? null : championship.settings.overlayId
    const row = categoryName === null
      ? supabase.overlayTemplates.getDefaultRow()
      : supabase.overlayTemplates.getRow({ rowId: categoryName })
    row.then(setCategory)
    
  }, [category, championship.settings.overlay, championship.settings.overlayId, info])
  
  const ItemList = useMemo(() => {
    if (!templates) return undefined;
    return templates.map((template) => {
      return (
        <Item key={template} active={activeItem === template} size={'small'} onClick={() => setActiveItem(template)}>
          {template}
        </Item>
      );
    });
  }, [activeItem, templates]);
  
  
  const stage = useSelector(selectedStageSelector);
  const game = stage?.matches.find((match) => match.id === gameId);
  if (!game) return <Navigate to={'/tourneys'}/>
  
  const onClickGetLinkOverlay = () => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      toast.error('Необходимо авторизоваться');
      return;
    }
    
    const encodedToken = window.btoa(token);
    navigator.clipboard.writeText(`${window.location.origin}/cover/${gameId}/?token=${encodedToken}`)
      .then(() => {
        toast.success('Ссылка скопирована');
      })
      .catch(() => {
        toast.error('Не удалось скопировать ссылку');
      });
  }
  
  return (
    <Container className="container">
      <div>
        <HeaderTemplate size={'medium'}>Табло</HeaderTemplate>
        <TemplatesList>{ItemList}</TemplatesList>
      </div>
      <RightPanel>
        <Header>
          <BarItem>
            <BaseText size={'medium'}>{activeItem}</BaseText>
            <Tools>
              <Button onClick={onClickGetLinkOverlay}>
                <UDIcon name="chain"/> <BaseText size={'small'}>Скопировать ссылку</BaseText>
              </Button>
            </Tools>
          </BarItem>
          <Divider/>
          <BaseText size={'small'}/>
        </Header>
        <CanvasWrapper css={wrapperStyle}>
          <CanvasComponent
            game={game}
            championship={championship}
            resolution={category?.resolution}
            template={category?.templates[ activeItem ?? '' ]}
          />
        </CanvasWrapper>
      </RightPanel>
    </Container>
  );
}

export default Templates;


const wrapperStyle = css`
  background: #ffffff url(${pattern}) no-repeat center;
  border-radius: 8px;
  background-size: cover;
  overflow: clip;
`
