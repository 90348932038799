import React from 'react';
import UDTabs, { Tab } from 'modules/ud-ui/components/tabs';
import { TourneyEditFormPart } from '../edit-form/constants';

const createFormTabs: Tab[] = Object
  .values(TourneyEditFormPart)
  .map((tab) => {
    if (tab.hash === TourneyEditFormPart.COMMON.hash) {
      return tab;
    }

    return {
      ...tab,
      disabled: true,
    };
  });

const TourneysComponentsCreateFormTabs = () => {
  return <UDTabs tabs={createFormTabs} defaultTab={createFormTabs[0]} />;
};

export default TourneysComponentsCreateFormTabs;
