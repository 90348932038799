import styled from '@emotion/styled';
import React from 'react';
import UDIcon from '../../../../ud-ui/components/icon';
import ValueBlock from './valueBlock';

interface IProps {
  value: number;
  setValue: (value: number) => void;
}

function Counter(props: IProps) {
  const {value, setValue} = props;
  
  function increment() {
    setValue(value + 1);
  }
  
  function decrement() {
    setValue(value - 1);
  }
  
  return (
    <MainBlock className={'d-flex justify-content-between flex-row'}>
      <Sign className={'flex-center'} onClick={decrement}><UDIcon name={'round-minus'}/></Sign>
      <ValueBlock className={'flex-center'} text={value} textSize={'large'}/>
      <Sign className={'flex-center'} onClick={increment}><UDIcon name={'round-plus'}/></Sign>
    </MainBlock>
  );
}

export default Counter;

const MainBlock = styled.div`
    gap: 6px;
`

const Sign = styled.button`
    border: none;
    background: none;

    font-size: 18px;
    color: #9093AA;

    width: 20px;
    height: 20px;
    cursor: pointer;
`
