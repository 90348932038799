export const formatPhone = (value = ''): string => {
  const normalizedPhone = value.replace(/[^0-9]/g, '');

  return normalizedPhone.replace(/^(\d?)(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/, (value, ...parts) => {
    const results = [];

    if (parts[0]) {
      results.push('+7');
    }

    if (parts[1]) {
      results.push(' (', parts[1]);
    }

    if (parts[2]) {
      results.push(') ', parts[2]);
    }

    if (parts[3]) {
      results.push('-', parts[3]);
    }

    if (parts[4]) {
      results.push('-', parts[4]);
    }

    return results.join('');
  });
};
