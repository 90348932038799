import React, { CSSProperties } from 'react';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import styled from '@emotion/styled';
import { GameDetails, GameDetailsTeamMember, GameEventType } from '../../../domain/interfaces/GameDetails';

type GoalEvent = {
  period: number;
  minutes: number;
  seconds: number;
};

type PlayerWithEvents = GameDetailsTeamMember & {
  goals: string[];
};

type PlayerCard = {
  period: number;
  minutes: number;
  seconds: number;
  type: GameEventType.YELLOW_CARD | GameEventType.RED_CARD;
  player: GameDetailsTeamMember;
};

const ProtocolContainer = styled.div`
  width: 100%;
  height: 100%;
  font-family: Arial, serif;
  font-size: 10px;

  table {
    width: 98%;
  }

  table, th, td {
    border: 1px solid black;
    border-collapse: collapse;
  }

  .protocol-table {
    th {
      height: 24px;
    }

    td {
      text-align: left;
      padding: 4px
    }

    td.text-center {
      text-align: center;
      padding: 0;
    }
  }
`;

const TeamTitle = styled.div`
  text-align: center;
  font-weight: bold;
  margin: 10px 0 6px;
`;

const PlayersTable = (props: { players: PlayerWithEvents[]; }) => {
  const {players} = props;
  const itemsCount = Math.max(16, players.length);
  const items = Array(itemsCount).fill(null);

  return (
    <table className="protocol-table">
      <thead>
      <tr>
        <th>#</th>
        <th>ФИО</th>
        <th>Голы (мин.)</th>
        <th>Передачи (мин.)</th>
      </tr>
      </thead>
      <tbody>
      {items.map((_, index) => (
        <tr key={index}>
          <td className="text-center" width="36px">{players[ index ]?.number}</td>
          <td>{players[ index ]?.lastName} {players[ index ]?.firstName}</td>
          <td width="80px">{players[ index ]?.goals.join(', ')}</td>
          <td width="80px">&nbsp;</td>
        </tr>
      ))}
      </tbody>
    </table>
  );
};

const CardsTable = (props: { cards?: PlayerCard[]; }) => {
  const cards = props.cards ?? [];
  const itemsCount = Math.max(6, cards.length);
  const items = Array(itemsCount).fill(null);

  const cardTypeText = {
    [ GameEventType.YELLOW_CARD ]: 'Пр.',
    [ GameEventType.RED_CARD ]: 'Уд.',
    undefined: '',
  };

  return (
    <table className="protocol-table">
      <thead>
      <tr>
        <th>Мин.</th>
        <th>Пр/Уд</th>
        <th>ФИО</th>
        <th>Причина</th>
      </tr>
      </thead>
      <tbody>
      {items.map((_, index) => (
        <tr key={index}>
          <td className="text-center" width="42px">{cards[ index ]?.minutes}</td>
          <td className="text-center" width="48px">{cardTypeText[ cards[ index ]?.type ]}</td>
          <td>{cards[ index ]?.player.lastName} {cards[ index ]?.player.firstName}</td>
          <td width="92px">&nbsp;</td>
        </tr>
      ))}
      </tbody>
    </table>
  );
};

type UnderlinedTextProps = {
  width?: string;
  textAlign?: "center" | "left" | "right";
  display?: 'block' | 'inline-block' | 'inline' | 'none';
};

const UnderlinedText = styled.div`
  border-bottom: 1px solid black;
  width: ${(props: UnderlinedTextProps) => props.width ?? 'auto'};
  text-align: ${(props: UnderlinedTextProps) => props.textAlign ?? 'left'};
  display: ${(props: UnderlinedTextProps) => props.display ?? 'block'};
`;

const TwoColumnsProtocolRow = (props: { title: string; }) => (
  <div className="row" style={{marginTop: '10px'}}>
    <div className="col-6">
      <div className="row">
        <div className="col-5">{props.title}</div>
        <div className="col-6" style={{ width: '56%' }}>
          <UnderlinedText>&nbsp;</UnderlinedText>
        </div>
      </div>
    </div>

    <div className="col-6">
      <div className="row">
        <div className="col-5">{props.title}</div>
        <div className="col-6" style={{ width: '56%' }}>
          <UnderlinedText>&nbsp;</UnderlinedText>
        </div>
      </div>
    </div>
  </div>
);

type MatchProtocolToPrintProps = {
  matchDetails: GameDetails | null;
  logoUrl?: string;
};

const styles: Record<string, CSSProperties> = {
  container: {width: '100%', height: '100%', fontFamily: 'Arial', fontSize: '12px'},
  infoLeftBlock: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '0 16px 0 0',
    marginBottom: '16px',
  },
  infoRightBlock: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '0 16px 0 0',
    marginBottom: '16px',
  },
  placeInfo: {
    display: 'flex'
  },
};

export const MatchProtocolToPrint = React.forwardRef((props: MatchProtocolToPrintProps, ref: React.ForwardedRef<HTMLDivElement>) => {
  const {matchDetails, logoUrl} = props;

  const playersById: Record<string, GameDetailsTeamMember> = {};
  matchDetails?.teams.forEach((team) => {
    team.members.forEach((member) => {
      playersById[ member.id ] = member;
    });
  });

  const goalsByMember: Record<string, GoalEvent[]> = {};
  const teamsCards: Record<string, PlayerCard[]> = {};

  matchDetails?.events.forEach((event) => {
    const {type, period, minutes, seconds} = event;

    if (type === GameEventType.GOAL || /*type === GameEventType.AFTER_MATCH_PENALTY_GOAL ||*/ type === GameEventType.PASS_AND_GOAL) {
      let playerId: number;

      if (type === GameEventType.PASS_AND_GOAL) {
        [, playerId] = event.playerIds;
      } else {
        [playerId] = event.playerIds;
      }

      goalsByMember[ playerId ] = [
        ...(goalsByMember[ playerId ] || []),
        {period, minutes, seconds},
      ];
    }

    if (type === GameEventType.YELLOW_CARD || type === GameEventType.RED_CARD) {
      const {teamId} = event;
      const [playerId] = event.playerIds;
      const player = playersById[ playerId ] || null;

      if (player) {
        teamsCards[ teamId ] = [
          ...(teamsCards[ teamId ] || []),
          {
            type,
            period,
            minutes,
            seconds,
            player
          },
        ];
      }
    }
  });

  const teamsPlayers: Array<PlayerWithEvents[]> = [];
  matchDetails?.teams.forEach((team) => {
    const players: PlayerWithEvents[] = [];
    team.members.forEach((member) => {
      const goalsByPeriod = (goalsByMember[ member.id ] || []).reduce<Record<string, string[]>>((acc, item) => {
        return {
          ...acc,
          [ item.period ]: [
            ...(acc[ item.period ] || []),
            `${item.minutes}'`,
          ],
        };
      }, {});

      const goals = Object.keys(goalsByPeriod).map((period) => {
        return `${period} (${goalsByPeriod[ period ].join(', ')})`;
      });

      players.push({
        ...member,
        goals,
      });
    });

    teamsPlayers.push(players);
  });


  const firstTeamScore = matchDetails?.result?.firstTeamScore ?? 0;
  const secondTeamScore = matchDetails?.result?.secondTeamScore ?? 0;
  const totalScore = matchDetails?.hasScore ? `${firstTeamScore} : ${secondTeamScore}` : '';

  let winner: string = '';
  if (firstTeamScore > secondTeamScore) {
    winner = matchDetails?.teams[ 0 ].name ?? '';
  } else if (secondTeamScore > firstTeamScore) {
    winner = matchDetails?.teams[ 1 ].name ?? '';
  }

  return (
    <ProtocolContainer ref={ref}>
      {!matchDetails ? (<div>Загрузка...</div>) : (
        <div className="container py-6">
          <h2 style={{marginBottom: '10px'}}>
            {logoUrl && (
              <img style={{verticalAlign: 'middle', marginRight: '10px'}} src={logoUrl} height={32}/>
            )}
            {matchDetails.tournament.name}
          </h2>
          <h2 style={{marginBottom: '10px', textAlign: 'center'}}>{matchDetails.championship.name}</h2>
          <h2 style={{marginBottom: '32px'}}>Протокол матча</h2>

          <div className="row">
            <div className="col-6">
              <div style={styles.infoLeftBlock}>
                <div>Место проведения</div>
                <UnderlinedText width="200px" textAlign="center">&nbsp;</UnderlinedText>
              </div>

              <TeamTitle>{matchDetails.teams[ 0 ].name}</TeamTitle>
              <PlayersTable players={teamsPlayers[ 0 ]}/>
            </div>

            <div className="col-6">
              <div style={styles.infoRightBlock}>
                <div className="text">Дата и время проведения</div>
                <UnderlinedText width="200px" textAlign="center">
                  {format(matchDetails.date, 'd MMMM yyyy HH:mm', {locale: ru})}
                </UnderlinedText>
              </div>

              <TeamTitle>{matchDetails.teams[ 1 ].name}</TeamTitle>
              <PlayersTable players={teamsPlayers[ 1 ]}/>
            </div>
          </div>

          <h3 style={{textAlign: 'center', margin: '16px 0'}}>НАКАЗАНИЯ:</h3>
          <div className="row">
            <div className="col-6">
              <CardsTable cards={teamsCards[ matchDetails.teams[ 0 ].id ]}/>
            </div>
            <div className="col-6">
              <CardsTable cards={teamsCards[ matchDetails.teams[ 1 ].id ]}/>
            </div>
          </div>

          <div style={{marginTop: '16px'}}>
            <div className="row">
              <div className="col-6" style={{paddingTop: '16px'}}>
                <span style={{width: '13%', display: 'inline-block'}}>Результат&nbsp;</span>
                <UnderlinedText
                  display="inline-block"
                  width="100px"
                  textAlign="center"
                  style={{width: '24%', marginRight: '2%'}}
                >
                  {totalScore}&nbsp;
                </UnderlinedText>&nbsp;
                <span style={{width: '13%', display: 'inline-block'}}>В пользу&nbsp;</span>
                <UnderlinedText
                  display="inline-block"
                  width="160px"
                  textAlign="center"
                  style={{width: '45%'}}
                >
                  {winner}&nbsp;
                </UnderlinedText>
              </div>

              <div className="col-6" style={{paddingTop: '16px'}}>
                <span style={{width: '20%', display: 'inline-block'}}>Счёт 1-й тайм&nbsp;</span>
                <UnderlinedText
                  display="inline-block"
                  width="90px"
                  textAlign="center"
                  style={{width: '18%', marginRight: '2%'}}
                >&nbsp;</UnderlinedText>&nbsp;
                <span style={{width: '12%', display: 'inline-block'}}>В пользу&nbsp;</span>
                <UnderlinedText display="inline-block" width="150px" textAlign="center"
                                style={{width: '45%'}}>&nbsp;</UnderlinedText>
              </div>
            </div>

            <div className="row" style={{marginTop: '10px'}}>
              <div className="col-2" style={{width: '15.5%'}}>Лучший игрок матча</div>
              <div className="col-10">
                <UnderlinedText>&nbsp;</UnderlinedText>
              </div>
            </div>

            <TwoColumnsProtocolRow title="Оценки за судейство (из 10)"/>
            <TwoColumnsProtocolRow title="Количество болельщиков"/>
            <TwoColumnsProtocolRow title="Дисциплина (оценка судьи)"/>
            <TwoColumnsProtocolRow title="Подпись капитана"/>
            <TwoColumnsProtocolRow title="Подпись капитана"/>

            <div className="row" style={{marginTop: '10px'}}>
              <div className="col-6">
                <div className="row">
                  <div className="col-5">Главный судья</div>
                  <div className="col-6" style={{width: '56%'}}>
                    <UnderlinedText>&nbsp;</UnderlinedText>
                  </div>
                </div>
              </div>

              <div className="col-6">
                <div className="row">
                  <div className="col-5">Подпись судьи</div>
                  <div className="col-6" style={{width: '56%'}}>
                    <UnderlinedText>&nbsp;</UnderlinedText>
                  </div>
                </div>
              </div>
            </div>

            <div className="row" style={{marginTop: '10px', width: '98.9%'}}>
              <div className="col-2">Судейский корпус</div>
              <div className="col-10">
                <UnderlinedText>&nbsp;</UnderlinedText>
              </div>
            </div>

            <div className="row" style={{marginTop: '10px', width: '98.9%'}}>
              <div className="col-1">Примечание</div>
              <div className="col-11">
                <UnderlinedText>&nbsp;</UnderlinedText>
              </div>
            </div>

            <div style={{marginTop: '10px', width: '98.9%'}}>
              <UnderlinedText>&nbsp;</UnderlinedText>
            </div>
            <div style={{marginTop: '10px', width: '98.9%'}}>
              <UnderlinedText>&nbsp;</UnderlinedText>
            </div>
          </div>
        </div>
      )}
    </ProtocolContainer>
  );
});
