import styled from '@emotion/styled';

export const BreadcrumbsContainer = styled.div`
  display: inline-block;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  margin-bottom: 16px;

  .link {
    color: ${({ theme }) => theme.colors.SurfaceRaisinBlack50};
    cursor: pointer;
    font-size: 15px;

    &:after {
      content: '▸';
      color: ${({ theme }) => theme.colors.SurfaceRaisinBlack50};
      font-size: 16px;
      padding: 0 8px;
    }

    &:last-child {
      cursor: auto;

      &:after {
        display: none;
      }
    }

    &.new {
      color: ${({ theme }) => theme.colors.SurfaceRomanSilver30};
    }
  }
`;
