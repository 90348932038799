import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import AuthLayoutsMain from '../../layouts';
import { AppDispatch } from '../../../../../store/store';
import { useAuth } from '../../hooks/useAuth';
import UDButton from '../../../../ud-ui/components/button';
import { switchUserTournament } from '../../../store/actions';
import { useAuthGuard } from '../../hooks/useAuthGuard';

const AuthPagesSelectTournament = () => {
  useAuthGuard();

  const dispatch = useDispatch<AppDispatch>();
  const { availableTournaments } = useAuth();

  const handleTournamentSelected = useCallback((tournamentId: number) => () => {
    dispatch(switchUserTournament(tournamentId));
  }, [dispatch]);

  return (
    <AuthLayoutsMain
      title='Выберите турнир'
      subtitle=''
      contentClassName="tournaments-list"
    >
      <div>
        {availableTournaments.map((tournament) => (
          <div
            className="mt-5"
            key={tournament.id}
          >
            <UDButton
              className="w-100"
              variant="secondary"
              type="submit"
              onClick={handleTournamentSelected(tournament.id)}
            >
              {tournament.name}
            </UDButton>
          </div>
        ))}
      </div>
    </AuthLayoutsMain>
  );
};

export default AuthPagesSelectTournament;
