import React from 'react';

type UndoIconProps = {
  width?: number;
  height?: number;
  color?: string;
} & React.SVGProps<SVGSVGElement>;

const UndoIcon = (props: UndoIconProps) => {
  const {
    width,
    height,
    color = '#242424',
    ...rest
  } = props;
  
  return (
    <svg width={width ?? 32} height={height ?? 32} viewBox="0 0 32 32" fill="none"
         xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M10.6875 14.125H17.875C19.7735 14.125 21.3125 15.664 21.3125 17.5625V17.5625C21.3125 19.461 19.7735 21 17.875 21H16M10.6875 14.125L13.8125 11M10.6875 14.125L13.8125 17.25"
        stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default UndoIcon;
