/** @jsxImportSource @emotion/react */
import React, { SVGProps } from 'react';

type IProps = {
  width?: number;
  height?: number;
  color?: string
} & SVGProps<SVGSVGElement>;

const RectanglePlusIcon = (props: IProps) => {
  const {
    width,
    height,
    color = '#242424',
    ...rest
  } = props;
  
  return (
    <svg width={width ?? 32} height={height ?? 32} viewBox="0 0 35 32" fill="none"
         id={'rectangle-plus'}
         xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M8 11.5C8 9.567 9.567 8 11.5 8H20.5C22.433 8 24 9.567 24 11.5V17.0625V20.5C24 22.433 22.433 24 20.5 24H18.125H11.5C9.567 24 8 22.433 8 20.5V11.5Z"
        stroke={color}/>
      <path d="M11 16H21M16 11V21" stroke={color} strokeLinecap="round"/>
      <path d="M29 19L32 16L29 13" stroke="#8F8F8F" strokeWidth="1.1" strokeLinecap="round"/>
    </svg>
  );
};

export default RectanglePlusIcon;
