import styled from '@emotion/styled';
import svg from './football-field.svg';

export const FootballField = styled.div`
  position: relative;
  background-image: url(${svg});
  background-size: cover;
  width: 100%;
  height: 0;
  padding-bottom: 100%;

  .player-card {
    position: absolute;
    width: 32%;
    height: 18%;
  }
  
  &.formation-2 {
    .player-1 {
      top: 15%;
      left: 12%;
    }
    
    .player-2 {
      top: 15%;
      right: 12%;
    }
  }

  &.formation-2-1 {
    .player-1 {
      top: 15%;
      left: 12%;
    }

    .player-2 {
      top: 15%;
      right: 12%;
    }

    .player-3 {
      top: 39%;
      left: 34%;
    }
  }

  &.formation-2-2 {
    .player-1 {
      top: 15%;
      left: 12%;
    }

    .player-2 {
      top: 15%;
      right: 12%;
    }

    .player-3 {
      top: 39%;
      left: 12%;
    }

    .player-4 {
      top: 39%;
      right: 12%;
    }
  }
  
  &.formation-1-2-2 {
    .player-1 {
      top: -9%;
      left: 34%;
    }
    
    .player-2 {
      top: 15%;
      left: 12%;
    }

    .player-3 {
      top: 15%;
      right: 12%;
    }

    .player-4 {
      top: 39%;
      left: 16%;
    }

    .player-5 {
      top: 39%;
      right: 16%;
    }
  }

  &.formation-1-3-2 {
    .player-1 {
      top: -9%;
      left: 34%;
    }

    .player-2 {
      top: 15%;
      left: 0;
    }

    .player-3 {
      top: 15%;
      left: 34%;
    }

    .player-4 {
      top: 15%;
      right: 0;
    }

    .player-5 {
      top: 39%;
      left: 16%;
    }

    .player-6 {
      top: 39%;
      right: 16%;
    }
  }
  
  &.formation-1-3-3 {
    .player-1 {
      top: -9%;
      left: 34%;
    }

    .player-2 {
      top: 15%;
      left: 0;
    }

    .player-3 {
      top: 15%;
      left: 34%;
    }

    .player-4 {
      top: 15%;
      right: 0;
    }

    .player-5 {
      top: 39%;
      left: 0;
    }

    .player-6 {
      top: 39%;
      left: 34%;
    }

    .player-7 {
      top: 39%;
      right: 0;
    }
  }

  &.formation-1-3-2-2 {
    .player-card {
      width: 30%;
      height: 16%;
    }

    .player-1 {
      top: -9%;
      left: 35%;
    }

    .player-2 {
      top: 12%;
      left: 2%;
    }

    .player-3 {
      top: 12%;
      left: 35%;
    }

    .player-4 {
      top: 12%;
      right: 2%;
    }

    .player-5 {
      top: 33%;
      left: 16%;
    }

    .player-6 {
      top: 33%;
      right: 16%;
    }

    .player-7 {
      top: 54%;
      left: 16%;
    }

    .player-8 {
      top: 54%;
      right: 16%;
    }
  }

  &.formation-1-3-3-2 {
    .player-card {
      width: 30%;
      height: 16%;
    }

    .player-1 {
      top: -9%;
      left: 35%;
    }

    .player-2 {
      top: 12%;
      left: 2%;
    }

    .player-3 {
      top: 12%;
      left: 35%;
    }

    .player-4 {
      top: 12%;
      right: 2%;
    }

    .player-5 {
      top: 33%;
      left: 2%;
    }

    .player-6 {
      top: 33%;
      right: 35%;
    }

    .player-7 {
      top: 33%;
      right: 2%;
    }

    .player-8 {
      top: 54%;
      left: 16%;
    }

    .player-9 {
      top: 54%;
      right: 16%;
    }
  }
  
  &.formation-1-2-4-2 {
    .player-card {
      width: 24%;
      height: 10%;
    }

    .player-1 {
      top: -5%;
      left: 38%;
    }

    .player-2 {
      top: 10%;
      left: 14%;
    }

    .player-3 {
      top: 10%;
      right: 14%;
    }

    .player-4 {
      top: 27%;
      left: 22%;
    }

    .player-5 {
      top: 27%;
      right: 22%;
    }

    .player-6 {
      top: 39%;
      left: 2%;
    }

    .player-7 {
      top: 39%;
      right: 2%;
    }

    .player-8 {
      top: 57%;
      left: 15%;
    }

    .player-9 {
      top: 57%;
      right: 15%;
    }
  }

  &.formation-1-3-3-3 {
    .player-card {
      width: 30%;
      height: 16%;
    }

    .player-1 {
      top: -9%;
      left: 35%;
    }

    .player-2 {
      top: 12%;
      left: 2%;
    }

    .player-3 {
      top: 12%;
      left: 35%;
    }

    .player-4 {
      top: 12%;
      right: 2%;
    }

    .player-5 {
      top: 33%;
      left: 2%;
    }

    .player-6 {
      top: 33%;
      right: 35%;
    }

    .player-7 {
      top: 33%;
      right: 2%;
    }

    .player-8 {
      top: 54%;
      left: 2%;
    }

    .player-9 {
      top: 54%;
      right: 35%;
    }

    .player-10 {
      top: 54%;
      right: 2%;
    }
  }

  &.formation-1-4-4-2 {
    .player-card {
      width: 24%;
      height: 10%;
    }

    .player-1 {
      top: -5%;
      left: 37%;
    }

    .player-2 {
      top: 12%;
      left: 0;
    }

    .player-3 {
      top: 12%;
      left: 25.5%;
    }

    .player-4 {
      top: 12%;
      right: 25.5%;
    }

    .player-5 {
      top: 12%;
      right: 0;
    }

    .player-6 {
      top: 29%;
      left: 0;
    }

    .player-7 {
      top: 29%;
      left: 25.5%;
    }

    .player-8 {
      top: 29%;
      right: 25.5%;
    }

    .player-9 {
      top: 29%;
      right: 0;
    }

    .player-10 {
      top: 46%;
      left: 20%;
    }

    .player-11 {
      top: 46%;
      right: 20%;
    }
  }
`;
