const support = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M13 8C13 10.7614 10.7614 13 8 13C5.23858 13 3 10.7614 3 8C3 5.23858 5.23858 3 8 3C10.7614 3 13 5.23858 13 8ZM14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8ZM8.26977 9.29348H7.45581V8.85598C7.45116 8.22826 7.74884 7.80027 8.38605 7.41033C8.92558 7.07269 9.13488 6.7731 9.13488 6.26902V6.25951C9.13488 5.67459 8.68837 5.24185 7.99535 5.24185C7.29302 5.24185 6.84651 5.66508 6.8 6.34035L6.7907 6.34511L6 6.34986V6.34035C6.05116 5.31793 6.72093 4.5 8.02791 4.5C9.2 4.5 10 5.24185 10 6.21671V6.22622C10 6.96807 9.62326 7.49592 9.02791 7.86209C8.43721 8.21875 8.26977 8.48505 8.26977 8.98438V9.29348ZM8.56744 10.839C8.56744 11.2242 8.30233 11.5 7.92558 11.5C7.54884 11.5 7.27907 11.2242 7.27907 10.839C7.27907 10.449 7.54884 10.1732 7.92558 10.1732C8.30233 10.1732 8.56744 10.449 8.56744 10.839Z"
    fill="currentColor"
  />
);

export default support;
