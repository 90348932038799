import axios, { AxiosInstance } from 'axios';
import { IDadataRepository } from '../../domain/repositories/dadata.repository';
import { DadataAreaType, IDadataArea } from '../../domain/entities/dadata-area.entity';
import { mapDadataAreaList } from '../mappers/dadata-area.mapper';

export class DadataRepository implements IDadataRepository {
  private client: AxiosInstance;

  constructor(token: string) {
    this.client = axios.create({
      headers: {
        'Authorization': `Token ${token}`,
      },
    });
  }

  async getSuggestedAreas(name: string, types?: DadataAreaType[]): Promise<IDadataArea[]> {
    const url = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address';
    const data = { query: name };

    const response = await this.client.post(url, data);
    const { suggestions } = response.data;

    return mapDadataAreaList(suggestions, types);
  }
}
