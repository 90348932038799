import { createSlice } from '@reduxjs/toolkit';
import { AuthenticatedUser } from '../domain/interfaces/AuthSignInResponse';
import {
  authCheck, authRestore, authSignIn, authSignUp, authSignOut, authRequestAuthCode, switchUserTournament,
  clearActiveTournamentId
} from './actions';
import { AuthAvailableTournament } from '../domain/interfaces/AuthAvailableTournament';
import { loadState } from './persist';

type AuthSliceState = {
  loading: boolean;
  error: { [key: string]: string; } | null;
  authed: boolean;
  authChecking: boolean;
  authChecked: boolean;
  user: AuthenticatedUser | null;
  activeTournamentId: number | null;
  availableTournaments: AuthAvailableTournament[];
  codeRequesting: boolean;
  codeRequested: boolean;
};

const persistedState = loadState();

const initialState: AuthSliceState = {
  loading: false,
  error: null,
  authed: false,
  authChecking: false,
  authChecked: false,
  user: null,
  availableTournaments: [],
  codeRequesting: false,
  codeRequested: false,
  ...persistedState,
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(authRestore.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(authRestore.fulfilled, (state) => {
      state.loading = false;
    });

    builder.addCase(authRestore.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(authRequestAuthCode.pending, (state) => {
      state.codeRequesting = true;
    });

    builder.addCase(authRequestAuthCode.fulfilled, (state) => {
      state.codeRequesting = false;
      state.codeRequested = true;
    });

    builder.addCase(authRequestAuthCode.rejected, (state) => {
      state.codeRequesting = false;
      state.codeRequested = false;
    });

    builder.addCase(authSignIn.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(authSignIn.fulfilled, (state) => {
      state.loading = false;
      state.authed = true;
      state.codeRequested = false;
    });

    builder.addCase(authSignIn.rejected, (state) => {
      state.loading = false;
      state.authed = false;
    });

    builder.addCase(authSignUp.pending, (state) => {
      state.codeRequesting = true;
    });

    builder.addCase(authSignUp.fulfilled, (state, action) => {
      state.codeRequesting = false;
      state.codeRequested = true;

      if (action.payload.errors) {
        state.error = Object
          .keys(action.payload.errors!)
          .reduce((result, key) => ({
            ...result,
            [key]: action.payload.errors![key][0]
          }), {});
      }
    });

    builder.addCase(authSignUp.rejected, (state) => {
      state.codeRequesting = false;
      state.codeRequested = false;
    });

    builder.addCase(authCheck.pending, (state) => {
      state.authChecking = true;
    });

    builder.addCase(authCheck.fulfilled, (state, action) => {
      const user = action.payload?.user || null;
      const availableTournaments = action.payload?.availableTournaments || [];
      const hasTournament = availableTournaments.some((tournament) => tournament.id === state.activeTournamentId);

      if (state.activeTournamentId && !hasTournament) {
        state.activeTournamentId = null;
      } else if (availableTournaments.length === 1) {
        state.activeTournamentId = availableTournaments[0].id;
      }

      state.authed = true;
      state.authChecking = false;
      state.authChecked = true;
      state.user = user;
      state.availableTournaments = availableTournaments;
    });

    builder.addCase(authCheck.rejected, (state) => {
      state.authed = false;
      state.authChecking = false;
      state.authChecked = true;
      state.user = null;
    });

    builder.addCase(authSignOut, (state) => {
      state.authed = false;
      state.user = null;
      state.availableTournaments = [];
    });

    builder.addCase(switchUserTournament, (state, action) => {
      state.activeTournamentId = action.payload;
    });

    builder.addCase(clearActiveTournamentId, (state) => {
      state.activeTournamentId = null;
    });
  },
});

const authReducer = slice.reducer;
export default authReducer;
