import React from 'react';
import styled from "@emotion/styled";
import DoneIcon from "../../../../ud-ui/components/icon/done-icon";

function Checkbox(props: IProps) {
  const { checked, onChange } = props;
  
  return (
    <MainBlock checked={checked} onClick={onChange} className={'flex-center'}>
      <DoneIcon color={'white'} width={10} height={10}/>
    </MainBlock>
  );
}

interface IProps {
  checked: boolean
  onChange: () => void
}

export default Checkbox;

const MainBlock = styled.div<{ checked: boolean }>`
    background: ${({ checked }) => checked ? ' #007AFF' : 'white'};
    border: 1px solid ${({ checked }) => checked ? 'transparent' : 'gray'};
    border-radius: 4px;
    width: 16px;
    height: 16px;
`
