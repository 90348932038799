import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CircleArrowIcon from '../../../../ud-ui/components/icon/circle-arrow-icon';
import {
  setActiveOverlay,
  setFouls,
  setGameInfo,
  setPeriod,
  setScore,
  setStatsForMember,
  setTimer
} from '../../../store/overlay';
import { selectGameInfo } from '../../../store/selectors';
import Text from '../components/text';
import { Dashboard } from './dashboard';
import { Fouls } from './fouls';
import { Period } from './period';
import { Score } from './score';
import Team from './team';
import { TeamsName } from './teamsName';
import CircleArrowCogwheelIcon from "../../../../ud-ui/components/icon/circle-arrow-cogwheel-icon";
import { getScore } from "../../../domain/utils/getScore";
import { useParams } from "react-router-dom";
import { getMappedGameInfo } from "../../../domain/utils/getMappedGameInfo";
import classNames from "classnames";
import { getFouls } from "../../../domain/utils/getFouls";
import { Button, MainBlock } from "./style";
import { getTime } from "../../../domain/utils/getTime";

function Joystick() {
  const dispatch = useDispatch()
  const gameInfo = useSelector(selectGameInfo)
  const { matchId: id } = useParams()
  const [syncData, setSyncData] = useState(false)
  
  const onClickReset = () => {
    dispatch(setScore([0, 0]));
    dispatch(setFouls([0, 0]));
    dispatch(setTimer(0));
    dispatch(setPeriod(1));
    dispatch(setActiveOverlay(null));
    if (!gameInfo) return
    if (!gameInfo.teams?.firstTeam) return
    if (!gameInfo.teams?.secondTeam) return
    const allMembers = gameInfo.teams.firstTeam.members?.concat(gameInfo.teams.secondTeam.members ?? []) ?? []
    for (const member of allMembers) {
      dispatch(setStatsForMember({
        id: member.id,
        stats: { qtyGoalsMatch: 0, qtyYellowCardsMatch: 0, qtyRedCardsMatch: 0 }
      }))
    }
  };
  
  const onClickSync = () => {
    if (syncData) return
    
    setSyncData(true)
    const matchId = id ? parseInt(id) : 0;
    const gameInfo = getMappedGameInfo(matchId)
    const score = getScore(matchId)
    const fouls = getFouls(matchId)
    const time = getTime(matchId)
    
    Promise.all([gameInfo, score, fouls, time]).then((data) => {
      data[ 0 ] && dispatch(setGameInfo(data[ 0 ]));
      data[ 1 ] && dispatch(setScore(data[ 1 ]));
      data[ 2 ] && dispatch(setFouls(data[ 2 ]));
      data[ 2 ] && dispatch(setTimer(data[ 3 ].seconds * 1000));
      data[ 2 ] && dispatch(setPeriod(data[ 3 ].currentPeriod));
      setSyncData(false)
    })
  }
  
  return (
    <MainBlock className={'d-flex flex-column'} style={{ zIndex: 99 }}>
      <TeamsName/>
      <Score/>
      <Fouls/>
      <Period/>
      <Dashboard/>
      <Button
        color={'#D9B244'}
        onClick={onClickSync}
        className={classNames('flex-center', { 'loading': syncData })}
      >
        {!syncData && (
          <>
            <Text size={'medium'} color={'inherit'}>Синхронизировать</Text>
            <CircleArrowIcon height={13} width={12} color={'white'}/>
          </>
        )
        }
      </Button>
      <Button
        color={'#686A79'}
        onClick={onClickReset}
        className={'flex-center'}
      >
        <Text size={'medium'} color={'inherit'}>Сбросить все настройки</Text>
        <CircleArrowCogwheelIcon/>
      </Button>
      <Team teamIndex={0}/>
      <Team teamIndex={1}/>
    </MainBlock>
  );
}

export default Joystick;
