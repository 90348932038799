const layers = (
  <>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_11561_43398)">
        <path
          d="M14.4532 8.71192C15.4268 8.22511 16.5728 8.22511 17.5465 8.71192L23.684 11.7807C24.4825 12.1799 24.4825 13.3194 23.684 13.7186L17.5465 16.7874C16.5728 17.2742 15.4268 17.2742 14.4532 16.7874L8.31563 13.7186C7.51716 13.3194 7.51716 12.1799 8.31563 11.7807L14.4532 8.71192Z"
          stroke="#242424" strokeWidth="1.08333"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M9.50001 17.083L8.07357 17.7962C6.87587 18.3951 6.87587 20.1043 8.07357 20.7031L14.2112 23.7719C15.3373 24.335 16.6628 24.335 17.7889 23.7719L23.9265 20.7031C25.1242 20.1043 25.1242 18.3951 23.9265 17.7962L22.5 17.083L21.382 17.642L23.4792 18.6907C23.9399 18.921 23.9399 19.5784 23.4792 19.8087L17.3417 22.8775C16.4971 23.2998 15.503 23.2998 14.6584 22.8775L8.52079 19.8087C8.06013 19.5784 8.06013 18.921 8.52078 18.6907L10.618 17.642L9.50001 17.083Z"
              fill="#242424"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M9.50001 13.833L8.07357 14.5462C6.87587 15.1451 6.87587 16.8543 8.07357 17.4531L14.2112 20.5219C15.3373 21.085 16.6628 21.085 17.7889 20.5219L23.9265 17.4531C25.1242 16.8543 25.1242 15.1451 23.9265 14.5462L22.5 13.833L21.382 14.392L23.4792 15.4407C23.9399 15.671 23.9399 16.3284 23.4792 16.5587L17.3417 19.6275C16.4971 20.0498 15.503 20.0498 14.6584 19.6275L8.52079 16.5587C8.06013 16.3284 8.06013 15.671 8.52078 15.4407L10.618 14.392L9.50001 13.833Z"
              fill="#242424"/>
      </g>
      <defs>
        <clipPath id="clip0_11561_43398">
          <rect x="3" y="3" width="26" height="26" rx="13" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  </>
)

export default layers;
