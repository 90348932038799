import React from 'react';
import classNames from 'classnames';
import { Link, LinkProps } from 'react-router-dom';
import { UDTextType } from '../text';

type Props = {
  type?: UDTextType;
  disabled?: boolean;
} & LinkProps;

const UDLink = (props: Props) => {
  const { type = 'callout', disabled, className, ...otherProps } = props;
  return (
    <Link
      {...otherProps}
      className={classNames('link', 'text', type, className, { disabled })}
    />
  );
};

export default UDLink;
