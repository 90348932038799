import { createSlice, ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { GameDetails, GameEvent } from '../domain/interfaces/GameDetails';
import { mapGameEvent } from '../domain/mappers/game-event-mapper';
import { sortGameEvents } from '../utils/games-utils';
import {
  addGameEvent, deleteGameEvent, editGameEvent, fetchGameDetails, saveGameDetails, editGameScore,
  changeGameDetailsVideoUrl
} from './actions';

export type GameDetailsSliceState = {
  isLoading: boolean;
  isLoaded: boolean;
  eventsChanged: boolean;
  isSaving: boolean;
  isSaved: boolean;
  data: GameDetails | null;
};

const initialState: GameDetailsSliceState = {
  isLoading: false,
  isLoaded: false,
  eventsChanged: false,
  isSaving: false,
  isSaved: false,
  data: null,
};

function createFetchGameDetailsReducers(builder: ActionReducerMapBuilder<GameDetailsSliceState>) {
  builder.addCase(fetchGameDetails.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(fetchGameDetails.fulfilled, (state, action) => {
    state.isLoading = false;
    state.isLoaded = true;
    state.eventsChanged = false;
    state.data = action.payload;
  });

  builder.addCase(fetchGameDetails.rejected, (state) => {
    state.isLoading = false;
    state.isLoaded = true;
  });
}

function createAddGameEventReducers(builder: ActionReducerMapBuilder<GameDetailsSliceState>) {
  builder.addCase(addGameEvent, (state, action) => {
    if (!state.data) {
      return;
    }

    const { events: prevEvents } = state.data;
    const { data, periodSettings } = action.payload;

    let nextEventData = data;
    if (data.period === 0) {
      const lastPenaltySecond = prevEvents
        .slice()
        .reverse()
        .find((e) => e.period === data.period && e.teamId === data.teamId)
        ?.seconds || 0;

      nextEventData = {
        ...nextEventData,
        seconds: lastPenaltySecond + 60,
      };
    }

    const events = sortGameEvents([
      ...state.data.events,
      mapGameEvent(nextEventData, periodSettings),
    ]);

    state.eventsChanged = true;
    state.data = {
      ...state.data,
      events,
    };
  });
}

function createEditGameEventReducers(builder: ActionReducerMapBuilder<GameDetailsSliceState>) {
  builder.addCase(editGameEvent, (state, action) => {
    const { data, periodSettings } = action.payload;

    if (!state.data || !data.id) {
      return;
    }

    const id = data.id!;
    const updatedEvents = state.data.events.map((event) => {
      if (event.id === id) {
        return mapGameEvent(data, periodSettings);
      }

      return event;
    });

    state.eventsChanged = true;
    state.data = {
      ...state.data,
      events: sortGameEvents(updatedEvents),
    };
  });
}

function createDeleteGameEventReducers(builder: ActionReducerMapBuilder<GameDetailsSliceState>) {
  builder.addCase(deleteGameEvent, (state, action) => {
    const id = action.payload;
    if (!state.data || !id) {
      return;
    }

    const updatedEvents = state.data.events.reduce((result: GameEvent[], event) => {
      if (event.id === id) {
        return result;
      }

      return [
        ...result,
        event,
      ];
    }, []);

    state.eventsChanged = true;
    state.data = {
      ...state.data,
      events: sortGameEvents(updatedEvents),
    };
  });
}

function createEditGameScoreReducer(builder: ActionReducerMapBuilder<GameDetailsSliceState>) {
  builder.addCase(editGameScore, (state, action) => {
    if (!state.data) {
      return;
    }

    state.eventsChanged = true;
    state.data = {
      ...state.data,
      result: action.payload,
    };
  });
}

function createChangeGameDetailsVideoUrlReducer(builder: ActionReducerMapBuilder<GameDetailsSliceState>) {
  builder.addCase(changeGameDetailsVideoUrl, (state, action) => {
    const videoUrl = action.payload;

    if (!state.data) {
      return;
    }

    state.eventsChanged = true;
    state.data = {
      ...state.data,
      videoUrls: videoUrl ? [videoUrl] : [],
    };
  });
}

function createSaveGameDetailsReducer(builder: ActionReducerMapBuilder<GameDetailsSliceState>) {
  builder.addCase(saveGameDetails.pending, (state) => {
    state.isSaving = true;
  });

  builder.addCase(saveGameDetails.fulfilled, (state, { payload }) => {
    state.isSaving = false;
    state.isSaved = true;
    state.eventsChanged = false;

    if (state.data) {
      state.data = {
        ...state.data,
        participants: payload.participants,
      };
    }
  });

  builder.addCase(saveGameDetails.rejected, (state) => {
    state.isSaving = false;
    state.isSaved = false;
  });
}

export const gameDetailsSlice = createSlice({
  name: 'details',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    createFetchGameDetailsReducers(builder);
    createAddGameEventReducers(builder);
    createEditGameEventReducers(builder);
    createDeleteGameEventReducers(builder);
    createSaveGameDetailsReducer(builder);
    createEditGameScoreReducer(builder);
    createChangeGameDetailsVideoUrlReducer(builder);
  },
});
