/** @jsxImportSource @emotion/react */
import UDTooltip from "../../../../../ud-form/components/tooltip";
import { IconButton, tooltipStyles } from "./styles";
import React, { useEffect } from "react";
import history from "../../../../../ud-ui/components/fabric-canvas/addons/history";
import InteractiveClickableIcon from "./interactiveClickableIcon";
import RedoIcon from "../../../../../ud-ui/components/icon/redo-icon";
import { useSelector } from "react-redux";
import { selectActiveModal, selectFabricManager } from "../../../../store/selectors";

export function Redo() {
  const activeModal = useSelector(selectActiveModal);
  const fabricManager = useSelector(selectFabricManager);
  const [isActive, setIsActive] = React.useState(history.isInit() ?? history.redoIsActive())
  
  const onClickRedo = () => {
    if (!fabricManager) return
    history.redo()
  }
  
  useEffect(() => {
    history.history.subscribe(() => {
      setIsActive(history.redoIsActive())
    })
    history.index.subscribe(() => {
      setIsActive(history.redoIsActive())
    })
  }, [])
  
  return (
    <UDTooltip show={!activeModal} css={tooltipStyles} text={"Вперед"}>
      <IconButton onClick={onClickRedo}>
        <InteractiveClickableIcon isActive={isActive} child={RedoIcon}/>
      </IconButton>
    </UDTooltip>
  );
}
