import { AxiosRequestConfig } from 'axios';
import { HttpResource } from './httpResource';

type RequestConfig = Pick<AxiosRequestConfig, 'onUploadProgress' | 'signal' | 'cancelToken' | 'validateStatus'>;
type RequestUrlModifier = (url: string) => string;

const defaultUrlModifier: RequestUrlModifier = (url) => url;

export class Resource {
  constructor(
    private readonly _url: string,
    private readonly _resource: HttpResource,
  ) {}

  get resource(): HttpResource {
    return this._resource;
  }

  get url() {
    return this._url;
  }

  public child(url: string): Resource {
    return new Resource(`${this.url}/${url}`, this.resource);
  }

  public absolute(url: string): Resource {
    return new Resource(url, this._resource);
  }

  public get<T>(params?: AxiosRequestConfig['params'], urlModifier: RequestUrlModifier = defaultUrlModifier) {
    return this.resource.client
      .get<T>(urlModifier(this.url), { params })
      .then((r) => r.data);
  }

  public post(data?: any, config?: RequestConfig) {
    return this.resource.client
      .post(this.url, data, config)
      .then((r) => r.data);
  }

  public put(data?: any, config?: RequestConfig, urlModifier: RequestUrlModifier = defaultUrlModifier) {
    return this.resource.client
      .put(urlModifier(this.url), data, config)
      .then((r) => r.data);
  }

  public patch(data?: any, config?: RequestConfig, urlModifier: RequestUrlModifier = defaultUrlModifier) {
    return this.resource.client
      .patch(urlModifier(this.url), data, config)
      .then((r) => r.data);
  }

  public delete(urlModifier: RequestUrlModifier = defaultUrlModifier) {
    return this.resource.client
      .delete(urlModifier(this.url))
      .then((r) => r.data);
  }
}
