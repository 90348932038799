const alignRight = (<>
  <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_11652_13708)">
      <rect x="14.3335" y="16" width="1" height="16" rx="0.5" transform="rotate(180 14.3335 16)" fill="#007AFF"/>
      <path
        d="M11.3335 9.30993C11.3335 10.486 10.3237 11.4082 9.15242 11.3017L2.15243 10.6654C1.12227 10.5717 0.333496 9.70798 0.333496 8.67357L0.333496 7.32643C0.333496 6.29202 1.12227 5.42829 2.15242 5.33464L9.15242 4.69828C10.3237 4.5918 11.3335 5.514 11.3335 6.69007L11.3335 9.30993Z"
        fill="#007AFF"/>
    </g>
    <defs>
      <clipPath id="clip0_11652_13708">
        <rect width="14" height="16" fill="white" transform="translate(14.3335 16) rotate(180)"/>
      </clipPath>
    </defs>
  </svg>
</>)

export default alignRight
