import BaseFabric from "./baseFabric";
import { fabric } from "fabric";
import { canvasConstructor } from "../types";
import { Canvas } from "fabric/fabric-impl";
import SnapToGrid from "../addons/snapToGrid";

export default class Interactive extends BaseFabric {
  canvas: Canvas
  
  constructor(data?: canvasConstructor) {
    super();
    const { width = 1920, height = 1080, backgroundColor = 'transparent' } = data ?? {}
    
    this.canvas = new fabric.Canvas('canvas', {
      height,
      width,
      imageSmoothingEnabled: false,
      preserveObjectStacking: true,
      backgroundColor: backgroundColor ?? '#FFFFFF',
    })
    
    this.resolution.value = { width, height }
    
    this.init()
  }
  
  sendBackgroundElemsToBack() {
    this.canvas.getObjects().forEach((obj) => {
      if (obj.data?.isSave === false) {
        this.canvas.sendToBack(obj)
      }
    })
  }
  
  reselectActiveObjects() {
    const activeElem = this.canvas.getActiveObjects()
    this.canvas.discardActiveObject()
    const activeSelection = new fabric.ActiveSelection(activeElem, { canvas: this.canvas })
    this.canvas.setActiveObject(activeSelection)
    this.canvas.requestRenderAll()
  }
  
  getLeftTopCurVP() {
    const viewport = this.canvas.viewportTransform;
    const zoom = this.canvas.getZoom()
    const left = SnapToGrid.snapGrid(viewport ? -1 * viewport[ 4 ] / zoom + 20 : 20);
    const top = SnapToGrid.snapGrid(viewport ? -1 * viewport[ 5 ] / zoom + 20 : 20);
    return { left, top };
  }
}
