import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { format } from 'date-fns';
import i18n from 'i18n';
import { toast } from 'react-toastify';
import { UpsertTournamentTeamDto } from '../../teams/domain/dtos/UpsertTournamentTeam.dto';
import { Team } from '../../teams/domain/interfaces/Team';
import teamsResource from '../../teams/domain/resources/teamsResource';
import { Organizer } from '../domain/interfaces/Organizer';
import { UpsertOrganizerInput } from '../domain/interfaces/UpsertOrganizerInput';
import organizerRepository from '../domain/repositories/organizatorRepository';
import organizerResource from '../domain/resources/organizerResource';

const PREFIX = 'organizer';

export const fetchOrganizer = createAsyncThunk<Organizer | null, { tournamentId: number; }>(
  `${PREFIX}/fetchOrganizer`,
  async (payload) => {
    try {
      const [organizer, availableFeatures] = await Promise.all([
        organizerRepository.fetchOrganizer(payload.tournamentId),
        organizerRepository.fetchAvailableFeature(payload.tournamentId),
      ]);
      
      if (!organizer) return null;
      
      organizer.availableFeatures = availableFeatures;
      return organizer;
    } catch (err) {
      console.log(err);
      return null;
    }
  },
);

export const upsertOrganizer = createAsyncThunk<Organizer, UpsertOrganizerInput>(
  `${PREFIX}/upsertOrganizer`,
  async (payload) => {
    const {
      id,
      name,
      description,
      phone,
      email,
      address,
      sportType,
      adminUserId,
      logo,
    } = payload;
    
    try {
      const organizer = await organizerRepository.upsertOrganizer({
        id,
        name,
        description,
        phone,
        email,
        address,
        sportType,
        adminUserId,
      });
      
      if (logo) {
        await organizerResource.uploadLogo(organizer.id, logo);
        const updated = await organizerRepository.fetchOrganizer(organizer.id);
        
        return updated!;
      }
      
      toast.success(i18n.t('settings.saveSettingsSuccess'));
      
      return organizer;
    } catch (err) {
      console.error(`${PREFIX}/upsertOrganizer error:`, err);
      toast.error(i18n.t('settings.saveSettingsInternalError'));
      
      throw err;
    }
  },
);

export const resetOrganizer = createAction(`${PREFIX}/resetOrganizer`);

export const createTeam = createAsyncThunk<Team, UpsertTournamentTeamDto>(
  `${PREFIX}/createTournamentTeam`,
  async (payload) => {
    try {
      const team = {name: payload.name, tournamentId: payload.tournamentId}
      const teamData = await organizerRepository.upsertTeam(team);
      const teamId = teamData.id;
      
      if (payload.logo && payload.logo[0]) {
        const res = await teamsResource.uploadLogo(payload.logo[0], teamId);
        teamData.logo = {id: res.id, url: res.emblem};
      }
      
      teamData.createdAt = format(new Date(), 'dd.MM.yyyy');
      
      return teamData;
    } catch (err) {
      console.error(`${PREFIX}/createTournamentTeam error:`, err);
      throw err;
    }
  },
);

export const editTeam = createAsyncThunk<Team, UpsertTournamentTeamDto>(
  `${PREFIX}/editTournamentTeam`,
  async (payload) => {
    try {
      const team = {id: payload.id, name: payload.name, tournamentId: payload.tournamentId}
      const teamData = await organizerRepository.upsertTeam(team);
      const teamId = teamData.id;
      
      if (payload.logo !== undefined) {
        const res = await teamsResource.uploadLogo(payload.logo[0], teamId);
        teamData.logo = {id: res.id, url: res.emblem};
      }
      
      return teamData;
    } catch (err) {
      console.error(`${PREFIX}/editTournamentTeam error:`, err);
      throw err;
    }
  },
);
