const alignLeft = (<>
  <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_11652_13700)">
      <rect x="0.666748" width="1" height="16" rx="0.5" fill="#007AFF"/>
      <path
        d="M3.66675 6.69007C3.66675 5.514 4.67659 4.5918 5.84782 4.69828L12.8478 5.33464C13.878 5.42829 14.6667 6.29202 14.6667 7.32643V8.67357C14.6667 9.70798 13.878 10.5717 12.8478 10.6654L5.84782 11.3017C4.67659 11.4082 3.66675 10.486 3.66675 9.30993L3.66675 6.69007Z"
        fill="#007AFF"/>
    </g>
    <defs>
      <clipPath id="clip0_11652_13700">
        <rect width="14" height="16" fill="white" transform="translate(0.666748)"/>
      </clipPath>
    </defs>
  </svg>
</>)

export default alignLeft
