import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import styled from '@emotion/styled';
import * as yup from 'yup';
import { format } from 'date-fns';
import UDText from '../../../../ud-ui/components/text';
import UDFormInput from '../../../../ud-form/components/input';
import BorderedInput from '../bordered-input';
import UDButton from '../../../../ud-ui/components/button';
import { AppDispatch } from '../../../../../store/store';
import { upsertChampionshipPlayerApplication } from '../../../store/championshipTeams/actions';
import { ApplicationStatus } from '../../../../applications/domain/enums/ApplicationStatus';
import { birthDateFormatter } from '../../../../ud-form/components/input/formatters/birthDateFormatter';
import { SuggestedPlayerList } from './create-team-player-form-fields';
import { authStoreUserTournamentId } from '../../../../auth/store/selectors';
import { TournamentUser } from '../../../domain/interfaces/TournamentUser';
import playersRepository from '../../../domain/repositories/players.repository';
import { CreateUserInputDto } from '../../../domain/dtos/CreateUserInput.dto';

const CreateTeamPlayerFormWrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
`;

const CreateTeamPlayerFormContainer = styled.div`
  padding: 16px 20px 24px 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 1px rgba(26, 26, 41, 0.08), 0 8px 16px rgba(26, 26, 41, 0.08);
  margin-bottom: 42px;
`;

const CreateTeamPlayerAutocompleteContainer = styled.div`
  position: relative;
`;

const CreateTeamPlayerFormFields = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;

  > div {
    width: 100%;
    margin: 0 5px;
    &:first-child {
      margin-left: 0;
    }
  }
`;

const teamPlayerValidator = yup
  .object()
  .shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    middleName: yup.string().optional(),
    birthDate: yup.string().matches(/^(\d{2})\.(\d{2})\.(\d{4})$/).required(),
  });

type CreateTeamPlayerFormProps = {
  teamId: number;
  championshipId: number;
  existPlayerIds: number[];
};

type TeamPlayerValues = {
  lastName: string;
  firstName: string;
  middleName: string;
  birthDate: string | null;
  time?: number,
};

const CreateTeamPlayerForm = ({ teamId, championshipId }: CreateTeamPlayerFormProps) => {
  const { t } = useTranslation();
  const tournamentId = useSelector(authStoreUserTournamentId);

  const [initialValues, setInitialValues] = useState<TeamPlayerValues>({
    firstName: '',
    lastName: '',
    middleName: '',
    birthDate: '',
  });

  const firstNameRef = useRef<HTMLInputElement>(null);
  const lastNameRef = useRef<HTMLInputElement>(null);
  const middleNameRef = useRef<HTMLInputElement>(null);
  const birthDateRef = useRef<HTMLInputElement>(null);
  const suggestionListRef = useRef<HTMLDivElement>(null);

  const dispatch: AppDispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSuggestLoading, setIsSuggestLoading] = useState<boolean>(false);
  const [suggestQuery, setSuggestQuery] = useState<string>('');
  const [users, setUsers] = useState<TournamentUser[]>([]);
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const [suggestionVisible, setSuggestionVisible] = useState<boolean>(true);

  const showLoadingIcon = useMemo(() => isLoading || isSuggestLoading, [isLoading, isSuggestLoading]);

  useEffect(() => {
      if (tournamentId && suggestQuery.length > 2) {
        setIsSuggestLoading(true);

        playersRepository
          .suggestTournamentPlayers(tournamentId, suggestQuery)
          .then((items) => setUsers(items))
          .finally(() => setIsSuggestLoading(false));
      } else if (suggestQuery.length <= 2 && users.length) {
        setUsers([]);
      }
  }, [suggestQuery, tournamentId]);

  const handleChange = useCallback(() => {
    const nameParts = [];

    if (lastNameRef.current) {
      nameParts.push(lastNameRef.current.value);
    }

    if (firstNameRef.current) {
      nameParts.push(firstNameRef.current.value);
    }

    if (middleNameRef.current) {
      nameParts.push(middleNameRef.current.value);
    }

    const name = nameParts
      .filter((value): value is string => Boolean(value))
      .map((item) => item.trim())
      .join(' ');

    setSelectedUserId(null);

    if (suggestQuery !== name) {
      setSuggestQuery(name);
    }
  }, [suggestQuery]);

  const handleFocus = useCallback(() => {
    setSuggestionVisible(true);
  }, []);

  const handleBlur = useCallback((e) => {
    if (e.relatedTarget && suggestionListRef.current && e.relatedTarget === suggestionListRef.current) {
      return;
    }

    setSuggestionVisible(false);
  }, []);

  const handleSelectUser = useCallback((user: TournamentUser) => {
    const { id, surname: lastName, name: firstName } = user;
    const middleName = user.middleName || '';
    const birthDate = user.birthDate ? format(new Date(user.birthDate), 'dd.MM.uuuu') : user.birthDate;

    setUsers([]);
    setSelectedUserId(id);
    setInitialValues({
      lastName,
      firstName,
      middleName,
      birthDate,
      time: Date.now(),
    });
  }, []);

  const handleSubmit = useCallback((values, { resetForm }) => {
    setIsLoading(true);

    const createUserOrUpdateApplication: CreateUserInputDto = {
      name: values.firstName,
      surname: values.lastName,
      middleName: values.middleName,
      birthDate: values.birthDate,
    };

    if (selectedUserId) {
      createUserOrUpdateApplication.userId = selectedUserId;
    }

    dispatch(upsertChampionshipPlayerApplication({
      teamId,
      championshipId,
      createUserOrUpdateApplication,
      state: ApplicationStatus.PENDING,
    })).then(() => {
      resetForm({
        values: {
          firstName: '',
          lastName: '',
          middleName: '',
          birthDate: '',
        },
      });
    }).finally(() => {
      setIsLoading(false);
    });
  }, [championshipId, dispatch, selectedUserId, teamId]);

  return (
    <CreateTeamPlayerFormWrapper>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={teamPlayerValidator}
        onSubmit={handleSubmit}
      >
        {(formProps) => (
          <form autoComplete="off" onSubmit={formProps.handleSubmit}>
            <CreateTeamPlayerFormContainer>
              <UDText type="subhead" className="bold">
                {t('applications.addPlayerFormTitle')}
              </UDText>

              <CreateTeamPlayerAutocompleteContainer>
                <CreateTeamPlayerFormFields>
                  <UDFormInput
                    autoComplete="none"
                    name="lastName"
                    placeholder="Фамилия"
                    ref={lastNameRef}
                    inputComponent={BorderedInput}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                  />
                  <UDFormInput
                    autoComplete="none"
                    name="firstName"
                    placeholder="Имя"
                    ref={firstNameRef}
                    inputComponent={BorderedInput}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                  />
                  <UDFormInput
                    autoComplete="none"
                    name="middleName"
                    placeholder="Отчество"
                    ref={middleNameRef}
                    inputComponent={BorderedInput}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                  />
                  <UDFormInput
                    name="birthDate"
                    placeholder="Дата рождения"
                    mask="дд.мм.гггг"
                    ref={birthDateRef}
                    formatter={birthDateFormatter}
                    inputComponent={BorderedInput}
                  />
                  <UDButton
                    className="ml-4"
                    type="submit"
                    variant="primary"
                    disabled={showLoadingIcon}
                    loading={showLoadingIcon}
                  >{t('applications.addPlayerButtonTitle')}</UDButton>
                </CreateTeamPlayerFormFields>

                {suggestionVisible && (
                  <SuggestedPlayerList
                    ref={suggestionListRef}
                    users={users}
                    onClick={handleSelectUser}
                  />
                )}
              </CreateTeamPlayerAutocompleteContainer>
            </CreateTeamPlayerFormContainer>
          </form>
        )}
      </Formik>
    </CreateTeamPlayerFormWrapper>
  );
};

export  default CreateTeamPlayerForm;
