/** @jsxImportSource @emotion/react */
import { onHover, ZoomContainer } from "./styles";
import { EmptyButton } from "../rightToolbar/components";
import RoundMinusIcon from "../../../../../ud-ui/components/icon/round-minus-icon";
import RoundPlusIcon from "../../../../../ud-ui/components/icon/round-plus-icon";
import QuestionMarkIcon from "../../../../../ud-ui/components/icon/question-mark-icon";
import React from "react";
import useZoom from "../../../../../ud-ui/components/fabric-canvas/hooks/useZoom";
import { useSelector } from "react-redux";
import { selectFabricManager } from "../../../../store/selectors";
import useTipModal from "../../../hooks/modals/tip";

export function Zoom() {
  const manager = useSelector(selectFabricManager)
  const { increaseZoom, reduceZoom, zoom } = useZoom(manager)
  const { modal, open } = useTipModal()
  
  return (
    <ZoomContainer>
      <EmptyButton centered onClick={reduceZoom}><RoundMinusIcon/></EmptyButton>
      {zoom}%
      <EmptyButton centered onClick={increaseZoom}><RoundPlusIcon/></EmptyButton>
      <EmptyButton css={onHover} onClick={open} centered>
        <QuestionMarkIcon/>
      </EmptyButton>
      {modal}
    </ZoomContainer>
  );
}
