import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  championshipTeamsDataSelector,
  championshipTeamsIsLoadedSelector, championshipTeamsIsLoadingSelector
} from '../../store/championship/selectors';
import { fetchChampionshipTeams } from '../../store/championship/actions';
import { useChampionship } from './use-championship';

export const useAcceptedChampionshipTeams = () => {
  const dispatch = useDispatch();
  const { data: championship } = useChampionship();
  const isLoading = useSelector(championshipTeamsIsLoadingSelector);
  const isLoaded = useSelector(championshipTeamsIsLoadedSelector);
  const teams = useSelector(championshipTeamsDataSelector);

  useEffect(() => {
    if (!isLoaded && !isLoading && championship) {
      dispatch(fetchChampionshipTeams(championship.id));
    }
  }, [dispatch, championship, isLoaded, isLoading]);

  return {
    isLoading,
    isLoaded,
    teams,
  };
}
