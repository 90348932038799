/** @jsxImportSource @emotion/react */
import classNames from 'classnames';
import React, { useRef } from 'react';
import UDIcon from '../icon';
import UDText from '../text';
import Portal from '../portal';
import * as S from './styles';
import { SerializedStyles } from "@emotion/react";

type DivProps = Omit<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  'children'
> & { css?: SerializedStyles };

export type UDModalProps = {
  show: boolean;
  children: React.ReactNode | React.ReactNode[];
  header?: {
    title: string;
    subtitle: string;
  };
  overlayProps?: DivProps;
  containerProps?: DivProps;
  closeIconProps?: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;
  onClose?: React.MouseEventHandler<HTMLImageElement>;
};

const UDModal = (props: UDModalProps) => {
  const nodeRef = useRef(null);
  
  if (!props.show) {
    return null;
  }
  
  return (
    <Portal wrapperId='portal_modal_container'>
      <S.ModalOverlay
        {...props.overlayProps}
        className={classNames(
          'd-flex align-items-center justify-content-center',
          props.overlayProps?.className,
        )}
      >
        <S.ModalContainer
          {...props.containerProps}
          className={classNames(
            'pt-6 pb-8 px-10',
            props.containerProps?.className,
          )}
          ref={nodeRef}
        >
          <UDIcon
            name="close"
            componentProps={{
              onClick: props.onClose,
              className: 'close py-6 px-10',
              ...props.closeIconProps,
            }}
          />
          {props.header && (
            <>
              <UDText type="headline" className="color-SurfaceWhite center mb-4">
                {props.header.title}
              </UDText>
              <UDText
                type="callout"
                className="color-SurfaceRomanSilver30 center">
                {props.header.subtitle}
              </UDText>
            </>
          )}
          {props.children}
        </S.ModalContainer>
      </S.ModalOverlay>
    </Portal>
  );
};

export default UDModal;
