export default function heightValidator(height: string | undefined) {
  if (!height) {
    return 'Высота не может быть пустой'
  }
  if (+height <= 0) {
    return 'Высота не может быть меньше или равна 0'
  }
  if (+height > 4320) {
    return 'Высота не может быть больше 4096'
  }
  return undefined
}
