const voleyball = (
  <>
    <path
      d="M8.31769 8.22541C8.11668 11.0261 6.60083 12.6754 5.55452 13.4855C6.0488 13.7067 6.57833 13.8605 7.13262 13.9415L7.35988 13.757C8.73547 12.6417 10.3998 10.6578 10.7846 7.53237C10.0188 7.58787 9.19 7.79638 8.31769 8.22541Z"
      fill="currentColor"
    />
    <path
      d="M13.7683 6.34051L13.4645 6.15375C12.3792 5.48471 9.53729 4.13913 6.07808 5.57921C6.51536 6.27075 7.13489 6.94579 8.0087 7.53533C10.5153 6.31201 12.7265 6.80254 13.9626 7.29756C13.9236 6.9698 13.8576 6.65028 13.7683 6.34051Z"
      fill="currentColor"
    />
    <path
      d="M8.00345 2C6.94964 2 5.95958 2.27377 5.09853 2.75155L5.10153 2.77855C5.17128 3.41234 5.35129 4.16013 5.71206 4.91993C9.04152 3.49934 11.8564 4.4489 13.3123 5.2057C12.3065 3.29983 10.3076 2 8.00345 2Z"
      fill="currentColor"
    />
    <path
      d="M4.4076 3.20459C3.92007 3.57062 3.49029 4.00714 3.13402 4.50217L3.16627 4.71144C3.43929 6.47179 4.29584 8.94319 6.77474 10.9241C7.18427 10.186 7.49554 9.27096 7.57279 8.14589C5.40441 6.67505 4.64086 4.72044 4.4076 3.20459Z"
      fill="currentColor"
    />
    <path
      d="M2.55203 5.50049C2.20101 6.26404 2 7.11084 2 8.00565C2 10.1545 3.13182 12.0341 4.82767 13.0955L4.89968 13.0437C5.3242 12.7399 5.87624 12.2487 6.37277 11.5616C3.96137 9.66325 2.95481 7.33585 2.55203 5.50049Z"
      fill="currentColor"
    />
    <path
      d="M13.9002 8.08589C13.2702 7.80687 12.4647 7.56686 11.5421 7.52111C11.1858 10.6638 9.63322 12.7384 8.22013 14C11.3928 13.8867 13.9355 11.3163 14.0015 8.13014L13.9002 8.08589Z"
      fill="currentColor"
    />
  </>
);
export default voleyball;
