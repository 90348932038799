import React from 'react';
import LeftToolbar from "../components/editor/leftToolbar/leftToolbar";
import styled from "@emotion/styled";
import Header from "../components/editor/header";
import RightToolbar from "../components/editor/rightToolbar/rightToolbar";
import Content from "../components/editor/mainWindow/content";

const TemplateEditor = () => {
  return (
    <MainBlock>
      <Header/>
      <div className={'d-flex flex-row h-100'}>
        <LeftToolbar/>
        <Content/>
        <RightToolbar/>
      </div>
    </MainBlock>
  );
};

export default TemplateEditor;

const MainBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`
