import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import UDButton from '../../../../../../ud-ui/components/button';
import UDIcon from '../../../../../../ud-ui/components/icon';
import GamesList from '../../../../../../games/ui/components/games-list/games-list';
import { useModal } from '../../../../../../ud-ui/components/modal/useModal';
import CreateGameForm, { UpsertMatchParams } from '../../../../../../games/ui/components/create-game-form';
import { StageV2 } from '../../../../../domain/interfaces/Stage';
import { deleteChampionshipGame, upsertChampionshipGame } from '../../../../../store/championship/actions';
import { AppDispatch } from '../../../../../../../store/store';
import { Game } from '../../../../../../games/domain/interfaces/Game';

type StageMatchesProps = {
  tournamentId: number;
  championshipId: number;
  stage: StageV2;
  onShowResultsClick?: (gameId: number) => void;
  onShowTemplatesClick?: (gameId: number) => void;
};

const StageMatches = (props: StageMatchesProps) => {
  const {
    stage,
    tournamentId,
    championshipId,
    onShowTemplatesClick,
    onShowResultsClick,
  } = props

  const dispatch = useDispatch<AppDispatch>();
  const [selectedGame, selectGame] = useState<Game | null>(null);

  const { open, close, Modal } = useModal({ defaultShow: false });
  const { open: openEdit, close: closeEdit, Modal: EditModal } = useModal({ defaultShow: false });

  const { teams } = stage;

  const handleCreateGameClick = useCallback(() => {
    open();
  }, [open]);

  const handleEditGameClick = useCallback((game: Game) => {
    selectGame(game);
    openEdit();
  }, [openEdit]);

  const handleCloseEditMatchDialog = useCallback(() => {
    selectGame(null);
  }, []);

  const onShowGameResultClick = useCallback((gameId: number) => {
    if (onShowResultsClick) {
      onShowResultsClick(gameId);
    }
  }, [onShowResultsClick]);

  const onShowGameTemplatesClick = useCallback((gameId: number) => {
    if (onShowTemplatesClick) {
      onShowTemplatesClick(gameId);
    }
  }, [onShowTemplatesClick]);

  const handleDeleteGameClick = useCallback((gameId: number) => {
    dispatch(deleteChampionshipGame(gameId))
      .then((result) => {
        if (result.payload) {
          toast.success('Матч удален');
        } else {
          toast.error('Ошибка удаления матча');
        }
      })
      .catch(() => {
        toast.error('Ошибка удаления матча');
      });
  }, [dispatch]);

  const handleCreateGameSubmit = useCallback(async (values: UpsertMatchParams) => {
    const params = {
      tournamentId,
      championshipId,
      values,
    };

    dispatch(upsertChampionshipGame(params))
      .then((result) => {
        if (result.payload) {
          close();
        } else {
          toast.error('Ошибка создания матча');
        }
      })
      .catch((err) => {
        console.error('create game error:', err);
        toast.error('Ошибка создания матча');
      });
  }, [tournamentId, championshipId, close, dispatch]);

  const handleEditGameSubmit = useCallback(async (values: UpsertMatchParams) => {
    if (!selectedGame) {
      return;
    }

    const params = {
      tournamentId,
      championshipId,
      matchId: selectedGame.id,
      values,
    };

    dispatch(upsertChampionshipGame(params))
      .then((result) => {
        if (result.payload) {
          closeEdit();
        } else {
          toast.error('Ошибка редактирования матча');
        }
      })
      .catch((err) => {
        console.error('update game error:', err);
        toast.error('Ошибка редактирования матча');
      });
  }, [tournamentId, championshipId, selectedGame, closeEdit, dispatch]);

  return (
    <div className="row gy-2">
      <div className='col-12'>
        <div className='row'>
          <div className="col-6">
            <div className="d-flex flex-row">
            </div>
          </div>
          <div className="col-6 d-flex justify-content-end">
            <UDButton
              variant="dark"
              className="d-flex align-items-center ml-6"
              onClick={handleCreateGameClick}
            >
              <UDIcon name="plus" componentProps={{ className: 'mr-2' }} />
              Добавить матч
            </UDButton>
          </div>
        </div>
      </div>
      <div className="col-12">
        <div className="row">
          <div className="col-12">
            <GamesList
              games={stage.matches}
              activeGameId={selectedGame?.id}
              onEditGameClick={handleEditGameClick}
              onDeleteGameClick={handleDeleteGameClick}
              onShowGameResultClick={onShowGameResultClick}
              onShowGameTemplatesClick={onShowGameTemplatesClick}
            />
          </div>
        </div>
      </div>
      <div className="col-6"></div>

      <Modal header={{title: 'Добавление матча', subtitle: ''}}>
        <CreateGameForm
          stageId={stage.id}
          rounds={stage.rounds}
          teams={teams}
          requiredRound={true}
          onSubmit={handleCreateGameSubmit}
        />
      </Modal>
      <EditModal
        header={{title: 'Редактирование матча', subtitle: ''}}
        onClose={handleCloseEditMatchDialog}
      >
        <CreateGameForm
          stageId={stage.id}
          rounds={stage.rounds}
          teams={teams}
          game={selectedGame!}
          requiredRound={true}
          onSubmit={handleEditGameSubmit}
        />
      </EditModal>
    </div>
  );
};

export default StageMatches;
