class Customization {
  // border
  borderColor = '#F5D956'
  borderWidth = 4
  
  //corner
  cornerSize = 10
  cornerColor = '#F2F3F4'
  cornerStrokeColor = '#000000'
  cornerStyle: "circle" | "rect" | undefined = 'circle'
  transparentCorners = false
  
  getAllProperties() {
    return {
      borderColor: this.borderColor,
      borderScaleFactor: this.borderWidth,
      cornerSize: this.cornerSize,
      cornerColor: this.cornerColor,
      cornerStrokeColor: this.cornerStrokeColor,
      cornerStyle: this.cornerStyle,
      transparentCorners: this.transparentCorners,
    }
  }
  
  setBorderColor(color: string) {
    this.borderColor = color
  }
  
  setBorderWidth(width: number) {
    this.borderWidth = width
  }
  
  setCornerSize(size: number) {
    this.cornerSize = size
  }
  
  setCornerColor(color: string) {
    this.cornerColor = color
  }
  
  setCornerStrokeColor(color: string) {
    this.cornerStrokeColor = color
  }
  
  setCornerStyle(style: "circle" | "rect") {
    this.cornerStyle = style
  }
  
  setTransparentCorners(transparent: boolean) {
    this.transparentCorners = transparent
  }
}

const customization = new Customization()
export default customization
