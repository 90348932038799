import React from 'react';

interface IProps {
  width?: number;
  height?: number;
  color?: string;
}

export default function CornerIcon(props: IProps) {
  const {
    width,
    height,
    color = '#929292',
    ...rest
  } = props;
  
  return (
    <svg width={width ?? 8} height={height ?? 8} viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M0.5 8V4.5C0.5 2.29086 2.29086 0.5 4.5 0.5H8" stroke={color}/>
    </svg>
  );
}
