const alignBottom = (<>
  <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_11652_13720)">
      <rect x="0.333496" y="14" width="1" height="16" rx="0.5" transform="rotate(-90 0.333496 14)" fill="#007AFF"/>
      <path
        d="M7.02356 11C5.8475 11 4.9253 9.99016 5.03178 8.81893L5.66814 1.81893C5.76179 0.788773 6.62552 2.13697e-08 7.65993 3.37048e-08L9.00707 4.97693e-08C10.0415 6.21045e-08 10.9052 0.788771 10.9989 1.81893L11.6352 8.81893C11.7417 9.99016 10.8195 11 9.64343 11L7.02356 11Z"
        fill="#007AFF"/>
    </g>
    <defs>
      <clipPath id="clip0_11652_13720">
        <rect width="14" height="16" fill="white" transform="translate(0.333496 14) rotate(-90)"/>
      </clipPath>
    </defs>
  </svg>
</>)

export default alignBottom
