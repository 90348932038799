const redo = (
  <>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.3125 14.125H14.125C12.2265 14.125 10.6875 15.664 10.6875 17.5625V17.5625C10.6875 19.461 12.2265 21 14.125 21H16M21.3125 14.125L18.1875 11M21.3125 14.125L18.1875 17.25"
        stroke="#242424" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  </>
)
export default redo
