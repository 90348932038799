const done = (
  <path
    d="M2 11.2L6.8 16L18.8 4"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
);
export default done;
