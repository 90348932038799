/** @jsxImportSource @emotion/react */
import UDTooltip from "../../../../../ud-form/components/tooltip";
import { IconButton, ModalButton, tooltipStyles } from "./styles";
import React, { useEffect, useState } from "react";
import InteractiveToggleIcon from "./interactiveToggleIcon";
import RectanglePlusIcon from "../../../../../ud-ui/components/icon/rectangle-plus-icon";
import BaseModal from "../../elements/modals/baseModal";
import history from "../../../../../ud-ui/components/fabric-canvas/addons/history";
import Elements from "../../../../../ud-ui/components/fabric-canvas/addons/elements";
import { useDispatch, useSelector } from "react-redux";
import { selectActiveModal, selectFabricManager } from "../../../../store/selectors";
import { ModalState } from "../../../../domain/interface/modalState";
import { setActiveModal } from "../../../../store/templateEditor";


export function AddPlaceholder() {
  const dispatch = useDispatch()
  const activeModal = useSelector(selectActiveModal);
  const fabricManager = useSelector(selectFabricManager);
  const [isSelected, setSelected] = useState<boolean>(false);
  const [modalState, setModalState] = useState<ModalState>({ isModalOpen: false, modalStyle: {}, });
  
  
  useEffect(() => {
    const clickListener = () => {
      if (isSelected) {
        setSelected(false)
        setModalState((prev) => ({ ...prev, isModalOpen: false }))
      }
    };
    
    const closeModalOnEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setSelected(false)
        setModalState((prev) => ({ ...prev, isModalOpen: false }))
        dispatch(setActiveModal(undefined))
      }
    }
    
    document.addEventListener('keydown', closeModalOnEsc)
    document.addEventListener('click', clickListener)
    return () => {
      document.removeEventListener('keydown', closeModalOnEsc)
      document.removeEventListener('click', clickListener)
    }
  }, [activeModal, dispatch, isSelected])
  
  useEffect(() => {
    if (!isSelected && activeModal === 'addPlaceholder') {
      dispatch(setActiveModal(undefined))
    }
    // eslint-disable-next-line
  }, [isSelected]);
  
  const onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (isSelected) {
      setModalState((prev) => ({ ...prev, isModalOpen: false }))
      setSelected(false)
      dispatch(setActiveModal(undefined))
      return
    }
    
    const target = e.currentTarget;
    if (!target) return;
    
    const bounding = target.getBoundingClientRect();
    setModalState(
      {
        isModalOpen: true,
        modalStyle: {
          left: `${bounding.left + bounding.width + 15}px`,
          width: 300,
        },
      }
    )
    setSelected(true)
    dispatch(setActiveModal("addPlaceholder"))
  }
  
  const addPlaceholder = (text: string, blockName: string, type: string) => {
    if (!fabricManager) return
    history.saveState()
    if (type === 'text') {
      fabricManager.canvas.add(Elements.createTextPlaceholder(fabricManager.canvas, text, blockName, type))
    } else {
      fabricManager.canvas.add(Elements.createPlaceholder(fabricManager.canvas, text, blockName, type))
    }
    
    setModalState((prev) => ({ ...prev, isModalOpen: false }))
    dispatch(setActiveModal(undefined))
  }
  
  
  const placeholderButtons = placeholdersList.map((placeholder) => {
    return (
      <ModalButton
        key={placeholder.blockName}
        onClick={() => addPlaceholder(placeholder.text, placeholder.blockName, placeholder.type)}>
        {placeholder.name}
      </ModalButton>
    )
  })
  
  return (
    <>
      <UDTooltip show={!activeModal} css={tooltipStyles} text={"Блоки"}>
        <IconButton onClick={onClick}>
          <InteractiveToggleIcon child={RectanglePlusIcon}/>
        </IconButton>
      </UDTooltip>
      <BaseModal isOpen={modalState.isModalOpen && isSelected} style={modalState.modalStyle}>
        {placeholderButtons}
      </BaseModal>
    </>
  );
}

const placeholdersList = [
  { text: 'Город', blockName: 'city', name: 'Город', type: 'text' },
  { text: 'Логотип турнира', blockName: 'organizerLogo', name: 'Логотип турнира', type: 'image' },
  { text: 'Название чемпионата', blockName: 'championshipName', name: 'Название чемпионата', type: 'text' },
  { text: 'Время начала матча', blockName: 'startMatchTime', name: 'Время начала матча', type: 'text' },
  { text: 'Дата начала матча', blockName: 'startMatchDate', name: 'Дата начала матча', type: 'text' },
  { text: 'Время матча', blockName: 'matchTime', name: 'Время матча', type: 'text' },
  { text: 'Логотип\nкоманды №1', blockName: 'teamLogo0', name: 'Логотип команды №1', type: 'image' },
  { text: 'Логотип\nкоманды №2', blockName: 'teamLogo1', name: 'Логотип команды №2', type: 'image' },
  { text: 'Название команды №1', blockName: 'teamName0', name: 'Название команды №1', type: 'text' },
  { text: 'Название команды №2', blockName: 'teamName1', name: 'Название команды №2', type: 'text' },
  { text: 'Счёт команды №1', blockName: 'teamScore0', name: 'Счёт команды №1', type: 'text' },
  { text: 'Счёт команды №2', blockName: 'teamScore1', name: 'Счёт команды №2', type: 'text' },
  { text: 'Фолы команды №1', blockName: 'teamFouls0', name: 'Фолы команды №1', type: 'text' },
  { text: 'Фолы команды №2', blockName: 'teamFouls1', name: 'Фолы команды №2', type: 'text' },
  {
    text: 'Последние результаты\nкоманды №1',
    blockName: 'lastGameResults0',
    name: 'Последние результаты команды №1',
    type: 'image',
  },
  {
    text: 'Последние результаты\nкоманды №2',
    blockName: 'lastGameResults1',
    name: 'Последние результаты команды №2',
    type: 'image'
  },
]
