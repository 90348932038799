import { ChampionshipSettings } from '../../../tourneys/domain/interfaces/ChampionshipSettings';

export type GamePeriodSettings = {
  duration: number;
  startMinute: number;
  finishMinute: number;
};

export type GamePeriodsSettings = Record<number, GamePeriodSettings>

export function getPeriodsSettings(settings: ChampionshipSettings): GamePeriodsSettings {
  if (!settings.gamePeriodsNumber || !settings.periodTime) {
    return {
      0: { duration: 2000, startMinute: 0, finishMinute: 2000 },
      1: { duration: 20, startMinute: 0, finishMinute: 20 },
      2: { duration: 20, startMinute: 20, finishMinute: 40 },
    };
  }

  const periodsSettings: Record<number, GamePeriodSettings> = {
    0: { duration: 2000, startMinute: 0, finishMinute: 2000 },
  };

  for (let i = 1; i <= settings.gamePeriodsNumber; i++) {
    periodsSettings[i] = {
      duration: settings.periodTime,
      startMinute: settings.periodTime * (i - 1),
      finishMinute: settings.periodTime * i,
    };
  }

  if (settings.gameExtraPeriodsNumber && settings.periodExtraTime) {
    const mainPeriodsTime = settings.gamePeriodsNumber * settings.periodTime;
    const extraPeriods = settings.gameExtraPeriodsNumber;
    const extraPeriodTime = settings.periodExtraTime;

    for (let i = 1; i <= extraPeriods; i++) {
      periodsSettings[settings.gamePeriodsNumber + 1] = {
        duration: extraPeriodTime,
        startMinute: mainPeriodsTime + extraPeriodTime * (i - 1),
        finishMinute: mainPeriodsTime + extraPeriodTime * i,
      };
    }
  }

  return periodsSettings;
}

function getPeriodSettings(periodsSettings: GamePeriodsSettings, period: number): GamePeriodSettings {
  if (periodsSettings[period]) {
    return periodsSettings[period];
  }

  const periods = Object.keys(periodsSettings).map(Number);
  const maxPeriod = Math.max(...periods);

  if (period > maxPeriod) {
    const last = periodsSettings[maxPeriod];
    const settings = {
      ...periodsSettings,
      [period]: {
        duration: last.duration,
        startMinute: last.startMinute + last.duration,
        finishMinute: last.finishMinute + last.duration,
      }
    };

    return getPeriodSettings(settings, period);
  }

  return periodsSettings[period];
}

export function getGameEventTime(periodsSettings: GamePeriodsSettings, period: number, seconds: number): { minutes: number, extraMinutes: number | null } {
  const periodData = getPeriodSettings(periodsSettings, period);
  const timeInMinutes = Math.floor(seconds /  60);
  const isExtraTime = seconds >= periodData.duration * 60;

  const minutes = isExtraTime ? periodData.finishMinute : periodData.startMinute + timeInMinutes;
  const extraMinutes = isExtraTime ? timeInMinutes - periodData.duration : null;

  return {
    minutes,
    extraMinutes,
  };
}
