import styled from '@emotion/styled';

export const Separator = styled.div<{ colorUp?: string, colorDown: string, width?: number }>`
    width: ${({width}) => width ?? 5}px;
    min-width: ${({width}) => width ?? 5}px;
    height: 100%;
    background: ${({colorDown}) => colorDown};
    position: relative;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        clip-path: ${({colorUp}) => colorUp && 'polygon(0 0, 0 52%, 100% 48%, 100% 0)'};
        background-color: ${({colorUp}) => colorUp};
    }
`;
