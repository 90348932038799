import styled from "@emotion/styled";

export const MainBlock = styled.div`
  height: calc(100vh - 70px * 2);
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 32px;
`

export const RowsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  overflow-y: auto;
  padding-inline: 24px;

  &::-webkit-scrollbar {
    width: 7px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #CBCBCB;
    border-radius: 8px;
  }
`
