import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from "@emotion/styled";
import { DivProps } from "../../../../typings";

type UDTooltipProps = {
  children: React.ReactNode
  text?: string
  show?: boolean
} & DivProps

function UDTooltip(props: UDTooltipProps) {
  const {
    children,
    text,
    show = true,
    ...toolTipProps
  } = props;
  
  const refSetTimeout = useRef<NodeJS.Timeout>();
  const [showToolTip, setShowToolTip] = useState(false);
  
  const showTip = useCallback(() => {
    if (!show) {
      return;
    }
    setShowToolTip(true);
  }, [show]);
  
  const hideTip = useCallback(() => {
    setShowToolTip(false);
  }, []);
  
  useEffect(() => {
    if (show) { return; }
    hideTip()
    clearTimeout(refSetTimeout.current);
  }, [hideTip, show])
  
  const onMouseEnterHandler = () => {
    refSetTimeout.current = setTimeout(showTip, 750);
  };
  
  const onMouseLeaveHandler = () => {
    clearTimeout(refSetTimeout.current);
    hideTip()
  };
  
  return (
    <MainBlock onMouseEnter={onMouseEnterHandler} onMouseLeave={onMouseLeaveHandler}>
      {children}
      {showToolTip && text && <Tooltip {...toolTipProps}>{text}</Tooltip>}
    </MainBlock>
  );
}

export default UDTooltip;

const MainBlock = styled.div`
  display: flex;
  position: relative;
`

const Tooltip = styled.div`
  position: absolute;
  width: 183px;
  margin-left: calc(100% + 10px);
  margin-top: -10px;
  justify-content: center;
  background-color: #FFF;
  border-radius: 8px;
  white-space: pre-line;
  pointer-events: none;
  z-index: 100;
  filter: drop-shadow(0px 4px 2px #00000040);
  
  padding: 16px;
  font-family: SF Pro Display, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  color: #000000;
  
  &:before {
    content: '';
    position: absolute;
    top: 10px;
    left: -10px;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #FFF;
  }
`
