export default function widthValidator(width: string | undefined) {
  if (!width) {
    return 'Ширина не может быть пустой'
  }
  if (+width <= 0) {
    return 'Ширина не может быть меньше или равна 0'
  }
  if (+width > 7680) {
    return 'Ширина не может быть больше 4096'
  }
  return undefined
}
