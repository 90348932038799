import React from 'react';
import { useSelector } from 'react-redux';
import { Event } from '../../../domain/interfaces/events';
import { selectOverlayState } from '../../../store/selectors';
import Card from './card';
import Goal from './goal'

interface IProps {
  event: Event
}

function RenderEvent(props: IProps) {
  const { event } = props
  const { gameInfo, score } = useSelector(selectOverlayState)
  if (!gameInfo) return null
  
  const leagueLogo = gameInfo.organizer?.logo
  const teamIndex = gameInfo.teams?.firstTeam?.id === event.teamId ? 0 : 1
  const teamLogo = gameInfo.teams?.firstTeam?.id === event.teamId ? gameInfo.teams.firstTeam.logo : gameInfo.teams?.secondTeam?.logo
  const firstPlayer = event.players[ 0 ]
    ? (gameInfo.teams?.secondTeam?.members?.concat(gameInfo.teams?.firstTeam?.members ?? []) ?? []).find((member) => member.id === event?.players[ 0 ]?.id)
    : null
  const secondPlayer = event.players[ 1 ]
    ? (gameInfo.teams?.secondTeam?.members?.concat(gameInfo.teams?.firstTeam?.members ?? []) ?? []).find((member) => member.id === event?.players[ 1 ]?.id)
    : null
  
  switch (event.type) {
    case 'PENALTY_GOAL':
    case 'GOAL':
      if (!firstPlayer) return null
      if (!teamLogo) return null
      if (!leagueLogo) return null
      return <Goal key={event.id} data={{
        teamLogo,
        leagueLogo,
        minute: event.minutes,
        score: score[ teamIndex ],
        lastName: firstPlayer.surname,
      }}/>
    case 'PASS_AND_GOAL':
      if (!secondPlayer) return null
      if (!teamLogo) return null
      if (!leagueLogo) return null
      return <Goal key={event.id} data={{
        teamLogo,
        leagueLogo,
        minute: event.minutes,
        score: score[ teamIndex ],
        lastName: secondPlayer.surname,
      }}/>
    case 'RED_CARD':
      if (!firstPlayer) return null
      if (!teamLogo) return null
      if (!leagueLogo) return null
      return <Card key={event.id} data={{
        teamLogo,
        leagueLogo,
        minute: event.minutes,
        cards: firstPlayer.qtyRedCardsMatch,
        lastName: firstPlayer.surname,
      }} color={'red'}
      />
    case 'YELLOW_CARD':
      if (!firstPlayer) return null
      if (!teamLogo) return null
      if (!leagueLogo) return null
      return <Card key={event.id} data={{
        teamLogo,
        leagueLogo,
        minute: event.minutes,
        cards: firstPlayer.qtyYellowCardsMatch,
        lastName: firstPlayer.surname,
      }} color={'yellow'}
      />
    default:
      return null
  }
}

export default RenderEvent;
