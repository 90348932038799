import { useCallback, useMemo } from "react";
import { useOrganizer } from "../../../../../organizer/ui/hooks/useOrganizer";
import { useDispatch, useSelector } from "react-redux";
import { selectActiveCategory, selectActiveTemplate } from "../../../../store/selectors";
import supabase from "../../../../../core/supabase/supabase";
import { setTemplate, updateCategory } from "../../../../store/templateEditor";
import useUniversalModal, { IUNModalProps } from "../useUniversalModal";
import _ from "lodash";
import templateNameValidator from "../../../../domain/validators/templateName.validator";

function useRenameModal() {
  const dispatch = useDispatch();
  const { info } = useOrganizer();
  const category = useSelector(selectActiveCategory);
  const templates = useMemo(() => {
    if (!category) return;
    return _(category.templates).toPairs().orderBy((value) => {return value[ 1 ].id}).value().map(value => value[ 0 ]);
  }, [category]);
  const activeTemplate = useSelector(selectActiveTemplate);
  
  const validator = useCallback((values: { [ p: string ]: string }) => {
    const errors: { [ p: string ]: string | undefined } = {}
    
    const nameError = templateNameValidator(values.name?.trim(), templates)
    if (nameError) {errors.name = nameError}
    
    return errors
  }, [templates]);
  
  const onSubmit = useCallback((values: { [ p: string ]: string }) => {
    if (!info) return;
    if (!activeTemplate) return;
    if (!category) return;
    
    const { name } = values
    
    let replaceableTemplate = category.templates[ activeTemplate ]
    const filteredTemplates = _.pickBy(category.templates, (_, key) => {
      return activeTemplate !== key
    })
    
    const newTemplates = {
      ...filteredTemplates,
      [ name ]: replaceableTemplate
    }
    
    supabase.overlayTemplates.updateRow({ rowId: category.id }, { templates: newTemplates })
    dispatch(updateCategory({ name: category.name, templates: newTemplates, resolution: category.resolution }))
    dispatch(setTemplate({ templateName: name }))
  }, [info, activeTemplate, category, dispatch]);
  
  const data: IUNModalProps = useMemo(() => {
    return {
      initialValue: {
        name: activeTemplate ?? '',
      },
      headerText: 'Переименовать',
      validator,
      buttonText: 'Сохранить',
      input: {
        placeholder: 'Название табло'
      },
      onSubmit,
    }
  }, [activeTemplate, onSubmit, validator]);
  
  const { modal, close, open } = useUniversalModal(data)
  
  return { modal, close, open }
}

export default useRenameModal;
