import { generateUUID } from 'modules/utils/uuid';
import React from 'react';
import * as S from './styles';

export type UDCheckboxProps = {
  className?: string;
} & Omit<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >,
  'className' | 'hidden' | 'id'
>;

const UDCheckbox = (props: UDCheckboxProps) => {
  const { className, ...inputProps } = props;
  const id = generateUUID();
  return (
    <S.CheckboxLabel htmlFor={id} className={className}>
      <S.CheckboxInput {...inputProps} hidden type="checkbox" id={id} />
      <S.CustomCheckbox />
    </S.CheckboxLabel>
  );
};

export default UDCheckbox;
