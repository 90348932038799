import { useMemo } from 'react';
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';
import debounce from 'modules/utils/debounce';
import { TeamDTO } from 'modules/teams/domain/dtos/Team.dto';
import { useOrganizer } from 'modules/organizer/ui/hooks/useOrganizer';
import useLocalStorage from 'modules/main/ui/hooks/useLocalStorage';

const defaultFilters = {
  search: StringParam,
  page: withDefault(NumberParam, 1),
  perPage: withDefault(NumberParam, 10),
};

export const useTeamList = () => {
  const { info, isLoaded, isLoading } = useOrganizer();
  const [filters, setFilters] = useQueryParams(defaultFilters);
  const [teamsPerPage, setTeamsPerPage] = useLocalStorage(
    'teamsPerPage',
    filters.perPage
  );

  const changeFilter = useMemo(
    () =>
      debounce((changes: { [key: string]: any }) => {
        if (changes.perPage && teamsPerPage !== changes.perPage) {
          setTeamsPerPage(
            (prevTeamsPerPage: number) => (prevTeamsPerPage = changes.perPage)
          );
        }
        if (changes.perPage && changes.perPage !== filters.perPage) {
          changes.page = 1;
        }
        setFilters({
          ...filters,
          ...changes,
        });
      }, 300),
    [filters, setFilters, teamsPerPage, setTeamsPerPage]
  );

  const { teams, pages } = useMemo(() => {
    if (!isLoaded || isLoading) {
      return {
        teams: [],
        pages: 0,
      };
    }

    const teams =
      (info?.teams || []).filter((team: TeamDTO) => {
        if (!filters.search) {
          return team;
        }

        const { name } = team;

        const includesSearch =
          !filters.search ||
          name.toLowerCase().includes(filters.search.toLowerCase());

        return includesSearch;
      }) || [];

    const pages = Math.ceil(teams.length / teamsPerPage);
    const offset = (filters.page - 1) * teamsPerPage;
    const pageTeams = teams.slice(offset, offset + teamsPerPage);

    return {
      teams: pageTeams,
      pages,
    };
  }, [info?.teams, isLoading, isLoaded, filters, teamsPerPage]);

  return {
    changeFilter,
    filters,
    teams,
    isLoading,
    pages,
    teamsPerPage,
  };
};
