import { EventType } from '../interfaces/events';

export const time = {
  second: 1000,
  minute: 1000 * 60,
  hour: 1000 * 60 * 60,
}

export const durations: Record<EventType, any> = {
  GOAL: time.second * 5,
  CORNER: time.second * 5,
  PASS_AND_GOAL: time.second * 5,
  PENALTY_GOAL: time.second * 5,
  PENALTY_MISSED: time.second * 5,
  OWN_GOAL: time.second * 5,
  FOUL: time.second * 5,
  REPLACEMENT: time.second * 5,
  TIME_OUT: time.second * 5,
  SAVE: time.second * 5,
  RED_CARD: time.second * 5,
  YELLOW_CARD: time.second * 5,
}
