const leftLarge = (
  <path
    d="M10.3998 2.39999L4.7998 7.99999L10.3998 13.6"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
);

export default leftLarge;
