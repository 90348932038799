import classNames from 'classnames';
import { useFormikContext } from 'formik';
import React from 'react';
import UDFormDateInput, { UDFormDateInputProps } from '.';

type Props = {
  startName: string;
  endName: string;
  startLabel?: string;
  endLabel?: string;
} & Omit<UDFormDateInputProps, 'name' | 'label'>;

const UDFormDateRange = (props: Props) => {
  const {
    startName,
    endName,
    startLabel,
    endLabel,
    labelProps,
    containerProps,
    disabled,
    minDate,
    autoComplete,
  } = props;

  const { getFieldProps } = useFormikContext();

  const selectedStart = getFieldProps(startName).value;
  const selectedEnd = getFieldProps(endName).value;
  const startMinDate = minDate || null;
  const endMinDate = selectedStart || minDate || null;

  return (
    <div
      {...containerProps}
      className={classNames('row gx-2', containerProps?.className)}>
      <UDFormDateInput
        disabled={disabled}
        name={startName}
        containerProps={{ className: 'col-6' }}
        label={startLabel}
        labelProps={labelProps}
        minDate={startMinDate}
        maxDate={selectedEnd}
        autoComplete={autoComplete}
      />
      <UDFormDateInput
        disabled={disabled}
        name={endName}
        containerProps={{ className: 'col-6' }}
        label={endLabel}
        labelProps={labelProps}
        minDate={endMinDate}
        autoComplete={autoComplete}
      />
    </div>
  );
};

export default UDFormDateRange;
