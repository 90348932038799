import React from 'react';

type FreeKickIconProps = {
  width: number;
  height: number;
  color?: string;
};

const FreeKickIcon = (props: FreeKickIconProps) => {
  const {
    width,
    height,
    color = '#F2CA18',
  } = props;

  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 18 24"
      fill="none" 
    >
      <path
        d="M8.3165 15.8006V14.0645H8.17974C7.58196 14.5483 6.26891 15.6176 5.08042 16.6299L4.83354 16.3724C6.43683 15.0057 8.26259 13.5402 8.28568 13.5213C8.84333 13.0738 8.9329 12.2587 8.48537 11.701C8.03752 11.1431 7.22275 11.0535 6.66511 11.501C6.56624 11.5803 4.68303 13.0924 3.038 14.4998C2.61808 14.8591 2.21359 15.2116 1.85846 15.5317C1.85814 15.532 1.8575 15.5323 1.85718 15.5327C1.42281 15.924 1.10145 16.2316 0.874151 16.4727C-0.0347439 17.4367 0.172357 18.1521 0.506238 18.5822C0.800949 18.9624 1.15345 19.0975 1.51491 19.0975C2.09181 19.0975 2.69181 18.7527 3.11564 18.5087C3.43957 18.3225 3.83477 18.0817 4.30282 17.7857V26.3857C4.30282 26.4059 4.30507 26.4255 4.30603 26.4454L3.11014 42.0681C3.02571 43.1706 3.8511 44.1317 4.95292 44.2162C6.05503 44.3007 7.01686 43.4759 7.10134 42.3738L8.22951 27.637H9.13257L10.2607 42.3738C10.3452 43.4762 11.3073 44.3006 12.4092 44.2162C13.5109 44.1318 14.336 43.1703 14.2519 42.0681L13.0561 26.4446C13.057 26.425 13.0589 26.4057 13.0589 26.3858L13.0586 16.037C14.6128 14.7991 18.1095 10.917 16.6639 1.75961C16.5525 1.05332 15.8892 0.571443 15.1829 0.682559C14.4766 0.793962 13.9941 1.45723 14.1058 2.16355C15.4067 10.4027 12.3093 13.377 11.386 14.0641H9.04531V15.8003L8.3165 15.8006Z"
        fill={color}
      />
      <path 
        d="M7.47433 10.8604C7.8172 10.8604 8.14273 10.9666 8.41594 11.157V10.7461H10.146C10.5258 10.7461 10.834 11.054 10.834 11.4341C10.834 11.8139 10.5261 12.1221 10.146 12.1221C9.76624 12.1221 9.45805 11.8142 9.45805 11.4341C9.45805 11.411 9.46254 11.3891 9.46479 11.3667H8.66251C8.69719 11.4026 8.73154 11.4392 8.763 11.4787C9.0391 11.8225 9.1643 12.2537 9.11647 12.6919C9.09977 12.8435 9.06189 12.9895 9.00668 13.1273C10.8651 12.9622 12.3227 11.402 12.3227 9.50047C12.3227 7.4888 10.6921 5.85864 8.68089 5.85864C6.66922 5.85864 5.03906 7.48921 5.03906 9.50047C5.03906 10.3439 5.32639 11.1195 5.80731 11.7365C6.13509 11.4704 6.36463 11.2858 6.44233 11.2235C6.73448 10.9891 7.10078 10.8604 7.47451 10.8604L7.47433 10.8604Z"
        fill={color}
      />
    </svg>
  );
};

export default FreeKickIcon;