import styled from '@emotion/styled';

export const GameEventsList = styled.div`
  position: relative;
  margin: 0 auto;
  max-width: 800px;
  width: 100%;

  &::after {
    content: '';
    position: absolute;
    width: 3px;
    background-color: #8F8E99;
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -2px;
  }

  &.empty {
    &::after {
      display: none;
    }
  }

  .empty-list {
    margin-top: 20px;
    text-align: center;
    font-family: -apple-system,BlinkMacSystemFont,sans-serif;
  }

  .game-event {
    position: relative;
    width: 50%;
    padding: 20px 50px;

    &.left {
      left: 0;
    }

    &.right {
      left: 50%;
    }
  }
`;

type GameResultEventMinuteProps = {
  minutes: number;
  extraMinutes?: number;
  isLeft: boolean;
};

export const GameResultEventMinute = styled.div<GameResultEventMinuteProps>`
  position: relative;
  box-shadow: 0 2px 8px rgba(36, 46, 76, 0.25);
  border-radius: 8px;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  
  .game-event-time {
    position: absolute;
    top: ${(props: GameResultEventMinuteProps) => `${props.extraMinutes ? 'calc(50% - 20px)' : 'calc(50% - 14px)' }`};
    right: ${(props: GameResultEventMinuteProps) => props.isLeft ? '-76px' : 'auto'};
    left: ${(props: GameResultEventMinuteProps) => props.isLeft ? 'auto' : '-74px'};
    width: 52px;
    content: ${(props: GameResultEventMinuteProps) => `"${props.minutes}’ ${props.extraMinutes ? '+ ' + props.extraMinutes : ''}"`};
    padding: 4px 0;
    text-align: center;
    border-radius: 8px;
    background: #F5D956;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
    .extra-minutes {
      font-size: 12px;
    }
  }

  &::before {
    background: #FFF;
    width: 15px;
    height: 15px;
    transform: rotate(-45deg);
    content: ' ';
    position: absolute;
    top: calc(50% - 9px);
    right: ${(props: GameResultEventMinuteProps) => props.isLeft ? '-9px' : 'auto'};
    left: ${(props: GameResultEventMinuteProps) => props.isLeft ? 'auto' : '-9px'};
    border: 1px solid #D8D8D8;
    border-color: ${(props: GameResultEventMinuteProps) => props.isLeft ? 'transparent rgb(36 46 76 / 12%) rgb(36 46 76 / 12%) transparent' : 'rgb(36 46 76 / 12%) transparent transparent rgb(36 46 76 / 12%)'};
  }

  .game-event-card {
    display: flex;
  }

  .game-event-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    background: #2D2D47;
    border-radius: ${(props: GameResultEventMinuteProps) => props.isLeft ? '8px 0 0 8px' : '0 8px 8px 0'};
    order: ${(props: GameResultEventMinuteProps) => props.isLeft ? 0 : 1};
  }

  .game-event-body {
    flex: 1;
    background: #FFFFFF;
    border-radius: ${(props: GameResultEventMinuteProps) => props.isLeft ? '0 8px 8px 0' : '8px 0 0 8px'};
    padding: 10px 20px;
    min-height: 84px;
  }

  .game-event-type {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #2D2D47;
  }

  .game-event-player-info {
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    color: #1A1A29;
    margin: 10px 0 2px 0;
  }

  .game-event-player-position {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #8F8E99;
  }
`;

export const GameResultEventPeriod = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 1;
  
  .period-title {
    background: #2D2D47;
    border-radius: 8px;
    color: #FFFFFF;
    font-family: -apple-system,BlinkMacSystemFont,sans-serif;
    font-size: 14px;
    padding: 6px 12px;
  }
`;
