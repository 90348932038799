import React, { ChangeEvent, useEffect, useState } from 'react';
import styled from "@emotion/styled";

interface IProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'onSubmit' | 'onBlur' | 'onFocus'> {
  onChange?: (value: string) => void;
  onSubmit?: (value: string) => void;
  onBlur?: (value: string) => void;
  onFocus?: (value: string) => void;
  value: string;
  icon?: React.ReactNode;
  unit?: string
}

function Input(props: IProps) {
  const { onChange, onSubmit, onBlur, onFocus, value, icon, unit, ...rest } = props
  const [viewState, setViewState] = useState(value)
  const [isFocus, setIsFocus] = useState(false)
  
  useEffect(() => {
    setViewState(value)
  }, [value]);
  
  useEffect(() => {
    if (isFocus) {
      const onlyDigits = value.replace(/\D/g, '')
      setViewState(onlyDigits)
    } else {
      setViewState(value + (unit ?? ''))
    }
    
    // eslint-disable-next-line
  }, [isFocus]);
  
  const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    if (onChange) onChange(value)
  }
  
  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (!event.target) return
      if (onSubmit) {onSubmit((event.target as HTMLInputElement).value)}
    }
  };
  
  const onBlurInput = (event: React.FocusEvent<HTMLInputElement>) => {
    setIsFocus(false)
    if (!event.target) return
    if (onBlur) {onBlur((event.target as HTMLInputElement).value)}
  };
  
  const onFocusInput = (event: React.FocusEvent<HTMLInputElement>) => {
    setIsFocus(true)
    if (!event.target) return
    if (onFocus) {onFocus((event.target as HTMLInputElement).value)}
  };
  
  return (
    <MainBlock {...rest}>
      {icon}
      <InputBlock
        onFocus={onFocusInput}
        onBlur={onBlurInput}
        onKeyDown={onKeyDown}
        value={viewState}
        onChange={onChangeInput}
      />
    </MainBlock>
  );
}

export default Input;

export const IconWrapper = styled.span`
  font-family: Inter, sans-serif;
  font-size: 10px;
  font-weight: 400;
  text-align: center;
  color: #929292;
  min-height: 16px;
  min-width: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const MainBlock = styled.div`
  border-radius: 8px;
  background: #F2F3F4;
  
  height: 32px;
  
  padding: 8px 8px 8px 4px;
  
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const InputBlock = styled.input`
  width: 100%;
  height: 100%;
  background: transparent;
  border: none;
  outline: none;
  margin-left: 4px;
  
  font-family: Inter, sans-serif;
  font-size: 11px;
  font-weight: 400;
  text-align: left;
`
