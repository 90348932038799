import React from 'react';
import { HotkeyItem, Hotkeys, Item as ItemBlock, VertDivider } from "./style";
import UDText from "../../../../../ud-ui/components/text";

function Item(props: IProps) {
  return (
    <ItemBlock>
      <UDText type="caption-1">{props.text}</UDText>
      <Hotkeys>
        <HotkeyItem>
          {props.windows.icon}
          <UDText type="caption-1">{props.windows.text}</UDText>
        </HotkeyItem>
        <VertDivider color={'#E7E7E7'}/>
        <HotkeyItem>
          {props.mac.icon}
          <UDText type="caption-1">{props.mac.text}</UDText>
        </HotkeyItem>
      </Hotkeys>
    </ItemBlock>
  );
}

export default Item;

interface IProps {
  text: string
  windows: {
    icon: JSX.Element
    text: string
  }
  mac: {
    icon: JSX.Element
    text: string
  }
}
