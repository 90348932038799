/** @jsxImportSource @emotion/react */
import UDTooltip from "../../../../../ud-form/components/tooltip";
import { IconButton, tooltipStyles } from "./styles";
import PenIcon from "../../../../../ud-ui/components/icon/pen-icon";
import React, { useState } from "react";
import InteractiveToggleIcon from "./interactiveToggleIcon";
import lineDrawer from "../../../../../ud-ui/components/fabric-canvas/addons/lineDrawer";
import { selectActiveModal, selectFabricManager } from "../../../../store/selectors";
import { useSelector } from "react-redux";

export function PenTool() {
  const activeModal = useSelector(selectActiveModal);
  const fabricManager = useSelector(selectFabricManager);
  const [isSelected, setSelected] = useState<boolean>(false);
  
  const onClickPen = () => {
    if (!fabricManager) return
    if (isSelected) {
      setSelected(false)
      lineDrawer.edit()
      return
    }
    
    setSelected(true)
    lineDrawer.draw()
  }
  
  return (
    <UDTooltip show={!activeModal} css={tooltipStyles} text={"Перо"}>
      <IconButton onClick={onClickPen}>
        <InteractiveToggleIcon isTurnOffWhenClickSomewhere={false} child={PenIcon}/>
      </ IconButton>
    </UDTooltip>
  );
}
