import styled from '@emotion/styled';

export const TeamSelectOptionContainer = styled.div`
  display: flex;
  align-items: center;
  
  .team-select-logo {
    margin-right: 16px;
  }
`;

type RoundHeadersProps = {
  rounds: number;
};

export const RoundHeaders = styled.div<RoundHeadersProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  
  .round-title {
    width: ${(props) => `${(100 / props.rounds)}%`};
    text-align: center;
  }
`;

export const RoundBracketsContainer = styled.div`
  display: flex;
  
  .round {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
`;
