import React from 'react';
import styled from '@emotion/styled';
import UDText from 'modules/ud-ui/components/text';
import UDButton from 'modules/ud-ui/components/button';
import UDColorableIcon from 'modules/ud-ui/components/icon/colorable-icon';

const StageGroupEmptyTableWrapper = styled.div`
  background: #FFF;
  padding: 34px 0;
`;

type StageGroupEmptyTableProps = {
  onAddClick: () => void;
};

const StageGroupEmptyBody = ({ onAddClick }: StageGroupEmptyTableProps) => {
  return (
    <StageGroupEmptyTableWrapper className="d-flex flex-column align-items-center">
      <UDText type="headline" className="mb-6">
        Команды еще не добавлены
      </UDText>
      <UDButton
        variant="dark"
        className="d-flex flex-row align-items-center"
        onClick={onAddClick}>
        <UDColorableIcon
          name="plus"
          componentProps={{ className: 'mr-2 color-AccentNapplesYellow' }}
        />
        Добавить команду
      </UDButton>
    </StageGroupEmptyTableWrapper>
  );
};

export default StageGroupEmptyBody
