import React from 'react';

type CircleChevronIconProps = {
  width: number;
  height: number;
  color?: string;
};

const CircleChevronIcon = ({ width, height, color = '#F5D956' }: CircleChevronIconProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10" r="9" fill={color}/>
      <path d="M6.625 8.875L10 12.25L13.375 8.875" stroke="#0A0A0B" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default CircleChevronIcon;
