import axios, { AxiosInstance } from "axios";
import { ITokenStorage } from "./domain/interfaces/ITokenStorage";
import { TokenStorage } from "./tokenStorage";

const isProd = process.env.REACT_APP_ENVIRONMENT === 'production';

export class GraphqlResource {
  constructor(
    private readonly client: AxiosInstance,
    private readonly tokenStorage: ITokenStorage,
  ) {}

  query<T>(query: string, variables?: Record<string, any>) {
    const headers = this.prepareHeaders();
    const data = {
      query,
      variables,
    };

    return this.client.post<T>('/', data, { headers });
  }

  private prepareHeaders(): Record<string, string> {
    const token = this.tokenStorage.getToken();
    if (token) {
      return {
        Authorization: `Bearer ${token}`,
        'x-api-env': isProd ? 'prod' : 'dev',
      };
    }

    return {};
  }
}

const graphqlResource = new GraphqlResource(
  axios.create({ baseURL: '/api/graphql' }),
  new TokenStorage(),
);

export default graphqlResource;
