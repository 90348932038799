import { GameDetailsDTO } from '../interfaces/GameDetailsResponseDTO';
import { GameDetails, GameDetailsTeam, GameEvent, GameEventType } from '../interfaces/GameDetails';
import { sortGameEvents } from 'modules/games/utils/games-utils';
import { GamePeriodsSettings, getGameEventTime } from './match-periods-settings';

function mapGameEvents(match: GameDetailsDTO, periodSettings: GamePeriodsSettings): GameEvent[] {
  const teams = match.teamMatches.map((teamMatch) => teamMatch.team);
  const { matchEvents } = match;

  if (!matchEvents || !matchEvents.length /* || !matchEvents[0].period */) {
    return [];
  }

  const events = matchEvents.map((matchEvent) => {
    const period = matchEvent.period ?? 1;
    const { minutes, extraMinutes} = getGameEventTime(periodSettings, period, matchEvent.second);

    const firstPlayerId = matchEvent.user.id;
    const secondaryPlayerId = matchEvent.secondaryUser?.id || 0;
    const team = teams.find((team) => team.members.some((member) => member.user.id === firstPlayerId));
    const { id: teamId } = team || {};
    const type = secondaryPlayerId && matchEvent.type === GameEventType.GOAL
      ? GameEventType.PASS_AND_GOAL
      : matchEvent.type as GameEventType;

    let playerIds: number[];
    if (type === GameEventType.PASS_AND_GOAL) {
      playerIds = [secondaryPlayerId || firstPlayerId, firstPlayerId];
    } else {
      playerIds = secondaryPlayerId ? [firstPlayerId, secondaryPlayerId] : [firstPlayerId];
    }

    if (!teamId) {
      const applicationTeam = teams.find((t) => {
        const app = t.championshipApplications.find((ca) => (
          ca.championship.id === match.championship.id
          && ca.user.id === firstPlayerId
        ));

        return !!app;
      });


      return {
        id: matchEvent.id,
        type: matchEvent.type,
        seconds: matchEvent.second,
        period,
        minutes,
        extraMinutes,
        teamId: applicationTeam ? applicationTeam.id : 0,
        playerIds,
      } as GameEvent;
    }

    return {
      id: matchEvent.id,
      type,
      seconds: matchEvent.second,
      period,
      minutes,
      extraMinutes,
      teamId,
      playerIds,
    } as GameEvent;
  });

  return sortGameEvents(events);
}

export function mapGameDetails(match: GameDetailsDTO, periodSettings: GamePeriodsSettings): GameDetails {
  const teams = match.teamMatches.map((teamMatch) => {
    const { team } = teamMatch;
    const currentApplications = team.championshipApplications.filter((item) => item.championship.id === match.championship.id);
    const allMembers: Record<number, { position: string, number: number; }> = team.members.reduce((result, member) => ({
      ...result,
      [member.user.id]: {
        position: member.position,
        number: member.number,
      }
    }), {});

    return {
      id: team.id,
      name: team.name,
      logo: team.emblem?.externalUrl,
      members: currentApplications.map((item) => ({
        id: item.user.id,
        teamId: team.id,
        firstName: item.user.name,
        lastName: item.user.surname,
        avatar: item.user.photo?.externalUrl || '',
        position: (allMembers[item.user.id]?.position || 'defender').toLowerCase(),
        number: allMembers[item.user.id]?.number || 100,
      })),
    };
  }) as [GameDetailsTeam, GameDetailsTeam];

  const participants = (match.participants || []).reduce<number[]>((results, item) => {
    if (item.match.id === match.id && item.isInvolved) {
      return [
        ...results,
        item.user.id,
      ];
    }

    return results;
  }, []);

  const hasScore = match.teamMatches[0].score !== null && match.teamMatches[1].score !== null;

  return {
    id: match.id,
    sportType: match.sportType,
    date: new Date(match.date),
    championship: {
      id: match.championship.id,
      name: match.championship.name,
    },
    tournament: {
      id: match.tournament.id,
      name: match.tournament.name,
    },
    hasScore,
    result: {
      firstTeamScore: match.teamMatches[0].score ?? 0,
      secondTeamScore: match.teamMatches[1].score ?? 0,
    },
    teams,
    events: mapGameEvents(match, periodSettings),
    round: match.round
      ? {
        id: match.round.id,
        name: match.round.name,
        stageId: match.round.stage.id,
      }
      : undefined,
    videoUrls: match.videos.map((video) => video.externalUrl),
    participants,
  };
}
