import { useEffect, useState } from 'react';
import Static from "../canvases/static";
import { useFabricOptions } from "../types";
import Interactive from "../canvases/interactive";
import { EmotionJSX } from "@emotion/react/types/jsx-namespace";

export default function useFabric<T extends 'static' | 'interactive'>(type: T, options?: useFabricOptions): {
  canvas: EmotionJSX.Element;
  manager: T extends 'static' ? Static : T extends 'interactive' ? Interactive : undefined;
} {
  const [manager, setManager] = useState<any>(undefined);
  const { data, style, dependence = [] } = options ?? {};
  
  useEffect(() => {
    setManager(type === 'static' ? new Static(data) : new Interactive(data))
  }, [...dependence]);
  
  const canvas = (
    <canvas style={style} id={'canvas'}/>
  );
  
  return { manager, canvas };
}
