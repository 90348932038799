import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import UDTabs, { Tab } from 'modules/ud-ui/components/tabs';
import { defaultTab, TeamEditFormPart } from './constants';
import UDText from '../../../../ud-ui/components/text';
import { Team } from '../../../domain/interfaces/Team';

type CreateEditFormParams = {
  tab: Tab;
  count?: number;
  isActive?: boolean;
};

const createEditFormTab = (params: CreateEditFormParams): Tab => {
  const { tab, count, isActive } = params;

  if (count !== undefined) {
    const badgeBgColor = isActive
      ? 'bgc-SurfaceWhite'
      : 'bgc-AccentNapplesYellow';
    const className = classNames([
      'color-SurfaceRaisinBlack50',
      badgeBgColor,
      'p-1',
      'br-4',
      'ml-2',
      'line-height-1',
      'caption-1',
    ]);

    return {
      hash: tab.hash,
      label: (
        <>
          {tab.label}{' '}
          <UDText type="subhead" className={className}>
            {count}
          </UDText>
        </>
      ),
    };
  }

  return tab;
};

type TeamEditFormTabsProps = {
  team: Team;
};

const TeamEditFormTabs = ({ team }: TeamEditFormTabsProps) => {
  const location = useLocation();
  const activeTab = (location.hash || '').split('/')[0];

  const tabs = useMemo(() => {
    return Object.values(TeamEditFormPart).map((tab) =>
      createEditFormTab({
        tab: tab,
        isActive: tab.hash === activeTab,
      })
    );
  }, [activeTab]);

  return <UDTabs tabs={tabs} defaultTab={defaultTab} />;
};

export default TeamEditFormTabs;
