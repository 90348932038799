import { ITokenStorage } from "./domain/interfaces/ITokenStorage";

export class TokenStorage implements ITokenStorage {
  private readonly tokenKey = 'authToken';

  public saveToken(token: string): void {
    localStorage.setItem(this.tokenKey, token);
  }

  public getToken() {
    return localStorage.getItem(this.tokenKey);
  }

  public removeToken() {
    localStorage.removeItem(this.tokenKey);
  }
}