import React from 'react';

type PlayIconProps = {
  width: number;
  height: number;
  color?: string;
};

const PlayIcon = (props: PlayIconProps) => {
  const {
    width,
    height,
    color = '#F2CA18',
  } = props;

  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none" 
    >
      <path
        d="M11.1133 0V12.9898H13.9999V0H11.1133Z"
        fill={color}
        />
      <path
        d="M0 13.0521L10.7527 6.84408V6.21781L0 0.00976562V13.0521Z"
        fill={color}
      />
    </svg>
  );
};

export default PlayIcon;