import React from "react";

const templates = (
  <svg width={16} height={16} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.225 1.00238C7.65121 1.01015 7.30674 1.04337 7.02822 1.18529C6.70834 1.34827 6.44827 1.60834 6.28529 1.92822C6.14337 2.20674 6.11015 2.55121 6.10238 3.125M15.875 1.00238C16.4488 1.01015 16.7933 1.04337 17.0718 1.18529C17.3917 1.34827 17.6517 1.60834 17.8147 1.92822C17.9566 2.20674 17.9898 2.5512 17.9976 3.12499M17.9976 10.775C17.9898 11.3488 17.9566 11.6933 17.8147 11.9718C17.6517 12.2917 17.3917 12.5517 17.0718 12.7147C16.7933 12.8566 16.4488 12.8898 15.875 12.8976M18 6.09999V7.79999M11.2 1H12.9M3.72 18H10.18C11.1321 18 11.6081 18 11.9718 17.8147C12.2917 17.6517 12.5517 17.3917 12.7147 17.0718C12.9 16.7081 12.9 16.2321 12.9 15.28V8.82C12.9 7.86791 12.9 7.39187 12.7147 7.02822C12.5517 6.70834 12.2917 6.44827 11.9718 6.28529C11.6081 6.1 11.1321 6.1 10.18 6.1H3.72C2.76791 6.1 2.29187 6.1 1.92822 6.28529C1.60834 6.44827 1.34827 6.70834 1.18529 7.02822C1 7.39187 1 7.86791 1 8.82V15.28C1 16.2321 1 16.7081 1.18529 17.0718C1.34827 17.3917 1.60834 17.6517 1.92822 17.8147C2.29187 18 2.76791 18 3.72 18Z"
      stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export default templates;
