import { DropResult } from 'react-beautiful-dnd';

export const onDragEnd = (
  result: DropResult,
  callback: (from: number, to: number) => any,
) => {
  const { destination, source } = result;
  if (!destination) {
    return;
  }
  if (destination.index === source.index) {
    return;
  }
  callback(source.index, destination.index);
};
