const monitor = (
  <>
    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.4 17H13.6M10 13.4444V17M5.32 13.4444H14.68C16.1921 13.4444 16.9482 13.4444 17.5258 13.1538C18.0338 12.8981 18.4469 12.4902 18.7057 11.9884C19 11.418 19 10.6713 19 9.17778V5.26667C19 3.77319 19 3.02646 18.7057 2.45603C18.4469 1.95426 18.0338 1.54631 17.5258 1.29065C16.9482 1 16.1921 1 14.68 1H5.32C3.80786 1 3.05179 1 2.47423 1.29065C1.96619 1.54631 1.55314 1.95426 1.29428 2.45603C1 3.02646 1 3.77319 1 5.26667V9.17778C1 10.6713 1 11.418 1.29428 11.9884C1.55314 12.4902 1.96619 12.8981 2.47423 13.1538C3.05179 13.4444 3.80786 13.4444 5.32 13.4444Z"
        stroke="#1A1A29" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </>
);

export default monitor
