import graphqlResource, { GraphqlResource } from 'modules/core/graphqlResource';
import { CreateTournamentTeamResponseDto } from '../../../teams/domain/dtos/CreateTournamentTeamResponse.dto';
import { UpsertTournamentTeamDto } from '../../../teams/domain/dtos/UpsertTournamentTeam.dto';
import { Team } from '../../../teams/domain/interfaces/Team';
import { mapTeamDTOToTeam } from '../../../tourneys/domain/data-mappers/championship-game-teams-mapper';
import { OrganizerDataMapper } from '../data-mappers/organizer.data-mapper';
import { AuthAvailableFeatureResponse } from '../interfaces/AvaibleFeatureDTO';
import { Feature } from '../interfaces/AvailableFeature';
import { Organizer } from '../interfaces/Organizer';
import { OrganizerDTO } from '../interfaces/OrganizerDTO';
import { UpsertOrganizerResponse } from '../interfaces/UpserOrganizerResponse';
import { UpsertOrganizerInput } from '../interfaces/UpsertOrganizerInput';

export class OrganizerRepository {
  constructor(
    private readonly graphqlResource: GraphqlResource,
  ) {}
  
  async fetchOrganizer(tournamentId: number): Promise<Organizer | null> {
    const query = `
      query getTournamentDetails($id: Int!) {
        tournament(id: $id) {
          id
          name
          sportType
          email
          phone
          description
          championships {
            id
            name
            startDate
            endDate
          }
          images {
            type
            externalUrl
            createdAt
          }
          address {
            id
            addressLine1
            addressLine2
            city
            country
            region
          }
          teams {
            id
            name
            emblem {
              id
              externalUrl
            }
            createdAt
            members {
              id
              position
              number
              user {
                id
                name
                surname
                middleName
                birthDate
                photo {
                  id
                  externalUrl
                }
              }
            }
          }
        }
      }
    `;
    
    const response = await this.graphqlResource.query<OrganizerDTO>(query, {id: tournamentId});
    const dataMapper = new OrganizerDataMapper();
    
    return dataMapper.decode(response.data.data.tournament);
  }
  
  async upsertOrganizer(input: UpsertOrganizerInput): Promise<Organizer> {
    const query = `
      mutation upsertTournament($input: UpsertTournamentInput!) {
        upsertTournament(input: $input) {
          id
          name
          sportType
          email
          phone
          description
          championships {
            id
            name
            startDate
            endDate
          }
          images {
            type
            externalUrl
          }
          address {
            id
            addressLine1
            addressLine2
            city
            country
            region
          }
          teams {
            id
            name
            emblem {
              id
              externalUrl
            }
            members {
              id
              position
              number
              user {
                id
                name
                surname
                middleName
                birthDate
                photo {
                  id
                  externalUrl
                }
              }
            }
          }
        }
      }
    `;
    
    const variables = {input};
    const response = await graphqlResource.query<UpsertOrganizerResponse>(query, variables);
    const {data: result} = response;
    
    if (result.errors) {
      throw new Error(result.errors[0].message);
    }
    
    const dataMapper = new OrganizerDataMapper();
    return dataMapper.decode(result.data.upsertTournament);
  }
  
  public async upsertTeam(values: UpsertTournamentTeamDto): Promise<Team> {
    const query = `
      mutation upsertTeam($input: UpsertTeamInput!) {
        upsertTeam(input: $input) {
          id
          name
          emblem {
            id
            externalUrl
          }
          createdAt
          members {
            id
            position
            number
            user {
              id
              name
              surname
              photo {
                id
                externalUrl
              }
            }
          }
        }
      }
    `;
    
    const variables = {
      input: values,
    };
    
    const response = await this.graphqlResource.query<CreateTournamentTeamResponseDto>(query, variables);
    const {upsertTeam: result} = response.data.data;
    
    return mapTeamDTOToTeam(result, null);
  }
  
  async fetchAvailableFeature(tournamentId: number): Promise<Feature> {
    const query = `
      query getMyFeatures($id: Int!) {
        features(tournamentId: $id) {
          feature
          isEnabled
        }
      }
    `;
    
    const variables = {id: tournamentId};
    const response = await graphqlResource.query<AuthAvailableFeatureResponse>(query, variables);
    const {data: result} = response;
    
    if (result.errors) {
      throw new Error(result.errors[0].message);
    }
    
    const mappedFeatures: Feature = {
      MEDIA_MANAGER: false,
      ACTIVE_CHAMPIONSHIPS: false,
      OBS_INTEGRATION: false,
      JOYSTICK: false,
    };
    
    result.data.features.forEach((loopFeature) => {
      const feature = loopFeature.feature
      mappedFeatures[feature] = loopFeature.isEnabled;
    });
    
    return mappedFeatures;
  }
  
}

const organizerRepository = new OrganizerRepository(graphqlResource);
export default organizerRepository;
