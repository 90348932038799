import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from 'modules/auth/ui/hooks/useAuth';
import { useAuthGuard } from 'modules/auth/ui/hooks/useAuthGuard';
import { Pages } from 'modules/navigation/domain/enums/pages';

type SecureRouteProps = {
  children: JSX.Element;
  tournamentRequired?: boolean;
};

const SecureRoute = (props: SecureRouteProps) => {
  useAuthGuard();

  const {
    tournamentRequired = true,
    children,
  } = props;

  const {
    isAuthed,
    isChecked,
    isChecking,
    userTournamentId,
    availableTournaments,
  } = useAuth();

  if (!isChecked || isChecking) {
    return null;
  }

  if (!isAuthed) {
    return (
      <Navigate to={Pages.SIGN_IN} />
    );
  }

  if (tournamentRequired && !availableTournaments.length) {
    return (
      <Navigate to={Pages.SETTINGS} />
    );
  }

  const isInvalidUserTournamentId = (
    !userTournamentId
    || availableTournaments.every((item) => item.id !== userTournamentId)
  );

  if (tournamentRequired && isInvalidUserTournamentId) {
    return (
      <Navigate to={Pages.SELECT_TOURNAMENT} />
    );
  }

  return children;
};

export default SecureRoute;


