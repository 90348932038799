import { Canvas, Object, StaticCanvas } from "fabric/fabric-impl";
import Interactive from "../../canvases/interactive";

export class SingleAlignment {
  canvas!: Canvas | StaticCanvas
  manager!: Interactive
  
  init(manager: Interactive) {
    this.canvas = manager.canvas
    this.manager = manager
  }
  
  centerVertical = (object: Object) => {
    const width = this.manager.resolution.value.width
    if (!object || !width || !object?.width || !object?.top) return
    object.set({ left: width / 2 - object.getScaledWidth() / 2 });
    // setPosition(prevState => ({ ...prevState, x: round(width / 2 - (object?.width ?? 0) / 2) }))
    this.canvas.renderAll()
    return {left: width / 2 - object.getScaledWidth() / 2, top: object.top};
  }
  
  centerHorizontal = (object: Object) => {
    const height = this.manager.resolution.value.height
    if (!object || !height || !object.height || !object?.left) return
    object.set({ top: height / 2 - object.getScaledHeight() / 2 });
    // setPosition(prevState => ({ ...prevState, y: round(height / 2 - (object?.height ?? 0) / 2) }))
    this.canvas.renderAll()
    return {left: object.left, top: height / 2 - object.getScaledHeight() / 2};
  }
}

const singleAlignment = new SingleAlignment()
export default singleAlignment
