const penTool = (
  <>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.5 6.96009L21.7724 17.242C22.1265 17.8225 22.0459 18.5685 21.576 19.0599L18.7294 22.0367C18.4464 22.3326 18.0547 22.5 17.6453 22.5H13.7048C13.3175 22.5 12.9452 22.3502 12.6658 22.0819L9.50497 19.0463C8.99738 18.5588 8.89694 17.784 9.26344 17.1833L15.5 6.96009Z"
        stroke="#242424"/>
      <path d="M15.5 7.5L15.5 16.5" stroke="#242424"/>
      <circle cx="15.5" cy="17.5" r="1.25" fill="#242424"/>
      <path d="M13 25H18" stroke="#242424" strokeLinecap="round"/>
    </svg>
  </>
)

export default penTool
