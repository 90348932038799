import React from 'react';

type EditIconProps = {
  width?: number;
  height?: number;
  color?: string;
};

const EditIcon = (props: EditIconProps) => {
  const {
    width,
    height,
    color = '#2D2D47',
  } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ?? 15}
      height={height ?? 15}
      viewBox="0 0 15 15"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.752208 11.5962L0.568525 13.6167C0.526121 14.0831 0.916895 14.4739 1.38335 14.4315L3.40386 14.2478L11.8891 5.76257L9.23748 3.11091L0.752208 11.5962ZM14.0105 3.64123C14.3034 3.34834 14.3034 2.87347 14.0105 2.58057L12.4195 0.989583C12.1266 0.696689 11.6517 0.69669 11.3588 0.989583L9.76781 2.58058L12.4195 5.23224L14.0105 3.64123Z"
        fill={color}
      />
    </svg>
  );
};

export default EditIcon;
