import UDFormInput from '../../../../ud-form/components/input';
import { Formik } from 'formik';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editTeam } from '../../../../organizer/store/actions';
import UDButton from '../../../../ud-ui/components/button';
import { useNavigate } from 'react-router-dom';
import { Pages } from '../../../../navigation/domain/enums/pages';
import { organizerIsTeamCreatingSelector } from '../../../../organizer/store/selectors';
import { AppDispatch } from '../../../../../store/store';
import UDFormDropZone from 'modules/ud-form/components/dropzone/index';
import { tourneysCommonStoreImagesSelector } from 'modules/tourneys/store/common/selectors';
import { Team } from '../../../domain/interfaces/Team';
import { AnnouncedTeam } from '../../../domain/interfaces/AnnouncedTeam';

type EditTeamFormProps = {
  tournamentId: number;
  team: Team | AnnouncedTeam;
};

const EditTeamForm = ({ tournamentId, team }: EditTeamFormProps) => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const isLoading = useSelector(organizerIsTeamCreatingSelector);

  const handleCancelClick = useCallback(() => {
    navigate(Pages.TEAMS.INDEX, { replace: true });
  }, [navigate]);

  const handleSubmit = useCallback(
    (values) => {
      if (
        team.name !== values.name ||
        (team.logo && team.logo.url !== values.logo[0].url) ||
        (!team.logo && values.logo)
      ) {
        const teamToEdit = {
          id: team.id,
          name: values.name,
          logo:
            (team.logo && team.logo.url !== values.logo[0].url) ||
            (!team.logo && values.logo)
              ? values.logo
              : undefined,
          tournamentId,
        };
        dispatch(editTeam(teamToEdit)).then(() => {
          handleCancelClick();
        });
      } else {
        handleCancelClick();
      }
    },
    [dispatch, handleCancelClick, team, tournamentId]
  );

  const images = useSelector(tourneysCommonStoreImagesSelector);
  const initialValues = {
    name: team.name,
    logo: team.logo ? [team.logo] : [],
    tournamentId,
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {(formProps) => (
        <form
          className="d-flex flex-column justify-content-between h-100"
          onSubmit={formProps.handleSubmit}
        >
          <input
            type="hidden"
            name="tournamentId"
            value={formProps.initialValues.tournamentId}
          />

          <div className="row align-items-start gx-4">
            <UDFormInput
              name="name"
              label="Название команды"
              placeholder="Введите название"
              containerProps={{ className: 'col-6' }}
            />

            <UDFormDropZone
              name="logo"
              label="Логотип команды"
              value={team.logo ? [team.logo] : images}
              containerProps={{ className: 'col-6' }}
              isCover={true}
              formProps={{ className: 'bgc-SurfaceWhite br-8 p-6' }}
            />
          </div>

          <div className="d-flex justify-content-end mb-2">
            <UDButton
              type="button"
              variant="secondary"
              disabled={isLoading}
              onClick={handleCancelClick}
              className="mr-4"
            >
              Отменить
            </UDButton>

            <UDButton
              type="submit"
              variant="primary"
              disabled={isLoading}
              loading={isLoading}
            >
              Сохранить
            </UDButton>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default EditTeamForm;
