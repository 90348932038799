import styled from '@emotion/styled';

const Icon = styled.svg``;
Icon.defaultProps = {
  version: '1.1',
  xmlns: 'http://www.w3.org/2000/svg',
  xmlnsXlink: 'http://www.w3.org/1999/xlink',
};
export { Icon };

export const SVG = styled(Icon)``;
