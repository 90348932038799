import React, { useCallback } from 'react';
import { Team } from '../../../domain/interfaces/Team';
import TeamsComponentsTeamCard from '../team-card';
import UDButton from '../../../../ud-ui/components/button';
import UDIcon from '../../../../ud-ui/components/icon';
import { useNavigate } from 'react-router-dom';
import { Pages } from '../../../../navigation/domain/enums/pages';

type TeamsListProps = {
  teams: Team[];
  teamsPage?: number;
  teamsPerPage?: number;
};

const TeamsList = ({ teams, teamsPage, teamsPerPage }: TeamsListProps) => {
  const navigate = useNavigate();
  const handleCreateClick = useCallback(() => {
    navigate(Pages.TEAMS.CREATE, { replace: true });
  }, [navigate]);

  let page: number,
    perPage: number = 0;

  if (teamsPage && teamsPerPage) {
    page = teamsPage;
    perPage = teamsPerPage;
  }

  if (!teams.length) {
    return (
      <div className="container pt-6">
        <div className="flex-column flex-center mt-24">
          <div className="text body bold mb-2">
            У вас еще нет ни одной команды
          </div>
          <div className="text body">Давайте добавим?</div>
          <div className="mt-6">
            <UDButton
              className="d-flex align-items-center"
              variant="dark"
              onClick={handleCreateClick}
            >
              <UDIcon name="plus" componentProps={{ className: 'mr-2' }} />
              <span>Добавить команду</span>
            </UDButton>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="pt-6">
      {teams.map((team, index) => (
        <TeamsComponentsTeamCard
          key={team.id}
          team={team}
          index={index + (page - 1) * perPage}
        />
      ))}
    </div>
  );
};

export default TeamsList;
