const basketball = (
  <>
    <path
      d="M12.4126 3.93734C12.3307 4.41723 12.288 4.91683 12.288 5.42928C12.288 5.69033 12.2998 5.94817 12.3215 6.20086C12.7699 6.17556 13.2347 6.16293 13.7131 6.16164C13.4431 5.32555 12.9947 4.56897 12.4126 3.93734Z"
      fill="currentColor"
    />
    <path
      d="M12.0464 3.57253C10.9786 2.59647 9.5585 2 8.00139 2C7.65418 2 7.31446 2.0313 6.98331 2.0881C8.53421 2.91584 9.7771 4.44336 10.5506 6.36955C10.9821 6.31146 11.4292 6.26452 11.893 6.23002C11.8709 5.9681 11.8593 5.70083 11.8593 5.42949C11.8593 4.78757 11.9239 4.16452 12.0464 3.57253Z"
      fill="currentColor"
    />
    <path
      d="M10.2751 6.84018C9.62913 6.93749 9.02151 7.06052 8.44989 7.20389C8.86076 8.11457 9.09481 9.1515 9.09481 10.2516C9.09481 11.6804 8.77588 12.9644 8.17449 13.9973C8.68246 13.9825 9.18612 13.9051 9.67438 13.7634C10.01 13.6661 10.3362 13.539 10.6498 13.3847C10.6819 13.3689 10.7134 13.3521 10.7454 13.3356L10.7949 13.0832C10.8967 12.444 11.0019 11.783 11.0019 11.109C11.0019 9.57461 10.743 8.12168 10.2751 6.84018Z"
      fill="currentColor"
    />
    <path
      d="M13.8334 6.59009C13.3287 6.58902 12.8402 6.60123 12.3695 6.62739C12.5577 8.0111 13.0623 9.18755 13.7374 9.76583C13.9095 9.20771 14.0025 8.61509 14.0025 8.00125C14.0025 7.51513 13.9429 7.04298 13.8334 6.59009Z"
      fill="currentColor"
    />
    <path
      d="M3.52218 4.14331C3.48725 4.14331 3.45274 4.14139 3.41779 4.14031C2.73428 4.95285 2.26319 5.9411 2.08273 7.01319C2.08209 7.01769 2.08123 7.02198 2.08036 7.02648C2.05572 7.1748 2.03772 7.32483 2.02422 7.47594C2.02208 7.50058 2.02035 7.52566 2.01864 7.55032C2.00729 7.69967 2 7.8497 2 8.00123C2 8.62173 2.09473 9.22056 2.27027 9.78402C3.49688 8.74881 5.29617 7.64008 7.83298 6.9253C6.91458 5.25246 5.32597 4.14331 3.52218 4.14331Z"
      fill="currentColor"
    />
    <path
      d="M6.31376 2.24329C6.30776 2.245 6.30176 2.2465 6.29597 2.24842C6.1513 2.2913 6.00941 2.33952 5.86988 2.39266C5.8493 2.40058 5.82895 2.40895 5.80837 2.41688C5.66947 2.47152 5.53252 2.52982 5.39942 2.59412C5.39578 2.59583 5.39234 2.59776 5.3887 2.5997C5.26181 2.66122 5.13836 2.72831 5.01705 2.79838C4.99197 2.81296 4.96667 2.82688 4.94161 2.84167C4.82309 2.91219 4.70735 2.98678 4.59441 3.0648C4.5719 3.08043 4.54982 3.09652 4.52753 3.11239C4.28535 3.28516 4.05667 3.4744 3.84383 3.67974C3.83675 3.6866 3.8297 3.69324 3.82241 3.70011C3.8147 3.70761 3.80804 3.71574 3.80033 3.72324C5.6815 3.83234 7.31941 5.04138 8.25731 6.81174C8.83943 6.66279 9.45842 6.53461 10.1162 6.43237C9.30108 4.4541 7.9686 2.93579 6.31376 2.24329Z"
      fill="currentColor"
    />
    <path
      d="M11.9386 6.65594C11.5138 6.68723 11.1029 6.72903 10.7062 6.78046C11.1728 8.08786 11.4306 9.55903 11.4306 11.1093C11.4306 11.7827 11.3327 12.4282 11.2332 13.0541C12.2901 12.3757 13.1206 11.3759 13.5864 10.193C12.7543 9.57209 12.1474 8.24539 11.9386 6.65594Z"
      fill="currentColor"
    />
  </>
);

export default basketball;
