/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useState } from 'react';
import { Button, disableButtonCSS, MainBlock, modalContainerCss } from "./styles";
import UDText from "../../../../../../ud-ui/components/text";
import PlanCard from "../../../../elements/planCard";
import { useModal } from "../../../../../../ud-ui/components/modal/useModal";
import { Plan } from "../../../../../domain/interfaces/plan";
import UDButton from "../../../../../../ud-ui/components/button";

function PlansBlock() {
  // TODO Сделать подгрузку планов и текущего активного плана
  const currentPlan = "Базовый";
  const plans: Plan[] = [
    {
      id: 0,
      name: 'Бесплатный',
      price: 0,
      isActive: false,
      date: {
        from: '20.07.24',
      }
    }, {
      id: 1,
      name: 'Базовый',
      price: 1500,
      isActive: true,
      date: {
        from: '20.07.24',
        to: '31.12.24',
      }
    }, {
      id: 2,
      name: 'Продвинутый',
      price: 2500,
      isActive: false,
      date: {
        from: '20.07.24',
        to: '31.12.24',
      }
    }, {
      id: 3,
      name: 'Профессиональный',
      price: 3500,
      isActive: false,
      date: {
        from: '20.07.24',
        to: '31.12.24',
      }
    }
  ]
  const [selectedPlan, setSelectedPlan] = useState<Plan>()
  const [tariff, setTariff] = useState<string>()
  const { open, close, Modal } = useModal({ defaultShow: false })
  
  const closeModal = useCallback(() => {
    setTariff(undefined)
    setSelectedPlan(undefined)
    close()
  }, [close])
  
  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        closeModal()
      }
    }
    
    document.addEventListener('keydown', onKeyDown)
    
    return () => {
      document.removeEventListener('keydown', onKeyDown)
    }
  }, [closeModal])
  
  return (
    <MainBlock>
      <div className={'mb-7'}>
        <UDText type={'subhead'}>Данный пользователь использует план: <span
          style={{ color: '#F5D956', fontWeight: "bold" }}>{currentPlan}</span></UDText>
      </div>
      <div className={'d-flex flex-column'} style={{ gap: 10 }}>
        {plans.map((plan, index) => {
          const planLength = plans.length
          
          const onClick = () => {
            open()
            setSelectedPlan(plan)
          }
          
          return (
            <PlanCard
              onClick={onClick}
              key={plan.id}
              name={plan.name}
              price={plan.price}
              isActive={plan.isActive}
              date={plan.date}
              widthPercent={100 - (planLength - (index + 1)) * 5}
            />)
        })}
      </div>
      <Modal
        onClose={closeModal}
        overlayProps={{
          id: 'overlay',
          onClick: (event) => {
            event.preventDefault();
            const target = event.target as HTMLElement
            if (target?.id === 'overlay') {
              closeModal()
            }
          }
        }}
        containerProps={{ css: modalContainerCss }}
        closeIconProps={{ style: { paddingInline: 22, paddingBlock: 22 }, className: 'close' }}
      >
        {selectedPlan?.isActive ? (
          <div className={'d-flex flex-column align-items-start'}>
            <UDText type={'title-3'} style={{ color: 'white', marginBottom: 30 }}>{selectedPlan.name} план</UDText>
            <UDText type={'subhead'} style={{ color: 'white', marginBottom: 26 }}>
              План подключен и будет действовать до <span style={{ fontWeight: 'bold' }}>{selectedPlan.date.to}</span>
            </UDText>
            <UDButton style={{ width: 130 }} onClick={closeModal} variant={'primary'}>Понятно</UDButton>
          </div>
        ) : (
          <div className={'d-flex flex-column align-items-start'}>
            <UDText type={'title-3'} style={{ color: 'white', marginBottom: 30 }}>{selectedPlan?.name} план</UDText>
            <UDText type={'subhead'} style={{ color: 'white', marginBottom: 16 }}>Подключить этот план:</UDText>
            <div style={{ marginBottom: 30, display: 'flex' }}>
              <Button
                onClick={() => setTariff('month')}
                className={'flex-center'}
                style={{
                  marginRight: 16,
                  border: tariff === 'month' ? '2px solid #F5D956' : '2px solid transparent',
                }}>
                На 1 месяц
              </Button>
              <Button
                onClick={() => setTariff('year')}
                className={'flex-center'}
                style={{
                  border: tariff === 'year' ? '2px solid #F5D956' : '2px solid transparent'
                }}>
                На 12 месяцев
              </Button>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
              <UDButton
                css={disableButtonCSS}
                disabled={!tariff}
                style={{ width: 186, height: 36 }}
                onClick={closeModal}
                variant={'primary'}>
                Подключить
              </UDButton>
              <UDText type={'caption-1'} style={{ color: '#A8A8A8' }}>один месяц за {selectedPlan?.price} руб.</UDText>
            </div>
          </div>
        )}
      </Modal>
    </MainBlock>
  );
}

export default PlansBlock;
