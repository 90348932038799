import React from 'react';
import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";

export interface IWrapperProps extends React.HTMLAttributes<HTMLDivElement> {
  duration: number
}

function TimerDisappearance(props: IWrapperProps) {
  const { duration, ...rest } = props

  return (
    <MainElem duration={duration} {...rest}>
      {props.children}
    </MainElem>
  );
}

export default TimerDisappearance;

const fluctuation = keyframes`
  0% {
    opacity: 0;
  }

  20%, 80% {
    opacity: 1;
  }

  99% {
    opacity: 0;
  }

  100% {
    opacity: 0;
    display: none;
  }
`

const MainElem = styled.div<{ duration: number }>`
  width: 100%;
  height: 100%;

  animation: ${fluctuation} ${props => props.duration}ms normal forwards;

  position: absolute;
`
