import styled from '@emotion/styled';

export const GameResult= styled.div`
  display: flex;
  flex-direction: column;

  .create-events {
    display: flex;
    margin-top: 20px;
    justify-content: space-between;

    > div {
      width: calc(50% - 25px);
    }
  }

  .game-result-events {
    margin-top: 20px;
  }
`;