import React from 'react';
import { pathsMap } from './map';
import * as S from './styles';

export type UDColorableIconProps = {
  name: keyof typeof pathsMap;
  size?: number;
  componentProps?: React.SVGProps<SVGSVGElement>;
};

/**
 * https://caniuse.com/currentcolor
 *
 * https://www.w3.org/TR/css-color-3/#currentcolor
 *
 * https://www.pinkdroids.com/blog/svg-react-styled-components/
 */
const UDColorableIcon = (props: UDColorableIconProps) => {
  return (
    <S.SVG
      width={props.componentProps?.width || props.size || 16}
      height={props.componentProps?.height || props.size || 16}
      viewBox="0 0 16 16"
      fill="none"
      {...props.componentProps}>
      {pathsMap[props.name]}
    </S.SVG>
  );
};

export default UDColorableIcon;
