import React, { SVGProps } from 'react';

type ReplacementIconProps = SVGProps<any> & {
  width: number;
  height: number;
  color?: string;
};

const ArrowRectangleIcon = (props: ReplacementIconProps) => {
  const {
    width,
    height,
    ...rest
  } = props;
  
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M15 5.66667L15 1.00001H10.3333" fill="currentColor"/>
      <path
        d="M15 5.66667L15 1.00001M15 1.00001H10.3333M15 1.00001L8 8M6.44444 1H4.73333C3.42654 1 2.77315 1 2.27402 1.25432C1.83498 1.47802 1.47802 1.83498 1.25432 2.27402C1 2.77315 1 3.42654 1 4.73333V11.2667C1 12.5735 1 13.2269 1.25432 13.726C1.47802 14.165 1.83498 14.522 2.27402 14.7457C2.77315 15 3.42654 15 4.73333 15H11.2667C12.5735 15 13.2269 15 13.726 14.7457C14.165 14.522 14.522 14.165 14.7457 13.726C15 13.2269 15 12.5735 15 11.2667V9.55556"
        stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  
  );
};

export default ArrowRectangleIcon;
