import React from 'react';
import { useTranslation } from 'react-i18next';
import { BreadcrumbsContainer } from './breadcrumbs.styles';

type BreadcrumbsProps = {
  activeTeamName?: string;
  className?: string;
  onGoToListClick: () => void;
};

export const Breadcrumbs = ({ activeTeamName, className, onGoToListClick }: BreadcrumbsProps) => {
  const { t } = useTranslation();

  return (
    <BreadcrumbsContainer className={className}>
      <span
        className="link"
        onClick={onGoToListClick}
      >
        {t('championship.symbolicTeams.allTeamsTitle')}
      </span>

      {activeTeamName
        ? (<span className="link">{activeTeamName}</span>)
        : (<span className="link new">{t('championship.symbolicTeams.newTeamNameTitle')}</span>)
      }
    </BreadcrumbsContainer>
  );
};
