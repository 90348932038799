import { RootState } from "../../../store/store";
import { createSelector } from "@reduxjs/toolkit";

export const organizersCommonStoreSelector = (state: RootState) => state.organizers;
export const selectOrganizersData = createSelector(organizersCommonStoreSelector, (state) => state.organizers);
export const selectOrganizersFilter = createSelector(organizersCommonStoreSelector, (state) => state.filter);
export const selectOrganizersFilterBySport = createSelector(organizersCommonStoreSelector, (state) => state.filterBySportType);
export const selectOrganizersSort = createSelector(organizersCommonStoreSelector, (state) => state.sortByDate);
export const selectOrganizersOptions = createSelector(organizersCommonStoreSelector, (state) => state);
export const selectOrganizerById = (id: number) => createSelector(organizersCommonStoreSelector, (state) => state.organizers.find(item => item.id === id));
