import { HTMLAttributes } from "react";
import styled from "@emotion/styled";
import Text from "./text";

interface IProps extends HTMLAttributes<HTMLDivElement> {
  text: string | number
  textSize?: 'small' | 'medium' | 'large'
  color?: string
}

function ValueBlock(props: IProps) {
  const {
    text,
    textSize = 'medium',
    color = '#FFF',
    ...rest
  } = props

  return (
    <MainBlock className={'flex-center'} {...rest}>
      <Text color={color} size={textSize}>
        {text}
      </Text>
    </MainBlock>
  );
}

export default ValueBlock;

const MainBlock = styled.div<{ width?: string, height?: string }>`
  background: #0a0a0b;

  width: 30px;
  height: 20px;
`
