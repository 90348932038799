import React from 'react';

type CircleArrowIconProps = {
  width: number;
  height: number;
  color?: string;
};

const CircleArrowIcon = (props: CircleArrowIconProps) => {
  const {
    width,
    height,
    color = '#9193AA',
  } = props;
  
  return (
    <svg width={width ?? 13} height={height ?? 12} viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 4.88889C12 4.88889 10.8392 3.37123 9.8962 2.46569C8.95318 1.56015 7.65002 1 6.21053 1C3.33283 1 1 3.23858 1 6C1 8.76142 3.33283 11 6.21053 11C8.586 11 10.5902 9.4746 11.2174 7.38889M12 4.88889V1.55556M12 4.88889H8.52632"
        stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default CircleArrowIcon;
