import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { GameDetailsTeamMember, GameEvent, GameEventType } from '../../../domain/interfaces/GameDetails';
import FootballPassAndGoalIcon from 'modules/ud-ui/components/icon/football-pass-and-goal-icon';
import FootballCornerIcon from 'modules/ud-ui/components/icon/football-corner-icon';
import FreeKickIcon from 'modules/ud-ui/components/icon/free-kick-icon';
import FootballPenaltyIcon from 'modules/ud-ui/components/icon/football-penalty-icon';
import ReplacementIcon from 'modules/ud-ui/components/icon/replacement-icon';
import TimeoutIcon from 'modules/ud-ui/components/icon/timeout-icon';
import FootballCardIcon from 'modules/ud-ui/components/icon/football-card-icon';
import CupIcon from 'modules/ud-ui/components/icon/cup-icon';
import OwnGoalIcon from 'modules/ud-ui/components/icon/own-goal-icon';
import FootballIcon from 'modules/ud-ui/components/icon/football-icon';
import EditIcon from 'modules/ud-ui/components/icon/edit-icon';
import MossedPenaltyIcon from 'modules/ud-ui/components/icon/missed-penalty-icon';
import * as styles from './game-result-events.list.styles';

const EventEventButtom = styled.span`
  cursor: pointer;
  margin-left: 8px;
`;

type GameResultEventIconProps = {
  type: GameEventType;
};

const GameResultEventIcon = (props: GameResultEventIconProps) => {
  const width = 20;
  const height = 20;

  switch (props.type) {
    case GameEventType.GOAL:
    case GameEventType.AFTER_MATCH_PENALTY_GOAL:
      return (
        <FootballIcon
          width={width}
          height={height}
        />
      );
    case GameEventType.PASS_AND_GOAL:
      return (
        <FootballPassAndGoalIcon
          width={30}
          height={28}
        />
      );
    case GameEventType.CORNER:
      return (
        <FootballCornerIcon
          width={24}
          height={28}
        />
      );
    case GameEventType.FOUL:
      return (
        <FreeKickIcon
          width={20}
          height={26}
        />
      );
    case GameEventType.PENALTY:
      return (
        <FootballPenaltyIcon
          width={31}
          height={24}
        />
      );
    case GameEventType.RED_CARD:
      return (
        <FootballCardIcon
          width={width}
          height={height}
          type='red'
        />
      );
    case GameEventType.YELLOW_CARD:
      return (
        <FootballCardIcon
          width={width}
          height={height}
          type='yellow'
        />
      );
    case GameEventType.REPLACEMENT:
      return (
        <ReplacementIcon
          width={width}
          height={height}
        />
      );
    case GameEventType.TIMEOUT:
      return (
        <TimeoutIcon
          width={20}
          height={25}
        />
      );
    case GameEventType.OWN_GOAL:
      return (
        <OwnGoalIcon
          width={40}
          height={14}
        />
      );
    case GameEventType.AFTER_MATCH_PENALTY_MISSED:
      return (
        <MossedPenaltyIcon
          width={16}
          height={16}
        />
      );
    default:
      return (
        <CupIcon
          width={width}
          height={height}
        />
      );
  }
}

type GameResultEventProps = {
  event: GameEvent;
  members: Record<number, GameDetailsTeamMember>
  isLeft: boolean;
  onEditClick?: (eventId: number) => void;
};

export const GameResultEvent = (props: GameResultEventProps) => {
  const { t } = useTranslation();

  const { event, members, isLeft, onEditClick } = props;
  const className = `game-event ${isLeft ? 'left' : 'right'}`;

  const handleEditClick = useCallback(() => {
    if (onEditClick) {
      onEditClick(event.id);
    }
  }, [event, onEditClick]);

  return (
    <div className={className}>
      <div></div>
      <styles.GameResultEventMinute minutes={event.minutes} extraMinutes={event.extraMinutes} isLeft={isLeft}>
        <div className="game-event-time">
          <div className="minutes">{event.minutes}’</div>
          {event.extraMinutes && (<div className="extra-minutes">+{event.extraMinutes}</div>)}
        </div>

        <div className='game-event-card'>
          <div className='game-event-icon'>
            <GameResultEventIcon type={event.type} />
          </div>
          <div className='game-event-body'>
            <div className='game-event-type'>
              {t(`games.eventTypes.${event.type}`)}

              <EventEventButtom onClick={handleEditClick}>
                <EditIcon width={14} height={14} />
              </EventEventButtom>
            </div>
            {event.playerIds.map((playerId) => (
              <div
                key={playerId}
                className='game-event-player'
              >
                <div className='game-event-player-info'>
                  {members[playerId].lastName} {members[playerId].firstName}
                </div>
                <div className='game-event-player-position'>
                  #{members[playerId].number}, {t(`player.position.${members[playerId].position}`)}
                </div>
              </div>
            ))}
          </div>
        </div>
      </styles.GameResultEventMinute>
      <div></div>
    </div>
  );
};
