import React, { useState, useCallback, useMemo } from 'react';
import UDButton from 'modules/ud-ui/components/button';
import UDIcon from 'modules/ud-ui/components/icon';
import UpsertPlayerForm from './upsert-player';
import { TournamentUser } from 'modules/teams/domain/interfaces/TournamentUser';
import PlayersComponentsPlayerCard from './player-card';
import TeamPlayersApplicationFilters, {
  TeamPlayersFilterValues,
} from './players-filters/players-filters';

type TeamsComponentsFormPlayersListPartProps = {
  tournamentId: number;
  teamId: number;
  players: TournamentUser[];
};

const TeamsComponentsFormPlayersListPart = (
  props: TeamsComponentsFormPlayersListPartProps
) => {
  const { tournamentId, teamId, players } = props;
  const [showForm, setShowForm] = useState(false);
  const [playerId, setPlayerId] = useState(-1);
  const [name, setName] = useState<string>('');
  const playersList = useMemo(() => {
    if (!teamId && !players.length) {
      return [];
    }

    return players.filter((player) => {
      const query = name.toLowerCase();
      const firstName = player.name.toLowerCase();
      const surname = player.surname.toLowerCase();
      const middleName = player.middleName
        ? player.middleName.toLowerCase()
        : '';

      return (
        firstName.includes(query) ||
        surname.includes(query) ||
        middleName.includes(query)
      );
    });
  }, [name, players, teamId]);

  const handleFilterChange = useCallback((values: TeamPlayersFilterValues) => {
    setName(values.name);
  }, []);

  if (showForm) {
    return (
      <div className="pt-6">
        <div className="d-flex justify-content-end mb-6">
          <UDButton
            className="d-flex align-items-center"
            variant="dark"
            onClick={() => setShowForm(true)}
          >
            <UDIcon name="plus" componentProps={{ className: 'mr-2' }} />
            <span>Добавить игрока</span>
          </UDButton>
        </div>
        <UpsertPlayerForm
          tournamentId={tournamentId}
          teamId={teamId}
          playerId={playerId}
          setShowForm={setShowForm}
          setPlayerId={setPlayerId}
        />
      </div>
    );
  } else if (players.length === 0) {
    return (
      <div className="container pt-6">
        <div className="flex-column flex-center mt-24">
          <div className="text body bold mb-2">В команде пока нет игроков</div>
          <div className="text body">Давайте добавим?</div>
          <div className="mt-6">
            <UDButton
              className="d-flex align-items-center"
              variant="dark"
              onClick={() => setShowForm(true)}
            >
              <UDIcon name="plus" componentProps={{ className: 'mr-2' }} />
              <span>Добавить игрока</span>
            </UDButton>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="pt-6">
      <div className="d-flex justify-content-between mb-6">
        <TeamPlayersApplicationFilters
          name={name}
          inputPlaceholder="Поиск по игроку"
          onChange={handleFilterChange}
          className="w-50"
        />
        <UDButton
          className="d-flex align-items-center"
          variant="dark"
          onClick={() => setShowForm(true)}
        >
          <UDIcon name="plus" componentProps={{ className: 'mr-2' }} />
          <span>Добавить игрока</span>
        </UDButton>
      </div>

      {playersList.map((player, index) => (
        <PlayersComponentsPlayerCard
          key={player.id}
          teamId={teamId}
          player={player}
          index={index}
          setPlayerId={setPlayerId}
          setShowForm={setShowForm}
        />
      ))}
    </div>
  );
};

export default TeamsComponentsFormPlayersListPart;
