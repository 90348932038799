import React from 'react';
import UDPagination from '../../../../ud-ui/components/pagination';
import { Team } from 'modules/teams/domain/interfaces/Team';
import TeamsList from 'modules/teams/ui/components/teams-list/teams-list';

type TeamsBodyProps = {
  filters: any;
  teams: Team[];
  pages: number;
  teamsPerPage: number;
  onPageChange: (page: number) => any;
};

export const TeamsBody = (props: TeamsBodyProps) => {
  const { filters, teams, pages, onPageChange, teamsPerPage } = props;

  return (
    <>
      <TeamsList
        teams={teams}
        teamsPage={filters.page}
        teamsPerPage={teamsPerPage}
      />
      <UDPagination
        className="justify-content-end px-6 my-8"
        pages={pages}
        currentPage={filters.page}
        onPageChange={onPageChange}
      />
    </>
  );
};
