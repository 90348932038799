import React, { ReactElement, useCallback } from 'react';
import { FieldProps, useFormikContext } from 'formik';
import UDSelect, { UDSelectProps } from './component';
import { UDFormComponentProps } from '../types';
import UDFormFieldWrapper from '../../wrapper';
import { useOrganizer } from "../../../organizer/ui/hooks/useOrganizer";
import UDInput from "../input/component";
import styled from "@emotion/styled";

type UDFormSelectProps<isMulti extends boolean> = UDFormComponentProps & UDSelectProps<isMulti> & {
  selectComponent?: (props: any) => ReactElement<any, any> | null;
};

type Returning = <isMulti extends boolean = false>(
  props: UDFormSelectProps<isMulti>,
) => JSX.Element;

const UdFormTemplateSelect: Returning = (props) => {
  const {
    name,
    containerProps,
    required,
    label,
    labelProps,
    selectComponent,
    ...otherSelectProps
  } = props;
  
  const isDev = process.env.REACT_APP_ENVIRONMENT === 'development';
  const { info } = useOrganizer();
  
  const { setFieldValue } = useFormikContext();
  const onChange = useCallback(
    (value, isMulti) => {
      setFieldValue(name, value);
      
      if (props.onChange) {
        props.onChange(value, isMulti);
      }
    },
    [name, props, setFieldValue],
  );
  
  const Component = selectComponent || UDSelect;
  
  return (
    <UDFormFieldWrapper
      name={name}
      containerProps={containerProps}
      label={label}
      labelProps={labelProps}
      required={required}>
      {({ field }: FieldProps<any>) => {
        const isAvailable = info?.availableFeatures.OBS_INTEGRATION || isDev;
        if (!isAvailable) {
          const text = <Link title="This is my tooltip" href={''} className={'mr-3'}>Оформить подписку</Link>
          const tooltip = "Для бесплатной версии доступен только один шаблон.";
          return <UDInput
            value={"ImGame"}
            style={{userSelect: "none", pointerEvents: 'none'}}
            iconProps={{ name: 'info', position: "icon-right", component: text, tooltip }}
          />
        }
        return (<Component {...otherSelectProps} {...field} onChange={onChange}/>)
      }}
    </UDFormFieldWrapper>
  );
};

export default UdFormTemplateSelect;

const Link = styled.a`
    font-family: SF Pro Display, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.71px;
    text-align: left;
    color: #007AFF;

    &:visited {
        color: #007AFF;
    }
`
