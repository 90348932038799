import React from 'react';

type NotepadPenIconProps = {
  width?: number;
  height?: number;
  color?: string
} & React.SVGProps<SVGSVGElement>;

const UploadIcon = (props: NotepadPenIconProps) => {
  const {
    width,
    height,
    color = '#242424',
    ...rest
  } = props;
  
  return (
    <svg width={width ?? 32} height={height ?? 32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M11.0002 23.3636L11.0002 16.9996M11.0002 16.9996L13.7844 19.7839M11.0002 16.9996L8.21592 19.7839"
            stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
      <path
        d="M18.5 23H23.5C24.6046 23 25.5 22.1046 25.5 21V16V13.5C25.5 12.3954 24.6046 11.5 23.5 11.5H18.313C18.0411 11.5 17.781 11.3893 17.5925 11.1935L15.5901 9.11305C15.213 8.72134 14.6928 8.5 14.1491 8.5H10C8.89543 8.5 8 9.39543 8 10.5V12.5"
        stroke={color} strokeLinecap="round"/>
      <circle cx="11" cy="20" r="5.5" stroke={color}/>
      <path
        d="M10.707 6V6C10.8696 5.16727 11.6694 4.61834 12.5049 4.76602L23.9382 6.78693C25.0077 6.97596 25.7309 7.98394 25.5673 9.0576L25.4999 9.5"
        stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  
  );
};

export default UploadIcon;
