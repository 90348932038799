import arrowRight from './icons/arrow-right';
import basketball from './icons/basketball-ball';
import calendar from './icons/calendar';
import chain from './icons/chain';
import chevronDown from './icons/chevron-down';
import close from './icons/close';
import cup from './icons/cup';
import cyclicArrow from './icons/cyclic-arrow';
import done from './icons/done';
import down from './icons/down';
import drag from './icons/drag';
import eyeClosed from './icons/eye-closed';
import eyeOpened from './icons/eye-opened';
import floppy from './icons/floppy';
import gear from './icons/gear';
import geoPin from './icons/geo_pin';
import leftLarge from './icons/left-large';
import logout from './icons/logout';
import monitor from './icons/monitor';
import navigation from './icons/navigation';
import notification from './icons/notification';
import plus from './icons/plus';
import rightLarge from './icons/right-large';
import roundMinus from './icons/round-minus';
import roundPlus from './icons/round-plus';
import search from './icons/search';
import soccer from './icons/soccer-ball';
import support from './icons/support';
import up from './icons/up';
import voleyball from './icons/voleyball-ball';
import layers from './icons/layers';
import penTool from './icons/penTool';
import redo from './icons/redo';
import square from './icons/square';
import text from './icons/text';
import undo from './icons/undo';
import upload from './icons/upload';
import alignLeft from "./icons/alignLeft";
import alignRight from "./icons/alignRight";
import alignTop from "./icons/alignTop";
import alignBottom from "./icons/alignBottom";
import alignCenterHorizontal from "./icons/alignCenterHorizontal";
import alignCenterVertical from "./icons/alignCenterVertical";
import corner from "./icons/corner";
import rectanglePlus from "./icons/rectanglePlus";
import trashCan from "./icons/trashCan";
import rectangle from "./icons/rectangle";
import circle from "./icons/circle";
import info from "./icons/info";
import templates from "./icons/templates";
import organizers from "./icons/organizers";

export const iconsMap = {
  'eye-closed': require('./icons/eye-closed.svg').default,
  'eye-opened': require('./icons/eye-opened.svg').default,
  close: require('./icons/close.svg').default,
  plus: require('./icons/plus.svg').default,
  gear: require('./icons/gear.svg').default,
  notification: require('./icons/notification.svg').default,
  support: require('./icons/support.svg').default,
  cup: require('./icons/cup.svg').default,
  calendar: require('./icons/calendar.svg').default,
  logout: require('./icons/logout.svg').default,
  'league-logo': require('./icons/league-logo.svg').default,
  'soccer-field': require('./icons/league-logo.svg').default,
  'down-indicator': require('./icons/down-indicator.svg').default,
  up: require('./icons/up.svg').default,
  down: require('./icons/down.svg').default,
  'left-large': require('./icons/left-large.svg').default,
  'right-large': require('./icons/right-large.svg').default,
  drag: require('./icons/drag.svg').default,
  tournament_1: require('./icons/tournaments_logo/tournament_1.png'),
  tournament_2: require('./icons/tournaments_logo/tournament_2.png'),
  tournament_3: require('./icons/tournaments_logo/tournament_3.png'),
  tournament_4: require('./icons/tournaments_logo/tournament_4.png'),
  floppy: require('./icons/floppy.svg').default,
  chain: require('./icons/chain.svg').default,
  monitor: require('./icons/monitor.svg').default,
  'cyclic-arrow': require('./icons/cyclic-arrow.svg').default,
  'round-plus': require('./icons/round-plus.svg').default,
  'round-minus': require('./icons/round-minus.svg').default,
  'layers': require('./icons/layers.svg').default,
  'penTool': require('./icons/penTool.svg').default,
  'redo': require('./icons/redo.svg').default,
  'square': require('./icons/square.svg').default,
  'text': require('./icons/text.svg').default,
  'undo': require('./icons/undo.svg').default,
  'upload': require('./icons/upload.svg').default,
  'align-left': require('./icons/alignLeft.svg').default,
  'align-right': require('./icons/alignRight.svg').default,
  'align-top': require('./icons/alignTop.svg').default,
  'align-bottom': require('./icons/alignBottom.svg').default,
  'align-center-horizontal': require('./icons/alignCenterHorizontal.svg').default,
  'align-center-vertical': require('./icons/alignCenterVertical.svg').default,
  'corner': require('./icons/corner.svg').default,
  'rectangle-plus': require('./icons/rectanglePlus.svg').default,
  'trash-can': require('./icons/trashCan.svg').default,
  'rectangle': require('./icons/rectangle.svg').default,
  'circle': require('./icons/circle.svg').default,
  'info': require('./icons/info.svg').default,
};

export const pathsMap = {
  cup: cup,
  'baskeball-ball': basketball,
  calendar: calendar,
  close: close,
  'eye-closed': eyeClosed,
  'eye-opened': eyeOpened,
  gear: gear,
  logout: logout,
  notification: notification,
  plus: plus,
  'soccer-ball': soccer,
  support: support,
  'voleyball-ball': voleyball,
  'geo-pin': geoPin,
  search: search,
  'chevron-down': chevronDown,
  done: done,
  up: up,
  down: down,
  'left-large': leftLarge,
  'right-large': rightLarge,
  drag: drag,
  'arrow-right': arrowRight,
  navigation: navigation,
  floppy: floppy,
  chain: chain,
  monitor: monitor,
  cyclicArrow: cyclicArrow,
  roundPlus: roundPlus,
  roundMinus: roundMinus,
  layers: layers,
  penTool: penTool,
  redo: redo,
  square: square,
  text: text,
  undo: undo,
  upload: upload,
  'align-left': alignLeft,
  'align-right': alignRight,
  'align-top': alignTop,
  'align-bottom': alignBottom,
  'align-center-horizontal': alignCenterHorizontal,
  'align-center-vertical': alignCenterVertical,
  corner: corner,
  'rectangle-plus': rectanglePlus,
  'trash-can': trashCan,
  'rectangle': rectangle,
  'circle': circle,
  info: info,
  templates: templates,
  organizers: organizers
};
