import React from 'react';

interface IProps {
  width?: number;
  height?: number;
  color?: string;
}

export default function AlignTextJustifyIcon(props: IProps) {
  const {
    width,
    height,
    color = "#242424",
    ...rest
  } = props;
  
  return (
    <svg width={width ?? 18} height={height ?? 18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M2.39307 3.375C2.39307 2.96079 2.72885 2.625 3.14307 2.625H15.1431C15.5573 2.625 15.8931 2.96079 15.8931 3.375C15.8931 3.78921 15.5573 4.125 15.1431 4.125H3.14307C2.72885 4.125 2.39307 3.78921 2.39307 3.375ZM2.39307 14.625C2.39307 14.2108 2.72885 13.875 3.14307 13.875H15.1431C15.5573 13.875 15.8931 14.2108 15.8931 14.625C15.8931 15.0392 15.5573 15.375 15.1431 15.375H3.14307C2.72885 15.375 2.39307 15.0392 2.39307 14.625ZM2.39307 10.875C2.39307 10.4608 2.72885 10.125 3.14307 10.125H15.1431C15.5573 10.125 15.8931 10.4608 15.8931 10.875C15.8931 11.2892 15.5573 11.625 15.1431 11.625H3.14307C2.72885 11.625 2.39307 11.2892 2.39307 10.875ZM2.39307 7.125C2.39307 6.71079 2.72885 6.375 3.14307 6.375H15.1431C15.5573 6.375 15.8931 6.71079 15.8931 7.125C15.8931 7.53921 15.5573 7.875 15.1431 7.875H3.14307C2.72885 7.875 2.39307 7.53921 2.39307 7.125Z"
        fill={color}/>
    </svg>
  );
}
