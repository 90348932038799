import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { selectActiveCategory, selectActiveTemplate, selectPreviewState } from "../../../../store/selectors";
import { setActiveObjects, setFabricManager, setPreview } from "../../../../store/templateEditor";
import useFabric from "../../../../../ud-ui/components/fabric-canvas/hooks/useFabric";
import zoom from "../../../../../ud-ui/components/fabric-canvas/addons/zoom";
import SnapToGrid from "../../../../../ud-ui/components/fabric-canvas/addons/snapToGrid";
import history from "../../../../../ud-ui/components/fabric-canvas/addons/history";
import backgroundElems from "../../../../../ud-ui/components/fabric-canvas/addons/backgroundElems";
import lineDrawer from "../../../../../ud-ui/components/fabric-canvas/addons/lineDrawer";
import layerManager from "../../../../../ud-ui/components/fabric-canvas/addons/layerManager";
import useLocalStorage from "../../../hooks/useLocalStorage";
import alignment from "../../../../../ud-ui/components/fabric-canvas/addons/alignment/alignment";

function Canvas() {
  const dispatch = useDispatch()
  const category = useSelector(selectActiveCategory)
  const activeTemplate = useSelector(selectActiveTemplate)
  const { getDataFromLocalStorage } = useLocalStorage()
  const {
    template: templateBeforePreview,
    history: historyOfChanges,
    curHistoryIndex,
    resolution,
  } = useSelector(selectPreviewState)
  const loadedResolution = useMemo(() => {
    const supabase = category?.resolution
    if (supabase) return supabase
    
    const { resolution: LocalStorage } = getDataFromLocalStorage()
    if (!resolution) return LocalStorage
    
    return resolution
  }, [category?.resolution, getDataFromLocalStorage, resolution]);
  const { manager, canvas } = useFabric('interactive', { data: { ...loadedResolution, backgroundColor: '#FFFFFF' } })
  
  const resizeCanvas = useCallback(() => {
    if (!manager) return
    manager.setDimensions({ width: window.innerWidth - 424, height: window.innerHeight - 135 })
  }, [manager]);
  
  useEffect(() => {
    if (!manager) return
    dispatch(setFabricManager(manager))
    
    const templateLocalStorageRaw = localStorage.getItem('template');
    const templateLocalStorage = templateLocalStorageRaw && templateLocalStorageRaw !== 'undefined' ? JSON.parse(templateLocalStorageRaw) : undefined;
    const loadedTemplate = templateBeforePreview ?? category?.templates[ activeTemplate ?? '' ] ?? templateLocalStorage ?? '';
    
    manager.loadLocal({ template: loadedTemplate, safeBackground: true, })
      .then(() => {
          backgroundElems.drawBorder(manager.canvas, manager.resolution.value)
          backgroundElems.drawGrid(manager.canvas, {
            resolution: manager.resolution.value,
            gridStep: SnapToGrid.gridStep
          })
          if (historyOfChanges && curHistoryIndex) {
            history.setHistory({ history: historyOfChanges, index: curHistoryIndex })
          } else {
            history.init()
          }
          dispatch(setPreview({}))
          history.activeHistory()
        }
      )
    
    history.loadManager(manager)
    zoom.init(manager)
    zoom.enableDragging()
    zoom.enableZoom()
    SnapToGrid.enableSnapToGrid(manager?.canvas)
    layerManager.init(manager)
    lineDrawer.init(manager.canvas)
    alignment.init(manager)
    
    manager.canvas.on('selection:created', (_) => {
      const activeObjects = manager.canvas.getActiveObjects()
      dispatch(setActiveObjects(activeObjects))
    })
    
    manager.canvas.on('selection:updated', (_) => {
      const activeObjects = manager.canvas.getActiveObjects()
      dispatch(setActiveObjects(activeObjects))
    })
    
    manager.canvas.on('selection:cleared', (_) => {
      dispatch(setActiveObjects([]))
    })
    
    window.addEventListener('resize', resizeCanvas, false);
    
    resizeCanvas();
    manager.normalizedViewport()
    
    return () => {
      window.removeEventListener('resize', resizeCanvas, false);
      history.clearHistory()
    };
    
    // eslint-disable-next-line
  }, [activeTemplate, dispatch, manager])
  
  return canvas;
}

export default Canvas;
