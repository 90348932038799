import React from 'react';
import { GameDetails } from "../../../domain/interfaces/GameDetails";
import TeamLogo from '../../../../ud-ui/components/team-logo';
import * as styles from './game-result-header.styles';
import EditIcon from 'modules/ud-ui/components/icon/edit-icon';

type GameResultHeaderProps = {
  isLoading: boolean;
  game: GameDetails | null;
  onEditScoreClick: () => void;
};

export const GameResultHeader = (props: GameResultHeaderProps) => {
  const {
    isLoading,
    game,
    onEditScoreClick,
  } = props;

  if (isLoading || !game) {
    return (
      <styles.GameResultHeader className="is-loading"/>
    );
  }

  return (
    <styles.GameResultHeader >
      <>
        <styles.GameTeamInfo className='right-logo'>
          <div className='team-name'>{game.teams[0].name}</div>
          <TeamLogo
            url={game.teams[0].logo}
            size={60}
          />
        </styles.GameTeamInfo>
        <div className='game-score'>
          <styles.EditScoreWrapper>
            <styles.EditScoreButton onClick={onEditScoreClick}>
              <EditIcon width={24} height={24} />
            </styles.EditScoreButton>
          </styles.EditScoreWrapper>
          <styles.ScoreWrapper>
            {game.result.firstTeamScore} : {game.result.secondTeamScore}
          </styles.ScoreWrapper>
        </div>
        <styles.GameTeamInfo>
          <TeamLogo
            url={game.teams[1].logo}
            size={60}
          />
          <div className='team-name'>{game.teams[1].name}</div>
        </styles.GameTeamInfo>
      </>
    </styles.GameResultHeader>
  );
};
