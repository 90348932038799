import supabase from "../../../core/supabase/supabase";
import getAvailableFeature from "../repositories/availableFeature";
import { GameInfoMapped } from "../interfaces/gameInfoMapped";
import { TemplatesRow } from "../../../core/domain/interfaces/receivedData";
import { getMappedGameInfo } from "./getMappedGameInfo";
import { getScore } from "./getScore";
import { updateMembersStats } from "./updateMembersStats";
import { getFouls } from "./getFouls";
import { getTime } from "./getTime";
import _ from "lodash";
import { templates } from "../interfaces/templates";

export async function getData(matchId: number): Promise<{
  score: [number, number];
  fouls: [number, number];
  gameInfo: GameInfoMapped;
  featureIsActive: boolean;
  templates: templates;
  category: TemplatesRow | undefined;
  resolution: { width: number; height: number }
  time: { seconds: number, currentPeriod: number }
} | undefined> {
  
  const gameInfoMapped = await getMappedGameInfo(matchId)
  if (!gameInfoMapped) return undefined
  if (!gameInfoMapped.organizer) return undefined
  if (!gameInfoMapped.championship) return undefined
  if (!gameInfoMapped.teams) return undefined
  
  const features = await getAvailableFeature(gameInfoMapped.organizer.id)
  await updateMembersStats(gameInfoMapped)
  
  const categoryId = !gameInfoMapped.championship.overlayId ? null : +gameInfoMapped.championship.overlayId
  const row = await (categoryId === null
    ? supabase.overlayTemplates.getDefaultRow()
    : supabase.overlayTemplates.getRow({ rowId: categoryId }))
  
  const templates = _(row?.templates).toPairs().value().map(value => ({
    ...value[ 1 ],
    id: +value[ 1 ].id,
    name: value[ 0 ]
  }))
  
  return {
    gameInfo: gameInfoMapped,
    score: await getScore(matchId),
    fouls: await getFouls(matchId),
    time: await getTime(matchId),
    featureIsActive: features.OBS_INTEGRATION,
    resolution: row?.resolution ?? { width: 1920, height: 1080 },
    templates: templates,
    category: row,
  }
}
