import React from 'react';
import classNames from 'classnames';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { UDTextType } from '../text';

type Props = {
  type?: UDTextType;
  disabled?: boolean;
} & NavLinkProps;

const UDNavLink = (props: Props) => {
  const { type = 'callout', disabled, className, ...otherProps } = props;
  return (
    <NavLink
      {...otherProps}
      className={({ isActive }) =>
        classNames('link', 'text', type, className, {
          disabled,
          active: isActive,
        })
      }
    />
  );
};

export default UDNavLink;
