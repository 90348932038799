import React from 'react';
import { MainBlock } from "./styles";
import SearchInput from "../../elements/searchInput";
import Select from "../../elements/select";
import { useDispatch, useSelector } from "react-redux";
import { setFilter, setFilterByNumber, setFilterBySportType, setSortByDate } from "../../../store";
import { onlyNumber } from "../../../domain/utils/regx/onlyNumber";
import { selectOrganizersOptions } from "../../../store/selectors";
import _ from "lodash";
import { SportType } from "../../../../core/domain/enums/SportType";
import { useTranslation } from "react-i18next";


function Header() {
  const dispatch = useDispatch()
  const { t } = useTranslation();
  const { filterByNumber, filter, sortByDate, filterBySportType } = useSelector(selectOrganizersOptions)
  
  const filterByPhone = {
    headerText: 'Номер телефона:',
    value: filterByNumber,
    placeholder: '79139332245',
    parser: onlyNumber,
    onChange: (value: string) => {
      dispatch(setFilterByNumber(value))
    }
  }
  
  const searchInput = {
    headerText: 'Поиск по тексту:',
    value: filter,
    placeholder: 'Название, ФИО или город',
    onChange: (value: string) => {
      dispatch(setFilter(value))
    }
  }
  
  const sortByDateOptions = [
    { value: 'none', label: 'Все' },
    { value: 'desc', label: 'Новые' },
    { value: 'asc', label: 'Старые' },
  ]
  const sortByData = {
    headerText: 'Сортировать по дате:',
    options: sortByDateOptions,
    value: sortByDateOptions.find(({ value }) => value === sortByDate) ?? sortByDateOptions[ 0 ],
    onChange: (value: { label: string | number | JSX.Element, value: string } | undefined) => {
      const val = value?.value
      dispatch(setSortByDate(val))
    }
  }
  
  const sportTypes = _.keys(SportType).map((key) => ({ value: key, label: _.capitalize(t('sportType.' + key)) }))
  sportTypes.unshift({ value: 'all', label: 'Все' })
  const filterBySport = {
    headerText: 'Тип спорта:',
    options: sportTypes,
    value: sportTypes.find(({ value }) => value === filterBySportType) ?? sportTypes[ 0 ],
    onChange: (value: { label: string | number | JSX.Element, value: string } | undefined) => {
      const val = value?.value
      dispatch(setFilterBySportType(val as SportType))
    }
  }
  
  return (
    <MainBlock>
      <SearchInput style={{ width: 451 }} {...searchInput} />
      <SearchInput style={{ width: 250 }} {...filterByPhone} />
      <Select style={{ width: 151 }} {...sortByData}/>
      <Select style={{ flexGrow: 1 }} {...filterBySport}/>
    </MainBlock>
  );
}

export default Header;
