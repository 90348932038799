import { Organizer } from "modules/organizer/domain/interfaces/Organizer";
import { useFetchOrganizer } from "../hooks/useFetchOrganizer";
import { useOrganizer } from "../hooks/useOrganizer";

type OrganizerContainerProps = {
  children: (isLoading: boolean, info: Organizer | null) => JSX.Element;
};

const OrganizerContainer = (props: OrganizerContainerProps) => {
  useFetchOrganizer();
  const { isLoading, info } = useOrganizer();

  return props.children(isLoading, info);
};

export default OrganizerContainer;