const upload = (
  <>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.6875 14.125H17.875C19.7735 14.125 21.3125 15.664 21.3125 17.5625V17.5625C21.3125 19.461 19.7735 21 17.875 21H16M10.6875 14.125L13.8125 11M10.6875 14.125L13.8125 17.25"
        stroke="#242424" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  </>
)

export default upload
