import { organizerInfoSelector, organizerIsLoadedSelector, organizerIsLoadingSelector } from "modules/organizer/store/selectors"
import { useSelector } from "react-redux"

export const useOrganizer = () => {
  const isLoading = useSelector(organizerIsLoadingSelector);
  const isLoaded = useSelector(organizerIsLoadedSelector);
  const info = useSelector(organizerInfoSelector);

  return {
    isLoading,
    isLoaded,
    info,
  };
}