import React from 'react';
import styled from "@emotion/styled";
import { history } from "../../../../../store/store";
import ArrowLeftIcon from "../../../../ud-ui/components/icon/arrow-left-icon";
import { Pages } from "../../../../navigation/domain/enums/pages";

function Header() {
  const onClickBack = () => {
    history.push(Pages.TEMPLATE.CHOOSING)
  }
  
  return (
    <MainBlock>
      <Button onClick={onClickBack}><ArrowLeftIcon/> <span>Назад к списку</span></Button>
    </MainBlock>
  );
}

export default Header;

const MainBlock = styled.div`
    display: flex;
    flex-direction: row;
    height: 48px;
    background: #FDFEFF;
    width: 100%;
    border-bottom: 1px solid #E2E3E4;
    padding-right: 15px;
`

const Button = styled.button`
    border: none;
    background: none;
    cursor: pointer;
    width: 200px;
    padding-right: 20px;
    padding-left: 15px;
    

    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;

    font-family: Inter, sans-serif;
    color: black;
    font-size: 11px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    gap: 10px;
`
