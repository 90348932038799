import React from 'react';
import UDText from '../text';

type Props = {
  children: string;
};

const UDHeaderTitle = (props: Props) => {
  return (
    <UDText type="headline" className="color-SurfaceWhite">
      {props.children}
    </UDText>
  );
};

export default UDHeaderTitle;
