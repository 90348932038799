import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { durations, time } from '../../domain/constants/time';
import {
  setActiveOverlay,
  setCategory,
  setEvents,
  setFouls,
  setGameInfo,
  setPeriod,
  setScore,
  setTimer
} from '../../store/overlay';
import { selectOverlayState } from '../../store/selectors';
import Joystick from '../components/joystick/joystick';
import RenderEvent from '../components/overlays/renderEvent';
import TimerDisappearance from '../components/wrappers/timerDisappearance';
import useTimer from "../hooks/useTimer";
import { millisToMinutesAndSeconds } from "../../domain/utils/convertTime";
import { fabric } from "fabric";
import supabase from "../../../core/supabase/supabase";
import useFabric from "../../../ud-ui/components/fabric-canvas/hooks/useFabric";
import useReplacer from "../../../ud-ui/components/fabric-canvas/hooks/useReplacer";
import { getData } from "../../domain/utils/getData";
import { useQueryParam } from "use-query-params";
import { TokenStorage } from "../../../core/tokenStorage";
import { templates } from "../../domain/interfaces/templates";

function Overlay() {
  const encodeToken = useQueryParam('token')[ 0 ] as string | undefined
  const { matchId: id } = useParams()
  const matchId = id ? parseInt(id) : 0;
  
  useEffect(() => {
    if (!encodeToken) return
    const token = window.atob(encodeToken)
    const tokenStorage = new TokenStorage()
    tokenStorage.saveToken(token)
  }, [encodeToken]);
  
  const dispatch = useDispatch()
  const { gameInfo, activeOverlay, events } = useSelector(selectOverlayState)
  const [featureIsAvailable, setAvailable] = React.useState<undefined | boolean>(undefined)
  const [templates, setTemplates] = React.useState<templates>([])
  const [resolutions, setResolutions] = React.useState<{ width: number, height: number }>({ width: 1920, height: 1080 })
  const { canvas, manager } = useFabric('static')
  const replacer = useReplacer(manager)
  const { timer } = useTimer()
  
  useEffect(() => {
    if (!manager) return
    if (!resolutions) return
    manager?.setResolution(resolutions)
    manager?.setDimensions(resolutions)
    manager.normalizedViewport()
  }, [manager, resolutions]);
  
  useEffect(() => {
    if (!gameInfo) return;
    if (!manager) return
    const curActiveOverlay = activeOverlay
    
    const allObject = manager.canvas.getObjects()
    const promiseAll: Promise<void>[] = []
    
    // TODO Вынести в addons
    if (allObject.length > 0) {
      for (const obj of allObject) {
        promiseAll.push(new Promise<void>((resolve) => {
          obj.animate(
            'opacity',
            0,
            {
              duration: time.second / 2,
              onChange: manager.canvas.renderAll.bind(manager.canvas),
              onComplete: resolve
            })
        }))
      }
    }
    
    const smoothAppearance = (object: fabric.Object) => {
      object.animate('opacity', 1,
        {
          from: 0,
          duration: time.second / 2,
          onChange: manager.canvas.renderAll.bind(manager.canvas),
        })
    }
    
    Promise.all(promiseAll).then(() => {
      if (curActiveOverlay !== activeOverlay) return
      if (!activeOverlay) {
        manager.clear()
        return
      }
      
      if (!templates.length) return
      const template = templates.find(template => template.id === activeOverlay)
      if (!template) return;
      
      const onLoad = () => {
        const loadedObject = manager.canvas.getObjects()
        for (const obj of loadedObject) {
          obj.set({ opacity: 0 })
        }
        
        replacer.replaceImage('teamLogo0', gameInfo.teams?.firstTeam?.logo ?? '', 0, smoothAppearance)
        replacer.replaceImage('teamLogo1', gameInfo.teams?.secondTeam?.logo ?? '', 0, smoothAppearance)
        replacer.replaceImage('organizerLogo', gameInfo.organizer?.logo ?? '', 0, smoothAppearance)
        replacer.replaceText('teamName0', (gameInfo.teams?.firstTeam?.name ?? 'Неизвестно').toUpperCase(), 0)
        replacer.replaceText('teamName1', (gameInfo.teams?.secondTeam?.name ?? 'Неизвестно').toUpperCase(), 0)
        replacer.replaceText('teamScore0', (gameInfo.teams?.firstTeam?.score ?? 0).toString(), 0)
        replacer.replaceText('teamScore1', (gameInfo.teams?.secondTeam?.score ?? 0).toString(), 0)
        replacer.replaceText('teamFouls0', (gameInfo.teams?.firstTeam?.fouls ?? 0).toString(), 0)
        replacer.replaceText('teamFouls1', (gameInfo.teams?.secondTeam?.fouls ?? 0).toString(), 0)
        replacer.replaceText('city', gameInfo.match?.address.city ?? 'Неизвестный город', 0)
        replacer.replaceText('championshipName', gameInfo.championship?.name ?? 'Неизвестный чемпионат', 0)
        replacer.replaceText('startMatchTime', gameInfo.match?.dateInfo.time ?? 'Неизвестное время', 0)
        replacer.replaceText('startMatchDate', gameInfo.match?.dateInfo.date ?? 'Неизвестная дата', 0)
        replacer.replaceText('matchTime', millisToMinutesAndSeconds(timer), 0)
        replacer.replaceLastResultGame('lastGameResults0', gameInfo.teams?.firstTeam?.lastGamesResults.map((x) => x.result), 0)
        replacer.replaceLastResultGame('lastGameResults1', gameInfo.teams?.secondTeam?.lastGamesResults.map((x) => x.result), 0)
        
        const allObject = manager.canvas.getObjects()
        for (const obj of allObject) {
          smoothAppearance(obj)
        }
      }
      
      manager.loadLocal({ template }).then(onLoad)
    })
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeOverlay, manager, replacer, templates])
  
  useEffect(() => {
    if (!gameInfo) return;
    replacer.updateText('teamScore0', (gameInfo.teams?.firstTeam?.score as 0).toString())
    replacer.updateText('teamScore1', (gameInfo.teams?.secondTeam?.score as 0).toString())
    replacer.updateText('teamFouls0', (gameInfo.teams?.firstTeam?.fouls as 0).toString())
    replacer.updateText('teamFouls1', (gameInfo.teams?.secondTeam?.fouls as 0).toString())
  }, [gameInfo, replacer])
  
  useEffect(() => {
    replacer.updateText('matchTime', millisToMinutesAndSeconds(timer))
  }, [replacer, timer])
  
  useEffect(() => {
    supabase.matchChronology.observeChanges(matchId)
    
    getData(matchId).then((data) => {
      const isDev = process.env.REACT_APP_ENVIRONMENT === 'development';
      
      if (!data) return
      dispatch(setGameInfo(data.gameInfo))
      dispatch(setScore(data.score))
      dispatch(setFouls(data.fouls))
      dispatch(setTimer(data.time.seconds))
      dispatch(setPeriod(data.time.currentPeriod))
      setResolutions(data.resolution)
      setTemplates(data.templates)
      setAvailable(data.featureIsActive || isDev)
      if (!data.category) return
      dispatch(setCategory({ category: data.category }))
    })
    
    supabase.matchChronology.events.subscribe((newEvents) => {
      dispatch(setEvents(newEvents));
    })
    
    supabase.matchChronology.activeTemplateId.subscribe((activeOverlayId) => {
      dispatch(setActiveOverlay(activeOverlayId ?? null))
    })
    
    return () => {
      supabase.matchChronology.stopObserveChanges()
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, matchId]);
  
  const rootDiv = document.getElementById('root')
  const body = document.getElementsByTagName('body')[ 0 ]
  rootDiv?.setAttribute('style', 'background: unset')
  body?.setAttribute('style', 'background: unset')
  
  if (featureIsAvailable === false) {return (<h1>Подписка не активирована</h1>)}
  
  return (
    <div key={'overlay'} className={'d-flex justify-content-end'} style={{ width: '100%', height: '100%' }}>
      <div
        className={'d-flex justify-content-end'}
        style={{ background: 'unset', width: '100%', height: '100%', position: 'relative' }}
      >
        <div
          style={{
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 80,
            width: '100%',
            height: '100%'
          }}>
          {canvas}
        </div>
        {/*<FlagDisappearances active={activeOverlay === 'team0'} duration={time.second}>*/}
        {/*  <TeamComposition teamIndex={0}/>*/}
        {/*</FlagDisappearances>*/}
        {/*<FlagDisappearances active={activeOverlay === 'team1'} duration={time.second}>*/}
        {/*  <TeamComposition teamIndex={1}/>*/}
        {/*</FlagDisappearances>*/}
        {events.map((event) => {
          return (
            <TimerDisappearance duration={durations[ event.type ]} key={event.id}>
              <RenderEvent event={event}/>
            </TimerDisappearance>
          )
        })}
      </div>
      {featureIsAvailable && gameInfo && <Joystick key={'joystick'}/>}
    </div>
  );
}

export default Overlay;
