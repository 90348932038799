import { Object } from "fabric/fabric-impl";
import history from "./history";
import Interactive from "../canvases/interactive";

export class LayerManager {
  manager!: Interactive
  
  init(manager: Interactive) {
    this.manager = manager
  }
  
  isInit() {
    return !!this.manager
  }
  
  sendToFront = (object: Object) => {
    object?.bringToFront()
    history.isInit() && history.saveState()
  }
  
  sendToBack = (object: Object) => {
    object?.sendToBack()
    this.manager.sendBackgroundElemsToBack()
    history.isInit() && history.saveState()
  }
}

const layerManager = new LayerManager()
export default layerManager
