import React, { useMemo } from 'react';
import { useMatch, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import UDMainLayout from '../../../ud-ui/layout/main';
import { selectOrganizerTeamById } from 'modules/organizer/store/selectors';
import UDBreadcrumbs from 'modules/ud-ui/components/breadcrumbs';
import { Pages } from 'modules/navigation/domain/enums/pages';
import TeamEditFormTabs from '../components/edit-team-form/tabs';
import TeamComponentsEditForm from '../components/edit-team-form';

const TeamsEditPage = () => {
  const { id } = useParams();
  const teamId = id ? parseInt(id) : 0;
  const team = useSelector(selectOrganizerTeamById(teamId));
  const teamPlayersPage = useMatch(`${Pages.TEAMS.EDIT}/players`);
  
  
  const breadcrumbsItems = useMemo(() => {
    const items = [{ label: 'Команды', to: Pages.TEAMS.INDEX }];

    if (!team) {
      return items;
    }

    items.push({
      label: team.name,
      to: Pages.TEAMS.builders.edit(team.id),
    });
    
    if (teamPlayersPage) {
      items.push({
        label: 'Игроки',
        to: Pages.TEAMS.builders.edit(team.id, 'players'),
      });
    }

    return items;
  }, [team, teamPlayersPage]);

  const breadcrumbs = team ? (
    <UDBreadcrumbs className="my-10" breadcrumbs={breadcrumbsItems} />
  ) : null;

  return (
    <UDMainLayout headerContent={breadcrumbs}>
      <div className="container h-100 pt-6">
        {team && (
          <>
            <div className="pb-6">
              <TeamEditFormTabs team={team} />
            </div>
            <div className="">
              <TeamComponentsEditForm team={team} />
            </div>
          </>
        )}
      </div>
    </UDMainLayout>
  );
};

export default TeamsEditPage;
