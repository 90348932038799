import React, { useEffect } from 'react';
import { Button, EmptyButton, Item, LongDivider, ShortDivider, SubItem } from "./components";
import { useSelector } from "react-redux";
import { selectFabricManager } from "../../../../store/selectors";
import { IEvent, Text as FabricText, Textbox } from "fabric/fabric-impl";
import Selector from "../../elements/selector";
import Input from "../../elements/input";
import ColorPicker from "../../elements/colorPicker";
import AlignTextLeftIcon from "../../../../../ud-ui/components/icon/align-text-left-icon";
import AlignTextCenterIcon from "../../../../../ud-ui/components/icon/align-text-center-icon";
import AlignTextJustifyIcon from "../../../../../ud-ui/components/icon/align-text-justify-icon";
import AlignTextRightIcon from "../../../../../ud-ui/components/icon/align-text-right-icon";
import SnapToGrid from "../../../../../ud-ui/components/fabric-canvas/addons/snapToGrid";
import layerManager from "../../../../../ud-ui/components/fabric-canvas/addons/layerManager";
import ArrowRightIcon from "../../../../../ud-ui/components/icon/arrow-right-icon";

interface IProps {
  text: Textbox | FabricText
}

function Text(props: IProps) {
  const text = props.text
  const fabricManager = useSelector(selectFabricManager);
  const [color, setColor] = React.useState(text.fill?.toString());
  const [fontSize, setFontSize] = React.useState(text.fontSize);
  const [fontWeight, setFontWeight] = React.useState(text.fontWeight);
  const [fontFamily, setFontFamily] = React.useState(text.fontFamily);
  const [scale, setScale] = React.useState(text.scaleY);
  
  useEffect(() => {
    setColor(text.fill?.toString());
    setFontSize(text.fontSize);
    setFontWeight(text.fontWeight);
    setFontFamily(text.fontFamily);
    setScale(text.scaleY)
    text.setControlsVisibility({ tl: false, tr: false, br: false, bl: false })
    
    const onScale = (e: IEvent<Event>) => {
      if (e.transform?.corner === 'ml') {
        if (text.width === undefined || text.left === undefined) return
        let tl = SnapToGrid.snapGrid(text.left ?? 0);
        if (!tl) return
        text.set({ left: tl, width: text.width + text.left - tl })
      }
      
      if (e.transform?.corner === 'mr') {
        const width = text.width ?? 0
        text.set({ width: SnapToGrid.snapGrid(width), scaleX: 1 })
      }
    }
    
    const updateScaling = () => {
      setScale(text.scaleY ?? 1)
    }
    
    text.on('resizing', onScale)
    text.on('scaling', updateScaling)
    return () => {
      text.off('resizing', onScale)
      text.off('scaling', updateScaling)
    }
  }, [text])
  
  const onChangeColor = (color: string) => {
    setColor(color)
    text.set({ fill: color })
    fabricManager?.render()
  }
  
  const onChangeFontSize = (value: number) => {
    if (isNaN(value)) return
    setFontSize(value)
    text.set({ fontSize: value })
    fabricManager?.render()
  }
  
  const onChangeFontFamily = (value: string) => {
    setFontFamily(value)
    text.set({ fontFamily: value })
    fabricManager?.render()
  }
  const onChangeFontWeight = (value: string) => {
    setFontWeight(value)
    text.set({ fontWeight: value })
    fabricManager?.render()
  }
  
  const onChangeTextAlign = (value: string) => {
    text.set({ textAlign: value })
    fabricManager?.render()
  }
  
  return (
    <>
      <Item>
        <div>Текст</div>
      </Item>
      <ShortDivider/>
      <Item>
        <span>Тип шрифта</span>
        <Selector
          styledEachOption
          onChange={(event) => {onChangeFontFamily((event?.value) ?? '')}}
          options={fontOptions}
          defaultValue={fontOptions.find((option) => option.value === fontFamily)}
        />
        <SubItem style={{ display: 'flex', gap: 8, justifyContent: 'space-between' }}>
          <Selector
            className={'w-100'}
            onChange={(event) => {onChangeFontWeight((event?.value) ?? '')}}
            options={fontWeightsOptions}
            defaultValue={fontWeightsOptions.find((option) => option.value === fontWeight)}
          />
          <Input
            style={{ width: 55 }}
            unit="px"
            onChange={(value) => {onChangeFontSize(+value)}}
            value={fontSize?.toString() ?? '20'}/>
        </SubItem>
      </Item>
      <LongDivider/>
      <Item>
        <span>Цвет шрифта</span>
        <ColorPicker color={color?.toUpperCase() ?? '#000000'} onChange={onChangeColor}/>
      </Item>
      <LongDivider/>
      <Item style={{ flexDirection: 'row', gap: 8, justifyContent: 'left' }}>
        <EmptyButton onClick={() => {onChangeTextAlign('left') }}><AlignTextLeftIcon/></EmptyButton>
        <EmptyButton onClick={() => {onChangeTextAlign('center') }}><AlignTextCenterIcon/></EmptyButton>
        <EmptyButton onClick={() => {onChangeTextAlign('right') }}><AlignTextRightIcon/></EmptyButton>
        <EmptyButton onClick={() => {onChangeTextAlign('justify') }}><AlignTextJustifyIcon/></EmptyButton>
      </Item>
      <LongDivider/>
      <Item>
        Слои
        <Button onClick={() => layerManager.sendToFront(text)}>
          <ArrowRightIcon height={16} width={16} color={'#929292'} direction={'up'}/> Поднять вверх
        </Button>
        <Button onClick={() => layerManager.sendToBack(text)}>
          <ArrowRightIcon height={16} width={16} color={'#929292'} direction={'down'}/> Переместить вниз
        </Button>
      </Item>
      <Item>
        <SubItem>Размер по Y: {(scale ?? 1).toFixed(4)}</SubItem>
      </Item>
    </>);
}

export default Text;

const fonts = {
  OpenSans: 'Open Sans, sans-serif',
  BigShouldersDisplay: 'Big Shoulders Display, sans-serif',
  Inter: 'Inter, sans-serif',
  TimesNewRoman: 'Times New Roman, serif',
  Arial: 'Arial, sans-serif',
  Georgia: 'Georgia, serif',
  Tahoma: 'Tahoma, sans-serif',
  CourierNew: 'Courier New, monospace',
  BrushScriptMT: 'Brush Script MT, cursive',
}

const fontOptions = Object.entries(fonts).map(font => ({
  value: font[ 1 ],
  label: font[ 0 ],
}));

const fontWeights = {
  Bolder: '900',
  Bold: 'bold',
  Normal: 'normal',
  Lighter: 'lighter',
}
const fontWeightsOptions = Object.entries(fontWeights).map(font => ({ value: font[ 1 ], label: font[ 0 ] }));
