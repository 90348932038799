import { Organizer } from "../interfaces/Organizer";
import _ from "lodash";

export function filteringBySportType(organizers: Organizer[] | undefined, filter: string | undefined) {
  if (!filter || filter === "all") return organizers
  return _.reduce(organizers, (acc, organizer) => {
    if (organizer.sportType === filter) {
      acc.push(organizer)
      return acc
    }
    
    return acc
  }, [] as Organizer[]);
}
