import styled from '@emotion/styled';

export const GameEventCreatorContainer = styled.div`
  border-radius: 8px;
  border: 2px dashed #ccc;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.is-loading {
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border: none;
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
  }

  @keyframes shine {
    to {
      background-position-x: -200%;
    }
  }
`;