import React from 'react';

type IProps = {
  width?: number;
  height?: number;
  color?: string
} & React.SVGProps<SVGSVGElement>;

const TextIcon = (props: IProps) => {
  const {
    width,
    height,
    color = '#242424',
    ...rest
  } = props;
  
  return (
    <svg width={width ?? 32} height={height ?? 32} viewBox="0 0 32 32" fill="none"
         xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M8 21L11.3348 11.4719C11.4338 11.1893 11.7005 11 12 11V11C12.2995 11 12.5662 11.1893 12.6652 11.4719L14.95 18M16 21L14.95 18M14.95 18H9.05"
        stroke={color} strokeLinecap="round"/>
      <path
        d="M24.5 21V18.5M19 14.5V14.5C19.6403 13.8597 20.5087 13.5 21.4142 13.5H21.5V13.5C23.1569 13.5 24.5 14.8431 24.5 16.5V18.5M24.5 18.5V18.5C23.896 20.0099 22.4336 21 20.8074 21H20.6541C19.5172 21 18.6469 19.9716 18.8077 18.8461V18.8461C18.9255 18.0216 19.4766 17.3093 20.25 17V17V17C21.0571 16.6772 21.9525 16.6508 22.7772 16.9257L22.9028 16.9676C22.9675 16.9892 23.0306 17.0153 23.0916 17.0458L23.1509 17.0754C23.702 17.351 24.149 17.798 24.4246 18.3491L24.5 18.5Z"
        stroke={color} strokeLinecap="round"/>
    </svg>
  
  );
};

export default TextIcon;
