import { RootState } from '../../../store/store';

export const loadState = () => {
  // return { activeTournamentId: null };

  try {
    const activeTournamentId = localStorage.getItem('imgame:activeTournamentId');
    if (activeTournamentId) {
      return {
        activeTournamentId: Number(activeTournamentId),
      };
    }

    return { activeTournamentId: null };
  } catch (err) {
    return { activeTournamentId: null };
  }
};

export const saveState = (state: RootState) => {
  try {
    const activeTournamentId = state.auth.activeTournamentId;
    const availableTournaments = state.auth.availableTournaments;
    const isValidTournamentId = availableTournaments.some((tournament) => tournament.id === activeTournamentId);

    if (!activeTournamentId) {
      localStorage.removeItem('imgame:activeTournamentId');
    }

    if (isValidTournamentId) {
      localStorage.setItem('imgame:activeTournamentId', String(activeTournamentId));
    }
  } catch (err) {}
};

