/** @jsxImportSource @emotion/react */
import UDTooltip from "../../../../../ud-form/components/tooltip";
import { IconButton, ModalButton, tooltipStyles } from "./styles";
import SquareIcon from "../../../../../ud-ui/components/icon/square-icon";
import React, { useEffect, useState } from "react";
import InteractiveToggleIcon from "./interactiveToggleIcon";
import { useDispatch, useSelector } from "react-redux";
import { selectActiveModal, selectFabricManager } from "../../../../store/selectors";
import { ModalState } from "../../../../domain/interface/modalState";
import UDIcon from "../../../../../ud-ui/components/icon";
import BaseModal from "../../elements/modals/baseModal";
import history from "../../../../../ud-ui/components/fabric-canvas/addons/history";
import { fabric } from "fabric";
import customization from "../../../../../ud-ui/components/fabric-canvas/addons/customization";
import { setActiveModal } from "../../../../store/templateEditor";

export function AddShape() {
  const dispatch = useDispatch()
  const activeModal = useSelector(selectActiveModal);
  const fabricManager = useSelector(selectFabricManager);
  const [isSelected, setSelected] = useState<boolean>(false);
  const [modalState, setModalState] = useState<ModalState>({ isModalOpen: false, modalStyle: {}, });
  
  useEffect(() => {
    const clickListener = () => {
      if (isSelected) {
        setSelected(false)
        setModalState((prev) => ({ ...prev, isModalOpen: false }))
      }
    };
    
    const closeModalOnEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setSelected(false)
        setModalState((prev) => ({ ...prev, isModalOpen: false }))
        dispatch(setActiveModal(undefined))
      }
    }
    
    document.addEventListener('keydown', closeModalOnEsc)
    document.addEventListener('click', clickListener)
    return () => {
      document.removeEventListener('keydown', closeModalOnEsc)
      document.removeEventListener('click', clickListener)
    }
  }, [dispatch, isSelected])
  
  useEffect(() => {
    if (!isSelected && activeModal === 'addShape') {
      dispatch(setActiveModal(undefined))
    }
    // eslint-disable-next-line
  }, [isSelected]);
  
  const onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (isSelected) {
      setModalState((prev) => ({ ...prev, isModalOpen: false }))
      setSelected(false)
      dispatch(setActiveModal(undefined))
      return
    }
    
    const target = e.currentTarget;
    if (!target) return;
    
    const bounding = target.getBoundingClientRect();
    setModalState(
      {
        isModalOpen: true,
        modalStyle: {
          top: `${bounding.top}px`,
          left: `${bounding.left + bounding.width + 15}px`,
          width: 300,
        },
      }
    )
    setSelected(true)
    dispatch(setActiveModal("addShape"))
  }
  
  const addShape = (shape: 'rect' | 'circle') => {
    if (!fabricManager) return
    history.saveState()
    const { left, top } = fabricManager.getLeftTopCurVP();
    
    switch (shape) {
      case 'rect':
        fabricManager.canvas.add(new fabric.Rect({
            width: 200,
            height: 100,
            fill: 'red',
            strokeWidth: 0,
            left,
            top,
            ...customization.getAllProperties(),
          })
        )
        break
      case 'circle':
        fabricManager.canvas.add(new fabric.Circle({
            radius: 100,
            fill: 'red',
            strokeWidth: 0,
            left,
            top,
            ...customization.getAllProperties(),
          })
        )
        break
    }
    
    setSelected(false)
    setModalState((prev) => ({ ...prev, isModalOpen: false }))
    dispatch(setActiveModal(undefined))
  }
  
  
  return (
    <>
      <UDTooltip show={!activeModal} css={tooltipStyles} text={"Фигуры"}>
        <IconButton onClick={onClick}>
          <InteractiveToggleIcon child={SquareIcon}/>
        </IconButton>
      </UDTooltip>
      <BaseModal isOpen={modalState.isModalOpen && isSelected} style={modalState.modalStyle}>
        <ModalButton onClick={() => {addShape('rect')}}><UDIcon name={'rectangle'}></UDIcon>Прямоугольник</ModalButton>
        <ModalButton onClick={() => {addShape('circle')}}><UDIcon name={'circle'}></UDIcon>Круг</ModalButton>
      </BaseModal>
    </>
  );
}
