import styled from '@emotion/styled';

export const Container = styled.div`
  display: grid;
  grid-template-areas:
    'menu header'
    'menu content'
    'menu footer';
  grid-template-columns: minmax(250px, 20%) 10fr;
  grid-template-rows: 70px 1fr 70px;
  height: 100%;
  width: 100%;
`;

export const Header = styled.header`
  grid-area: header;
  background: ${({ theme }) => theme.colors.SurfaceRichBlack60};
`;

export const Menu = styled.menu`
  grid-area: menu;
  height: 100%;
  background: ${({ theme }) => theme.colors.SurfaceIndependence40};
`;

export const Content = styled.div`
  grid-area: content;
  background: ${({ theme }) => theme.colors.SurfaceCultured10};
  overflow-y: auto;
`;

export const Footer = styled.footer`
  grid-area: footer;
  background-color: ${({ theme }) => theme.colors.SurfaceCultured10};
`;
