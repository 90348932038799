import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const MainBlock = styled.div`

`

export const stylesOptions = css`
  &:hover {
    background-color: #FFFBEB;
  }
`
