import { createSlice } from '@reduxjs/toolkit';
import { changeMainPageQuery } from './actions';

const slice = createSlice({
  name: 'mainPage',
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(changeMainPageQuery, (state, { payload }) => {
      //@ts-ignore
      state.query[payload.field] = payload.value;
    });
  },
});

const mainPageReducer = slice.reducer;
export default mainPageReducer;
