import React, { useCallback, useMemo } from 'react';
import styled from '@emotion/styled';
import { format } from 'date-fns';
import { TournamentUser } from '../../../domain/interfaces/TournamentUser';
import { TeamPlayerAvatar } from '../team-card/styles';

const defaultAvatar =
  'https://www.iconexperience.com/_img/o_collection_png/green_dark_grey/512x512/plain/user.png';

export const SuggestedPlayerListContainer = styled.div`
  border: 0.5px solid #EAEAEC;
  border-radius: 0 0 6px 6px;
  box-shadow: 0 0 1px rgba(26, 26, 41, 0.08), 0 8px 16px rgba(26, 26, 41, 0.08);
  cursor: pointer;
  position: absolute;
  width: 100%;
  margin-top: 4px;
`;

const SuggestedPlayerListItemContainer = styled.div`
  background-color: #fff;
  padding: 10px;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;

  &:hover {
    background-color: #F4F4F5;
  }
  
  &:last-child {
    border-radius: 0 0 6px 6px;
  }
`;

const StyledPlayerName = styled.div`
  margin-left: 10px;
`;

const StyledPlayerBirthDate = styled.div`
  color: #8F8E99;
  margin-left: 10px;
`;

type SuggestedPlayerListItemProps = {
  user: TournamentUser;
  onClick: (user: TournamentUser) => void;
};

const SuggestedPlayerListItem = ({ user, onClick }: SuggestedPlayerListItemProps) => {
  const avatarUrl = useMemo(() => {
    const userAvatar = user.photo?.url;
    const tournamentAvatar = user.tournamentAvatars?.length
      ? user.tournamentAvatars[0].image.externalUrl
      : null;

    return tournamentAvatar || userAvatar || defaultAvatar;
  }, [user.photo?.url, user.tournamentAvatars]);

  const birthDate = useMemo(() => {
    if (!user.birthDate) {
      return null;
    }

    return format(new Date(user.birthDate), 'dd.MM.uuuu');
  }, [user.birthDate]);

  const handleClick = useCallback(() => {
    onClick(user);
  }, [onClick, user]);

  return (
    <SuggestedPlayerListItemContainer onClick={handleClick}>
      <TeamPlayerAvatar
        url={avatarUrl}
        size={24}
        square
      />
      <StyledPlayerName>
        {user.surname} {user.name} {user.middleName}
      </StyledPlayerName>
      <StyledPlayerBirthDate>
        {birthDate}
      </StyledPlayerBirthDate>
    </SuggestedPlayerListItemContainer>
  );
};

type SuggestedPlayerListProps = {
  users: TournamentUser[];
  onClick: (user: TournamentUser) => void;
};

export const SuggestedPlayerList = React.forwardRef(({ users, onClick }: SuggestedPlayerListProps, ref: React.ForwardedRef<HTMLDivElement>) => {
  if (!users.length) {
    return null;
  }

  return (
    <SuggestedPlayerListContainer ref={ref} tabIndex={0}>
      {users.map((user) => (
        <SuggestedPlayerListItem
          key={user.id}
          user={user}
          onClick={onClick}
        />
      ))}
    </SuggestedPlayerListContainer>
  );
});
