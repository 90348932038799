import { combineReducers } from '@reduxjs/toolkit';
import tourneysCommonReducer from './common';
import tourneyChampionship from './championship';
import { groupsSlice, stagesSlice, bracketsSlice } from './stages';

const tourneysReducer = combineReducers({
  common: tourneysCommonReducer,
  championship: tourneyChampionship,
  stages: stagesSlice.reducer,
  groups: groupsSlice.reducer,
  brackets: bracketsSlice.reducer,
});

export default tourneysReducer;
