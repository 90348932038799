import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Items = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const Item = styled.div`
  background: #F6F6F6;
  padding: 8px 16px;
  border-radius: 8px;
  width: 100%;
  height: 43px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  
  p {
    text-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

export const Hotkeys = styled.div`
  display: flex;
  flex-direction: row;
  gap: 14px;
  height: 100%;
`

export const HotkeyItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  
  color: #DBB400;
`

export const VertDivider = styled.div<{color?: string, width?: number}>`
  width: ${({ width }) => width ?? 1}px;
  border-left: 1px solid ${({ color }) => color ?? '#EDEDED'};
  background-color: ${({ color }) => color ?? '#EDEDED'};
`

export const modalContainerCss = css`
  background: #FFFFFF;
  border-radius: 8px;
  border: 1px solid #EDEDED;
  padding: 34px 24px;
  width: 500px;
  min-height: 179px;
  position: relative;
`

export const iconModalCss = css`
  position: absolute !important;
  top: 0;
  right: 0;
  padding: 22px;
  cursor: pointer;
`
