import styled from '@emotion/styled';

const FilterButton = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border-radius: 8px;
  cursor: pointer;
  
  &.active {
    background-color: #F5D956;
    cursor: auto;
  }
`;

export default FilterButton;
