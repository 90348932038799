import React from 'react';
import { ArrayHelpers } from 'formik/dist/FieldArray';
import { Team } from '../../../../../../teams/domain/interfaces/Team';
import UDCheckbox, { UDCheckboxProps } from '../../../../../../ud-form/components/checkbox/component';
import { UDFormComponentProps } from '../../../../../../ud-form/components/types';
import UDFormFieldWrapper from '../../../../../../ud-form/wrapper';
import TeamGroupCard from './team-group-card';

type TeamCardCheckboxProps = {
  team: Team;
  arrayHelpers: ArrayHelpers;
} & UDCheckboxProps & UDFormComponentProps;

const TeamCardCheckbox = (props: TeamCardCheckboxProps) => {
  const {
    team,
    arrayHelpers,
    name,
    required,
    labelProps,
    label,
    containerProps,
    ...checkboxProps
  } = props;

  return (
    <UDFormFieldWrapper
      name={name}
      required={required}
      label={label}
      labelProps={{
        ...labelProps,
        style: { marginBottom: 0, ...labelProps?.style },
      }}
      containerProps={{
        ...containerProps,
        className: containerProps?.className,
      }}>
      {({ field }) => (
        <TeamGroupCard
          name={team.name}
          logoUrl={team.logo?.url}
        >
          <UDCheckbox
            {...checkboxProps}
            {...field}
            checked={field.value.includes(team.id)}
            onChange={(e) => {
              if (e.target.checked) {
                arrayHelpers.push(team.id);
              } else {
                const idx = field.value.indexOf(team.id);
                arrayHelpers.remove(idx);
              }
            }}
          />
        </TeamGroupCard>
      )}
    </UDFormFieldWrapper>
  );
};

export default TeamCardCheckbox;
