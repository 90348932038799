import React from 'react';

type TimeoutIconProps = {
  width: number;
  height: number;
  color?: string;
};

const TimeoutIcon = (props: TimeoutIconProps) => {
  const {
    width,
    height,
    color = '#F2CA18',
  } = props;

  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 22 27"
      fill="none" 
    >
      <path
        d="M7.05908 12.2519C7.05908 14.4061 8.80528 16.1523 10.9594 16.1523C13.1136 16.1523 14.8598 14.4061 14.8598 12.2519C14.8598 10.0978 13.114 8.35156 10.9594 8.35156C8.80528 8.35156 7.05908 10.0978 7.05908 12.2519ZM10.4014 13.4125H12.2539C12.6607 13.4125 12.9907 13.7424 12.9907 14.1493C12.9907 14.5561 12.6607 14.8861 12.2539 14.8861C11.8471 14.8861 11.5171 14.5561 11.5171 14.1493C11.5171 14.1246 11.5219 14.1011 11.5244 14.0768H10.4014L10.4014 13.4125Z"
        fill={color}
      />
      <path
        d="M12.2127 0.929916C12.04 0.721784 11.7243 0.689428 11.5115 0.858021L8.45558 3.27514L1.04127 8.8498C0.4546 9.29088 0.325782 10.1036 0.748672 10.6971L6.30956 18.5098V29.6402C6.30956 29.661 6.31177 29.6812 6.31251 29.7017L5.03092 46.0517C4.94038 47.2052 5.82481 48.2114 7.00512 48.2998C8.1858 48.3883 9.21676 47.5252 9.30731 46.3716L10.516 30.9492H11.4836L12.6923 46.3716C12.7828 47.5252 13.8133 48.3883 14.9945 48.2998C16.1748 48.2114 17.0592 47.2052 16.9687 46.0517L15.6874 29.7026C15.6885 29.6817 15.6908 29.6612 15.6908 29.6404V18.51L21.2517 10.6973C21.6745 10.1031 21.545 9.28961 20.9572 8.8489L16.0585 5.17583C16.0504 5.169 16.0438 5.16109 16.0353 5.15426L12.9032 2.81086C12.6857 2.64802 12.371 2.68864 12.2039 2.90144C12.0372 3.11389 12.0788 3.42124 12.2967 3.58444L14.1888 5.00005C13.6353 5.56514 13.1826 6.43647 13.5447 6.70794L18.2183 10.2121L13.5676 16.7462H11.3903V18.5629H10.6097V16.7462H8.43336L3.78122 10.2103L10.4506 5.19607C10.8154 4.92179 12.3854 1.13834 12.2128 0.930268L12.2127 0.929916Z"
        fill={color}
      />
    </svg>
  );
};

export default TimeoutIcon;