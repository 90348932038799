import { css, Theme } from "@emotion/react";

export const increaseClickArea = css`
  position: relative;
  
  &:after {
    content: "";
    position: absolute;
    width: 40px;
    height: 100px;
  }
`

export const onHoverButton = (theme: Theme) => css`
  color: ${theme.colors.Black24};
  transition: color 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    color: ${theme.colors.AccentNapplesYellow};
  }

  &:disabled {
    color: #D0D0D0;
  }
`
