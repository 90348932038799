import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GameDetails } from '../../../domain/interfaces/GameDetails';
import { GameEventDTO } from 'modules/games/domain/interfaces/GameEventDTO';
import UDButton from 'modules/ud-ui/components/button';
import { useModal } from 'modules/ud-ui/components/modal/useModal';
import { GameEventForm } from '../game-event-form';
import * as styles from './game-event-creator.styles';
import { addGameEvent } from 'modules/games/store/actions';
import { championshipSettingsSelector } from '../../../../tourneys/store/championship/selectors';
import { getPeriodsSettings } from '../../../domain/mappers/match-periods-settings';
import { ChampionshipSettings } from '../../../../tourneys/domain/interfaces/ChampionshipSettings';

type GameEventCreatorProps = {
  isLoading: boolean;
  teamIndex: number;
  game: GameDetails | null;
};

export const GameEventCreator = (props: GameEventCreatorProps) => {
  const dispatch = useDispatch();
  const settings = useSelector(championshipSettingsSelector);
  const periodSettings = getPeriodsSettings(settings || {} as ChampionshipSettings);

  const {
    isLoading,
    teamIndex,
    game,
  } = props;

  const { open, close, Modal } = useModal({ defaultShow: false });
  const onButtonClick = useCallback(() => {
    open();
  }, [open]);

  const handleAddEvent = useCallback((values: GameEventDTO) => {
    dispatch(addGameEvent({
      data: values,
      periodSettings,
    }));
    close();
  }, [close, dispatch, periodSettings]);

  const initialValues = game
    ? { teamId: game.teams[teamIndex].id }
    : { teamId: 0 };

  return (
    <styles.GameEventCreatorContainer className={isLoading ? 'is-loading' : ''}>
    {game && !isLoading && (
      <>
        <UDButton
          variant='primary'
          type='button'
          onClick={onButtonClick}
        >+ Добавить событие</UDButton>

        <Modal
          header={{title: 'Добавить событие матча', subtitle: ''}}
          containerProps={{style: {width: '500px'}}}
        >
          <GameEventForm
            initialValues={initialValues}
            members={game.teams[teamIndex].members}
            onSubmit={handleAddEvent}
          />
        </Modal>
      </>
    )}
    </styles.GameEventCreatorContainer>
  );
};
