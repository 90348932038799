import styled from '@emotion/styled';
import classNames from 'classnames';
import overridedSelectComponents, { DropDownWhite } from '../../ud-form/components/select/component/override';
import { UDSelect, UDSelectProps } from '../../ud-form/components/select/component';
import UDText from '../../ud-ui/components/text';

const StyledControlWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.AccentNapplesYellow};
  border-radius: 8px;
  overflow: hidden;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.SurfaceWhite};
  cursor: pointer;
  &.disabled {
    opacity: 0.5;
    pointer-events:none;
  }
  &.opened {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

const StyledControl = (props: any) => {
  return (
    <StyledControlWrapper
      {...props.innerProps}
      className={classNames('d-flex flex-row pl-4', {
        disabled: props.isDisabled,
        focused: props.isFocused,
        multi: props.isMulti,
        opened: props.menuIsOpen,
      })}>
      {props.children}
    </StyledControlWrapper>
  );
};

const StyledPlaceholder = (props: any) => {
  return (
    <UDText
      type="subhead"
      className="color-SurfaceRaisinBlack50"
      {...props.innerProps}>
      {props.children}
    </UDText>
  );
};

const StyledSelect = (props: UDSelectProps<false>) => {
  const selectComponents = {
    ...overridedSelectComponents,
    DropdownIndicator: DropDownWhite,
    Control: StyledControl,
    Placeholder: StyledPlaceholder,
  };

  return (
    <UDSelect
      {...props}
      components={selectComponents}
    />
  );
};

export default StyledSelect;
