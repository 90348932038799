import { FieldProps, useFormikContext } from 'formik';
import UDFormFieldWrapper from 'modules/ud-form/wrapper';
import React, { useCallback, useMemo } from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import UDInput, { UDInputProps } from '../input/component';
import { UDFormComponentProps } from '../types';

const formatTimeValue = (value: number): string => (value < 10) ? `0${value}` : `${value}`;
const parsStringTimeToSeconds = (str: string): number => {
  const [minutesStr, secondsStr] = str.split(':');
  const minutes = parseInt(minutesStr);
  const seconds = parseInt(secondsStr);

  return (minutes * 60) + seconds;
};

export type UDFormDurationInputProps = UDFormComponentProps
  & Omit<NumberFormatProps, 'hasError' | 'valid' | 'onChange' | 'value'>
  & { value?: number | null }
  & Pick<UDInputProps, 'hasError' | 'valid'>;

export const UDFormDurationInput = (props: UDFormDurationInputProps) => {
  const {
    name,
    label,
    labelProps,
    containerProps,
    required,
    value,
    ...otherProps
  } = props;

  const initialValue = useMemo(() => {
    if (!value) {
      return null;
    }

    let minutes = Math.floor(value / 60);
    let seconds = value - (minutes * 60);

    if (minutes > 99) {
      minutes = 99;
    }

    if (seconds > 59) {
      seconds = 59;
    }

    return `${formatTimeValue(minutes)}:${formatTimeValue(seconds)}`;
  }, [value]);

  const { setFieldValue } = useFormikContext();
  const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    if (!value) {
      setFieldValue(name, value);
    } else {
      setFieldValue(name, parsStringTimeToSeconds(value));
    }
  }, [name]);

  return (
    <UDFormFieldWrapper
      name={name}
      containerProps={containerProps}
      label={label}
      labelProps={labelProps}
      required={required}>
      {({ field, meta }: FieldProps<any>) => (
        <div>
          <NumberFormat
            {...field}
            format='##:##'
            placeholder='00:00'
            value={initialValue}
            customInput={UDInput}
            onChange={onChange}
            {...otherProps}
          />
        </div>
      )}
    </UDFormFieldWrapper>
  );
};