import React from 'react';
import classNames from 'classnames';
import styled from '@emotion/styled';
import { Logo } from './styles';
import { LinkProps } from 'react-router-dom';
import { Pages } from 'modules/navigation/domain/enums/pages';

const isDev = process.env.REACT_APP_ENVIRONMENT !== 'production';

const DevLogoLabel = styled.span`
  position: relative;
  top: -0.3em;
  font-size: 80%;
  color: ${({ theme }) => theme.colors.CaseError} !important;
`;

type Props = Omit<LinkProps, 'to'>;

const UDLogo = (props: Props) => {
  return (
    <Logo
      to={Pages.INDEX}
      {...props}
      className={classNames('text title-1 bold', props.className)}>
      <span>Im</span>Game
      {isDev && (
        <DevLogoLabel>Test</DevLogoLabel>
      )}
    </Logo>
  );
};

export default UDLogo;
