import React from 'react';

type ReplacementIconProps = {
  width: number;
  height: number;
  color?: string;
};

const ReplacementIcon = (props: ReplacementIconProps) => {
  const {
    width,
    height,
    color = '#F2CA18',
  } = props;

  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 28 24"
      fill="none" 
    >
      <path
        d="M7.23466 23.8722C6.92527 23.8722 6.62837 23.7493 6.4096 23.5305C6.19084 23.3118 6.06791 23.0151 6.06791 22.7055L6.06791 4.54153L2.08583 9.61796C1.82826 9.94611 1.41519 10.1117 1.00216 10.0526C0.589368 9.99376 0.23937 9.71874 0.0838928 9.33177C-0.0713248 8.9445 -0.00830041 8.50411 0.249271 8.17595L6.31636 0.446079C6.53773 0.164286 6.87604 -4.57764e-05 7.23416 -4.57764e-05C7.59226 -4.57764e-05 7.93054 0.164286 8.15169 0.446079L14.2188 8.17595C14.4102 8.4192 14.4969 8.7286 14.4599 9.03588C14.423 9.3432 14.2657 9.62342 14.0222 9.8146C13.7789 10.0058 13.4695 10.0925 13.1622 10.0558C12.8549 10.0188 12.575 9.86148 12.3835 9.61798L8.40143 4.54155L8.40143 22.7055C8.40143 23.0151 8.2785 23.3118 8.05974 23.5306C7.84098 23.7493 7.54406 23.8722 7.23466 23.8722ZM19.6022 1.16729V19.3312L15.6165 14.2548C15.3589 13.9269 14.9461 13.7613 14.5334 13.8207C14.1206 13.8798 13.7708 14.1545 13.6156 14.5415C13.4606 14.9285 13.5236 15.3689 13.7812 15.6968L19.8483 23.4267C20.0697 23.7085 20.408 23.8728 20.7661 23.8728C21.1242 23.8728 21.4625 23.7085 21.6836 23.4267L27.7507 15.6968C28.0083 15.3687 28.0713 14.9283 27.9161 14.541C27.7609 14.154 27.4109 13.879 26.9978 13.8201C26.5851 13.761 26.172 13.9267 25.9144 14.2548L21.9358 19.3312V1.16728C21.9358 0.750326 21.7133 0.365156 21.3524 0.156809C20.9914 -0.0515385 20.5466 -0.0515385 20.1856 0.156809C19.8247 0.365156 19.6022 0.750351 19.6022 1.16729Z"
        fill={color}
      />
    </svg>
  );
};

export default ReplacementIcon;