import styled from "@emotion/styled";

export const MainBlock = styled.div`

`

export const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 8px;
  &:hover {
    border: 1px solid #F5D956;
    cursor: pointer;
  }
`

export const IconContainer = styled.div`
  position: absolute;
  top: calc(62px / 2 - 30px / 2 / 2); // 62 высота блока с логотипом; 30 высота иконки
`
