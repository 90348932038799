import { AnnouncedTeamsMap } from '../../domain/interfaces/AnnouncedTeamsMap';
import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import {
  announceNewTeam,
  getChampionshipAnnouncedTeams, playerChampionshipApplicationNumberChanged,
  playerChampionshipApplicationPositionChanged, upsertChampionshipPlayerApplication,
  upsertChampionshipTeamApplication
} from './actions';
import { AnnouncedTeamStatus } from '../../domain/enums/AnnouncedTeamStatus';
import { ApplicationStatus } from '../../../applications/domain/enums/ApplicationStatus';

export type ChampionshipTeamsSliceState = {
  championshipId: number | null;
  isLoading: boolean;
  updatingPlayerApplication: number | null;
  updatingTeamApplication: number | null;
  data: AnnouncedTeamsMap | null;
};

const initialState: ChampionshipTeamsSliceState = {
  championshipId: null,
  isLoading: false,
  updatingPlayerApplication: null,
  updatingTeamApplication: null,
  data: null,
};

function createGetChampionshipAnnouncedTeamsReducer(builder: ActionReducerMapBuilder<ChampionshipTeamsSliceState>) {
  builder.addCase(getChampionshipAnnouncedTeams.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(getChampionshipAnnouncedTeams.fulfilled, (state, { payload, meta }) => {
    state.championshipId = meta.arg;
    state.data = payload;
    state.isLoading = false;
  });

  builder.addCase(getChampionshipAnnouncedTeams.rejected, (state) => {
    state.isLoading = false;
  });
}

function createUpsertChampionshipPlayerApplication(builder: ActionReducerMapBuilder<ChampionshipTeamsSliceState>) {
  builder.addCase(upsertChampionshipPlayerApplication.pending, (state, { meta }) => {
    if (meta.arg.id) {
      state.updatingPlayerApplication = meta.arg.id;
    }
  });

  builder.addCase(upsertChampionshipPlayerApplication.fulfilled, (state) => {
    state.updatingPlayerApplication = null;
  });

  builder.addCase(upsertChampionshipPlayerApplication.rejected, (state) => {
    state.updatingPlayerApplication = null;
  });
}

function createUpsertChampionshipTeamApplicationReducer(builder: ActionReducerMapBuilder<ChampionshipTeamsSliceState>) {
  builder.addCase(upsertChampionshipTeamApplication.pending, (state, { meta }) => {
    if (meta.arg.teamId) {
      state.updatingTeamApplication = meta.arg.teamId;
    }
  });

  builder.addCase(upsertChampionshipTeamApplication.fulfilled, (state, { payload }) => {
    if (state.data && payload) {
      const teamData = state.data[payload.teamId];

      state.data[payload.teamId] = {
        ...teamData,
        status: payload.status,
        applications: teamData.applications.map((app) => ({
          ...app,
          status: payload.applicationStatus,
        })),
      }
    }

    state.updatingTeamApplication = null;
  });

  builder.addCase(upsertChampionshipTeamApplication.rejected, (state) => {
    state.updatingTeamApplication = null;
  });
}

function createAnnounceNewTeamReducer(builder: ActionReducerMapBuilder<ChampionshipTeamsSliceState>) {
  builder.addCase(announceNewTeam.fulfilled,(state, { payload }) => {
    const createdAt = new Date();

    state.data = {
      ...state.data,
      [payload.id.toString()]: {
        id: payload.id,
        name: payload.name,
        status: AnnouncedTeamStatus.ANNOUNCED,
        applications: payload.players.map((player, index) => ({
          id: index,
          teamId: payload.id,
          championshipId: state.championshipId,
          status: ApplicationStatus.PENDING,
          number: player.number,
          position: player.position,
          player: {
            id: player.userId,
            firstName: player.firstName,
            lastName: player.lastName,
            logoUrl: player.avatar,
          },
          createdAt,
        })),
        applicationDate: createdAt,
        isNew: true,
      },
    };
  });
}

function createPlayerChampionshipApplicationNumberChangedReducer(builder: ActionReducerMapBuilder<ChampionshipTeamsSliceState>) {
  builder.addCase(playerChampionshipApplicationNumberChanged, (state, { payload }) => {
    if (!state.data || !state.data[payload.teamId]) {
      return;
    }

    state.data = {
      ...state.data,
      [payload.teamId]: {
        ...state.data[payload.teamId],
        applications: state.data[payload.teamId].applications.map((app) => {
          if (app.id === payload.id) {
            return {
              ...app,
              number: payload.number,
            };
          }

          return app;
        })
      }
    };
  });
}

function createPlayerChampionshipApplicationPositionChangedReducer(builder: ActionReducerMapBuilder<ChampionshipTeamsSliceState>) {
  builder.addCase(playerChampionshipApplicationPositionChanged, (state, { payload }) => {
    if (!state.data || !state.data[payload.teamId]) {
      return;
    }

    state.data = {
      ...state.data,
      [payload.teamId]: {
        ...state.data[payload.teamId],
        applications: state.data[payload.teamId].applications.map((app) => {
          if (app.id === payload.id) {
            return {
              ...app,
              position: payload.position,
            };
          }

          return app;
        })
      }
    };
  });
}

const slice = createSlice({
  name: 'teams/championshipTeams',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<ChampionshipTeamsSliceState>) => {
    createGetChampionshipAnnouncedTeamsReducer(builder);
    createUpsertChampionshipTeamApplicationReducer(builder);
    createAnnounceNewTeamReducer(builder);
    createUpsertChampionshipPlayerApplication(builder);
    createPlayerChampionshipApplicationNumberChangedReducer(builder);
    createPlayerChampionshipApplicationPositionChangedReducer(builder);
  }
});


export default slice.reducer;
