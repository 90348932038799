import React from 'react';

type IProps = {
  width?: number;
  height?: number;
  color?: string;
};

const NewEditIcon = ({ width, height, color = 'inherit' }: IProps) => {
  return (
    <svg width={width ?? 24} height={height ?? 24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
         color={color}>
      <path
        d="M17.3442 10.4135L9.41098 18.348C8.98558 18.772 8.45217 19.0748 7.86942 19.2201L4.82497 19.9802C4.71383 20.008 4.5974 20.0065 4.48699 19.9759C4.37659 19.9454 4.27597 19.8868 4.1949 19.8059C4.11383 19.7249 4.05508 19.6244 4.02435 19.5141C3.99361 19.4037 3.99195 19.2873 4.01951 19.1761L4.77963 16.1316C4.92616 15.548 5.22853 15.0152 5.65442 14.5901L13.5863 6.65558L17.3442 10.4121V10.4135ZM19.2244 4.77796C20.2606 5.81545 20.2606 7.49703 19.2244 8.53452L18.283 9.47333L14.5264 5.71544L15.4665 4.77796C15.9647 4.27984 16.6403 4 17.3448 4C18.0493 4 18.7249 4.27984 19.2231 4.77796H19.2244Z"
        fill="currentColor"/>
    </svg>
  
  
  );
};

export default NewEditIcon;
