import React from 'react';
import Select, { GroupBase, Props, StylesConfig } from "react-select";
import { SelectOption } from "../../../../ud-form/components/select/component";

export type UDSelectProps<
  isMulti extends boolean = boolean,
  Group extends GroupBase<SelectOption> = GroupBase<SelectOption>
> = Omit<
  Props<SelectOption, isMulti, Group>,
  'hideSelectedOptions' | 'isSearchable' | 'styles'
>;

interface IProps extends UDSelectProps<false> {
  styledEachOption?: boolean
}

function Selector(props: IProps) {
  const { styledEachOption } = props
  const style: StylesConfig<SelectOption<any, any>, false, GroupBase<SelectOption<any, any>>> | undefined = {
    option: (provided, state) => {
      return { ...provided, fontFamily: styledEachOption && state.data.value };
    },
    
    menu: (base) => {
      return {
        ...base,
        zIndex: 9999,
        background: '#F2F3F4',
      }
    },
    
    control: base => {
      return {
        ...base,
        background: '#F2F3F4',
        borderColor: 'transparent',
        borderRadius: '8px',
        width: '100%',
        minHeight: 32,
        ":hover": {
          borderColor: 'transparent',
        }
      }
    },
    
    valueContainer: (base, state) => {
      const font = state.getValue()[0]?.value ?? 'Open Sans, sans-serif'
      return {
        ...base,
        fontFamily: styledEachOption && font,
        fontSize: 11
      }
    },
    
    indicatorSeparator: base => {
      return {
        ...base,
        // height: '16px',
        marginBlock: 8,
        borderRadius: 1,
        backgroundColor: '#E2E3E4',
      }
    },
    
    dropdownIndicator: (base) => {
      return {
        ...base,
        svg: {
          color: '#929292',
          width: '16px',
          height: '16px',
          padding: 2.5,
          marginInline: 4,
        },
        padding: 0,
      }
    }
  }
  
  return (
    <Select
      {...props}
      isSearchable={false}
      styles={style}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary75: '#f5df71',
          primary50: '#f7e486',
          primary25: '#f5d63d',
          primary: '#F5D956',
        },
      })}
    />
  );
}

export default Selector;
