import { DataMapper } from 'modules/core/domain/interfaces/DataMapper';
import { SportType } from '../../../core/domain/enums/SportType';
import { mapTeamDTOToTeam } from '../../../tourneys/domain/data-mappers/championship-game-teams-mapper';
import { LatestTournament } from '../interfaces/LatestTournament';
import { Organizer } from '../interfaces/Organizer';
import { OrganizerInfoDTO } from '../interfaces/OrganizerDTO';

export class OrganizerDataMapper implements DataMapper<Organizer, OrganizerInfoDTO> {
  public decode(payload: OrganizerInfoDTO): Organizer {
    const tournament = payload;
    const latestTournaments = tournament.championships
      .sort((a, b) => new Date(b.startDate).valueOf() - new Date(a.startDate).valueOf())
      .slice(0, 5)
      .map((item) => ({
        id: item.id,
        name: item.name,
        startDate: item.startDate,
        endDate: item.endDate,
        // startDate: new Date(item.startDate),
        // endDate: item.endDate ? new Date(item.endDate) : undefined,
      } as LatestTournament));
    
    const logo = tournament.images.find((image) => image.type === 'logo');
    
    return {
      id: tournament.id,
      name: tournament.name,
      sportType: tournament.sportType as SportType,
      logoUrl: logo?.externalUrl,
      email: tournament.email,
      phone: tournament.phone,
      description: tournament.description,
      latestTournaments,
      address: tournament.address ?? {
        addressLine1: '',
        addressLine2: '',
        city: '',
        country: '',
      },
      availableFeatures: {
        MEDIA_MANAGER: false,
        ACTIVE_CHAMPIONSHIPS: false,
        OBS_INTEGRATION: false,
        JOYSTICK: false,
      },
      teams: tournament.teams.map((team) => mapTeamDTOToTeam(team, null)),
    } as Organizer;
  }
  
  public encode(payload: Organizer): OrganizerInfoDTO {
    return {
      id: payload.id,
      name: payload.name,
      sportType: payload.sportType,
      email: payload.email,
      phone: payload.phone,
      description: payload.description,
      championships: payload.latestTournaments.map((item) => ({
        id: item.id,
        name: item.name,
        startDate: item.startDate,
        endDate: item.endDate,
        // startDate: item.startDate.toISOString(),
        // endDate: item.endDate?.toDateString(),
      })),
      images: payload.logoUrl
        ? [{
          type: 'logo',
          externalUrl: payload.logoUrl,
        }]
        : undefined,
      address: {
        addressLine1: payload.address.addressLine1 ? payload.address.addressLine1 : undefined,
        addressLine2: payload.address.addressLine2 ? payload.address.addressLine2 : undefined,
        city: payload.address.city ? payload.address.city : undefined,
        country: payload.address.country ? payload.address.country : undefined,
      },
    } as OrganizerInfoDTO;
  }
}
