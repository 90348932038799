import React from 'react';
import { ColorBlock, ColorIcon, Image, ImageBlock, MainBlock } from "./styles";
import transparentBackground from '../../../../../../../ud-ui/components/images/transparentBackground.jpg';
import UDText from "../../../../../../../ud-ui/components/text";
import { SyncLoader } from "react-spinners";
import { DivProps } from "../../../../../../../../typings";

type IProps = DivProps & {
  color: string
  colorName: string
  img?: string
  isActive?: boolean
}

function BackgroundItem(props: IProps) {
  const { color, colorName, img, isActive, ...rest } = props
  return (
    <MainBlock isActive={isActive} {...rest}>
      <ImageBlock
        color={color === 'transparent' ? undefined : color}
        imgUrl={color === 'transparent' ? transparentBackground : undefined}
      >
        {img ? (
          <Image alt={'лого'} src={img}/>
        ) : (
          <SyncLoader />
        )}
      </ImageBlock>
      <ColorBlock>
        <ColorIcon
          color={color === 'transparent' ? undefined : color}
          imgUrl={color === 'transparent' ? transparentBackground : undefined}
        />
        <UDText type={'tip'} style={{ color: '#8F8E99' }}>{colorName}</UDText>
      </ColorBlock>
    </MainBlock>
  );
}

export default BackgroundItem;
