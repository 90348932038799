import { template } from "../../../ud-ui/components/fabric-canvas/types";

type ISetToLocalStorage = {
  template?: template,
  categoryId?: number,
  templateName?: string,
  userId?: number,
  resolution?: { width: number, height: number }
};
export default function useLocalStorage() {
  
  function clearLocalStorage() {
    localStorage.removeItem('category')
    localStorage.removeItem('templateName')
    localStorage.removeItem('userId')
    localStorage.removeItem('resolution')
    localStorage.removeItem('template')
  }
  
  function setDataToLocalStorage({ template, categoryId, templateName, userId, resolution }: ISetToLocalStorage) {
    template && localStorage.setItem('template', JSON.stringify(template))
    resolution && localStorage.setItem('resolution', JSON.stringify(resolution))
    categoryId && localStorage.setItem('category', categoryId.toString())
    templateName && localStorage.setItem('templateName', templateName)
    userId && localStorage.setItem('userId', userId.toString())
  }
  
  function getDataFromLocalStorage() {
    const categoryId = localStorage.getItem('category')
    const templateName = localStorage.getItem('templateName')
    const userId = localStorage.getItem('userId')
    const resolution = localStorage.getItem('resolution')
    const template = localStorage.getItem('template')
    
    const transformUserId = userId ? Number(userId) : null
    const transformCategoryId = categoryId ? Number(categoryId) : null
    const transformResolution = resolution ? JSON.parse(resolution) : null
    const transformTemplate = template ? JSON.parse(template) : null
    
    return {
      categoryId: transformCategoryId,
      templateName,
      userId: transformUserId,
      resolution: transformResolution,
      template: transformTemplate
    }
  }
  
  return { setDataToLocalStorage, getDataFromLocalStorage, clearLocalStorage }
}
