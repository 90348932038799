import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 120px;
  transition: border 0.5s ease;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.SurfaceCultured10};
  border-width: 1px;
  border-style: dashed;
  border-color: ${({ theme }) => theme.colors.SurfaceRomanSilver30};
  &.error {
    border-color: ${({ theme }) => theme.colors.CaseError};
  }
  &.focused {
    border-color: ${({ theme }) => theme.colors.SurfaceRichBlack60};
  }
  &.active {
    border-color: ${({ theme }) => theme.colors.SurfaceRichBlack60};
  }
`;
