import React from 'react';

interface IProps {
  width?: number;
  height?: number;
  color?: string;
}

export default function AlignTextRightIcon(props: IProps) {
  const {
    width,
    height,
    color = "#242424",
    ...rest
  } = props;
  
  return (
    <svg width={width ?? 18} height={height ?? 18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M2.67871 3.375C2.67871 2.96079 3.0145 2.625 3.42871 2.625H15.4287C15.8429 2.625 16.1787 2.96079 16.1787 3.375C16.1787 3.78921 15.8429 4.125 15.4287 4.125H3.42871C3.0145 4.125 2.67871 3.78921 2.67871 3.375ZM5.67871 14.625C5.67871 14.2108 6.0145 13.875 6.42871 13.875H15.4287C15.8429 13.875 16.1787 14.2108 16.1787 14.625C16.1787 15.0392 15.8429 15.375 15.4287 15.375H6.42871C6.0145 15.375 5.67871 15.0392 5.67871 14.625ZM2.67871 10.875C2.67871 10.4608 3.0145 10.125 3.42871 10.125H15.4287C15.8429 10.125 16.1787 10.4608 16.1787 10.875C16.1787 11.2892 15.8429 11.625 15.4287 11.625H3.42871C3.0145 11.625 2.67871 11.2892 2.67871 10.875ZM5.67871 7.125C5.67871 6.71079 6.0145 6.375 6.42871 6.375H15.4287C15.8429 6.375 16.1787 6.71079 16.1787 7.125C16.1787 7.53921 15.8429 7.875 15.4287 7.875H6.42871C6.0145 7.875 5.67871 7.53921 5.67871 7.125Z"
        fill={color}/>
    </svg>
  );
}
