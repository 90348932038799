import classNames from 'classnames';
import React from 'react';
import { useLocation } from 'react-router-dom';
import * as S from './styles';

export type Breadcrumb = {
  label: string;
  to: string;
};

type Props = {
  breadcrumbs: Breadcrumb[];
  className?: string;
};

const UDBreadcrumbs = (props: Props) => {
  const { breadcrumbs, className } = props;
  const location = useLocation();
  return (
    <div
      className={classNames('d-flex flex-row align-items-center', className)}>
      {breadcrumbs.map((breadcrumb, index) => (
        <S.Breadcrumb
          key={index}
          to={breadcrumb.to}
          className={classNames('text', {
            active: location.pathname === breadcrumb.to,
          })}>
          {breadcrumb.label}
        </S.Breadcrumb>
      ))}
    </div>
  );
};

export default UDBreadcrumbs;
