import React from 'react';

type PauseIconProps = {
  width: number;
  height: number;
  color?: string;
};

const PauseIcon = (props: PauseIconProps) => {
  const {
    width,
    height,
    color = '#F2CA18',
  } = props;

  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 13 14"
      fill="none" 
    >
      <rect
        width="4"
        height={height}
        fill={color}
      />
      <rect
        x="9"
        width="4"
        height={height}
        fill={color}
      />
    </svg>
  );
};

export default PauseIcon;