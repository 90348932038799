import React from 'react';

type IProps = {
  width?: number;
  height?: number;
  color?: string
} & React.SVGProps<SVGSVGElement>;

const TrashIcon = (props: IProps) => {
  const {
    width,
    height,
    color = '#242424',
    ...rest
  } = props;
  
  return (
    <svg width={width ?? 32} height={height ?? 32} viewBox="0 0 32 32" fill="none"
         xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M21.25 12.25L20.5995 21.3569C20.5434 22.1418 19.8903 22.75 19.1033 22.75H12.8967C12.1097 22.75 11.4566 22.1418 11.4005 21.3569L10.75 12.25M14.5 15.25V19.75M17.5 15.25V19.75M18.25 12.25V10C18.25 9.58579 17.9142 9.25 17.5 9.25H14.5C14.0858 9.25 13.75 9.58579 13.75 10V12.25M10 12.25H22"
        stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default TrashIcon;
