import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { Pages } from 'modules/navigation/domain/enums/pages';
import { history, RootState } from 'store/store';
import { AuthRequestAuthCodePayload } from '../domain/interfaces/AuthRequestAuthCodePayload';
import { AuthRestorePayload } from '../domain/interfaces/AuthRestorePayload';
import { AuthSignInPayload } from '../domain/interfaces/AuthSignInPayload';
import { AuthCheckResponse } from '../domain/interfaces/AuthSignInResponse';
import { AuthSignUpPayload } from '../domain/interfaces/AuthSignUpPayload';
import authService from '../domain/servcies/authService';
import { AuthSignUpResponse } from '../domain/interfaces/AuthSignUpResponse';

const PREFIX = 'auth';

const wait = (ms: number) =>
  new Promise((resolve) => {
    setTimeout(() => resolve('ok'), ms);
  });

export const authRestore = createAsyncThunk<void, AuthRestorePayload>(
  `${PREFIX}/authRestore`,
  async () => {
    await wait(3000);
  },
);

export const authSignIn = createAsyncThunk<void, AuthSignInPayload, { state: RootState }>(
  `${PREFIX}/authSignIn`,
  async (payload, { dispatch }) => {
    await authService.signIn(payload);
    dispatch(authCheck(payload.to));
    // const response = await authService.signIn(payload);
    // history.push(Pages.INDEX);
  },
);

export const authRequestAuthCode = createAsyncThunk<void, AuthRequestAuthCodePayload>(
  `${PREFIX}/authRequestAuthCode`,
  async (payload) => {
    await authService.requestAuthCode(payload);
  }
);

export const authSignOut = createAction(`${PREFIX}/authSignOut`, () => {
  authService.signOut();

  history.push(Pages.SIGN_IN);

  return {
    payload: null,
  };
});

export const authSignUp = createAsyncThunk<AuthSignUpResponse, AuthSignUpPayload>(
  `${PREFIX}/authSignUp`,
  async (payload) => {
    return authService.signUp(payload);
  },
);

export const authCheck = createAsyncThunk<AuthCheckResponse | null, string | undefined, { state: RootState }>(
  `${PREFIX}/authCheck`,
  async (continueFrom, thunkAPI) => {
    let result = null;

    try {
      result = await authService.check();

      const id = thunkAPI.getState().auth.activeTournamentId;
      const hasTournament = result?.availableTournaments.some((tournament) => tournament.id === id);

      if (result && !hasTournament) {
        thunkAPI.dispatch(clearActiveTournamentId());
      }
    } catch (err) {
      console.log('error', err);
    }

    if (!result) {
      history.push(Pages.SIGN_IN, { to: continueFrom});
    } else if (result.user) {
      history.push(continueFrom || Pages.INDEX);
    }

    return result;
  },
);

export const clearActiveTournamentId = createAction(`${PREFIX}/clearActiveTournamentId`);

export const switchUserTournament = createAction(`${PREFIX}/switchTournament`, (id: number) => {
  history.push(Pages.INDEX);

  return {
    payload: id,
  };
});
