import { Theme } from '@emotion/react';
import styled from '@emotion/styled';
import Password from 'react-better-password';

const commonStyles = ({ colors }: Theme) => `
background-color: inherit;
border-radius: 8px;
padding: 8px 16px;
border: 1px solid ${colors.SurfaceWhite};
outline: none;
color: ${colors.SurfaceRaisinBlack50};

&.icon-left {
  padding-left: 46px;
}
&.icon-right {
  padding-right: 46px;
}

&::placeholder {
  color: ${colors.SurfaceRomanSilver30};
  font: inherit;
}

&:hover,
&:focus {
  border: 1px solid ${colors.AccentNapplesYellow};
}

&.--error {
  border: 1px solid ${colors.CaseError};
  &::placeholder {
    color: ${colors.CaseError};
  }
  &:focus::placeholder,
  &:hover::placeholder {
    color: ${colors.SurfaceRomanSilver30};
  }
}

&:read-only,
&:disabled,
&:disabled::placeholder {
  color: ${colors.DisabledInputColor};
  user-select: none;
  pointer-events: none;
}`;

export const Input = styled.input`
  ${({ theme }) => commonStyles(theme)}
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  position: relative;
  background: rgba(0,0,0,0);

  &[type='number'] {
    -moz-appearance: textfield;
    padding-right: 24px;
  }
  
  &.input-mask {
    position: absolute;
    top: 0;
    left: 0;
    outline: none;
    visibility: hidden;
    
    &__visible {
      visibility: visible;
    }
  }
`;

export const PasswordInput = styled(Password)`
  ${({ theme }) => commonStyles(theme)}

  &.masked {
    color: ${({ theme }) => theme.colors.AccentNapplesYellow};
  }
`;

export const TextareaInput = styled.textarea`
  ${({ theme }) => commonStyles(theme)}

  & {
    resize: vertical;
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.SurfaceWhite};
  overflow: hidden;

  & .input {
    width: 100%;
  }

  &::after {
    position: absolute;
    top: -10px;
    right: -6px;
    content: '';
    display: none;
    width: 15px;
    height: 30px;
    transform: rotate(-45deg);
  }

  &.error::after {
    display: block;
    background-color: ${({ theme }) => theme.colors.CaseError};
  }

  &.valid::after {
    display: block;
    background-color: ${({ theme }) => theme.colors.CaseCorrect};
  }
`;

export const IconContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  &.icon-left {
    left: 16px;
  }
  &.icon-right {
    right: 16px;
  }
  &.number-controls {
    right: 8px;
  }
`;
