import { TFunction } from 'i18next';
import * as yup from 'yup';
import { AuthRequestAuthCodePayload } from '../interfaces/AuthRequestAuthCodePayload';

export const authRequestCodeValidator = (t: TFunction): yup.SchemaOf<AuthRequestAuthCodePayload> => yup
  .object()
  .shape({
    phone: yup.string()
      .required(t('signIn.validationError.phoneRequired'))
      .matches(/^\+7([0-9]{10})$/, t('signIn.validationError.phoneInvalid')),
  });
