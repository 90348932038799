import styled from '@emotion/styled';
import UDText from '../../../../ud-ui/components/text';

export const CardContainer = styled.div`
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.SurfaceWhite};
  cursor: pointer;
`;

export const StyledTournamentName = styled(UDText)`
  height: 40px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StatusIndicator = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 4px;

  &[data-status='open'] {
    background-color: ${({ theme }) => theme.colors.CaseCorrect};
  }
  &[data-status='close'] {
    background-color: ${({ theme }) => theme.colors.CaseError};
  }
`;
