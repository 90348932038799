import { useCallback, useMemo } from "react";
import { useOrganizer } from "../../../../../organizer/ui/hooks/useOrganizer";
import { useDispatch, useSelector } from "react-redux";
import { selectActiveCategory, selectActiveTemplate } from "../../../../store/selectors";
import supabase from "../../../../../core/supabase/supabase";
import { setTemplate, updateCategory } from "../../../../store/templateEditor";
import useUniversalModal, { IUNModalProps } from "../useUniversalModal";
import _ from "lodash";

function useDeleteModal() {
  const dispatch = useDispatch();
  const { info } = useOrganizer();
  const category = useSelector(selectActiveCategory);
  const templates = useMemo(() => {
    if (!category) return;
    return _(category.templates).toPairs().orderBy((value) => {return value[ 1 ].id}).value().map(value => value[ 0 ]);
  }, [category]);
  const activeTemplate = useSelector(selectActiveTemplate);
  
  const onSubmit = useCallback(() => {
    if (!info) return
    if (!category) return;
    if (!activeTemplate) return;
    
    const filteredTemplates = _.pickBy(category.templates, (_, key) => {
      return activeTemplate !== key
    })
    
    supabase.overlayTemplates.updateRow({ rowId: category.id }, { templates: filteredTemplates })
    dispatch(updateCategory({ name: category.name, templates: filteredTemplates }))
    dispatch(setTemplate({ templateName: templates ? templates[ 0 ] : 'Новый табло' }))
  }, [activeTemplate, category, dispatch, info, templates]);
  
  
  const data: IUNModalProps = useMemo(() => {
    return {
      initialValue: {},
      headerText: 'Удаление табло',
      contentText: 'Вы уверены, что хотите удалить этот табло? Это действие нельзя отменить.',
      buttonText: 'Удалить',
      onSubmit,
    }
  }, [onSubmit]);
  
  const { modal, close, open } = useUniversalModal(data)
  
  return { modal, close, open }
}

export default useDeleteModal;
