type ObserverCallback<T> = (newValue: T, oldValue: T) => void;

export class ObservableField<T> {
  private _value: T;
  private observers: ObserverCallback<T>[] = [];
  
  constructor(initialValue: T) {
    this._value = initialValue;
  }
  
  get value() {
    return this._value;
  }
  
  set value(newValue: T) {
    if (newValue !== this._value) {
      const oldValue = this._value;
      this._value = newValue;
      this.notifyObservers(newValue, oldValue);
    }
  }
  
  subscribe(callback: ObserverCallback<T>) {
    this.observers.push(callback);
  }
  
  unsubscribe(callback: ObserverCallback<T>) {
    this.observers = this.observers.filter(observer => observer !== callback);
  }
  
  private notifyObservers(newValue: T, oldValue: T) {
    this.observers.forEach(observer => observer(newValue, oldValue));
  }
}
