import React from 'react';
import { EmptyButton, Item, LongDivider, ShortDivider, SubItem } from "./components";
import UDIcon from "../../../../../ud-ui/components/icon";
import alignment from "../../../../../ud-ui/components/fabric-canvas/addons/alignment/alignment";

function Group() {
  return (
    <>
      <Item>
        Фигуры
      </Item>
      <ShortDivider/>
      <Item>
        <SubItem style={{ width: '100%', justifyContent: 'space-between' }}>
          <EmptyButton onClick={alignment.group.left}><UDIcon name={'align-left'}/></EmptyButton>
          <EmptyButton onClick={alignment.group.centerVertical}><UDIcon name={'align-center-vertical'}/></EmptyButton>
          <EmptyButton onClick={alignment.group.right}><UDIcon name={'align-right'}/></EmptyButton>
          <EmptyButton onClick={alignment.group.top}><UDIcon name={'align-top'}/></EmptyButton>
          <EmptyButton onClick={alignment.group.centerHorizontal}><UDIcon
            name={'align-center-horizontal'}/></EmptyButton>
          <EmptyButton onClick={alignment.group.bottom}><UDIcon name={'align-bottom'}/></EmptyButton>
        </SubItem>
        <LongDivider/>
      </Item>
    </>
  );
}

export default Group;
