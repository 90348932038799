import React from 'react';

interface IProps {
  height?: string;
  width?: string;
  color?: string;
}

function RoundMinusIcon(props: IProps) {
  const {
    height,
    width,
    color = '#242424'
  } = props
  
  return (
    <svg width={width ?? 18} height={height ?? 18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4 9H14" stroke={color} strokeLinecap="round"/>
    </svg>
  );
}

export default RoundMinusIcon;
