import { Button, EmptyButton, Item, LongDivider, ShortDivider, SubItem } from "./components";
import { useSelector } from "react-redux";
import { selectActiveObjects, selectFabricManager } from "../../../../store/selectors";
import { fabric } from "fabric";
import { round } from "../../../../domain/utils/helpers";
import Input, { IconWrapper } from "../../elements/input";
import { Rect } from "fabric/fabric-impl";
import { useEffect, useState } from "react";
import ArrowRightIcon from "../../../../../ud-ui/components/icon/arrow-right-icon";
import UDIcon from "../../../../../ud-ui/components/icon";
import CornerIcon from "../../../../../ud-ui/components/icon/corner-icon";
import layerManager from "../../../../../ud-ui/components/fabric-canvas/addons/layerManager";
import alignment from "../../../../../ud-ui/components/fabric-canvas/addons/alignment/alignment";

function Image() {
  const activeObjects = useSelector(selectActiveObjects)
  const fabricManager = useSelector(selectFabricManager)
  const object = activeObjects[ 0 ]
  
  const scale = object?.getObjectScaling()
  const isRect = object?.type === 'rect'
  const { width, height, top, left } = object?.getBoundingRect(true) ?? defaultBounds;
  const objectRadius = object?.data?.rx ?? 0
  
  const [position, setPosition] = useState<{ x: number, y: number }>({ x: round(left), y: round(top) })
  const [size, setSize] = useState<{ width: number, height: number }>({ width: round(width), height: round(height) })
  const [radius, setRadius] = useState<number>(objectRadius)
  
  useEffect(() => {
    setPosition({ x: round(left), y: round(top) })
    setSize({ width: round(width), height: round(height) })
    setRadius(objectRadius)
    
    let updatePosition = () => {
      const x = object?.get('left') ?? 0
      const y = object?.get('top') ?? 0
      setPosition({ x: round(x), y: round(y) })
    };
    
    let updateSize = () => {
      const { width, height } = object?.getBoundingRect(true) ?? defaultBounds
      setSize({ width: round(width), height: round(height) })
    };
    
    fabricManager?.canvas.on('mouse:up', updatePosition)
    fabricManager?.canvas.on('mouse:up', updateSize)
    
    return () => {
      fabricManager?.canvas.off('mouse:up', updatePosition)
      fabricManager?.canvas.off('mouse:up', updateSize)
    }
    
    // eslint-disable-next-line
  }, [activeObjects])
  
  const onChangePosition = (x: number, y: number) => {
    object?.setPositionByOrigin(new fabric.Point(x, y), 'left', 'top')
    setPosition({ x, y })
    fabricManager?.canvas.requestRenderAll()
  }
  
  const onChangeSize = (width: number, height: number) => {
    object?.set({ width: width / (scale?.scaleX ?? 1), height: height / (scale?.scaleY ?? 1) })
    setSize({ width, height })
    fabricManager?.canvas.requestRenderAll()
  }
  
  const onChangeRadius = (value: number) => {
    if (isRect) {
      if (!object.scaleX || !object.scaleY) return
      (object as Rect).set({ rx: value * (1 / object.scaleX), ry: value * (1 / object.scaleY) })
      object.data = { ...object.data, rx: value, ry: value }
    }
    setRadius(value)
    fabricManager?.canvas.requestRenderAll()
  }
  
  const onClickAlignmentCenterVertical = () => {
    const newPos = alignment.single.centerVertical(object)
    if (!newPos) return
    setPosition({ x: newPos.left, y: newPos.top })
  }
  
  const onClickAlignmentCenterHorizontal = () => {
    const newPos = alignment.single.centerHorizontal(object)
    if (!newPos) return
    setPosition({ x: newPos.left, y: newPos.top })
  }
  
  return (
    <>
      <Item>
        <div>Изображение</div>
      </Item>
      <ShortDivider/>
      <Item>
        <SubItem style={{ width: '26%', justifyContent: 'space-between' }}>
          <EmptyButton onClick={onClickAlignmentCenterHorizontal}>
            <UDIcon name={'align-center-horizontal'}/>
          </EmptyButton>
          <EmptyButton onClick={onClickAlignmentCenterVertical}>
            <UDIcon name={'align-center-vertical'}/>
          </EmptyButton>
        </SubItem>
        <SubItem>
          <span>Расположение</span>
          <Input
            style={{ width: 73 }}
            icon={<IconWrapper>X</IconWrapper>}
            value={position.x.toString()}
            onChange={(value) => onChangePosition(+value, position.y)}
          />
          <Input
            style={{ width: 73 }}
            icon={<IconWrapper>Y</IconWrapper>}
            value={position.y.toString()}
            onChange={(value) => onChangePosition(position.x, +value)}
          />
        </SubItem>
        <SubItem>
          <span>Размер</span>
          <Input
            icon={<IconWrapper>Ш</IconWrapper>}
            value={size.width.toString()}
            onChange={(value) => onChangeSize(+value, size.height)}
          />
          <Input
            icon={<IconWrapper>В</IconWrapper>}
            value={size.height.toString()}
            onChange={(value) => onChangeSize(size.width, +value)}
          />
        </SubItem>
        {isRect && (
          <SubItem style={{ justifyContent: 'space-between' }}>
            <span>Радиус</span>
            <Input
              icon={<IconWrapper><CornerIcon width={8} height={8}/></IconWrapper>}
              style={{ width: 127 }}
              value={radius.toString()}
              onChange={(value) => {onChangeRadius(+value)}}
            />
          </SubItem>
        )}
      </Item>
      <LongDivider/>
      <Item>
        Слои
        <Button onClick={() => layerManager.sendToFront(object)}>
          <ArrowRightIcon height={16} width={16} color={'#929292'} direction={'up'}/> Поднять вверх
        </Button>
        <Button onClick={() => layerManager.sendToBack(object)}>
          <ArrowRightIcon height={16} width={16} color={'#929292'} direction={'down'}/> Переместить вниз
        </Button>
      </Item>
    </>
  );
}

export default Image;

const defaultBounds = {
  x: 0,
  y: 0,
  width: 0,
  height: 0
}
