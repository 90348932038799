import React from 'react';

type CircleCloseIconProps = {
  width: number;
  height: number;
  color?: string;
};

const CircleCloseIcon = ({ width, height, color = '#F5D956' }: CircleCloseIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_3614_23748"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width={width}
        height={height}
      >
        <rect width={width} height={height} fill="white"/>
      </mask>
      <g mask="url(#mask0_3614_23748)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 12C2 6.47 6.47 2 12 2C17.53 2 22 6.47 22 12C22 17.53 17.53 22 12 22C6.47 22 2 17.53 2 12C2 12 2 6.47 2 12ZM12 10.59L14.59 8L16 9.41L13.41 12L16 14.59L14.59 16L12 13.41L9.41 16L8 14.59L10.59 12L8 9.41L9.41 8L12 10.59Z"
          fill={color}
        />
      </g>
    </svg>

  );
};

export default CircleCloseIcon;
