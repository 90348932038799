import { IDadataArea } from '../../domain';

export function isEqualDadataArea(area1: IDadataArea, area2: IDadataArea): boolean {
  return (
    area1.id === area2.id
    && area1.type === area2.type
    && area1.value === area2.value
    && area1.country === area2.country
    && area1.region === area2.region
    && area1.city === area2.city
    && area1.street === area2.street
    && area1.house === area2.house
    && area1.lat === area2.lat
    && area1.lon === area2.lon
  );
}
