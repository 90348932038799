const geoPin = (
  <>
    <circle cx="8" cy="8" r="6.5" stroke="currentColor" />
    <path
      d="M10.5 7C10.5 7.66498 10.0812 8.55509 9.46171 9.46491C8.99229 10.1543 8.44702 10.799 8 11.2793C7.55298 10.799 7.00771 10.1543 6.53829 9.46491C5.91879 8.55509 5.5 7.66498 5.5 7C5.5 5.61929 6.61929 4.5 8 4.5C9.38071 4.5 10.5 5.61929 10.5 7Z"
      stroke="currentColor"
    />
    <circle cx="8" cy="7" r="0.5" stroke="currentColor" />
  </>
);
export default geoPin;
