import React, { useCallback } from 'react';
import UDMainLayout from 'modules/ud-ui/layout/main';
import TourneyComponentsCreateForm from '../../components/create-form';
import { TourneyEncoded } from 'modules/tourneys/domain/interfaces/TourneyEncoded';
import UDBreadcrumbs from '../../../../ud-ui/components/breadcrumbs';
import { Pages } from "../../../../navigation/domain/enums/pages";

const TourneyPagesCreatePage = () => {
  const onSave = useCallback((_: Partial<TourneyEncoded>) => {}, []);

  const breadcrumbsItems = [{
    label: 'Создание турнира',
    to: Pages.TOURNEYS.CREATE
  }];

  const breadcrumbs = (
    <UDBreadcrumbs
      className="my-10"
      breadcrumbs={breadcrumbsItems}
    />
  );

  return (
    <UDMainLayout headerContent={breadcrumbs}>
      <TourneyComponentsCreateForm onSubmit={onSave} />
    </UDMainLayout>
  );
};

export default TourneyPagesCreatePage;
