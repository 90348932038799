/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import UDText from "../../../../../../../ud-ui/components/text";
import BackgroundItem from "../../elements/backgroundItem";
import { Container, IconContainer, MainBlock } from "./styles";
import EditIcon from "../../elements/editIcon";
import { DivProps } from "../../../../../../../../typings";

type IProps = DivProps & {
  img?: string
  color: string
  colorName: string
}

function LogoView(props: IProps) {
  const { img, color, colorName, onClick, ...rest } = props
  const [isHover, setIsHover] = useState(false)
  return (
    <MainBlock {...rest}>
      <UDText type={'footnote'}>Ваш логотип</UDText>
      <Container
        onClick={onClick}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        style={{ left: -6, borderRadius: 8 }}
      >
        <BackgroundItem
          color={color}
          colorName={colorName}
          img={img}
        />
        {isHover && (
          <IconContainer>
            <EditIcon/>
          </IconContainer>
        )}
      </Container>
    </MainBlock>
  );
}

export default LogoView;
