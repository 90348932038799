import { format, parseISO } from 'date-fns';

export function extractYear(date: Date | string): number {
  if (date instanceof Date) {
    return date.getFullYear()
  }

  const year = format(parseISO(date), 'yyyy');
  return parseInt(year);
}
