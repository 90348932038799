import React, { forwardRef } from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import UDInput, { UDInputProps } from '../../input/component';
import 'react-datepicker/dist/react-datepicker.css';

export type UDDateInputProps =
  Omit<ReactDatePickerProps, 'dateFormat' | 'customInput' | 'placeholderText'>
  & Pick<UDInputProps, 'hasError' | 'valid'>;

const DATE_FORMAT = 'dd.MM.yyyy';

/**
 *
 * https://github.com/date-fns/date-fns/blob/master/docs/upgradeGuide.md#string-arguments
 */
const parseFromString = (value: string | Date | null | undefined) =>  value ? new Date(value) : null;

const UDDateInput = forwardRef<HTMLInputElement, UDDateInputProps>((props: UDDateInputProps, ref) => {
  const { value, hasError, valid, maxDate, minDate, ...datePickerProps } = props;

  return (
    <DatePicker
      selected={parseFromString(value)}
      dateFormat={DATE_FORMAT}
      placeholderText="дд.мм.гггг"
      customInput={
        <UDInput
          ref={ref}
          iconProps={{ name: 'calendar' }}
          hasError={hasError}
          valid={valid}
        />
      }
      {...datePickerProps}
      minDate={parseFromString(minDate)}
      maxDate={parseFromString(maxDate)}
    />
  );
});

export default UDDateInput;
