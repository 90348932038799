import React, { useCallback } from 'react';
import classNames from 'classnames';
import UDColorableIcon from '../icon/colorable-icon';
import UDText from '../text';
import { allowNext, allowPrev } from './helpers';
import getNumberArray from './helpers/getNumberArray';
import * as S from './styles';

export type UDPaginationProps = {
  pages: number;
  currentPage: number;
  onPageChange: (page: number) => any;
  getHref?: (page: number) => string;
  className?: string;
  pagesShow?: number;
  disabled?: boolean;
};

const UDPagination = (props: UDPaginationProps) => {
  const { pages, currentPage, onPageChange, pagesShow = 10, disabled } = props;

  const realOffset = pagesShow - 1;
  const minPage =
    pages - currentPage < realOffset ? pages - realOffset : currentPage;
  const maxPage =
    currentPage + realOffset < pages ? currentPage + realOffset : pages;

  const pagesArray = getNumberArray(minPage < 1 ? 1 : minPage, maxPage);

  const allowPrevPage = allowPrev(currentPage, pages);
  const allowNextPage = allowNext(currentPage, pages);

  const pageChangeHandler: React.MouseEventHandler<HTMLDivElement> =
    useCallback(
      (e) => {
        onPageChange(Number(e.currentTarget.dataset.page));
      },
      [onPageChange]
    );

  return (
    <S.PaginationContainer className={props.className}>
      <S.StyledPagination className={classNames({ disabled: disabled })}>
        <S.PaginationArrow
          key={currentPage - 1}
          data-page={currentPage - 1}
          onClick={pageChangeHandler}
          className={classNames({ disallow: !allowPrevPage })}
        >
          <UDColorableIcon name="left-large" />
        </S.PaginationArrow>
        {pagesArray.map((page) => (
          <S.PaginationItem
            key={`pagination-page-${page}`}
            data-page={page}
            onClick={pageChangeHandler}
            className={classNames({ active: page === currentPage })}
          >
            <UDText type="footnote">{page}</UDText>
          </S.PaginationItem>
        ))}
        <S.PaginationArrow
          key={currentPage + 1}
          data-page={currentPage + 1}
          onClick={pageChangeHandler}
          className={classNames('arrow', { disallow: !allowNextPage })}
        >
          <UDColorableIcon name="right-large" />
        </S.PaginationArrow>
      </S.StyledPagination>
    </S.PaginationContainer>
  );
};

export default UDPagination;
