import { createSlice } from '@reduxjs/toolkit';
import bracketsAdapter from './brackets.adapter';
import { fetchStages, setStageBracketsTeams, upsertStage } from './stages.actions';
import { TournamentBracket } from '../../domain/interfaces/TournamentBracket';

const bracketsSlice = createSlice({
  name: 'brackets',
  initialState: bracketsAdapter.getInitialState(),
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchStages.fulfilled, (state, action) => {
      const brackets = action.payload.reduce<TournamentBracket[]>((acc, stage) => [
        ...acc,
        ...stage.brackets,
      ], []);

      bracketsAdapter.setAll(state, brackets);
    });

    builder.addMatcher(setStageBracketsTeams.fulfilled.match, (state, action) => {
      const brackets = action.payload;
      return bracketsAdapter.upsertMany(state, brackets);
    });

    builder.addMatcher(upsertStage.fulfilled.match, (state, action) => {
      const { id: stageId, brackets } = action.payload;
      const existingBrackets = bracketsAdapter.getSelectors().selectAll(state);
      const stageBracketKeys = existingBrackets
        .filter((b) => b.stageId === stageId)
        .map((b) => b.id);

      const removedStageBracketsState = bracketsAdapter.removeMany(state, stageBracketKeys);
      return bracketsAdapter.upsertMany(removedStageBracketsState, brackets);
    });
  },
});

export default bracketsSlice;
