import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Organizer } from "../domain/interfaces/Organizer";

type OrganizersSliceState = {
  organizers: Organizer[]
  filter?: string
  sortByDate?: string
  filterBySportType?: string
  filterByNumber?: string
};

const initialState: OrganizersSliceState = {
  organizers: [],
  filter: '',
  sortByDate: undefined,
  filterBySportType: undefined,
  filterByNumber: undefined,
};

const slice = createSlice({
  name: 'organizers',
  initialState,
  reducers: {
    setOrganizers: (state, action: PayloadAction<Organizer[]>) => {
      state.organizers = action.payload;
    },
    setFilter: (state, action: PayloadAction<string | undefined>) => {
      state.filter = action.payload;
    },
    setSortByDate: (state, action: PayloadAction<string | undefined>) => {
      state.sortByDate = action.payload;
    },
    setFilterBySportType: (state, action: PayloadAction<string | undefined>) => {
      state.filterBySportType = action.payload;
    },
    setFilterByNumber: (state, action: PayloadAction<string | undefined>) => {
      state.filterByNumber = action.payload;
    },
  },
});

const organizersReducer = slice.reducer;
export const {
  setOrganizers,
  setFilter,
  setSortByDate,
  setFilterBySportType,
  setFilterByNumber,
} = slice.actions;
export default organizersReducer;
