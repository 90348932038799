// BaseModal.js
import React from 'react';
import styled from '@emotion/styled';

interface ModalProps extends React.HTMLAttributes<HTMLDivElement> {
  isOpen: boolean;
}

export default function BaseModal(props: ModalProps) {
  const { isOpen, ...rest } = props;
  
  if (!isOpen) return null;
  
  return (
    <ModalContainer {...rest} />
  );
}

const ModalContainer = styled.div`
    position: absolute;
    background-color: white;
    box-shadow: 0 4px 4px #00000040;
    padding-block: 8px;
    z-index: 100;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
`;
