import styled from "@emotion/styled";

export const ShortDivider = styled.div`
    &:after {
        content: '';
        display: block;
        width: 45px;
        height: 1px;
        background: #242424;
        margin-left: 8px;
    }
`

export const LongDivider = styled.div`
    &:after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background: #E2E3E4;
    }
`

export const Item = styled.div`
    padding: 16px 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
`

export const Grid = styled.div`
    font-size: 11px;
    font-weight: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const SubItem = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;

    font-size: 11px;
    font-weight: inherit;
`

export const Input = styled.input`
    width: 65px;
    height: 24px;
`

export const EmptyButton = styled.button<{centered?: boolean}>`
  border: none;
  background: none;
  cursor: pointer;

  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  text-align: inherit;

  ${(props) => props.centered && `
    display: flex;
    justify-content: center;
    align-items: center;
  `}
`

export const Button = styled(EmptyButton)`
    border-radius: 8px;
    background: #F2F3F4;
    padding: 8px;
    display: flex;
    gap: 8px;

    font-family: Inter, sans-serif;
    font-size: 11px;
    font-weight: 400;
    text-align: left;
`
