export enum SportType {
  football = 'football',
  floorball = 'floorball',
  hockey = 'hockey',
  basketball = 'basketball',
  volleyball = 'volleyball',
  waterpolo = 'water_polo',
  bowling = 'bowling',
  minifootball = 'minifootball',
  tennis = 'tennis',
  tabletennis = 'tabletennis',
  badminton = 'badminton',
  darts = 'darts',
  beachvolleyball = 'beachvolleyball',
  beachfootball = 'beachfootball',
  grasshockey = 'grasshockey',
  counterstrike2 = 'counterstrike2',
  dota2 = 'dota2',
  leagueoflegends = 'leagueoflegends',
}
