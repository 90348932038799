import React from 'react';
import { useTranslation } from 'react-i18next';
import { PlayerRankContainer, PlayerRankWrapper } from './player-rank.styles';
import { SymbolicTeamFormation } from '../../../../../../domain';

type PlayerRankProps = {
  className?: string;
  formation: SymbolicTeamFormation;
  rank: number;
};

export const PlayerRank = ({ className, formation, rank }: PlayerRankProps) => {
  const { t } = useTranslation();

  return (
    <PlayerRankWrapper className={className}>
      <PlayerRankContainer className="text footnote">
        {t(`symbolicTeam.formation_${formation}.rank_${rank}`)}
      </PlayerRankContainer>
    </PlayerRankWrapper>
  )
};
