/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import UDMainLayout from "../../../ud-ui/layout/main";
import { useOrganizer } from "../../../organizer/ui/hooks/useOrganizer";
import { history } from "../../../../store/store";
import { Pages } from "../../../navigation/domain/enums/pages";
import { useDispatch, useSelector } from "react-redux";
import { selectActiveCategory, selectActiveTemplate } from "../../store/selectors";
import { setTemplate } from "../../store/templateEditor";
import ListContainer from "../components/elements/listContainer";
import styled from "@emotion/styled";
import {
  BarItem,
  BaseText,
  Button,
  Divider,
  Header,
  RightPanel,
  Tools
} from "../../../tourneys/ui/components/form-parts/stages/cover-templates/components/simpleComponents";
import ArrowRectangleIcon from "../../../ud-ui/components/icon/arrow-rectangle-icon";
import { EmptyButton } from "../components/editor/rightToolbar/components";
import UDBreadcrumbs from "../../../ud-ui/components/breadcrumbs";
import useFabric from "../../../ud-ui/components/fabric-canvas/hooks/useFabric";
import backgroundElems from "../../../ud-ui/components/fabric-canvas/addons/backgroundElems";
import pattern from "../../domain/images/circlePattern.svg"
import useLocalStorage from "../hooks/useLocalStorage";
import _ from "lodash";
import { css } from "@emotion/react";
import UDText from "../../../ud-ui/components/text";
import PlusIcon from "../../../ud-ui/components/icon/plus-icon";
import NewTrashIcon from "../../../ud-ui/components/icon/new-trash-icon";
import NewEditIcon from "../../../ud-ui/components/icon/new-edit-icon";
import { increaseClickArea, onHoverButton } from "./styles";
import useCreateModal from "../hooks/modals/templates/create";
import useDeleteModal from "../hooks/modals/templates/delete";
import useRenameModal from "../hooks/modals/templates/rename";
import { manual } from "../../domain/consts/urls";

function TemplatesChoosing() {
  const dispatch = useDispatch()
  const category = useSelector(selectActiveCategory)
  const activeTemplate = useSelector(selectActiveTemplate) ?? _.keys(category?.templates)[ 0 ] ?? 'Новое табло'
  const { info } = useOrganizer()
  const [isInit, setInit] = useState<boolean>(false)
  const { canvas, manager } = useFabric('static', { data: { ...category?.resolution }, style: { borderRadius: 8 } })
  const { clearLocalStorage } = useLocalStorage()
  clearLocalStorage()
  const templates = useMemo(() => {
    if (!category) return
    return _(category.templates).toPairs().orderBy((value) => {return value[ 1 ].id}).value().map(value => value[ 0 ])
  }, [category])
  const [hoverElem, setHoverElem] = useState<string>()
  
  if (!category) {
    history.push(Pages.TEMPLATE.INDEX)
  }
  
  const isEditable = useMemo(() => {
    if (!category) return false
    const isDev = process.env.REACT_APP_ENVIRONMENT !== 'production'
    if (isDev) return true
    return category?.name !== 'ImGame (стандарт)'
  }, [category]);
  
  useEffect(() => {
    if (!manager) return
    backgroundElems.init(manager.canvas)
    
    const resizeCanvas = () => {
      manager.setParentSize()
      manager.normalizedViewport()
    }
    
    window.addEventListener('resize', resizeCanvas, false);
    resizeCanvas()
    setInit(true)
  }, [manager]);
  
  useEffect(() => {
    if (!category) return;
    if (!activeTemplate) return
    if (!manager) return
    if (!info) return;
    
    const template = category.templates[ activeTemplate ]
    if (!template) {
      manager.clear(true)
      return
    }
    manager.loadLocal({ template, safeBackground: true })
  }, [activeTemplate, category, info, manager])
  
  const handleChangeOverlay = (value: string) => {
    if (!value) return
    dispatch(setTemplate({ templateName: value }))
  }
  
  function onClickFarther() {
    if (!isInit) return
    if (!activeTemplate) return
    if (!isEditable) return;
    
    handleChangeOverlay(activeTemplate)
    history.push(Pages.TEMPLATE.EDITOR)
  }
  
  const breadcrumbsItems = [
    { label: 'Турниры', to: Pages.TOURNEYS.INDEX },
    { label: 'Шаблоны', to: Pages.TEMPLATE.INDEX },
    { label: category?.name ?? 'Табло', to: Pages.TEMPLATE.CHOOSING },
  ];
  
  const breadcrumb = <UDBreadcrumbs className="my-10" breadcrumbs={breadcrumbsItems}/>
  
  const templatesList = templates?.map(templateName => {
    const template = templateName
    const onClick = () => {
      handleChangeOverlay(template)
    }
    const isActive = template === activeTemplate
    const isHover = template === hoverElem
    const isLast = templates?.length === 1
    
    const onEditClick = () => {
      handleChangeOverlay(template)
      openRenameModal()
    }
    
    const onDeleteClick = () => {
      handleChangeOverlay(template)
      openDeleteModal()
    }
    
    return (
      <TemplateItem
        onClick={onClick}
        key={template}
        onMouseEnter={() => setHoverElem(template)}
        onMouseLeave={() => setHoverElem(undefined)}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: isActive ? '#9093AA' : 'transparent',
          color: isActive ? '#FFFFFF' : '#1A1A29',
        }}
      >
        <span>{template}</span>
        {isEditable && isHover && (
          <div style={{ display: 'flex', gap: 18, justifyContent: 'center', alignItems: 'center' }}>
            <EmptyButton css={increaseClickArea} className={'flex-center'} onClick={onEditClick}>
              <NewEditIcon width={24} height={24} color={isActive ? '#FFFFFF' : '#1A1A29'}/>
            </EmptyButton>
            {!isLast && <EmptyButton css={increaseClickArea} className={'flex-center'} onClick={onDeleteClick}>
              <NewTrashIcon width={24} height={24} color={isActive ? '#FFFFFF' : '#1A1A29'}/>
            </EmptyButton>}
          </div>
        )}
      </TemplateItem>
    )
  })
  
  const {
    modal: renameModal,
    open: openRenameModal
  } = useRenameModal()
  
  const {
    modal: createModal,
    open: openCreateModal
  } = useCreateModal()
  
  const {
    modal: deleteModal,
    open: openDeleteModal
  } = useDeleteModal()
  
  const redirectToManual = useCallback(() => {
    window.open(manual, '_blank');
  }, []);
  
  return (
    <UDMainLayout headerContent={breadcrumb}>
      <MainBlock>
        <div style={{
          width: 260,
          minWidth: 260,
          display: "flex",
          flexDirection: "column",
          gap: 20,
          alignItems: "center"
        }}>
          <ListContainer
            contentProps={{ className: 'py-2', css: listContainerCss }}
            headerProps={{ title: 'Табло', style: { background: '#EAEAEC', color: '#1A1A29' }, }}
            style={{ height: 'unset', width: '100%' }}
          >
            {templatesList}
          </ListContainer>
          <EmptyButton onClick={openCreateModal} css={AddNewTemplateStyle}>
            <PlusIcon color={'#A2A2A2'}/>
            <UDText style={{ color: '#A2A2A2' }} type={'subhead'}>Добавить табло</UDText>
          </EmptyButton>
        </div>
        <RightPanel style={{ height: '100%', width: 'calc(100% - 276px)' }}>
          <Header>
            <BarItem>
              <BaseText
                size={'medium'}
                style={{ fontWeight: 'normal' }}
              >
                {activeTemplate}
              </BaseText>
              <Tools>
                <Button
                  disabled={!isEditable}
                  style={{ cursor: isEditable ? 'pointer' : 'not-allowed' }}
                  onClick={onClickFarther}
                  css={onHoverButton}
                >
                  <NewEditIcon width={24} height={24} color={isEditable ? undefined : '#D0D0D0'}/>
                  <UDText style={{ color: 'inherit' }} type={'subhead'}>Редактировать</UDText>
                </Button>
              </Tools>
            </BarItem>
            <Divider/>
            <BarItem>
              <EmptyButton onClick={redirectToManual} className={'d-flex'}>
                <BaseText size={'small'} css={onHoverButton}>
                  <ArrowRectangleIcon width={16} height={16} className={'mr-2'}/>
                  Интсрукция
                </BaseText>
              </EmptyButton>
            </BarItem>
          </Header>
          <CanvasWrapper>
            {canvas}
          </CanvasWrapper>
        </RightPanel>
      </MainBlock>
      {createModal}
      {renameModal}
      {deleteModal}
    </UDMainLayout>
  );
}

export default TemplatesChoosing;

const MainBlock = styled.div`
  margin-top: 60px;
  margin-inline: 24px;
  display: flex;
  gap: 16px;
  
  position: relative;
  
  height: calc(100% - 70px - 70px);
`

const TemplateItem = styled.div`
  width: 100%;
  max-width: 100%;
  border-radius: 8px;
  padding-inline: 16px;
  padding-block: 8px;
  background-color: transparent;
  height: 38px;
  
  font-family: SF Pro Text, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  
  span {
    width: 100%;
    max-width: 100%;
    
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const CanvasWrapper = styled.div`
  width: 100%;
  height: 100%;
  
  background: #ffffff url(${pattern}) no-repeat center;
  border-radius: 8px;
  background-size: cover;
`

const AddNewTemplateStyle = css`
  display: flex;
  flex-direction: row;
  gap: 10px;
  
  justify-content: center;
  align-items: center;
`

const listContainerCss = css`
  max-height: 400px;
  overflow-y: auto;
  
  &::-webkit-scrollbar {
    width: 4px;
    background-color: #fcfcfc;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: #2a2929;
    border-radius: 8px;
  }
`
