import styled from '@emotion/styled';

export const StyledBracketContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  
  &:first-child {
    margin-top: 0;
  }
  
  .teams-info {
    width: 100%;

    .team-info-row {
      position: relative;
      background: #FFFFFF;
      border-radius: 4px;
      padding: 10px;
      
      &:first-child {
        margin-bottom: 2px;
      }
      
      &:before {
        position: absolute;
        content: '';
        display: inline-block;
        width: 42px;
        /* height: 100%; */
        left: 0;
        top: 0;
        background: #EAEAEC;
        border-right: 23px solid white;
        border-top: 58px solid #EAEAEC;
        z-index: 0;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
    }
  }
  
  .bracket-link {
    height: 1px;
    width: 10%;
    background: #8F8E99;
  }
`;

export const StyledRoundBrackets = styled.div`
  flex: 1;
  
  .bracket-match {
    margin: 16px 25px;
    
    &.hasChild {
      &:after {
        content: "";
        border-color: #b9b9b9c9;
        border-width: 2px;
        position: absolute;
        display: block;
        width: 25px;
        right: -27px;
        height: 100%;
      }

      &:nth-of-type(odd) {
        &:after {
          border-right-style: solid;
          border-top-style: solid;
          top: 50%;
        }
      }
      
      &:nth-of-type(even) {
        &:after {
          border-right-style: solid;
          border-bottom-style: solid;
          top: -50%;
        }
      }
    }
    
    &.hasParent {
      &:before {
        content: '';
        border-top: 2px solid #b9b9b9c9;
        position: absolute;
        height: 2px;
        width: 25px;
        left: -24px;
        top: 50%;
      }
    }
  }
  
  &.round-1 {
    .bracket-match {
      margin-left: 0;
    }
  }
  
  &.round-2 {
    .bracket-match {
      &.hasChild {
        &:after {
          height: 200%;
        }

        &:nth-of-type(even) {
          &:after {
            top: -150%;
          }
        }
      }
    }
  }

  &.round-3 {
    .bracket-match {
      &.hasChild {
        &:after {
          height: 350%;
        }

        &:nth-of-type(even) {
          &:after {
            top: -300%;
          }
        }
      }
    }
  }
`;
