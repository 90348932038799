import React from 'react';
import { SymbolicTeamFormation, SymbolicTeamPlayer } from '../../../../domain';
import { FootballField } from './team-formation.styles';
import { PlayerCard } from './player-card';

type TeamFormationProps = {
  formation: SymbolicTeamFormation;
  players: SymbolicTeamPlayer[];
  selectedRank: number | null;
  onRankSelect?: (rank: number) => void;
};

export const TeamFormation = ({ formation, players, selectedRank, onRankSelect }: TeamFormationProps) => {
  return (
    <FootballField className={`formation-${formation}`}>
      {players.map((player) => (
        <PlayerCard
          className={`player-card player-${player.rank}`}
          key={player.rank}
          formation={formation}
          player={player}
          isRankActive={selectedRank === player.rank}
          onSelect={onRankSelect}
        />
      ))}
    </FootballField>
  );
};
