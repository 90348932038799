import axios, { AxiosInstance } from 'axios';

const isProd = process.env.REACT_APP_ENVIRONMENT === 'production';
const defaultHeaders = {
  'x-api-env': isProd ? 'prod' : 'dev',
};

export class HttpResource {
  private readonly instance: AxiosInstance;

  constructor(baseUrl: string) {
    this.instance = axios.create({
      baseURL: baseUrl,
      headers: defaultHeaders,
    });
  }

  get client(): AxiosInstance {
    return this.instance;
  }

  public setHeaders(headers: Record<string, string>): void {
    this.client.defaults.headers.common = {
      ...headers,
      ...defaultHeaders,
    };
  }

  public clearHeaders(): void {
    this.client.defaults.headers.common = defaultHeaders;
  }
}

const httpResource = new HttpResource('/api');

export default httpResource;
