import { useCallback, useMemo, useState } from "react";
import { useOrganizer } from "../../../../../organizer/ui/hooks/useOrganizer";
import categoryNameOnCreateValidator from "../../../../domain/validators/categoryName.onCreate.validator";
import { useDispatch, useSelector } from "react-redux";
import { selectCategories } from "../../../../store/selectors";
import supabase from "../../../../../core/supabase/supabase";
import { Category } from "../../../../domain/interface/category";
import { setCategories } from "../../../../store/templateEditor";
import useUniversalModal, { IUNModalProps } from "../useUniversalModal";
import SizeInputs from "./elements/size/sizeInputs";
import widthValidator from "../../../../domain/validators/width.validator";
import heightValidator from "../../../../domain/validators/height.validator";

function useCreateModal() {
  const dispatch = useDispatch()
  const { info } = useOrganizer()
  const [name, setName] = useState('')
  const categories = useSelector(selectCategories)
  
  const validator = useCallback((values: { [ p: string ]: string }) => {
    const errors: { [ p: string ]: string | undefined } = {}
    
    const nameError = categoryNameOnCreateValidator(values.name?.trim(), categories?.map(category => category.name))
    if (nameError) {errors.name = nameError}
    const widthError = widthValidator(values.width)
    if (widthError) {errors.width = widthError}
    const heightError = heightValidator(values.height)
    if (heightError) {errors.height = heightError}
    
    return errors
  }, [categories]);
  
  const onSubmit = useCallback((values: { [ p: string ]: string }) => {
    if (!info) {
      console.warn({ event: 'failed to create category: info is undefined', info })
      return
    }
    
    const { width, height, name } = values
    
    const templates = {
      "Новое табло": {
        "version": "5.3.0",
        "objects": [],
        "id": Date.now().toString(),
        createdAt: new Date().toISOString()
      }
    };
    
    supabase.overlayTemplates.insertRow({ userId: info.id, category: name.trim(), templates })
    const newRow: Category = {
      name: name.trim(),
      id: categories?.length ?? 0,
      created_at: new Date().toISOString(),
      resolution: { width: +width, height: +height },
      templates: templates,
      userId: info.id,
    }
    dispatch(setCategories([...categories ?? [], newRow]))
    setName('')
  }, [categories, name, dispatch, info]);
  
  const size = useMemo(() => <SizeInputs/>, [])
  
  const data: IUNModalProps = useMemo(() => {
    return {
      onSubmit,
      headerText: 'Новый шаблон',
      validator,
      initialValue: {
        width: '1920',
        height: '1080',
        name: '',
      },
      buttonText: 'Создать',
      input: {
        placeholder: 'Название шаблона'
      },
      additionalComponent: size,
    }
  }, [name, onSubmit, size, validator]);
  
  const { modal, close, open } = useUniversalModal(data)
  
  return { modal, close, open }
}

export default useCreateModal;
