import React, { ChangeEvent } from 'react';

import { MainBlock } from "./styles";
import UDInput from "../../../../ud-form/components/input/component";
import HeaderItem from "../../components/header/elements/headerItem";
import { DivProps } from "../../../../../typings";

type IProps = Omit<DivProps, 'onChange'> & {
  headerText: string
  value?: string
  onChange?: (value: string) => void
  parser?: (value: string) => string
  placeholder?: string
}

function SearchInput(props: IProps) {
  const { headerText, value, onChange, placeholder, parser, ...rest } = props;
  
  const onChangeComponent = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    onChange?.(value)
  };
  
  return (
    <MainBlock {...rest}>
      <HeaderItem header={{text: headerText }}>
        <UDInput
          onChange={onChangeComponent}
          value={value}
          iconProps={{ position: 'icon-left', name: 'search', size: 20, componentProps: { color: '#8F8E99' } }}
          placeholder={placeholder}
          style={{ height: 36, width: '100%' }}
          parser={parser}
        />
      </HeaderItem>
    </MainBlock>
  );
}

export default SearchInput;
