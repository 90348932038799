export enum TeamPlayersNumber {
  TWO = 2,
  THREE = 3,
  FOUR = 4,
  FIVE = 5,
  SIX,
  SEVEN,
  EIGHT,
  NINE,
  TEN,
  ELEVEN
}
