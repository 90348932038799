import React from 'react';
import { MainBlock } from "./styles";
import Icon from "../../../../../../../ud-ui/components/icon/edit-icon";
import { DivProps } from "../../../../../../../../typings";

function EditIcon(props: DivProps) {
  return (
    <MainBlock {...props}>
      <Icon width={16} height={16}/>
    </MainBlock>
  );
}

export default EditIcon;
