import { Game, GameTeam } from '../../../games/domain/interfaces/Game';
import { TeamPlayer } from '../../../teams/domain/interfaces/TeamPlayer';
import { GameDTO, TeamMatchDTO } from '../../../games/domain/interfaces/GameDTO';
import { ChampionshipSettingDTO } from '../interfaces/ChampionshipSettingDTO';
import { ChampionshipSettings, ChampionshipSettingsType } from '../interfaces/ChampionshipSettings';
import { SportType } from '../../../core/domain/enums/SportType';
import { TeamDTO } from '../../../teams/domain/dtos/Team.dto';
import { format } from 'date-fns';
import { TeamPlayersNumber } from '../../../../shared/types/team-players-number';

const mapPlayerPosition = (position?: string) => {
  const positions = ['defender', 'forward', 'goalkeeper', 'midfielder'];
  
  if (positions.includes(position || '')) {
    return position;
  }
  
  return 'midfielder';
}

export const mapTeamDTOToTeam = (dto: TeamDTO, score: number | null): GameTeam => ({
  id: dto.id,
  name: dto.name,
  logo: dto.emblem
    ? {
      id: dto.emblem.id,
      url: dto.emblem.externalUrl,
    }
    : undefined,
  createdAt: dto.createdAt ? format(new Date(dto.createdAt), 'dd.MM.yyyy') : undefined,
  players: dto.members
    ? dto.members.map((member) => ({
      id: member.id,
      userId: member.user.id,
      firstName: member.user.name,
      lastName: member.user.surname,
      middleName: member.user.middleName,
      birthDate: member.user.birthDate,
      position: mapPlayerPosition(member.position),
      number: member.number ?? 99,
      avatar: member.user.photo
        ? member.user.photo!.externalUrl
        : 'https://www.iconexperience.com/_img/o_collection_png/green_dark_grey/512x512/plain/user.png',
    } as TeamPlayer))
    : [],
  score,
});

export const mapGameTeams = (
  gameId: number,
  teams: [TeamDTO | null, TeamDTO | null],
  teamMatches: [TeamMatchDTO, TeamMatchDTO],
): [GameTeam | null, GameTeam | null] => {
  const mappedTeams = teamMatches.map((teamMatch) => {
    if (!teamMatch.team) {
      return null;
    }
    
    const team = teams.find((t) => t && t.id === teamMatch.team!.id);
    if (!team) {
      return null;
    }
    
    return mapTeamDTOToTeam(team, teamMatch.score);
  });
  
  return [mappedTeams[ 0 ], mappedTeams[ 1 ]];
};

export const mapGameDTOToGame = (match: GameDTO): Game => {
  return {
    id: match.id,
    sportType: match.sportType as SportType,
    date: new Date(match.date),
    address: '',
    teams: mapGameTeams(match.id, match.teams, match.teamMatches),
    hasResults: match.teamMatches.some((result) => result.score !== null),
    round: match.round
      ? {
        id: match.round.id,
        name: match.round.name,
        stageId: match.round.stage.id,
      }
      : undefined,
  };
}

function findSettingValue(key: ChampionshipSettingsType, settings: ChampionshipSettingDTO[]): string | null {
  const setting = settings.find(({ name }) => name === key);
  return setting && setting.value !== 'null' ? setting.value : null;
}

function parseString(value: string | null): string | null {
  return value || null;
}

function parseNumber(value: string | null): number | null {
  return value ? Number(value) : null;
}

function parseBoolean(value: string | null): boolean {
  return !!value && value === 'true';
}

export const mapChampionshipSettings = (settings?: ChampionshipSettingDTO[] | null): ChampionshipSettings => {
  const map = settings || [];
  const rankingKeys = [
    ChampionshipSettingsType.RANKING_POINTS_PERSONAL_MEETINGS,
    ChampionshipSettingsType.RANKING_WINS_PERSONAL_MEETINGS,
    ChampionshipSettingsType.RANKING_SCORED_CONCEDED_DIFF_PERSONAL_MEETINGS,
    ChampionshipSettingsType.RANKING_SCORED_GOALS_PERSONAL_MEETINGS,
    ChampionshipSettingsType.RANKING_SCORED_CONCEDED_DIFF_TOTAL,
    ChampionshipSettingsType.RANKING_SCORED_GOALS_TOTAL,
    ChampionshipSettingsType.RANKING_WINS_TOTAL,
  ];
  
  const ranking = rankingKeys
    .map((key, index) => {
      const settingValue = findSettingValue(key, map);
      const value = settingValue ? parseNumber(settingValue) : null;
      
      return {
        name: key as string,
        value: value ?? index,
      }
    })
    .sort((a, b) => a.value - b.value);
  
  const teamPlayersNumber = parseNumber(findSettingValue(ChampionshipSettingsType.TEAM_PLAYERS_NUMBER, map));
  
  return {
    teamPlayersNumber: teamPlayersNumber || TeamPlayersNumber.FIVE,
    
    applicationStartDate: parseString(findSettingValue(ChampionshipSettingsType.APPLICATION_START_DATE, map)),
    applicationEndDate: parseString(findSettingValue(ChampionshipSettingsType.APPLICATION_END_DATE, map)),
    applicationMinPlayers: parseNumber(findSettingValue(ChampionshipSettingsType.APPLICATION_MIN_PLAYERS, map)),
    applicationMaxPlayers: parseNumber(findSettingValue(ChampionshipSettingsType.APPLICATION_MAX_PLAYERS, map)),
    applicationTransfersAllowed: parseBoolean(findSettingValue(ChampionshipSettingsType.APPLICATION_TRANSFERS_ALLOWED, map)),
    participationFee: parseNumber(findSettingValue(ChampionshipSettingsType.PARTICIPATION_FEE, map)),
    
    winScore: parseNumber(findSettingValue(ChampionshipSettingsType.WIN_SCORE, map)),
    winScoreExtraTime: parseNumber(findSettingValue(ChampionshipSettingsType.WIN_SCORE_EXTRA_TIME, map)),
    winPenaltySeries: parseNumber(findSettingValue(ChampionshipSettingsType.WIN_PENALTY_SERIES, map)),
    
    lossScore: parseNumber(findSettingValue(ChampionshipSettingsType.LOSS_SCORE, map)),
    lossScoreExtraTime: parseNumber(findSettingValue(ChampionshipSettingsType.LOSS_SCORE_EXTRA_TIME, map)),
    lossPenaltySeries: parseNumber(findSettingValue(ChampionshipSettingsType.LOSS_PENALTY_SERIES, map)),
    technicalDefeatGoalsCount: parseBoolean(findSettingValue(ChampionshipSettingsType.TECHNICAL_DEFEAT_GOALS_COUNT, map)),
    
    drawScore: parseNumber(findSettingValue(ChampionshipSettingsType.DRAW_SCORE, map)),
    
    gamePeriodsNumber: parseNumber(findSettingValue(ChampionshipSettingsType.GAME_PERIODS_NUMBER, map)),
    periodTime: parseNumber(findSettingValue(ChampionshipSettingsType.PERIOD_TIME, map)),
    gameExtraPeriodsNumber: parseNumber(findSettingValue(ChampionshipSettingsType.GAME_EXTRA_PERIODS_NUMBER, map)),
    periodExtraTime: parseNumber(findSettingValue(ChampionshipSettingsType.PERIOD_EXTRA_TIME, map)),
    
    ranking,
    overlay: parseString(findSettingValue(ChampionshipSettingsType.OVERLAY, map)),
    overlayId: parseNumber(findSettingValue(ChampionshipSettingsType.OVERLAY_ID, map)),
  };
};

function camelToUnderscore(key: string): string {
  const result = key.replace(/([A-Z])/g, " $1");
  return result.split(' ').join('_').toLowerCase();
}

export const mapChampionshipSettingsToDTO = (settings: ChampionshipSettings): Array<{
  name: string,
  value: string
}> => {
  const { ranking = [], ...otherSettings } = settings;
  const settingsObj = otherSettings as any;
  
  const result = Object.keys(otherSettings)
    .filter((propertyName) => settingsObj[ propertyName ] !== null)
    .map((propertyName) => {
      const name = camelToUnderscore(propertyName);
      const value = settingsObj[ propertyName ];
      
      return {
        name,
        value: (value instanceof Date) ? value.toISOString() : String(value),
      };
    });
  
  ranking.forEach((item, index) => {
    result.push({
      name: item.name,
      value: String(index),
    });
  });
  
  return result;
}
