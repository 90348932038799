import styled from "@emotion/styled";

export const MainBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const Header = styled.div`

`
