import React from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@emotion/react';
import { ToastContainer, Slide } from 'react-toastify';
import { theme } from './styles/theme/theme';
import { store, history } from './store/store';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
import AppRouter from './modules/navigation/router';
import { QueryClient, QueryClientProvider } from 'react-query';
import { QueryParamProvider } from 'use-query-params';
import RouteAdapter from 'modules/navigation/utils/route-adapter';
// global styles start
import 'react-toastify/dist/ReactToastify.css';
import './styles/styles.scss';
// global styles end
// React date picker locale setup
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import ru from 'date-fns/locale/ru';
registerLocale('ru', ru);
setDefaultLocale('ru');
// React date picker locale setup end

const queryClient = new QueryClient();

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <Router history={history}>
          <QueryClientProvider client={queryClient} contextSharing={true}>
            <QueryParamProvider ReactRouterRoute={RouteAdapter}>
              <AppRouter />
            </QueryParamProvider>
          </QueryClientProvider>
        </Router>
      </Provider>
      <ToastContainer
        autoClose={3000}
        theme='colored'
        transition={Slide}
      />
    </ThemeProvider>
  );
}

export default App;
