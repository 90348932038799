import { useSelector } from 'react-redux';
import {
  championshipDataSelector,
  championshipIsLoadedSelector,
  championshipIsLoadingSelector,
} from '../../store/championship/selectors';

export const useChampionship = () => {
  const isLoading = useSelector(championshipIsLoadingSelector);
  const isLoaded = useSelector(championshipIsLoadedSelector);
  const data = useSelector(championshipDataSelector);

  return {
    isLoading,
    isLoaded,
    data,
  };
};
