import styled from '@emotion/styled';
import React from 'react';
import { useSelector } from 'react-redux';
import UDIcon from '../../../../ud-ui/components/icon';
import { selectGameInfo } from '../../../store/selectors';
import Block from '../components/block';
import { Separator } from '../components/separator';
import Text from '../components/text';

export function TeamsName() {
  const gameInfo = useSelector(selectGameInfo);
  
  return <div className={'d-flex'}>
    <Separator colorDown={'#FFF'}/>
    <Block className={'flex-center'} style={{gap: 4, height: 60, width: '100%', paddingInline: 8}}>
      <TeamName className={'flex-center'}>
        <Text color={'#FFF'} size={'large'} style={{maxWidth: '100%'}}>{gameInfo?.teams?.firstTeam?.name}</Text>
      </TeamName>
      <UDIcon componentProps={{style: {flexGrow: 2, flexBasis: 0}}} name={'cyclic-arrow'}/>
      <TeamName className={'flex-center'}>
        <Text color={'#FFF'} size={'large'} style={{maxWidth: '100%'}}>{gameInfo?.teams?.secondTeam?.name}</Text>
      </TeamName>
    </Block>
    <Separator colorDown={'#FFF'}/>
  </div>;
}

const TeamName = styled.div`
    flex-grow: 48;
    flex-basis: 0;
    overflow: hidden;
    max-width: 100%;
`;
