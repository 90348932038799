import React, { ReactNode } from 'react';
import { Team } from 'modules/teams/domain/interfaces/Team';
import { CardContainer } from './styles';
import TeamCardHeader from './team-card-header';
import { TeamPlayer } from '../../../domain/interfaces/TeamPlayer';
import { DivProps } from "../../../../../typings";

type Props = {
  team: Team;
  containerProps?: Omit<DivProps, 'children'>;
  actions?: JSX.Element;
  isSelectable?: boolean;
  selectedPlayerId?: number;
  onPlayerSelect?: (player: TeamPlayer, team: Team) => void;
  children?: ReactNode;
  index?: number;
};

const TeamsComponentsTeamCard = (props: Props) => {
  const { team, containerProps, actions, index } = props;

  return (
    <CardContainer className="mb-4 last-mb-0">
      <TeamCardHeader
        team={team}
        containerProps={containerProps}
        actions={actions}
        index={index}
      />
    </CardContainer>
  );
};

export default TeamsComponentsTeamCard;
