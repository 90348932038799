import React, { useCallback, useMemo } from 'react';
import UDHeaderTitle from '../../../ud-ui/components/header-title';
import UDMainLayout from '../../../ud-ui/layout/main';
import UDButton from 'modules/ud-ui/components/button';
import UDIcon from 'modules/ud-ui/components/icon';
import { useNavigate } from 'react-router-dom';
import { Pages } from '../../../navigation/domain/enums/pages';
import { useTeamList } from 'modules/main/ui/hooks/useTeamsList';
import MainComponentsTeamFilters from 'modules/main/ui/components/team-filters';
import { TeamBodySkeleton } from 'modules/main/ui/components/teams-body/teams-body-skeleton';
import { TeamsBody } from 'modules/main/ui/components/teams-body/teams-body';

const TeamsPage = () => {
  const navigate = useNavigate();
  const handleCreateClick = useCallback(() => {
    navigate(Pages.TEAMS.CREATE, { replace: true });
  }, [navigate]);

  const { teams, filters, changeFilter, isLoading, pages, teamsPerPage } =
    useTeamList();

  const bodyNode = useMemo(() => {
    if (isLoading) {
      return <TeamBodySkeleton resultsOnPage={teamsPerPage} />;
    }

    return (
      <div className="px-6">
        <TeamsBody
          filters={filters}
          teamsPerPage={teamsPerPage}
          teams={teams}
          pages={pages}
          onPageChange={(page) => changeFilter({ page })}
        />
      </div>
    );
  }, [isLoading, changeFilter, filters, pages, teams, teamsPerPage]);

  return (
    <UDMainLayout
      headerContent={
        <>
          <UDHeaderTitle>Команды</UDHeaderTitle>
          <div className="d-flex justify-content-end">
            <UDButton
              className="d-flex align-items-center ml-6"
              variant="dark"
              onClick={handleCreateClick}
            >
              <UDIcon name="plus" componentProps={{ className: 'mr-2' }} />
              <span>Добавить команду</span>
            </UDButton>
          </div>
        </>
      }
    >
      <MainComponentsTeamFilters
        onFilter={changeFilter}
        filters={filters}
        teamsPerPage={teamsPerPage}
      />
      {bodyNode}
    </UDMainLayout>
  );
};

export default TeamsPage;
