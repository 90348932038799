import styled from "@emotion/styled";

export const MainBlock = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  
  padding-inline: 24px;
  height: 64px;
`
