import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { OnChangeValue } from 'react-select';
import { format, differenceInYears } from 'date-fns';
import { PlayerApplication } from '../../../domain/interfaces/PlayerApplication';
import UDText from '../../../../ud-ui/components/text';
import { TeamPlayerAvatar } from '../team-card/styles';
import BorderedInput from '../bordered-input';
import PositionSelect from '../position-select';
import StyledSelect from '../../styled-select';
import {
  championshipPlayerApplicationChangeNumber,
  championshipPlayerApplicationChangePosition,
  upsertChampionshipPlayerApplication,
} from '../../../store/championshipTeams/actions';
import { ApplicationStatus } from '../../../../applications/domain/enums/ApplicationStatus';
import { SelectOption } from '../../../../ud-form/components/select/component';
import { PlayerPosition } from '../../../domain/enums/PlayerPosition';

const defaultAvatar =
  'https://www.iconexperience.com/_img/o_collection_png/green_dark_grey/512x512/plain/user.png';

const CardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 1px rgba(26, 26, 41, 0.08), 0 8px 16px rgba(26, 26, 41, 0.08);
  margin-bottom: 16px;
`;

type TeamPlayerCardProps = {
  teamId: number;
  index: number;
  player: PlayerApplication;
  isUpdating?: boolean;
};

const TeamPlayerCard = ({ index, player, isUpdating }: TeamPlayerCardProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [data, setData] = useState(player);

  const handleChange = useCallback(
    (option) => {
      dispatch(
        upsertChampionshipPlayerApplication({
          id: data.id,
          teamId: data.teamId,
          championshipId: data.championshipId,
          state: option.value.toUpperCase(),
          createUserOrUpdateApplication: {
            userId: data.player.id,
            name: data.player.firstName,
            surname: data.player.lastName,
            middleName: data.player.middleName,
            birthDate: data.player.birthDate,
            position: data.position ? data.position.toUpperCase() : data.position,
            number: data.number,
          },
        })
      );
    },
    [dispatch, data]
  );

  const name = [
    data.player.lastName,
    data.player.firstName,
    data.player.middleName,
  ]
    .filter(Boolean)
    .join(' ');

  const birthDate = data.player.birthDate
    ? new Date(data.player.birthDate)
    : null;
  const birthDateString = birthDate ? format(birthDate, 'dd.MM.yyyy') : '';
  const years = birthDate ? differenceInYears(new Date(), birthDate) : null;
  const yearsString = years ? t('ages', { count: years }) : '';
  const full = [birthDateString, yearsString].filter(Boolean).join(', ') || '-';

  const handleNumberChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const number = parseInt(event.target.value);
      setData({
        ...data,
        number,
      });

      dispatch(
        championshipPlayerApplicationChangeNumber({
          id: data.id,
          teamId: data.teamId,
          championshipId: data.championshipId,
          createUserOrUpdateApplication: {
            userId: data.player.id,
            name: data.player.firstName,
            surname: data.player.lastName,
            middleName: data.player.middleName,
            birthDate: data.player.birthDate,
            position: data.position ? data.position.toUpperCase() : data.position,
            number,
          },
          state: data.status.toUpperCase(),
        })
      );
    },
    [dispatch, data]
  );

  const handlePositionChange = useCallback(
    (option: OnChangeValue<SelectOption, false>) => {
      const position = option ? (option.value as PlayerPosition) : undefined;
      setData({
        ...data,
        position,
      });

      dispatch(
        championshipPlayerApplicationChangePosition({
          id: data.id,
          teamId: data.teamId,
          championshipId: data.championshipId,
          state: data.status,
          createUserOrUpdateApplication: {
            userId: data.player.id,
            name: data.player.firstName,
            surname: data.player.lastName,
            middleName: data.player.middleName,
            birthDate: data.player.birthDate,
            position: position ? position.toUpperCase() : position,
            number: data.number,
          },
        })
      );
    },
    [dispatch, data]
  );

  return (
    <CardContainer>
      <div className="d-flex align-items-center" style={{ flexBasis: '40%' }}>
        <UDText type="subhead" className="bold ml-3" style={{ width: '40px' }}>
          {index}
        </UDText>
        <TeamPlayerAvatar
          url={data.player.logoUrl || defaultAvatar}
          size={40}
          square
        />
        <UDText type="subhead" className="bold ml-4">
          {name}
        </UDText>
      </div>
      <UDText type="subhead" style={{ flexBasis: '15%' }}>
        {full}
      </UDText>
      <BorderedInput
        name="number"
        type="number"
        placeholder="Номер игрока"
        min={0}
        value={data.number}
        onChange={handleNumberChange}
      />
      <PositionSelect
        name="position"
        value={data.position}
        placeholder="Амплуа"
        onChange={handlePositionChange}
      />

      <StyledSelect
        isLoading={isUpdating}
        isDisabled={isUpdating}
        placeholder="Выбрать"
        options={[
          {
            value: ApplicationStatus.ACCEPTED,
            label: 'Подтвердить',
          },
          {
            value: ApplicationStatus.DECLINED,
            label: 'Отклонить',
          },
        ]}
        onChange={handleChange}
      />
    </CardContainer>
  );
};

export default TeamPlayerCard;
