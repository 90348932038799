import Observable from "../../../../utils/observer";
import Interactive from "../canvases/interactive";

class Zoom {
  private manager!: Interactive
  private isDragging = false
  private lastPosX?: number;
  private lastPosY?: number;
  
  onChangeZoom = new Observable()
  
  init(manager: Interactive) {
    this.manager = manager
  }
  
  enableDragging() {
    const canvas = this.manager.canvas
    
    canvas.on('mouse:down', (opt) => {
      const evt = opt.e;
      const useMeta = navigator.userAgent.toLowerCase().indexOf("mac") !== -1
      if ((useMeta && evt.metaKey) || (!useMeta && evt.altKey)) {
        this.isDragging = true;
        canvas.selection = false
        this.lastPosX = evt.clientX;
        this.lastPosY = evt.clientY;
      }
    });
    
    canvas.on('mouse:move', (opt) => {
      if (this.isDragging) {
        let e = opt.e;
        const vpt = canvas.viewportTransform;
        if (!vpt) return
        vpt[ 4 ] += e.clientX - (this?.lastPosX ?? 0);
        vpt[ 5 ] += e.clientY - (this?.lastPosY ?? 0);
        canvas.requestRenderAll();
        this.lastPosX = e.clientX;
        this.lastPosY = e.clientY;
      }
    });
    
    canvas.on('mouse:up', (_) => {
      canvas.setViewportTransform(canvas.viewportTransform ?? [1, 0, 0, 1, 0, 0]);
      this.isDragging = false;
      if ("selection" in canvas) {canvas.selection = true;}
    });
  }
  
  enableZoom() {
    const canvas = this.manager.canvas
    
    canvas.on('mouse:wheel', (opt) => {
      const delta = opt.e.deltaY;
      let zoom = canvas.getZoom();
      zoom *= 0.999 ** delta;
      if (zoom > 20) zoom = 20;
      if (zoom < 0.01) zoom = 0.01;
      canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
      opt.e.preventDefault();
      opt.e.stopPropagation();
      this.onChangeZoom.notify(zoom)
    });
  }
  
  setZoom(newZoom: number) {
    const canvas = this.manager.canvas
    
    let zoom = newZoom;
    if (zoom > 20) zoom = 20;
    if (zoom < 0.01) zoom = 0.01;
    const center = canvas.getCenter()
    canvas.zoomToPoint({ x: center.left, y: center.top }, zoom)
    canvas.requestRenderAll()
    this.onChangeZoom.notify(zoom)
  }
  
  getZoom() {
    const canvas = this.manager.canvas
    return canvas.getZoom();
  }
}

const zoom = new Zoom()
export default zoom
