import styled from '@emotion/styled';

export const FilterLink = styled.span`
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 14px;
  cursor: pointer;
  margin: 8px 12px;
  padding: 2px 0;
  border-bottom: 1px dashed #000;

  &:first-child {
    margin-left: 8px;
  }

  &.active {
    padding: 4px 8px;
    margin: 8px 4px;
    border: none;
    border-radius: 4px;
    background: #F5D956;

    &:first-child {
      margin-left: 0;
    }
  }
`;
