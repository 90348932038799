import { useSelector } from 'react-redux';
import {
  authStoreAuthedSelector,
  authStoreCheckingSelector,
  authStoreCheckedSelector, authStoreAvailableTournaments, authStoreUserTournamentId, authStoreUserSelector,
} from '../../store/selectors';

export const useAuth = () => {
  const isAuthed = useSelector(authStoreAuthedSelector);
  const isChecking = useSelector(authStoreCheckingSelector);
  const isChecked = useSelector(authStoreCheckedSelector);
  const user = useSelector(authStoreUserSelector);
  const userTournamentId = useSelector(authStoreUserTournamentId);
  const availableTournaments = useSelector(authStoreAvailableTournaments);

  return {
    isAuthed,
    isChecking,
    isChecked,
    userTournamentId,
    availableTournaments,
    user,
  };
};
