import BaseFabric from "./baseFabric";
import { canvasConstructor } from "../types";
import { fabric } from "fabric";
import { StaticCanvas } from "fabric/fabric-impl";

export default class Static extends BaseFabric {
  canvas: StaticCanvas
  
  constructor(data?: canvasConstructor) {
    super();
    
    const { width = 16 * 50, height = 9 * 50, backgroundColor = 'transparent' } = data ?? {}
    
    this.canvas = new fabric.StaticCanvas('canvas', {
      height,
      width,
      backgroundColor,
      selection: false,
      interactive: false,
      imageSmoothingEnabled: false,
    })
    
    this.resolution.value = { width, height }
    
    this.init()
  }
}
