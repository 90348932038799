import React, { useCallback, useState } from 'react';
import { SymbolicTeamBreadcrumbs, SymbolicTeamForm } from 'features/championship';
import { SymbolicTeam } from '../../../../domain/interfaces/SymbolicTeam';
import { SymbolicTeamsGrid } from './symbolic-teams-grid';
import { useDispatch, useSelector } from 'react-redux';
import { championshipSymbolicTeamSelectedSelector } from '../../../../store/championship/selectors';
import { selectSymbolicTeam } from '../../../../store/championship/actions';
import { useAcceptedChampionshipTeams } from '../../../hooks/use-accepted-championship-teams';

type ChampionshipSymbolicTeamsProps = {
  championshipId: number;
  items: SymbolicTeam[];
};

const ChampionshipSymbolicTeams = ({ championshipId, items }: ChampionshipSymbolicTeamsProps) => {
  const dispatch = useDispatch();
  const selectedSymbolicTeam = useSelector(championshipSymbolicTeamSelectedSelector);
  const { teams: allTeams } = useAcceptedChampionshipTeams();
  const [showForm, setShowForm] = useState<boolean>(false);

  const handleSelectTeam = useCallback((team: SymbolicTeam | null) => {
    dispatch(selectSymbolicTeam(team));
    setShowForm(true);
  }, [dispatch]);

  const handleShowList = useCallback(() => {
    dispatch(selectSymbolicTeam(null));
    setShowForm(false);
  }, [dispatch]);

  if (!showForm) {
    return (
      <div className="container">
        <SymbolicTeamsGrid
          items={items}
          onSelect={handleSelectTeam}
        />
      </div>
    );
  }

  return (
    <div>
      <SymbolicTeamBreadcrumbs
        className="container"
        activeTeamName={selectedSymbolicTeam?.name}
        onGoToListClick={handleShowList}
      />

      <div className="container">
        <SymbolicTeamForm
          championshipId={championshipId}
          allTeams={allTeams!}
          symbolicTeam={selectedSymbolicTeam}
        />
      </div>
    </div>
  );
};

export default ChampionshipSymbolicTeams;
