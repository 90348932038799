import React, { ChangeEvent, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { FieldArray, Formik, Form } from 'formik';
import { Team } from '../../../../../../teams/domain/interfaces/Team';
import { StageV2 } from '../../../../../domain/interfaces/Stage';
import UDCheckbox from '../../../../../../ud-form/components/checkbox/component';
import TeamCardCheckbox from './team-card-checkbox';
import TeamGroupCard from './team-group-card';
import UDButton from '../../../../../../ud-ui/components/button';
import { upsertStage } from '../../../../../store/stages/stages.actions';
import { AppDispatch } from '../../../../../../../store/store';

const StyledTeamGroupsLabel = styled.span`
  background: ${({ theme }) => theme.colors.AccentCornsilk};
  border-radius: 8px;
  padding: 8px;
`;

interface TeamGroupsFormValues {
  teamIds: number[];
}

type SetFieldValueFunction = (field: string, value: number[]) => void

type TeamGroupsFormProps = {
  stage: StageV2;
  allTeams: Team[];
};

const TeamGroupsForm = ({ stage, allTeams }: TeamGroupsFormProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const stageTeamIds = stage.teams.map(team => team.id);
  const initialValues: TeamGroupsFormValues = {
    teamIds: stageTeamIds,
  };

  const handleSelectAll = useCallback((setFieldValue: SetFieldValueFunction) => (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setFieldValue('teamIds', allTeams.map(({ id }) => id));
    } else {
      setFieldValue('teamIds', []);
    }
  }, [allTeams]);

  const handleSubmit = useCallback((values: TeamGroupsFormValues) => {
    setIsLoading(true);

    const { teamIds } = values;

    dispatch(upsertStage({
      id: stage.id,
      championshipId: stage.championshipId,
      name: stage.name,
      type: stage.type,
      teamIds,
    })).finally(() => {
      setIsLoading(false);
    });
  }, [dispatch, stage]);

  return (
    <div>
      <div className="mt-6 mb-4">
        <StyledTeamGroupsLabel className="text subhead">
          Выберите команды, которые нужно заявить на этап
        </StyledTeamGroupsLabel>
      </div>
      <div className="col-7">
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={handleSubmit}
        >
          {(formProps) => (
            <Form onSubmit={formProps.handleSubmit}>
              <div className="mb-4">
                <TeamGroupCard
                  name="Выбрать все"
                  showLogo={false}
                >
                  <UDCheckbox
                    name="selectAll"
                    checked={formProps.values.teamIds.length === allTeams.length}
                    onChange={handleSelectAll(formProps.setFieldValue)}
                  />
                </TeamGroupCard>
              </div>

              <FieldArray
                name="teamIds"
                render={arrayHelpers => (
                  <div>
                    {allTeams.map((team) => (
                      <div key={team.id} className="mb-4">
                        <TeamCardCheckbox
                          team={team}
                          arrayHelpers={arrayHelpers}
                          name="teamIds"
                        />
                      </div>
                    ))}
                  </div>
                )}
              />

              <div className="col-12">
                <UDButton
                  variant="primary"
                  type="submit"
                  disabled={!formProps.dirty || isLoading}
                  loading={isLoading}
                  className="col-12 mt-3 mb-3"
                >
                  Сохранить
                </UDButton>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default TeamGroupsForm;
