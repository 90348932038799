import classNames from 'classnames';
import React, { ReactChild } from 'react';
import { Button } from './styles';

type ButtonVariant = 'primary' | 'secondary' | 'dark' | 'light' | 'gray';
type ButtonTertiaryVariant = 'tertiary';
type ButtonSubVariant = 'for-dark' | 'for-light';

type ButtonCommonProps = {
  slim?: boolean;
  loading?: boolean;
  children: ReactChild | ReactChild[];
} & Omit<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >,
  'children'
>;

type TertiaryVariantProps = {
  variant: ButtonTertiaryVariant;
  subVariant: ButtonSubVariant;
} & ButtonCommonProps;

type CommonVariantProps = {
  variant: ButtonVariant;
  subVariant?: undefined;
} & ButtonCommonProps;

export type UDButtonProps = TertiaryVariantProps | CommonVariantProps;

const UDButton = (props: UDButtonProps) => {
  const { slim, loading, className, variant, subVariant, ...otherProps } =
    props;
  const buttonClassName = classNames(
    variant,
    subVariant,
    className,
    { slim, loading },
    'text subhead'
  );
  return (
    <Button {...otherProps} className={buttonClassName}>
      {props.children}
    </Button>
  );
};

export default UDButton;
