import React from 'react';
import { Team, TeamInfo } from '../../../../../../../../teams/domain/interfaces/Team';
import { SelectOption } from '../../../../../../../../ud-form/components/select/component';
import TeamLogo from '../../../../../../../../ud-ui/components/team-logo';
import { TeamSelectOptionContainer } from './team-select-option.styles';

export const teamToTeamOption = (team: Team | TeamInfo): SelectOption<number> => ({
  label: (
    <TeamSelectOptionContainer>
      <TeamLogo
        className="team-select-logo"
        url={team.logo?.url}
        size={32}
      />
      {team.name}
    </TeamSelectOptionContainer>
  ),
  value: team.id,
});
