import React, { useMemo } from 'react';
import { MainBlock } from "./styles";
import UDBreadcrumbs from "../../../../ud-ui/components/breadcrumbs";
import UDMainLayout from "../../../../ud-ui/layout/main";
import { Pages } from "../../../../navigation/domain/enums/pages";
import UDText from "../../../../ud-ui/components/text";
import OrganizerInfo from "../../components/organizer-info";
import UDButton from "../../../../ud-ui/components/button";
import { history } from "../../../../../store/store";
import { useMatch } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectOrganizerById } from "../../../store/selectors";
import { useAuth } from "../../../../auth/ui/hooks/useAuth";

function OrganizerCard() {
  const pageOrganizerView = useMatch(`${Pages.ORGANIZERS.VIEW}`);
  const organizer = useSelector(selectOrganizerById(+(pageOrganizerView?.params?.id ?? -1)));
  
  const breadcrumbs = useMemo(() => {
    const items = [{ label: 'Организаторы', to: Pages.ORGANIZERS.INDEX }];
    
    const { name, id } = organizer ?? {};
    if (name && id) {
      items.push({ label: name, to: `${Pages.ORGANIZERS.builders.view(id)}` });
    }
    
    return items
  }, [organizer])
  
  const { user } = useAuth()
  const isDev = process.env.REACT_APP_ENVIRONMENT === 'development'
  if (user?.id !== 20 && !isDev) {
    return null
  }
  
  return (
    <UDMainLayout headerContent={<UDBreadcrumbs className="my-10" breadcrumbs={breadcrumbs}/>}>
      <MainBlock>
        <div className='container py-3 d-flex flex-row align-items-center'>
          <UDText type="headline" className="mr-6">
            Информация об организаторе
          </UDText>
          <UDButton
            onClick={() => history.push(Pages.ORGANIZERS.INDEX)}
            className={'flex-center'}
            variant={'primary'}
            style={{ height: 36 }}
          >
            Вернуться к списку
          </UDButton>
        </div>
        <div className="container d-flex align-items-center bb-1"/>
        <div className="container py-6">
          <OrganizerInfo/>
        </div>
      </MainBlock>
    </UDMainLayout>
  );
}

export default OrganizerCard;
