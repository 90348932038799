import React from 'react';
import styled from '@emotion/styled';
import TeamLogo from '../../../../../../ud-ui/components/team-logo';
import UDText from '../../../../../../ud-ui/components/text';

const StyledCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  background-color: ${({ theme }) => theme.colors.SurfaceWhite};
  border-radius: 8px;
  box-shadow: 0px 0px 1px rgba(26, 26, 41, 0.08), 0px 8px 16px rgba(26, 26, 41, 0.08);
`;

const StyledTeamInfo = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  
  .team-logo {
    flex-shrink: 0;
    object-fit: contain;
  }
`;

type TeamGroupCardProps = {
  name: string;
  logoUrl?: string;
  showLogo?: boolean;
  children?: JSX.Element;
};

const TeamGroupCard = ({ name, logoUrl, showLogo = true, children }: TeamGroupCardProps) => {
  return (
    <StyledCardContainer>
      <StyledTeamInfo>
        {showLogo && (
          <TeamLogo
            className="team-logo mr-2"
            url={logoUrl}
            size={52}
          />
        )}

        <UDText type="subhead" className="bold">
          {name}
        </UDText>
      </StyledTeamInfo>
      {children}
    </StyledCardContainer>
  )
};

export default TeamGroupCard;
