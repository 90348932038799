import React from 'react';

type CupIconProps = {
  width: number;
  height: number;
  color?: string;
};

const DoneIcon = (props: CupIconProps) => {
  const {
    width,
    height,
    color = 'black',
  } = props;
  
  return (
    <svg width={width.toString()} height={height.toString()} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 11.2L6.8 16L18.8 4" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default DoneIcon;
