import React from 'react';
import { Header, MainBlock } from "./styles";
import UDText, { UDTextType } from "../../../../../../ud-ui/components/text";
import { DivProps } from "../../../../../../../typings";

type IProps = DivProps & {
  header: {
    text: string
    type?: UDTextType
  }
}

function HeaderItem(props: IProps) {
  const { header, children, ...rest } = props
  const { text, type = 'subhead' } = header
  
  return (
    <MainBlock {...rest}>
      <Header>
        <UDText style={{textWrap: 'nowrap'}} type={type} className="color-SurfaceRomanSilver30">{text}</UDText>
      </Header>
      {children}
    </MainBlock>
  );
}

export default HeaderItem;
