const logout = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M12.7963 13.6V11.2C12.7963 10.7582 12.4382 10.4 11.9965 10.4C11.5548 10.4 11.1968 10.7582 11.1968 11.2V13.6C11.1968 14.0418 10.8387 14.4 10.397 14.4H2.3993C1.9576 14.4 1.59954 14.0418 1.59954 13.6V2.4C1.59954 1.95817 1.9576 1.6 2.3993 1.6H10.397C10.8387 1.6 11.1968 1.95817 11.1968 2.4V4.8C11.1968 5.24183 11.5548 5.6 11.9965 5.6C12.4382 5.6 12.7963 5.24183 12.7963 4.8V2.4C12.7963 1.07452 11.7221 0 10.397 0H2.3993C1.07421 0 0 1.07452 0 2.4V13.6C0 14.9255 1.07421 16 2.3993 16H10.397C11.7221 16 12.7963 14.9255 12.7963 13.6ZM14.1639 5.83203L15.7634 7.43203C15.9148 7.58224 16 7.78672 16 8.00003C16 8.21334 15.9148 8.41782 15.7634 8.56803L14.1639 10.168C14.0137 10.3195 13.8093 10.4047 13.5961 10.4047C13.3828 10.4047 13.1784 10.3195 13.0282 10.168C12.8768 10.0178 12.7917 9.81334 12.7917 9.60003C12.7917 9.38672 12.8768 9.18224 13.0282 9.03203L13.2682 8.80003H7.99768C7.55598 8.80003 7.19791 8.44186 7.19791 8.00003C7.19791 7.5582 7.55598 7.20003 7.99768 7.20003H13.2682L13.0282 6.96803C12.7146 6.65433 12.7146 6.14573 13.0282 5.83203C13.3418 5.51833 13.8503 5.51833 14.1639 5.83203Z"
    fill="currentColor"
  />
);

export default logout;
