import React from 'react';

type FootballCardIconProps = {
  width: number;
  height: number;
  type?: 'yellow' | 'red';
};

const FootballCardIcon = (props: FootballCardIconProps) => {
  const {
    width,
    height,
    type = 'yellow',
  } = props;

  const color = type === 'red' ? '#FF7061' : '#F5D956';

  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 20"
      fill="none" 
    >
      <path
        d="M13.779 17.4595C13.669 18.5463 12.7957 19.3271 11.8275 19.2034L3.58842 18.1492C2.62092 18.0249 1.92585 17.044 2.03587 15.9572L3.47589 1.75635C3.58657 0.669506 4.45971 -0.110904 5.42819 0.0129149L13.6669 1.06678C14.6349 1.19078 15.3297 2.17195 15.2193 3.25876L13.779 17.4595Z"
        fill={color}
      />
      <path
        d="M2.53917 18.8476C1.57166 18.7238 0.876296 17.7425 0.986311 16.6557L2.42651 2.45519C2.45231 2.19921 2.52221 1.96085 2.62424 1.74683H1.76359C0.789444 1.74683 0 2.63346 0 3.72743V18.0191C0 19.1133 0.789231 20 1.76359 20H10.056C10.2625 20 10.4599 19.9577 10.6447 19.8845L2.53917 18.8476Z"
        fill={color}
      />
    </svg>
  );
};

export default FootballCardIcon;