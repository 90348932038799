import React from 'react';
import UDFormInput from 'modules/ud-form/components/input';
import UDText from 'modules/ud-ui/components/text';

const TourneysComponentsFormRulesPartWin = () => {
  return (
    <>
      <UDText type="callout" className="mb-4">
        🏆 Победа
      </UDText>
      <UDFormInput
        name="settings.winScore"
        placeholder="0 очков"
        containerProps={{ className: 'w-30 mb-4' }}
      />
      <div className="mb-4 d-flex align-items-center">
        <UDText type="callout" className="mr-auto color-SurfaceRaisinBlack50">
          В дополнительное время
        </UDText>
        <UDFormInput
          name="settings.winScoreExtraTime"
          placeholder="0 очков"
          containerProps={{ className: 'w-30' }}
        />
      </div>
      <div className="d-flex align-items-center">
        <UDText type="callout" className="mr-auto color-SurfaceRaisinBlack50">
          В серии послематчевых пенальти
        </UDText>
        <UDFormInput
          name="settings.winPenaltySeries"
          placeholder="0 очков"
          containerProps={{ className: 'w-30' }}
        />
      </div>
    </>
  );
};

export default TourneysComponentsFormRulesPartWin;
