import React, { ChangeEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { AnnouncedTeamStatus } from '../../../domain/enums/AnnouncedTeamStatus';
import UDInput from '../../../../ud-form/components/input/component';
import FilterButton from './filter-button';

export type ChampionshipApplicationFiltersOption<StatusType> = {
  status: StatusType;
  icon: React.JSXElementConstructor<{
    width: number;
    height: number;
    color?: string;
  }>;
  color: string;
};

export type ChampionshipTeamsFilterValues<StatusType> = {
  name: string;
  status: StatusType;
};

type ChampionshipApplicationFiltersProps<StatusType> = {
  selectedStatus: StatusType;
  name?: string;
  options: ChampionshipApplicationFiltersOption<StatusType>[];
  inputPlaceholder?: string;
  countByStatus?: {
    [key: string]: number;
  };
  onSelect?: (status: AnnouncedTeamStatus) => void;
  onChange?: (values: ChampionshipTeamsFilterValues<StatusType>) => void;
  className?: string;
};

function ChampionshipApplicationFilters<StatusType>(props: ChampionshipApplicationFiltersProps<StatusType>) {
  const {
    selectedStatus,
    name = '',
    options,
    inputPlaceholder,
    countByStatus,
    className,
    onChange,
  } = props;

  const { t } = useTranslation();

  const handleSelect = useCallback((status: StatusType) => () => {
    if (onChange && status !== selectedStatus) {
      onChange({
        name,
        status,
      });
    }
  }, [onChange, selectedStatus, name]);

  const handleOnChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange({
        name: event.target.value,
        status: selectedStatus,
      });
    }
  }, [onChange, selectedStatus]);

  return (
    <div className={classNames('d-flex align-items-center justify-content-between', className)}>
      <div className="w-30">
        <UDInput
          value={name}
          placeholder={inputPlaceholder}
          iconProps={{
            position: 'icon-right',
            name: 'search',
          }}
          onChange={handleOnChange}
        />
      </div>

      <div className="d-flex">
        {options.map((item) => {
          const isActive = item.status === selectedStatus;
          const statusString = item.status as any as string;

          return (
            <FilterButton
              key={statusString}
              className={classNames({ active: isActive })}
              onClick={handleSelect(item.status)}
            >
              <item.icon
                width={24}
                height={24}
                color={isActive ? 'white' : item.color}
              />
              <span className="ml-2 text subhead">
                {t(`applications.statusFilter.${item.status}`)}
                {countByStatus && countByStatus[statusString] !== undefined && (
                  <span className="ml-1 text bold">({countByStatus[statusString]})</span>
                )}
            </span>
            </FilterButton>
          )
        })}
      </div>
    </div>
  );
}

export default ChampionshipApplicationFilters;
