import styled from '@emotion/styled';

export const MenuLogout = styled.div`
  & .logout {
    padding: 6px;

    &:active {
      background-color: ${({ theme }) => theme.colors.SurfaceSpaceCadet50};
    }
    &:focus {
      outline-color: transparent;
      border-color: transparent;
    }
  }
  
  .organizer-link {
    display: flex;
    align-items: center;
  }
`;

export const MenuLogo = styled.img`
  margin-right: 8px;
`;
