import { SupabaseClient } from "@supabase/supabase-js";
import { TemplatesRow } from "../../domain/interfaces/receivedData";
import { supabaseTemplate } from "../../../ud-ui/components/fabric-canvas/types";
import { categoryDataMapper } from "../../domain/mappers/category-data-mapper";
import { Category } from "../../../template-editor/domain/interface/category";

export default class OverlayTemplates {
  constructor(private supabase: SupabaseClient) {}
  
  async getRow(data: { rowId: number }): Promise<TemplatesRow | undefined> {
    const { rowId } = data
    const { data: row, error } = await this.supabase
      .from('overlay_templates')
      .select('*')
      .eq('id', rowId)
    
    if (!error) return row[ 0 ]
    console.error(error)
  }
  
  async insertRow(data: {
    userId: number | null,
    category: string,
    templates?: Record<string, supabaseTemplate>,
    resolution?: { width: number, height: number }
  }) {
    const { error } = await this.supabase
      .from('overlay_templates')
      .insert(data)
    
    if (!error) return
    console.error({ event: 'insertTemplate', error })
  }
  
  async updateRow(searchData: { rowId: number | null }, data: {
    category?: string,
    templates?: Record<string, supabaseTemplate>,
    resolution?: { width: number, height: number }
  }) {
    const { error } = searchData.rowId !== null
      ? await this.supabase
        .from('overlay_templates')
        .update(data)
        .eq('id', searchData.rowId)
      : await this.supabase
        .from('overlay_templates')
        .update(data)
        .is('userId', null)
    
    if (!error) return
    console.error({ event: 'updateTemplate', error })
  }
  
  async getAllUserRows(userId: number): Promise<undefined | Category[]> {
    const result = await this.supabase
      .from('overlay_templates')
      .select('*')
      .or(`userId.eq.${userId},userId.is.null`)
    
    if (!result.error) {
      return categoryDataMapper(result.data)
    }
    console.error({ event: 'getAllCategories', error: result.error })
  }
  
  async deleteRow(data: { rowId: number }) {
    await this.supabase
      .from('overlay_templates')
      .delete()
      .eq('id', data.rowId)
  }
  
  async getDefaultRow(): Promise<TemplatesRow | undefined> {
    const { data: row, error } = await this.supabase
      .from('overlay_templates')
      .select('*')
      .is('userId', null)
    
    if (!error) return row[ 0 ]
    console.error(error)
  }
}
