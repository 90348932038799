import React from 'react';

interface IProps {
  width?: number;
  height?: number;
  color?: string;
}

export default function AlignTextCenterIcon(props: IProps) {
  const {
    width,
    height,
    color = "#242424",
    ...rest
  } = props;
  
  return (
    <svg width={width ?? 18} height={height ?? 18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M3.71436 3.375C3.71436 2.96079 4.05014 2.625 4.46436 2.625H14.9644C15.3786 2.625 15.7144 2.96079 15.7144 3.375C15.7144 3.78921 15.3786 4.125 14.9644 4.125H4.46436C4.05014 4.125 3.71436 3.78921 3.71436 3.375ZM5.96436 14.625C5.96436 14.2108 6.30014 13.875 6.71436 13.875H12.7144C13.1286 13.875 13.4644 14.2108 13.4644 14.625C13.4644 15.0392 13.1286 15.375 12.7144 15.375H6.71436C6.30014 15.375 5.96436 15.0392 5.96436 14.625ZM5.96436 7.125C5.96436 6.71079 6.30014 6.375 6.71436 6.375H12.7144C13.1286 6.375 13.4644 6.71079 13.4644 7.125C13.4644 7.53921 13.1286 7.875 12.7144 7.875H6.71436C6.30014 7.875 5.96436 7.53921 5.96436 7.125ZM3.71436 10.875C3.71436 10.4608 4.05014 10.125 4.46436 10.125H14.9644C15.3786 10.125 15.7144 10.4608 15.7144 10.875C15.7144 11.2892 15.3786 11.625 14.9644 11.625H4.46436C4.05014 11.625 3.71436 11.2892 3.71436 10.875Z"
        fill={color}/>
    </svg>
  );
}
