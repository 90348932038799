import React from 'react';
import classNames from 'classnames';
import UDFormFieldWrapper from '../../wrapper';
import { UDFormComponentProps } from '../types';
import UDTextareaInput from './component/textarea';
import { UDTextareaInputProps } from './component/types';

type Props = UDFormComponentProps &
  Omit<UDTextareaInputProps, 'hasError' | 'valid'>;

const UDFormTextearea = (props: Props) => {
  const {
    name,
    containerProps,
    required,
    label,
    labelProps,
    ...otherInputProps
  } = props;
  return (
    <UDFormFieldWrapper
      name={name}
      containerProps={containerProps}
      label={label}
      labelProps={labelProps}
      required>
      {({ field, meta }) => (
        <UDTextareaInput
          {...otherInputProps}
          wrapperProps={{
            ...otherInputProps.wrapperProps,
            className: classNames(
              'w-100',
              otherInputProps.wrapperProps?.className,
            ),
          }}
          {...field}
          hasError={!!meta.error}
          valid={!meta.error && meta.touched && meta.value}
        />
      )}
    </UDFormFieldWrapper>
  );
};

export default UDFormTextearea;
