import { BackgroundList, ButtonsBlock, MainBlock } from "./style";
import UDText from "../../../../../../../ud-ui/components/text";
import BackgroundItem from "../../elements/backgroundItem";
import UDButton from "../../../../../../../ud-ui/components/button";
import { useState } from "react";
import { DivProps } from "../../../../../../../../typings";

type IProps = Omit<DivProps, 'onSubmit'> & {
  img?: string
  onCancel?: () => void
  onSubmit?: (background: { name: string, value: string }) => void
  backgrounds?: { name: string, value: string }[]
}

function UDBackgroundSelection(props: IProps) {
  const { img, onCancel, onSubmit, backgrounds, ...rest } = props
  const [selectedBackground, setSelectedBackground] = useState<{
    name: string,
    value: string
  } | undefined>(backgrounds && backgrounds[ 0 ])
  const [isSubmit, setIsSubmit] = useState(false)
  const backgroundList = backgrounds?.map((background) => {
    // @ts-ignore
    const { name: colorName, value: color } = background
    const isActive = background.value === selectedBackground?.value
    const onClick = () => {
      setSelectedBackground(background)
    }
    return (
      <BackgroundItem isActive={isActive} onClick={onClick} color={color} colorName={colorName} img={img}/>
    )
  })
  
  const onClickCancel = () => {
    if (onCancel) onCancel()
  }
  
  const onClickSubmit = () => {
    if (!selectedBackground) throw new Error('No background selected');
    if (onSubmit) onSubmit(selectedBackground)
    setIsSubmit(true)
  }
  
  return (
    <MainBlock {...rest}>
      <UDText type={'footnote'}>Укажите фон для вашего логотипа</UDText>
      <BackgroundList>
        {backgroundList}
      </BackgroundList>
      <UDText type={'tip'} style={{ color: '#8F8E99' }}>
        *обратите внимание на цвет фона, он не должен сливаться с основными цветом вашего
        изображения
      </UDText>
      <ButtonsBlock>
        <UDButton
          loading={isSubmit}
          style={{ height: 36, width: 130 }}
          type={'button'}
          onClick={onClickSubmit}
          variant={'primary'}
        >
          Применить
        </UDButton>
        <UDButton
          style={{ height: 36, width: 130 }}
          type={'button'}
          onClick={onClickCancel}
          variant={'gray'}
        >
          Отмена
        </UDButton>
      </ButtonsBlock>
    </MainBlock>
  );
}

export default UDBackgroundSelection;
