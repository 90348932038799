import { TFunction } from 'i18next';
import * as yup from 'yup';
import { AuthSignInPayload } from '../interfaces/AuthSignInPayload';

export const authSignInValidator = (t: TFunction): yup.SchemaOf<AuthSignInPayload> => yup
  .object()
  .shape({
    username: yup.string()
      .required(t('signIn.validationError.phoneRequired'))
      .matches(/^\+7([0-9]{10})$/, t('signIn.validationError.phoneInvalid')),
    password: yup.string()
      .required(t('signIn.validationError.passwordRequired')),
    to: yup.string().optional(),
  });
