import styled from '@emotion/styled';

export const TabsContainer = styled.div`
  & .control {
    margin-right: 16px;
  }
  & .control:last-child {
    margin-right: 0;
  }

  & .arrow {
    &.active {
      display: none;
    }
  }
`;
