import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { StageV2 } from '../../../../domain/interfaces/Stage';
import UDIcon from '../../../../../ud-ui/components/icon';
import { StageType } from '../../../../domain/enums/StageType';

const StyledStageCardContainer = styled.div`
  background: ${({ theme }) => theme.colors.SurfaceWhite};
  border-radius: 8px;
  min-height: 157px;
`;

const StyledStageCardHeader = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.SurfacePlatinum20};
  padding: 16px 16px 10px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const StyledStageCardHeaderSettings = styled.div`
  display: flex;
  align-items: center;
`;

const StyledStageCardBody = styled.div`
  padding: 12px 16px;
`;

const StyledStageCardProperty = styled.div`
  display: inline-block;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
  font-size: 16px;
  margin: 10px 24px 10px 0;
  
  .label {
    color: ${({ theme }) => theme.colors.SurfaceRomanSilver30};
    margin-right: 10px;
  }
  
  .value {
    background: ${({ theme }) => theme.colors.SurfaceCultured10};
    border-radius: 8px;
    color: ${({ theme }) => theme.colors.SurfaceRaisinBlack50};
    padding: 4px 8px;
  }
`;

type StageCardProps = {
  stage: StageV2;
  onSelect?: () => void;
};

const StageCard = ({ stage, onSelect }: StageCardProps) => {
  const { t } = useTranslation();

  return (
    <StyledStageCardContainer>
      <StyledStageCardHeader onClick={onSelect}>
        <div className="text callout">{stage.name}</div>
        <StyledStageCardHeaderSettings>
          <UDIcon name="gear" />
          <span className="ml-2 text subhead">Настройки</span>
        </StyledStageCardHeaderSettings>
      </StyledStageCardHeader>
      <StyledStageCardBody>
        <StyledStageCardProperty>
          <span className="label">Тип этапа</span>
          <span className="value">{t(`roundType.${stage.type}`)}</span>
        </StyledStageCardProperty>
        <StyledStageCardProperty>
          <span className="label">Игра за место</span>
          <span className="value">1</span>
        </StyledStageCardProperty>
        <StyledStageCardProperty>
          <span className="label">Команд</span>
          <span className="value">{stage.teams.length}</span>
        </StyledStageCardProperty>
        <StyledStageCardProperty>
          <span className="label">Размер сетки</span>
          {stage.type === StageType.SINGLE_ELIMINATION
            ? (<span className="value">{stage.brackets.length}</span>)
            : (<span className="value">Исходя из списка команд</span>)
          }
        </StyledStageCardProperty>
      </StyledStageCardBody>
    </StyledStageCardContainer>
  );
};

export default StageCard;
