import { createSlice } from '@reduxjs/toolkit';
import groupsAdapter from './groups.adapter';
import { deleteStageTeamGroup, fetchStages, upsertStageTeamGroup } from './stages.actions';
import { TeamGroup } from '../../domain/interfaces/TeamGroup';

const groupsSlice = createSlice({
  name: 'groups',
  initialState: groupsAdapter.getInitialState({
    entities: {},
  }),
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchStages.fulfilled, (state, action) => {
      const groups = action.payload.reduce<TeamGroup[]>((acc, stage) => [
        ...acc,
        ...stage.teamGroups,
      ], []);

      groupsAdapter.setAll(state, groups);
    });

    builder.addCase(deleteStageTeamGroup.fulfilled, (state, action) => {
      const teamGroupId = action.meta.arg;
      groupsAdapter.removeOne(state, teamGroupId);
    });

    builder.addCase(upsertStageTeamGroup.fulfilled, (state, action) => {
      groupsAdapter.upsertOne(state, action.payload);
    });
  }
});

export default groupsSlice;
