import React from 'react';
import { Field, FieldProps } from 'formik';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import classNames from 'classnames';
import { UDFormComponentProps } from '../components/types';
import UDFormLabel from '../components/label';

type Props = UDFormComponentProps & {
  children: (fieldProps: FieldProps<any>) => JSX.Element;
};

const UDFormFieldWrapper = (props: Props) => {
  const { name, containerProps, labelProps, label, required, children } = props;
  return (
    <Field name={name}>
      {(fieldProps: FieldProps<any>) => (
        <div {...containerProps}>
          {label && (
            <UDFormLabel
              {...labelProps}
              error={fieldProps.meta.error}
              className={classNames('mb-2', labelProps?.className)}
            >
              {label}
            </UDFormLabel>
          )}
          <Tippy
            content={fieldProps.meta.error}
            visible={
              !!required && !fieldProps.meta.value && fieldProps.meta.touched
            }
            placement="right"
            interactive={false}
          >
            {children(fieldProps)}
          </Tippy>
        </div>
      )}
    </Field>
  );
};

export default UDFormFieldWrapper;
