import styled from '@emotion/styled';

export const PayersListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.SurfaceWhite};
  border-radius: 8px;
  padding: 10px;
`;

export const PayersListContent = styled.div`
  padding: 10px;
  max-height: 700px;
  overflow-x: auto;
`;
