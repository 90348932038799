import { useEffect, useMemo } from 'react';
import { Game } from "../../../../../../../games/domain/interfaces/Game";
import { ChampionshipStateData } from "../../../../../../store/championship";
import { useOrganizer } from "../../../../../../../organizer/ui/hooks/useOrganizer";
import { format } from "date-fns";
import ru from "date-fns/locale/ru";
import { getLastGameResult } from "../requesters/fetchLastGameResult";
import useFabric from "../../../../../../../ud-ui/components/fabric-canvas/hooks/useFabric";
import useReplacer from "../../../../../../../ud-ui/components/fabric-canvas/hooks/useReplacer";
import { resolution, template } from "../../../../../../../ud-ui/components/fabric-canvas/types";
import { getFouls } from "../../../../../../../stream/domain/utils/getFouls";

type CanvasProps = {
  game: Game
  championship: ChampionshipStateData
  template?: template
  resolution?: resolution
}

const CanvasComponent = ({ game, championship, template, resolution }: CanvasProps) => {
  const { info } = useOrganizer()
  const fouls = useMemo(() => getFouls(game.id), [game.id])
  const { canvas, manager } = useFabric("static", {
    data: {
      ...(resolution ?? { width: 1920, height: 1080 }),
      backgroundColor: 'transparent'
    },
    style: canvasStyle
  })
  const replacer = useReplacer(manager)
  const lastResults = useMemo(async () => {
    const results = await getLastGameResult(game.id)
    const firstTeamLastResult = results.find((result) => result.team.id === game?.teams[ 0 ]?.id)?.lastGamesResults ?? []
    const secondTeamLastResult = results.find((result) => result.team.id === game?.teams[ 1 ]?.id)?.lastGamesResults ?? []
    firstTeamLastResult.length = Math.min(firstTeamLastResult.length, 3)
    secondTeamLastResult.length = Math.min(secondTeamLastResult.length, 3)
    return [firstTeamLastResult.map(result => result.result as 'WON' | 'DRAW' | 'LOST'), secondTeamLastResult.map(result => result.result as 'WON' | 'DRAW' | 'LOST')]
  }, [game])
  
  useEffect(() => {
    if (!manager) return
    if (!resolution) return
    manager.setResolution(resolution)
    manager.setParentSize()
    manager.normalizedViewport()
    return () => {
      if (manager) manager.dispose()
    }
  }, [manager, resolution])
  
  useEffect(() => {
    if (!info) {
      console.warn({ event: 'info not found' });
      return;
    }
    
    if (!manager) {
      console.warn({ event: 'fabricManager not found' });
      return
    }
    
    const replaceAllPlaceHolders = () => {
      const firstTeam = game.teams[ 0 ]
      const secondTeam = game.teams[ 1 ]
      const city = championship.address?.city ? championship.address?.city.split(' ')[ 1 ] : 'Город'
      if (!firstTeam || !secondTeam) {
        console.warn({ event: 'teams not found', firstTeam, secondTeam });
        return
      }
      if (!firstTeam.logo || !secondTeam.logo) {
        console.warn({ event: 'logo not found', firstTeam, secondTeam });
        return
      }
      if (!info.logoUrl) {
        console.warn({ event: 'logoUrl not found', info });
        return
      }
      
      replacer.replaceImage('teamLogo0', firstTeam.logo.url)
      replacer.replaceImage('teamLogo1', secondTeam.logo.url)
      replacer.replaceImage('organizerLogo', info.logoUrl)
      replacer.replaceText('teamName0', firstTeam.name.toUpperCase())
      replacer.replaceText('teamName1', secondTeam.name.toUpperCase())
      replacer.replaceText('teamScore0', (firstTeam.score ?? 0).toString())
      replacer.replaceText('teamScore1', (secondTeam.score ?? 0).toString())
      replacer.replaceText('city', city)
      replacer.replaceText('championshipName', championship.name)
      replacer.replaceText('startMatchTime', format(new Date(game.date), 'HH:mm'))
      replacer.replaceText('startMatchDate', format(new Date(game.date), 'd MMMM', { locale: ru }))
      replacer.replaceText('matchTime', '00:00')
      fouls.then((fouls) => {
        replacer.replaceText('teamFouls0', (fouls[ 0 ] ?? 0).toString())
        replacer.replaceText('teamFouls1', (fouls[ 1 ] ?? 0).toString())
      })
      lastResults.then((results) => {
        replacer.replaceLastResultGame('lastGameResults0', results[ 0 ])
        replacer.replaceLastResultGame('lastGameResults1', results[ 1 ])
      })
    }
    
    if (template) {
      const data = { template, safeBackground: true };
      manager.loadLocal(data).then(replaceAllPlaceHolders);
    } else {
      manager.clear(true)
    }
  }, [championship.address?.city, championship.name, fouls, game.date, game.teams, info, lastResults, manager, replacer, template])
  
  return canvas
};

const canvasStyle = { height: '100%' }

export default CanvasComponent;
