import React, { useCallback, useMemo } from "react";
import { useOrganizer } from "../../../../../organizer/ui/hooks/useOrganizer";
import { useDispatch, useSelector } from "react-redux";
import { selectActiveCategoryId, selectCategories } from "../../../../store/selectors";
import supabase from "../../../../../core/supabase/supabase";
import { setCategories as setCategoriesToStore } from "../../../../store/templateEditor";
import useUniversalModal, { IUNModalProps } from "../useUniversalModal";
import SizeInputs from "./elements/size/sizeInputs";
import widthValidator from "../../../../domain/validators/width.validator";
import heightValidator from "../../../../domain/validators/height.validator";
import categoryNameOnEditValidator from "../../../../domain/validators/categoryName.onEdit.validator";

function useEditModal() {
  const dispatch = useDispatch()
  const { info } = useOrganizer()
  const categoryId = useSelector(selectActiveCategoryId)
  const categories = useSelector(selectCategories)
  const currentCategory = useMemo(() => {
    if (!categoryId) return
    if (!categories) return
    return categories?.find(category => category.id === categoryId)
  }, [categories, categoryId]);
  
  const validator = useCallback((values: { [ p: string ]: string }) => {
    const errors: { [ p: string ]: string | undefined } = {}
    
    const nameError = categoryNameOnEditValidator(values.name?.trim(), categories?.map(category => category.name), currentCategory?.name)
    if (nameError) {errors.name = nameError}
    const widthError = widthValidator(values.width)
    if (widthError) {errors.width = widthError}
    const heightError = heightValidator(values.height)
    if (heightError) {errors.height = heightError}
    
    return errors
  }, [categories, currentCategory?.name]);
  
  const onSubmit = useCallback((values: { [ p: string ]: string }) => {
    if (!info) return
    const { width, height, name } = values
    
    const initialName = categories?.find(category => category.id === categoryId)?.name
    if (!initialName) return
    if (!categoryId) return
    
    const resolution = { width: +width, height: +height };
    supabase.overlayTemplates.updateRow({ rowId: categoryId }, { category: name.trim(), resolution })
    dispatch(setCategoriesToStore(categories?.map(category => category.name === initialName ? {
      ...category,
      name: name.trim(),
      resolution,
    } : category)))
  }, [info, categories, categoryId, dispatch]);
  
  const size = useMemo(() => <SizeInputs/>, [])
  
  const data: IUNModalProps = useMemo(() => {
    return {
      onSubmit,
      headerText: 'Редактирование',
      validator,
      initialValue: {
        name: currentCategory?.name ?? '',
        width: currentCategory?.resolution.width?.toString() ?? '',
        height: currentCategory?.resolution.height?.toString() ?? '',
      },
      buttonText: 'Сохранить',
      input: {
        placeholder: 'Название шаблона'
      },
      additionalComponent: size,
    }
  }, [currentCategory?.name, currentCategory?.resolution?.height, currentCategory?.resolution?.width, onSubmit, size, validator]);
  
  const { modal, close, open } = useUniversalModal(data)
  
  return { modal, close, open }
}

export default useEditModal;
