import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useChampionship } from './use-championship';
import { useEffect } from 'react';
import { fetchChampionship } from '../../store/championship/actions';

export const useChampionshipFetch = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const { isLoading } = useChampionship();
  const championshipId = id ? parseInt(id) : null;

  useEffect(() => {
    if (!isLoading && championshipId) {
      dispatch(fetchChampionship(championshipId));
    }
  }, [championshipId]);
};
