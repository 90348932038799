import React, { useCallback } from 'react';
import classNames from 'classnames';
import { Team } from 'modules/teams/domain/interfaces/Team';
import UDFormSelect from 'modules/ud-form/components/select';
import { SelectOption, UDSelect, UDSelectProps } from 'modules/ud-form/components/select/component';
import overridedSelectComponents from 'modules/ud-form/components/select/component/override';
import { teamToTeamOption } from '../team-select-option';
import { StyledSelectControl, StyledSelectPlaceholder } from './bracket-team-field.styles';

const CustomSelect = (props: UDSelectProps) => {
  const components = {
    ...overridedSelectComponents,
    Control: (props: any) => {
      return (
        <StyledSelectControl
          {...props.innerProps}
          className={classNames('d-flex flex-row', {
            disabled: props.isDisabled,
            focused: props.isFocused,
            multi: props.isMulti,
            opened: props.menuIsOpen,
          })}>
          {props.children}
        </StyledSelectControl>
      );
    },
  };

  return <UDSelect {...props} components={components} />
};

type BracketTeamFieldProps = {
  index: number;
  roundId: number;
  bracketId: number;
  teams: Team[];
  onBracketTeamChange: (index: number, teamId: number | null) => void;
};
export const BracketTeamField = ({ index, roundId, bracketId, teams, onBracketTeamChange }: BracketTeamFieldProps) => {
  const teamsOptions = teams.map(teamToTeamOption);

  const handleChange = useCallback((value: SelectOption<number> | null) => {
    if (!onBracketTeamChange) return;

    const teamId = value ? value.value : null;
    onBracketTeamChange(index, teamId);
  }, [index, onBracketTeamChange]);

  return (
    <UDFormSelect
      name={`rounds[${roundId}].brackets[${bracketId}][${index}]`}
      options={teamsOptions}
      placeholder={(
        <StyledSelectPlaceholder>Выбрать команду</StyledSelectPlaceholder>
      )}
      selectComponent={CustomSelect}
      onChange={handleChange}
    />
  );
};
