import { GameEvent } from '../domain/interfaces/GameDetails';

export const parseGameEventTime = (seconds: number, period: number): { minutes: number, extraMinutes: number } => {
  const periodDuration = 30;

  const eventMinute = Math.floor(seconds / 60);
  const isExtraTime = eventMinute >= period * periodDuration;
  const minutes = isExtraTime ? period * periodDuration : eventMinute;
  const extraMinutes = eventMinute - minutes;

  return {
    minutes,
    extraMinutes,
  };
};

export const sortGameEvents = (events: GameEvent[]): GameEvent[] => {
  return events.sort((a, b) => {
    if (a.period === 0 && b.period > 0) {
      return 1;
    }

    if (b.period === 0 && a.period > 0) {
      return -1;
    }

    const periodDiff = a.period - b.period;
    if (periodDiff !== 0) {
      return periodDiff;
    }

    const secondsDiff = a.seconds - b.seconds;
    if (secondsDiff !== 0) {
      return secondsDiff;
    }

    return a.id - b.id;

    // const order: number = b.minutes - a.minutes;
    // if (order) {
    //   return order;
    // }
    //
    // const aExtra = a.extraMinutes ? a.extraMinutes : 9999;
    // const bExtra = b.extraMinutes ? b.extraMinutes : 9999;
    //
    // return aExtra - bExtra;
  });
};
