/** @jsxImportSource @emotion/react */
import React from 'react';
import { MainBlock, stylesOptions } from "./styles";
import HeaderItem from "../../components/header/elements/headerItem";
import { SelectOption, UDSelect } from "../../../../ud-form/components/select/component";
import { SingleValue } from 'react-select';
import overridedSelectComponents from "../../../../ud-form/components/select/component/override";
import classNames from "classnames";
import * as S from "../../../../ud-form/components/select/component/styles";
import UDText from "../../../../ud-ui/components/text";
import { DivProps } from "../../../../../typings";


type IProps = Omit<DivProps, 'onChange'> & {
  headerText: string
  onChange?: (value: { label: string | number | JSX.Element, value: string } | undefined) => void
  options: { label: string, value: string }[]
  value?: { label: string, value: string }
}

function Select(props: IProps) {
  const { headerText, options, value, onChange, ...rest } = props;
  
  const onChangeComponent = (newValue: SingleValue<SelectOption<string, string>>) => {
    if (!onChange) { return; }
    if (!newValue) { return; }
    onChange({ label: newValue.label, value: newValue.value });
  }
  
  const changedStyles = { ...overridedSelectComponents }
  changedStyles.Option = (props: any) => {
    const className = classNames(
      'd-flex align-items-center justify-content-between px-4',
      props.isMulti ? 'py-1' : 'py-2',
    );
    
    return (
      <S.Option
        css={stylesOptions}
        {...props.innerProps}
        className={className}>
        <UDText type="subhead">{props.children}</UDText>
        {props.isMulti && (
          <S.OptionCheckbox data-checked={props.isSelected}/>
        )}
      </S.Option>
    );
  };
  
  return (
    <MainBlock {...rest}>
      <HeaderItem header={{text: headerText }}>
        <UDSelect
          isMulti={false}
          options={options}
          value={value}
          onChange={onChangeComponent}
          components={changedStyles}
        />
      </HeaderItem>
    </MainBlock>
  );
}

export default Select;
