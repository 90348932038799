import styled from '@emotion/styled';
import React from 'react';
import { SymbolicTeam } from '../../../../domain/interfaces/SymbolicTeam';
import UDIcon from '../../../../../ud-ui/components/icon';
import UDButton, { UDButtonProps } from '../../../../../ud-ui/components/button';

const SymbolicTeamsGridContainer = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(2, 1fr);
`;

const SymbolicTeamCard = styled.div`
  background: #fff;
  border-radius: 8px;
  cursor: pointer;
  padding: 24px 16px;
`;

const AddSymbolicTeamCard = styled.div`
  border: 2px dashed grey;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  
  .add-symbolic-team-btn {
    display: flex;
    align-items: center;
    justify-content: center;  
  }
  .add-symbolic-team-btn-label {
    margin-left: 4px;
  }
`;

type SymbolicTeamsGridProps = {
  items: SymbolicTeam[];
  onSelect: (team: SymbolicTeam | null) => void;
};

export const SymbolicTeamsGrid = ({ items, onSelect }: SymbolicTeamsGridProps) => {
  return (
    <SymbolicTeamsGridContainer>
      {items.map((item) => (
        <SymbolicTeamCard
          key={item.id}
          onClick={() => onSelect(item)}
        >
          <div className="text callout">{item.name}</div>
        </SymbolicTeamCard>
      ))}
      <AddSymbolicTeamCard>
        <UDButton
          className="col-6 mt-3 mb-3 add-symbolic-team-btn"
          variant="dark"
          type="button"
          onClick={() => onSelect(null)}
        >
          <UDIcon name="plus" />
          <div className="add-symbolic-team-btn-label">Создать сборную</div>
        </UDButton>
      </AddSymbolicTeamCard>
    </SymbolicTeamsGridContainer>
  );
};
