import React from 'react';

interface IProps {
  width?: number;
  height?: number;
  color?: string;
}

export default function AlignTextLeftIcon(props: IProps) {
  const {
    width,
    height,
    color = "#242424",
    ...rest
  } = props;
  
  return (
    <svg width={width ?? 18} height={height ?? 18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M0 3.375C0 2.96079 0.335786 2.625 0.75 2.625H17.25C17.6642 2.625 18 2.96079 18 3.375C18 3.78921 17.6642 4.125 17.25 4.125H0.75C0.335787 4.125 0 3.78921 0 3.375ZM0 14.625C0 14.2108 0.335786 13.875 0.75 13.875H11.25C11.6642 13.875 12 14.2108 12 14.625C12 15.0392 11.6642 15.375 11.25 15.375H0.75C0.335786 15.375 0 15.0392 0 14.625ZM0 10.875C0 10.4608 0.335786 10.125 0.75 10.125H17.25C17.6642 10.125 18 10.4608 18 10.875C18 11.2892 17.6642 11.625 17.25 11.625H0.75C0.335787 11.625 0 11.2892 0 10.875ZM0 7.125C0 6.71079 0.335786 6.375 0.75 6.375H11.25C11.6642 6.375 12 6.71079 12 7.125C12 7.53921 11.6642 7.875 11.25 7.875H0.75C0.335786 7.875 0 7.53921 0 7.125Z"
        fill={color}/>
    </svg>
  );
}
