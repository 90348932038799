import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { ChampionshipTeamCardHeaderRow } from './styles';
import UDIcon from 'modules/ud-ui/components/icon';
import UDText from 'modules/ud-ui/components/text';
import TeamLogo from '../../../../ud-ui/components/team-logo';
import { DivProps } from "../../../../../typings";

type ChampionshipTeamCardHeaderProps = {
  teamName: string;
  teamLogo?: string;
  playersCount?: number;
  isExpanded: boolean;
  onExpandClick?: () => void;
  containerProps?: Omit<DivProps, 'children'>;
  prefixComponent?: JSX.Element;
  actions?: JSX.Element;
};

const ChampionshipTeamCardHeader = (props: ChampionshipTeamCardHeaderProps) => {
  const { t } = useTranslation();

  const {
    teamName,
    teamLogo,
    playersCount,
    isExpanded,
    onExpandClick,
    containerProps,
    prefixComponent,
    actions,
  } = props;

  const hasPlayersCount = playersCount !== undefined;

  return (
    <ChampionshipTeamCardHeaderRow
      {...containerProps}
      className={classNames(
        { expanded: isExpanded },
        containerProps?.className
      )}
    >
      <div className="team-info">
        {onExpandClick && (
          <div className="team-card-expand-btn" onClick={onExpandClick}>
            <UDIcon name="down-indicator" />
          </div>
        )}

        {prefixComponent}

        <TeamLogo className="team-logo mr-2" url={teamLogo} size={52} />

        <UDText type="subhead" className="bold">
          {teamName}
        </UDText>
      </div>
      {hasPlayersCount && (
        <UDText type="subhead" className="team-players-count">
          {t('teams.playersCount', { count: playersCount })}
        </UDText>
      )}
      {actions}
    </ChampionshipTeamCardHeaderRow>
  );
};

export default ChampionshipTeamCardHeader;
