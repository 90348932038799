import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { tourneysImageEntitiesAdapter } from './adapters';

export const tourneysCommonStoreSelector = (state: RootState) =>
  state.tourneys.common;

export const tourneysCommonStoreImagesSelector = createSelector(
  tourneysCommonStoreSelector,
  (state) =>
    tourneysImageEntitiesAdapter.getSelectors().selectAll(state.images),
);
