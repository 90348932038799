const rightLarge = (
  <path
    d="M5.6002 13.6L11.2002 8.00001L5.6002 2.40001"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
);
export default rightLarge;
