import React from 'react';
import { FieldArray } from 'formik';
import * as DnD from 'react-beautiful-dnd';
import UDText from 'modules/ud-ui/components/text';
import UDColorableIcon from 'modules/ud-ui/components/icon/colorable-icon';
import { onDragEnd } from 'modules/ud-ui/helpers/drag-n-drop';
import { useTranslation } from 'react-i18next';

const TourneysComponentsFormRulesPartRanking = () => {
  const { t } = useTranslation();

  return (
    <>
      <UDText type="callout" className="mb-3">
        ♟ Ранжирование команд в случае равенства очков
      </UDText>
      <FieldArray name="settings.ranking">
        {(fieldProps) => (
          <DnD.DragDropContext onDragEnd={(r) => onDragEnd(r, fieldProps.move)}>
            <DnD.Droppable
              droppableId="ranking-rules"
              direction="vertical"
              type="ranking-rule">
              {(dnd) => (
                <div ref={dnd.innerRef} {...dnd.droppableProps}>
                  {fieldProps.form.values.settings.ranking.map(
                    (rule: any, index: number) => (
                      <DnD.Draggable
                        draggableId={`ranking-rule-${index}`}
                        index={index}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="d-flex flex-row align-items-center py-1"
                            key={rule.value}>
                            <UDColorableIcon
                              name="drag"
                              componentProps={{
                                className: 'mr-2 color-SurfaceRomanSilver30',
                              }}
                            />
                            <UDText
                              type="subhead"
                              className="color-SurfaceRaisinBlack50">
                              {t(`championship.settings.${rule.name}`)}
                            </UDText>
                          </div>
                        )}
                      </DnD.Draggable>
                    ),
                  )}
                  {dnd.placeholder}
                </div>
              )}
            </DnD.Droppable>
          </DnD.DragDropContext>
        )}
      </FieldArray>
    </>
  );
};

export default TourneysComponentsFormRulesPartRanking;
