const alignCenterHorizontal = (<>
  <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="7.5" width="1" height="16" rx="0.5" transform="rotate(-90 0 7.5)" fill="#007AFF"/>
    <path
      d="M7 13.5C5.89543 13.5 5 12.6046 5 11.5L5 2.5C5 1.39543 5.89543 0.5 7 0.5L9 0.5C10.1046 0.5 11 1.39543 11 2.5L11 11.5C11 12.6046 10.1046 13.5 9 13.5L7 13.5Z"
      fill="#007AFF"/>
  </svg>
</>)

export default alignCenterHorizontal
