import React from 'react';
import TourneysComponentsCreateFormTabs from './tabs';
import { TourneyEncoded } from 'modules/tourneys/domain/interfaces/TourneyEncoded';
import { useOrganizer } from '../../../../organizer/ui/hooks/useOrganizer';
import { ChampionshipInfoForm } from 'features/championship';

type Props = {
  onSubmit: (data: Partial<TourneyEncoded>) => any;
};

const TourneyComponentsCreateForm = (props: Props) => {
  const { info } = useOrganizer();

  if (!info) {
    return null;
  }

  return (
    <>
      <div className="container py-6">
        <TourneysComponentsCreateFormTabs />
      </div>
      <ChampionshipInfoForm tournamentId={info!.id} />
    </>
  );
};

export default TourneyComponentsCreateForm;
