import React from 'react';

type CupIconProps = {
  width: number;
  height: number;
  color?: string;
};

const CupIcon = (props: CupIconProps) => {
  const {
    width,
    height,
    color = '#2D2D47',
  } = props;

  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 14 16"
      fill="none" 
    >
      <path
        d="M11 1H3C3.16667 3.35062 4.2 8.05185 7 8.05185C9.8 8.05185 10.8333 3.35062 11 1Z"
        fill={color}
      />
      <path
        d="M6 10.5704V7.91326L6.95584 8.2L8 7.93557V10.5704H9V12.0815H9.5C9.5 12.0815 9.32535 12.737 9.5 13.0889C9.62349 13.3377 10 13.5926 10 13.5926V14.6H4V13.5926C4 13.5926 4.37651 13.3377 4.5 13.0889C4.67465 12.737 4.5 12.0815 4.5 12.0815H5V10.5704H6Z"
        fill={color}
      />
      <path
        d="M11 2.00741H13C12.6667 3.51852 11.5 6.54074 9.5 6.54074M3 2.00741H1C1.33333 3.51852 2.5 6.54074 4.5 6.54074M5 12.0815V10.5704H6V7.91326L6.95584 8.2L8 7.93557V10.5704H9V12.0815M5 12.0815L4.5 12.0815C4.5 12.0815 4.67465 12.737 4.5 13.0889C4.37651 13.3377 4 13.5926 4 13.5926V14.6H10V13.5926C10 13.5926 9.62349 13.3377 9.5 13.0889C9.32535 12.737 9.5 12.0815 9.5 12.0815H9M5 12.0815H9M3 1H11C10.8333 3.35062 9.8 8.05185 7 8.05185C4.2 8.05185 3.16667 3.35062 3 1Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CupIcon;