import { Resource } from '../../../core/baseResource';
import httpResource from '../../../core/httpResource';

class OrganizerResource extends Resource {
  constructor() {
    super('tournaments', httpResource);
  }

  public async uploadLogo(tournamentId: number, logo: File) {
    const formData = new FormData();

    formData.append('logo', logo);
    formData.append('tournamentId', String(tournamentId));

    return await this.child('logo').post(formData);
  }
}

const organizerResource = new OrganizerResource();

export default organizerResource;
