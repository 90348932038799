import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { TFunction, useTranslation } from 'react-i18next';
import { unwrapResult } from '@reduxjs/toolkit';
import { Form, Formik } from 'formik';
import { getTeamPlayersNumberOptions } from 'shared/utils';
import { TeamPlayersNumber } from 'shared/types/team-players-number';
import UDFormDateRange from 'modules/ud-form/components/datetime/range';
import UDFormTextearea from 'modules/ud-form/components/input/textarea';
import UDFormSelect, { UDFormAddressSelect } from 'modules/ud-form/components/select';
import UDFormInput from 'modules/ud-form/components/input';
import UDButton from 'modules/ud-ui/components/button';
import { championshipDataSelector, championshipISavingSelector } from 'modules/tourneys/store/championship/selectors';
import { fetchOrganizer } from 'modules/organizer/store/actions';
import { upsertChampionship } from 'modules/tourneys/store/championship/actions';
import { Pages } from 'modules/navigation/domain/enums/pages';
import { AppDispatch } from 'store/store';
import {
  mapAddressToDadataArea,
  mapDadataAreaToAddress
} from 'shared/features/dadata-address-autocomplete/infra/mappers/dadata-area.mapper';
import { isEmptyAddress } from 'modules/core/helpers/isEmptyAddress';
import UdFormTemplateSelect from "../../../../../modules/ud-form/components/select/ud-form-template-select";
import supabase from "../../../../../modules/core/supabase/supabase";
import _ from "lodash";

const getTranslatedTeamPlayersNumberOption = (t: TFunction, playersNumber: number) => ({
  label: t(`championship.info.teamPlayersNumber.${playersNumber}`),
  value: playersNumber,
});

const getTranslatedTeamPlayersNumberOptions = (t: TFunction) => getTeamPlayersNumberOptions()
  .map(num => getTranslatedTeamPlayersNumberOption(t, num));

type ChampionshipInfoFormProps = {
  tournamentId: number;
  initialValues?: {
    id?: number;
    name: string;
    startDate: Date;
    endDate: Date;
  };
  onCreateGameModalOpen?: () => void;
};

export const ChampionshipInfoForm = ({ tournamentId }: ChampionshipInfoFormProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const championship = useSelector(championshipDataSelector);
  
  const isChampionshipSaving = useSelector(championshipISavingSelector);
  const navigate = useNavigate();
  
  const hasChampionship = !!championship;
  const typeOptions = getTranslatedTeamPlayersNumberOptions(t);
  const teamPlayersNumber = championship?.settings?.teamPlayersNumber || TeamPlayersNumber.FIVE;
  const tournamentType = getTranslatedTeamPlayersNumberOption(t, teamPlayersNumber);
  
  const overlay =  {
    label: championship?.settings.overlayId === null ? 'ImGame' : 'Загрузка',
    value: championship && championship.settings.overlayId ? +championship.settings.overlayId : null,
  }
  const overlayDefaultOptions = [overlay]
  const [overlayOptions, setOverlayOptions] = React.useState(overlayDefaultOptions)
  
  useEffect(() => {
    supabase.overlayTemplates.getAllUserRows(tournamentId).then((rows) => {
        if (!rows) return
        setOverlayOptions(rows.sort((a, b) => a.id - b.id).map((row) => ({
          label: row.name === 'ImGame (стандарт)' ? 'ImGame' : row.name,
          value: row.id
        })))
      }
    )
  }, [tournamentId]);
  
  
  const initialValues = {
    name: championship?.name || '',
    description: championship?.description || '',
    startDate: championship?.startDate ? new Date(championship.startDate) : '',
    endDate: championship?.endDate ? new Date(championship.endDate) : '',
    tournamentType,
    address: isEmptyAddress(championship?.address) ? undefined : mapAddressToDadataArea(championship?.address!),
    overlay: _.find(overlayOptions, {value: championship?.settings.overlayId}) ,
  };
  
  const handleSubmit = useCallback((values) => {
    const { address, tournamentType, overlay, ...otherValues } = values;
    const updatedAddress = address ? mapDadataAreaToAddress(address) : address;
    
    const data = {
      ...otherValues,
      id: championship?.id,
      tournamentId,
      address: updatedAddress,
      teamPlayersNumber: tournamentType.value,
      overlayId: overlay?.value ?? null,
    };
    
    dispatch(upsertChampionship(data)).then((result) => {
      dispatch(fetchOrganizer({ tournamentId }));
      
      if (!hasChampionship) {
        const championshipId = unwrapResult(result);
        const pageUrl = Pages.TOURNEYS.builders.edit(championshipId, 'rules');
        
        navigate(pageUrl, { replace: true });
      }
    });
  }, [championship?.id, dispatch, hasChampionship, navigate, tournamentId]);
  
  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={handleSubmit}
    >
      {(formProps) => (
        <Form onSubmit={formProps.handleSubmit}>
          <div className="container g-2">
            <div className="col-6">
              <UDFormInput
                name="name"
                label={t('championship.info.nameInput.label')}
                placeholder={t('championship.info.nameInput.placeholder')}
                containerProps={{ className: 'mb-5' }}
              />
              <UDFormDateRange
                autoComplete="off"
                startName="startDate"
                endName="endDate"
                startLabel={t('championship.info.startDateInput.label')}
                endLabel={t('championship.info.endDateInput.label')}
                containerProps={{ className: 'mb-5' }}
              />
              <UDFormSelect
                name="tournamentType"
                label={t('championship.info.typeSelect.label')}
                placeholder={t('championship.info.typeSelect.placeholder')}
                containerProps={{ className: 'mb-5' }}
                options={typeOptions}
              />
              <UDFormAddressSelect
                name="address"
                label={t('championship.info.addressInput.label')}
                placeholder={t('championship.info.addressInput.placeholder')}
                containerProps={{ className: 'mb-5' }}
              />
              <UdFormTemplateSelect
                name="overlay"
                label={t('championship.info.overlayInput.label')}
                placeholder={t('championship.info.overlayInput.placeholder')}
                containerProps={{ className: 'mb-5' }}
                options={overlayOptions}
              />
              <UDFormTextearea
                name="description"
                label={t('championship.info.descriptionInput.label')}
                placeholder={t('championship.info.descriptionInput.placeholder')}
                containerProps={{ className: 'mb-5' }}
              />
            </div>
            
            <UDButton
              variant="primary"
              type="submit"
              disabled={isChampionshipSaving || !formProps.dirty}
              className="col-6 mt-3 mb-3"
            >
              {t('championship.info.saveButton.title')}
            </UDButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};
