import React, { useCallback } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import UDButton from 'modules/ud-ui/components/button';
import UDColorableIcon from 'modules/ud-ui/components/icon/colorable-icon';
import * as S from './styles';
import { Pages } from '../../../navigation/domain/enums/pages';

export type Tab = {
  hash: string;
  label: React.ReactChild;
  disabled?: boolean;
};

type UDTabProps = {
  tab: Tab;
};

const UDTab = ({ tab }: UDTabProps) => {
  const navigate = useNavigate();
  const isActiveTourney = useMatch(`${Pages.TOURNEYS.EDIT}/${tab.hash}/*`);
  const isActiveTeam = useMatch(`${Pages.TEAMS.EDIT}/${tab.hash}/*`);
  const isActive = isActiveTeam || isActiveTourney;

  const handleTabChange: React.MouseEventHandler<HTMLButtonElement> =
    useCallback(
      (e) => {
        const tabPath = e.currentTarget.dataset.hash;
        if (tabPath) {
          navigate(tabPath, { replace: true });
        }
      },
      [navigate]
    );

  return (
    <UDButton
      key={tab.hash}
      disabled={tab.disabled}
      variant={isActive ? 'primary' : 'light'}
      data-hash={tab.hash}
      onClick={handleTabChange}
      type="button"
      className="control d-flex align-items-center"
    >
      {tab.label}
      <UDColorableIcon
        name="arrow-right"
        componentProps={{
          className: classNames('arrow ml-2', { active: isActive }),
        }}
      />
    </UDButton>
  );
};

type Props = {
  tabs: Tab[];
  beforeChangeTab?: (hash: string) => any;
  className?: string;
  defaultTab: Tab;
};

const UDTabs = (props: Props) => {
  const { tabs, className } = props;

  return (
    <S.TabsContainer
      className={classNames('d-flex flex-row align-items-center', className)}
    >
      {tabs.map((tab) => (
        <UDTab key={tab.hash} tab={tab} />
      ))}
    </S.TabsContainer>
  );
};

export default UDTabs;
