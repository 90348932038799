import React from 'react';

interface IProps {
  height?: number;
  width?: number;
  color?: string;
}

function QuestionMarkIcon(props: IProps) {
  const {
    height,
    width,
  } = props
  
  return (
    <svg width={width ?? 18} height={height ?? 18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="9" cy="9" r="8.65" stroke="currentColor" strokeWidth="0.7"/>
      <path
        strokeWidth={0.2}
        d="M8.32662 10.9545V10.9119C8.33135 10.4598 8.3787 10.0999 8.46866 9.83239C8.55862 9.56487 8.68646 9.34825 8.85218 9.18253C9.0179 9.01681 9.21677 8.86411 9.44877 8.72443C9.58845 8.6392 9.71393 8.53859 9.8252 8.42259C9.93646 8.30421 10.0241 8.16809 10.088 8.0142C10.1543 7.86032 10.1874 7.68987 10.1874 7.50284C10.1874 7.27083 10.133 7.0696 10.0241 6.89915C9.91516 6.72869 9.76956 6.5973 9.58727 6.50497C9.40498 6.41264 9.20256 6.36648 8.98002 6.36648C8.7859 6.36648 8.59887 6.40672 8.41895 6.48722C8.23902 6.56771 8.08869 6.69437 7.96795 6.86719C7.84721 7.04001 7.77737 7.2661 7.75843 7.54545H6.86355C6.88249 7.14299 6.98665 6.79853 7.17605 6.51207C7.36781 6.22562 7.61994 6.00663 7.93244 5.85511C8.24731 5.7036 8.5965 5.62784 8.98002 5.62784C9.39669 5.62784 9.75891 5.7107 10.0667 5.87642C10.3768 6.04214 10.6159 6.26941 10.784 6.55824C10.9545 6.84706 11.0397 7.17614 11.0397 7.54545C11.0397 7.80587 10.9994 8.04143 10.9189 8.25213C10.8408 8.46283 10.7272 8.65104 10.578 8.81676C10.4313 8.98248 10.2537 9.12926 10.0454 9.2571C9.83703 9.38731 9.67013 9.52462 9.54466 9.66903C9.41918 9.81108 9.32804 9.98035 9.27122 10.1768C9.2144 10.3733 9.18362 10.6184 9.17889 10.9119V10.9545H8.32662ZM8.78116 13.0568C8.60597 13.0568 8.45564 12.9941 8.33017 12.8686C8.20469 12.7431 8.14196 12.5928 8.14196 12.4176C8.14196 12.2424 8.20469 12.0921 8.33017 11.9666C8.45564 11.8411 8.60597 11.7784 8.78116 11.7784C8.95635 11.7784 9.10668 11.8411 9.23216 11.9666C9.35763 12.0921 9.42037 12.2424 9.42037 12.4176C9.42037 12.5336 9.39077 12.6402 9.33159 12.7372C9.27477 12.8343 9.19783 12.9124 9.10076 12.9716C9.00607 13.0284 8.89953 13.0568 8.78116 13.0568Z"
        fill="currentColor"/>
    </svg>
  );
}

export default QuestionMarkIcon;
