import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Pages } from 'modules/navigation/domain/enums/pages';
import UDButton from 'modules/ud-ui/components/button';
import UDIcon from 'modules/ud-ui/components/icon';
import UDMainLayout from 'modules/ud-ui/layout/main';
import { Tourney } from 'modules/tourneys/domain/interfaces/Tourney';
import { useTourneysList } from '../../hooks/useTourneysList';
import UDHeaderTitle from '../../../../ud-ui/components/header-title';
import { clearSelectedChampionship } from '../../../../tourneys/store/championship/actions';
import { TourneysBody } from '../../components/tourneys-body/tourneys-body';
import { TourneysBodySkeleton } from '../../components/tourneys-body/tourneys-body-skeleton';
import MainComponentsTourneysFilters from '../../components/tourneys-filters';

const MainPagesIndex = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { tourneys, years, filters, changeFilter, isLoading, pages } =
    useTourneysList();

  const goToTourneyCreate = useCallback(() => {
    dispatch(clearSelectedChampionship());
    navigate(Pages.TOURNEYS.CREATE);
  }, [dispatch, navigate]);

  const goToEdit = useCallback(
    (id: Tourney['id']) => {
      navigate(Pages.TOURNEYS.builders.edit(id));
    },
    [navigate]
  );

  const bodyNode = useMemo(() => {
    if (isLoading) {
      return <TourneysBodySkeleton resultsOnPage={filters.per_page} />;
    }

    return (
      <TourneysBody
        filters={filters}
        tourneys={tourneys}
        pages={pages}
        onDetail={goToEdit}
        onPageChange={(page) => changeFilter({ page })}
      />
    );
  }, [isLoading, changeFilter, filters, goToEdit, pages, tourneys]);

  return (
    <UDMainLayout
      headerContent={
        <>
          <UDHeaderTitle>Турниры</UDHeaderTitle>
          <UDButton
            variant="dark"
            className="d-flex align-items-center ml-6"
            onClick={goToTourneyCreate}
          >
            <UDIcon name="plus" componentProps={{ className: 'mr-2' }} />
            {t('championships.createTournamentButtonTitle')}
          </UDButton>
        </>
      }
    >
      <MainComponentsTourneysFilters
        onFilter={changeFilter}
        filters={filters}
        years={years}
      />
      {bodyNode}
    </UDMainLayout>
  );
};

export default MainPagesIndex;
