import styled from '@emotion/styled';

type TabMenuButtonProps = {
  className?: string;
  children: string;
  isActive: boolean;
  onClick?: () => void;
};

const TabMenuButton = (props: TabMenuButtonProps) => {
  const {
    className,
    children,
    onClick,
  } = props;

  return (
    <div
      className={className}
      onClick={onClick}
    >{children}</div>
  );
};

const StyledTabMenuButton = styled(TabMenuButton)<TabMenuButtonProps>`
  border-bottom: 2px solid;
  border-color: ${props => props.isActive ? '#23233B' : 'transparent'};
  color: #1A1A29;
  cursor: ${props => props.isActive ? 'auto' : 'pointer'};
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 700;
  font-size: 15px;
  padding: 4px 0;
`;

export default StyledTabMenuButton;
