export enum DadataAreaType {
  REGION = '1',
  CITY = '4',
  COMMUNITY = '6',
  STREET = '7',
  HOUSE = '8',
}

export interface IDadataArea {
  id?: number;
  type: DadataAreaType;
  value: string;
  city?: string;
  region?: string;
  country?: string;
  street?: string;
  house?: string;
  lat?: number;
  lon?: number;
}
