import React from 'react';
import MainComponentsTourneysList from '../tourneys-list';
import UDPagination from '../../../../ud-ui/components/pagination';
import { Championship } from '../../../../tourneys/domain/interfaces/Championship';
import { TourneyEncoded } from '../../../../tourneys/domain/interfaces/TourneyEncoded';

type TourneysBodyProps = {
  filters: any;
  tourneys: Championship[];
  pages: number;
  onDetail: (id: TourneyEncoded['id']) => any;
  onPageChange: (page: number) => any;
};

export const TourneysBody = (props: TourneysBodyProps) => {
  const {
    filters,
    tourneys,
    pages,
    onDetail,
    onPageChange,
  } = props;

  return (
    <>
      <MainComponentsTourneysList
        tourneys={tourneys}
        onDetail={onDetail}
      />
      <UDPagination
        className="justify-content-end px-6 my-8"
        pages={pages}
        currentPage={filters.page}
        onPageChange={onPageChange}
      />
    </>
  );
};
