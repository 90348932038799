import styled from '@emotion/styled';

export const PlayerRankWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const PlayerRankContainer = styled.div`
  border: 1px solid #F5D956;
  border-radius: 8px;
  padding: 8px 16px;
  text-align: center;
`;
