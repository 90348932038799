import { format } from 'date-fns';

const parseTime = (time: string): Date => {
  const timeWithSeconds = `${time}:00`;
  const [currentDateString] = new Date().toISOString().split('T');

  return new Date(`${currentDateString}T${timeWithSeconds}`);
};

const extractTime = (date: Date | null | string): any => {
  if (date === null) {
    return null;
  }
  if (date instanceof Date) {
    return format(date, 'HH:mm');
  }
  return date;
};

const timeHelpers = { parse: parseTime, extract: extractTime };
export { timeHelpers };
