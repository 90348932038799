import { TourneyRanking } from './TourneyRanking';
import { TeamPlayersNumber } from '../../../../shared/types/team-players-number';

export enum ChampionshipSettingsType {
  TEAM_PLAYERS_NUMBER = 'team_players_number',

  APPLICATION_START_DATE = 'application_start_date',
  APPLICATION_END_DATE = 'application_end_date',
  APPLICATION_MIN_PLAYERS = 'application_min_players',
  APPLICATION_MAX_PLAYERS = 'application_max_players',
  APPLICATION_TRANSFERS_ALLOWED = 'application_transfers_allowed',
  PARTICIPATION_FEE = 'participation_fee',

  WIN_SCORE = 'win_score',
  WIN_SCORE_EXTRA_TIME = 'win_score_extra_time',
  WIN_PENALTY_SERIES = 'win_penalty_series',

  LOSS_SCORE = 'loss_score',
  LOSS_SCORE_EXTRA_TIME = 'loss_score_extra_time',
  LOSS_PENALTY_SERIES = 'loss_penalty_series',
  TECHNICAL_DEFEAT_GOALS_COUNT = 'technical_defeat_goals_count',

  DRAW_SCORE = 'draw_score',

  GAME_PERIODS_NUMBER = 'game_periods_number',
  PERIOD_TIME = 'period_time',
  GAME_EXTRA_PERIODS_NUMBER = 'game_extra_periods_number',
  PERIOD_EXTRA_TIME = 'period_extra_time',

  RANKING_POINTS_PERSONAL_MEETINGS = 'ranking_points_personal_meetings',
  RANKING_WINS_PERSONAL_MEETINGS = 'ranking_wins_personal_meetings',
  RANKING_SCORED_CONCEDED_DIFF_PERSONAL_MEETINGS = 'ranking_scored_conceded_diff_personal_meetings',
  RANKING_SCORED_GOALS_PERSONAL_MEETINGS = 'ranking_scored_goals_personal_meetings',
  RANKING_SCORED_CONCEDED_DIFF_TOTAL = 'ranking_scored_conceded_diff_total',
  RANKING_SCORED_GOALS_TOTAL = 'ranking_scored_goals_total',
  RANKING_WINS_TOTAL = 'ranking_wins_total',
  
  OVERLAY = 'overlay',
  OVERLAY_ID = 'overlay_id',
}

export interface ChampionshipSettings {
  teamPlayersNumber: TeamPlayersNumber;

  applicationStartDate: string | null;
  applicationEndDate: string | null;
  applicationMinPlayers: number | null;
  applicationMaxPlayers: number | null;
  applicationTransfersAllowed: boolean;
  participationFee: number | null;

  winScore: number | null;
  winScoreExtraTime: number | null;
  winPenaltySeries: number | null;

  lossScore: number | null;
  lossScoreExtraTime: number | null;
  lossPenaltySeries: number | null;
  technicalDefeatGoalsCount: boolean;

  drawScore: number | null;

  gamePeriodsNumber: number | null;
  periodTime: number | null;
  gameExtraPeriodsNumber: number | null;
  periodExtraTime: number | null;

  ranking: TourneyRanking[];
  
  overlay: string | null;
  overlayId: number | null;
}
