import React from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { SchemaOf } from 'yup';
import { AuthRequestAuthCodePayload } from 'modules/auth/domain/interfaces/AuthRequestAuthCodePayload';
import UDFormInput from 'modules/ud-form/components/input';
import UDButton from 'modules/ud-ui/components/button';
import { formatPhone } from '../formatters/formatPhone';
import { parsePhone } from '../formatters/parsePhone';

type RequestAuthCodeFormProps = {
  initialValues: {
    phone: string;
  };
  isLoading: boolean;
  onSubmit: (values: AuthRequestAuthCodePayload) => void;
  validationSchema?: SchemaOf<AuthRequestAuthCodePayload>;
};

const RequestAuthCodeForm = (props: RequestAuthCodeFormProps) => {
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={props.initialValues}
      onSubmit={props.onSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={props.validationSchema}
    >
      {(formProps) => (
        <form onSubmit={formProps.handleSubmit}>
          <UDFormInput
            name="phone"
            label={t('signIn.phoneInputLabel')}
            placeholder={t('signIn.phoneInputPlaceholder')}
            containerProps={{ className: 'mb-6' }}
            formatter={formatPhone}
            parser={parsePhone}
          />
          <UDButton
            variant="primary"
            type="submit"
            className="w-100 mb-3"
            loading={props.isLoading}
          >
            {t('signIn.requestCodeButtonLabel')}
          </UDButton>
        </form>
      )}
    </Formik>
  );
}

export default RequestAuthCodeForm;
