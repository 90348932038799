const text = (
  <>
    <svg width="35" height="32" viewBox="0 0 35 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.2505 10.25H21.7505V16.7812V21.75H17.563H10.2505V10.25Z" stroke="#242424"/>
      <rect x="19.5" y="19.5" width="4.00038" height="4.00038" rx="1.5" fill="#FFFDFF" stroke="#242424"/>
      <rect x="19.5" y="8.5" width="4" height="4" rx="1.5" fill="#FFFDFF" stroke="#242424"/>
      <rect x="8.5" y="19.5" width="4.00002" height="4.00002" rx="1.5" fill="#FFFDFF" stroke="#242424"/>
      <rect x="8.5" y="8.5" width="4" height="4" rx="1.5" fill="#FFFDFF" stroke="#242424"/>
      <path d="M29 19L32 16L29 13" stroke="#8F8F8F" strokeWidth="1.1" strokeLinecap="round"/>
    </svg>
  </>
)

export default text
