import { createAsyncThunk, unwrapResult } from '@reduxjs/toolkit';
import tourneysRepository, {
  SetStageBracketsTeams, SetTournamentBracketParams,
  UpsertChampionshipStageParams, UpsertStageTeamGroupParams
} from '../../domain/repositories/tourneysRepository';
import { Stage, StageV2 } from '../../domain/interfaces/Stage';
import { TeamGroup } from '../../domain/interfaces/TeamGroup';
import { TournamentBracket } from '../../domain/interfaces/TournamentBracket';

const PREFIX = 'stages';

export const fetchStages = createAsyncThunk<Stage[], number>(
  `${PREFIX}/fetchStages`,
  async (championshipId) => {
    try {
      return await tourneysRepository.fetchChampionshipStages(championshipId);
    } catch (err) {
      console.error(`${PREFIX}/fetchStages error:`, err);
      throw err;
    }
  },
);

export const upsertStage = createAsyncThunk<StageV2, UpsertChampionshipStageParams>(
  `${PREFIX}/upsertStage`,
  async (params) => {
    try {
      const stage = await tourneysRepository.upsertChampionshipStage(params);
      const { teamGroups, ...data } = stage;
      const teamGroupIds = teamGroups.map(group => group.id);

      return {
        ...data,
        teamGroupIds,
      };
    } catch (err) {
      console.error(`${PREFIX}/upsertStage error:`, err);
      throw err;
    }
  },
);

export const deleteStage = createAsyncThunk<boolean, number>(
  `${PREFIX}/deleteStage`,
  async (stageId) => {
    try {
      return await tourneysRepository.deleteChampionshipStage(stageId);
    } catch (err) {
      console.error(`${PREFIX}/deleteStage error:`, err);
      throw err;
    }
  },
);

export const upsertStageTeamGroup = createAsyncThunk<TeamGroup, UpsertStageTeamGroupParams>(
  `${PREFIX}/upsertTeamGroup`,
  async (params) => {
    try {
      return await tourneysRepository.upsertStageTeamGroup(params);
    } catch (err) {
      console.error(`${PREFIX}/upsertTeamGroup error:`, err);
      throw err;
    }
  }
);

export const deleteStageTeamGroup = createAsyncThunk<boolean, number>(
  `${PREFIX}/deleteTeamGroup`,
  async (teamGroupId) => {
    try {
      return await tourneysRepository.deleteStageTeamGroup(teamGroupId);
    } catch (err) {
      console.error(`${PREFIX}/deleteTeamGroup error:`, err);
      throw err;
    }
  },
);

export const setStageBracketsTeams = createAsyncThunk<TournamentBracket[], SetStageBracketsTeams>(
  `${PREFIX}/setStageBracketsTeams`,
  async (params, { dispatch }) => {
    try {
      const promises = params.brackets.map((bracket) => {
        return dispatch(updateStageBracketTeams(bracket)).then(unwrapResult);
      });

      return await Promise.all(promises);

      // return await tourneysRepository.setStageBracketsTeams(params);
    } catch (err) {
      console.error(`${PREFIX}/setStageBracketsTeams error:`, err);
      throw err;
    }
  },
);

export const updateStageBracketTeams = createAsyncThunk<TournamentBracket, SetTournamentBracketParams>(
  `${PREFIX}/updateStageBracketTeams`,
  async (params) => {
    try {
      return await tourneysRepository.setTournamentBracket(params);
    } catch (err) {
      console.error(`${PREFIX}/updateStageBracketTeams error:`, err);
      throw err;
    }
  },
);
