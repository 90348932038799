import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { StageV2 } from '../../../../domain/interfaces/Stage';
import UDButton from '../../../../../ud-ui/components/button';
import UDIcon from '../../../../../ud-ui/components/icon';
import {
  showChampionshipStageForms
} from '../../../../store/championship/actions';
import { ChampionshipStateData } from '../../../../store/championship';
import { StagesGrid } from './stages-grid';
import { StagesGridSkeleton } from './stages-grid-skeleton';
import StageCard from './stage-card';
import StageForms from './stage-forms';
import { fetchStages } from '../../../../store/stages/stages.actions';
import {
  getStagesChampionshipIdSelector,
  isStagesIdleSelector, isStagesLoadedSelector, isStagesLoadingSelector, selectedStageSelector,
  stagesActions, stageSelectors
} from '../../../../store/stages';

const StyledAddStageBlock = styled.div`
  border: 2px dashed #8F8E99;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 157px;
`;

export const StyledAddStageButton = styled(UDButton)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledStagesBreadcrumbs = styled.div`
  display: inline-block;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  margin-bottom: 16px;
  
  .link {
    color: ${({ theme }) => theme.colors.SurfaceRaisinBlack50};
    cursor: pointer;
    font-size: 15px;
    
    &:after {
      content: '▸';
      color: ${({ theme }) => theme.colors.SurfaceRaisinBlack50};
      font-size: 16px;
      padding: 0 8px;
    }
    
    &:last-child {
      cursor: auto;

      &:after {
        display: none;
      }
    }
    
 
    &.new {
      color: ${({ theme }) => theme.colors.SurfaceRomanSilver30};
    }
  }
`;

type StagesProps = {
  championship: ChampionshipStateData;
};

const Stages = ({ championship }: StagesProps) => {
  const dispatch = useDispatch();

  const isIdle = useSelector(isStagesIdleSelector);
  const isLoading = useSelector(isStagesLoadingSelector);
  const isLoaded = useSelector(isStagesLoadedSelector);
  const stages = useSelector(stageSelectors.selectAll);
  const loadedStagesChampionshipId = useSelector(getStagesChampionshipIdSelector);
  const selectedStage = useSelector(selectedStageSelector);

  if (championship && (isIdle || (isLoaded && loadedStagesChampionshipId !== championship.id))) {
    dispatch(fetchStages(championship.id));
  }

  const { showStageForms } = championship;

  const handleAddStageClick = useCallback(() => {
    dispatch(showChampionshipStageForms(true));
  }, [dispatch]);

  const handleHideFormClick = useCallback(() => {
    dispatch(stagesActions.stageSelected(null));
    dispatch(showChampionshipStageForms(false));
  }, [dispatch]);

  const handleStageSelect = useCallback((stage: StageV2) => () => {
    dispatch(stagesActions.stageSelected(stage.id));
    dispatch(showChampionshipStageForms(true));
  }, [dispatch]);

  if (isLoading || !championship) {
    return (
      <div className="container">
        <StagesGridSkeleton />
      </div>
    );
  }

  if (!showStageForms) {
    return (
      <div className="container">
        <StagesGrid>
          {stages?.map((stage) => (
            <StageCard
              key={stage.id}
              stage={stage}
              onSelect={handleStageSelect(stage)}
            />
          ))}
          <StyledAddStageBlock>
            <StyledAddStageButton
              className="col-6 mt-3 mb-3"
              variant="dark"
              type="button"
              onClick={handleAddStageClick}
            >
              <UDIcon name="plus" />&nbsp;Создать этап
            </StyledAddStageButton>
          </StyledAddStageBlock>
        </StagesGrid>
      </div>
    );
  }

  return (
    <div>
      <StyledStagesBreadcrumbs className="container">
        <span
          className="link"
          onClick={handleHideFormClick}
        >Все этапы</span>
        {selectedStage
          ? (<span className="link">{selectedStage!.name}</span>)
          : (<span className="link new">Название</span>)
        }
      </StyledStagesBreadcrumbs>
      <StageForms championship={championship} />
    </div>
  );
};

export default Stages;
