import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { authCheck } from '../../store/actions';
import { useAuth } from './useAuth';

export const useAuthGuard = () => {
  const dispatch = useDispatch();
  const { isAuthed, isChecking } = useAuth();
  const location = useLocation();
  const fromPath = `${location.pathname}${location.hash}${location.search}`;
  
  useEffect(() => {
    if (!isAuthed && !isChecking) {
      dispatch(authCheck(fromPath));
    }
  }, [isAuthed, isChecking]);
};
