import styled from "@emotion/styled";

export const MainBlock = styled.div`
  border-radius: 50%;
  background-color: white;
  width: 30px;
  height: 30px;
  
  display: flex;
  justify-content: center;
  align-items: center;
`
