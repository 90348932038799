import React from 'react';
import UDFormInput from '../../../../../ud-form/components/input';
import UDFormDateRange from '../../../../../ud-form/components/datetime/range';
import UDText from '../../../../../ud-ui/components/text';
import UDFormCheckbox from '../../../../../ud-form/components/checkbox';

const ApplicationCompany = () => {
  return (
    <div className="row mt-5 g-2">
      <div className="col-6">
        <UDFormDateRange
          startName="settings.applicationStartDate"
          endName="settings.applicationEndDate"
          startLabel="Начало приема заявок"
          endLabel="Окончание приема заявок"
          containerProps={{ className: 'mb-5' }}
        />
        <div className="mb-4 d-flex align-items-center">
          <UDText type="callout" className="mr-auto color-SurfaceRaisinBlack50">
            Минимальное количество игроков в заявке
          </UDText>
          <UDFormInput
            name="settings.applicationMinPlayers"
            placeholder="0 чел"
            containerProps={{ className: 'w-30' }}
          />
        </div>
        <div className="mb-4 d-flex align-items-center">
          <UDText type="callout" className="mr-auto color-SurfaceRaisinBlack50">
            Максимальное количество игроков в заявке
          </UDText>
          <UDFormInput
            name="settings.applicationMaxPlayers"
            placeholder="0 чел"
            containerProps={{ className: 'w-30' }}
          />
        </div>
        <div className="d-flex align-items-center mb-4">
          <UDFormCheckbox name="settings.applicationTransfersAllowed" />
          <UDText type="callout" className="ml-2 color-SurfaceRaisinBlack50">
            Переходы возможны
          </UDText>
        </div>
        <div className="mb-4 d-flex align-items-center">
          <UDText type="callout" className="mr-auto color-SurfaceRaisinBlack50">
            Взнос за участие
          </UDText>
          <UDFormInput
            name="settings.participationFee"
            placeholder="0 ₽"
            containerProps={{ className: 'w-30' }}
          />
        </div>
      </div>
    </div>
  );
};

export default ApplicationCompany;
