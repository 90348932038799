import { useCallback, useEffect, useState } from "react";
import Interactive from "../canvases/interactive";
import Zoom from "../addons/zoom";
import { round } from "../../../../template-editor/domain/utils/helpers";

export default function useZoom(manager?: Interactive) {
  const [zoom, setZoom] = useState<number>(100)
  
  useEffect(() => {
    if (!manager) return
    Zoom.init(manager)
    Zoom.enableDragging()
    Zoom.enableZoom()
    
    Zoom.onChangeZoom.subscribe({
      update: (newZoom: number) => {
        setZoom(round(newZoom * 100, 0))
      }
    })
    
    setZoom((round((Zoom.getZoom() ?? 1) * 100, 0)))
  }, [manager])
  
  const reduceZoom = useCallback(() => {
    if (!manager) return
    const newZoom = zoom - 10
    Zoom.setZoom(newZoom / 100)
  }, [manager, zoom]);
  
  const increaseZoom = useCallback(() => {
    if (!manager) return
    const newZoom = zoom + 10
    Zoom.setZoom(newZoom / 100)
  }, [manager, zoom]);
  
  return {reduceZoom, increaseZoom, zoom}
}
