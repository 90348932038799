/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import UDMainLayout from "../../../ud-ui/layout/main";
import { history } from "../../../../store/store";
import { Pages } from "../../../navigation/domain/enums/pages";
import UDBreadcrumbs from "../../../ud-ui/components/breadcrumbs";
import UDButton from "../../../ud-ui/components/button";
import UDIcon from "../../../ud-ui/components/icon";
import ListContainer from "../components/elements/listContainer";
import styled from "@emotion/styled";
import { EmptyButton } from "../components/editor/rightToolbar/components";
import { useDispatch, useSelector } from "react-redux";
import { setActiveCategory, setCategories as setCategoriesToStore, setTemplate } from "../../store/templateEditor";
import { useOrganizer } from "../../../organizer/ui/hooks/useOrganizer";
import supabase from "../../../core/supabase/supabase";
import { selectCategories } from "../../store/selectors";
import NewEditIcon from "../../../ud-ui/components/icon/new-edit-icon";
import NewTrashIcon from "../../../ud-ui/components/icon/new-trash-icon";
import useCreateModal from "../hooks/modals/categories/create";
import useDeleteModal from "../hooks/modals/categories/delete";
import useEditModal from "../hooks/modals/categories/edit";

function ChoosingCategory() {
  const dispatch = useDispatch();
  dispatch(setTemplate({ templateName: undefined }))
  const categories = useSelector(selectCategories)
  const { isLoaded, info } = useOrganizer()
  
  useEffect(() => {
    dispatch(setActiveCategory(undefined))
  }, [dispatch])
  
  useEffect(() => {
    if (!info) return
    if (categories) return;
    supabase.overlayTemplates.getAllUserRows(info.id).then((result) => {
      if (!result) return
      dispatch(setCategoriesToStore(result))
    })
  }, [categories, dispatch, info])
  
  const breadcrumbsItems = [
    { label: 'Турниры', to: Pages.TOURNEYS.INDEX },
    { label: 'Шаблоны', to: Pages.TEMPLATE.INDEX },
  ];
  
  const breadcrumb = <UDBreadcrumbs className="my-10" breadcrumbs={breadcrumbsItems}/>
  
  const onClickTrashIcon = (categoryId: number) => {
    dispatch(setActiveCategory(categoryId))
    openDeleteModal()
  }
  
  const onClickRenameButton = (categoryId: number) => {
    dispatch(setActiveCategory(categoryId))
    openEditModal()
  }
  
  const CategoryList = categories?.map((category, index) => {
    const onClickCategory = () => {
      dispatch(setActiveCategory(category.id))
      history.push(Pages.TEMPLATE.CHOOSING)
    }
    
    const isDefault = index === 0
    
    return (
      <CategoryItem
        key={category.name}
        style={{ backgroundColor: index % 2 === 0 ? '#FFFFFF' : '#F7F7F7' }}
        className={'d-flex py-5 px-6 justify-content-between'}
      >
        <EmptyButton onClick={onClickCategory}><span>{category.name}</span></EmptyButton>
        <div className={'d-flex'} style={{ gap: 24 }}>
          <EmptyButton
            disabled={isDefault}
            onClick={onClickRenameButton.bind(null, category.id)}
            style={{ cursor: isDefault ? 'not-allowed' : 'pointer' }}
          >
            <NewEditIcon width={24} height={24} color={isDefault ? '#D0D0D0' : '#1A1A29'}/>
          </EmptyButton>
          <EmptyButton
            disabled={isDefault}
            onClick={onClickTrashIcon.bind(null, category.id)}
            style={{ cursor: isDefault ? 'not-allowed' : 'pointer' }}
          >
            <NewTrashIcon width={24} height={24} color={isDefault ? '#D0D0D0' : '#FF6E6E'}/>
          </EmptyButton>
        </div>
      </CategoryItem>
    )
  })
  
  const { modal: createModal, open: openCreateModal } = useCreateModal()
  const { modal: deleteModal, open: openDeleteModal } = useDeleteModal()
  const { modal: editModal, open: openEditModal } = useEditModal()
  
  return (
    <UDMainLayout headerContent={breadcrumb}>
      <div className={'d-flex w-100 justify-content-end mt-4 pr-6'}>
        <UDButton
          variant={"dark"}
          className="d-flex align-items-center"
          onClick={openCreateModal}
          disabled={!isLoaded}>
          <UDIcon name="plus" componentProps={{ className: 'mr-2' }}/>
          Добавить шаблон
        </UDButton>
      </div>
      <ListContainer
        className={'mt-4 px-6'}
        headerProps={{ title: 'Шаблоны' }}
        contentProps={{ className: 'h-100', style: { height: '100%' } }}
      >
        {CategoryList}
      </ListContainer>
      {createModal}
      {deleteModal}
      {editModal}
    </UDMainLayout>
  );
}

export default ChoosingCategory;

const CategoryItem = styled.div`
  font-family: SF Pro Text, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
`
