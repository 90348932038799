/** @jsxImportSource @emotion/react */
import React, { useRef } from "react";
import UDTooltip from "../../../../../ud-form/components/tooltip";
import { IconButton, tooltipStyles } from "./styles";
import UploadIcon from "../../../../../ud-ui/components/icon/upload-icon";
import InteractiveClickableIcon from "./interactiveClickableIcon";
import { fabric } from "fabric";
import customization from "../../../../../ud-ui/components/fabric-canvas/addons/customization";
import { selectActiveModal, selectFabricManager } from "../../../../store/selectors";
import { useSelector } from "react-redux";

export function AddImage() {
  const activeModal = useSelector(selectActiveModal);
  const fabricManager = useSelector(selectFabricManager);
  const inputFile = useRef<HTMLInputElement | null>(null);
  
  
  const onClickUploadImage = () => {
    if (!fabricManager) return
    if (!inputFile.current) return
    inputFile.current.click();
  }
  
  const onLoadImage = () => {
    if (!fabricManager) return
    if (!inputFile.current) return
    if (!inputFile.current.files) return
    const reader = new FileReader();
    reader.onload = function (e) {
      if (e === null) return
      if (e.target === null) return
      const src = e.target.result;
      if (typeof src === "string") {
        fabric.Image.fromURL(src, function (oImg) {
          const { left, top } = fabricManager.getLeftTopCurVP();
          oImg.set({
            left,
            top,
            ...customization.getAllProperties(),
          });
          fabricManager.canvas.setActiveObject(oImg);
          fabricManager.canvas.add(oImg);
        });
      }
    }
    reader.readAsDataURL(inputFile.current.files[ 0 ]);
  }
  
  return (
    <UDTooltip show={!activeModal} css={tooltipStyles} text={"Изображение"}>
      <input onChange={onLoadImage} type="file" accept="image/*" ref={inputFile} style={{ display: "none" }}/>
      <IconButton onClick={onClickUploadImage}>
        <InteractiveClickableIcon child={UploadIcon}/>
      </IconButton>
    </UDTooltip>
  );
}
