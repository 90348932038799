import styled from '@emotion/styled';
import pattern from './images/pattern.svg';

export const Container = styled.div`
  background-image: url(${pattern});

  & .image {
    position: fixed;
    top: 80px;
    width: max(431px, 50%);
    height: max(559px, 50%);
  }
`;
