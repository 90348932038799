const down = (
  <path
    d="M4 2L8 6L12 2"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
);

export default down;
