import React, { useCallback } from 'react';
import UDIcon from 'modules/ud-ui/components/icon';
import { IconContainer } from './styles';

type Props = {
  inputRef: React.RefObject<HTMLInputElement>;
};

const UDInputNumberControls = (props: Props) => {
  const stepUp = useCallback(() => {
    if (props.inputRef.current) {
      props.inputRef.current.stepUp();
      props.inputRef.current.dispatchEvent(new Event('change', { bubbles: true }));
    }
  }, [props.inputRef]);

  const stepDown = useCallback(() => {
    if (props.inputRef.current) {
      props.inputRef.current.stepDown();
      props.inputRef.current.dispatchEvent(new Event('change', { bubbles: true }));
    }
  }, [props.inputRef]);

  return (
    <IconContainer className="flex-center flex-column number-controls">
      <UDIcon
        name="up"
        componentProps={{
          className: 'pt-2 c-pointer',
          onClick: stepUp,
        }}
      />
      <UDIcon
        name="down"
        componentProps={{
          className: 'pb-2 c-pointer',
          onClick: stepDown,
        }}
      />
    </IconContainer>
  );
};

export default UDInputNumberControls;
