import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { Team } from 'modules/teams/domain/interfaces/Team';
import UDText from 'modules/ud-ui/components/text';
import UDButton from 'modules/ud-ui/components/button';
import { SelectOption } from 'modules/ud-form/components/select/component';
import { AppDispatch } from 'store/store';
import {
  stageBracketsByRoundSelector, stageBracketsSelector, stageRoundsSelector
} from '../../../../../../store/stages';
import { setStageBracketsTeams } from '../../../../../../store/stages/stages.actions';
import { SetTournamentBracketParams } from '../../../../../../domain/repositories/tourneysRepository';
import { Round } from '../../../../../../domain/interfaces/Round';
import { RoundHeaders, RoundBracketsContainer } from './stage-brackets-form.styles';
import { RoundBrackets } from './round-brackets';
import { teamToTeamOption } from './team-select-option';

const findRoundById = (id: number) => (round: Round) => round.id === id;

type StageBracketValues = {
  rounds: Record<number, {
    brackets: Record<number, Array<SelectOption | null>>;
  }>;
};

type ChangedBracketsMap = {
  [key: number]: [number | null, number | null];
};

type StageBracketsFormProps = {
  teams: Team[];
};

export const StageBracketsForm = ({ teams }: StageBracketsFormProps) => {
  const brackets = useSelector(stageBracketsSelector);
  const bracketsByRound = useSelector(stageBracketsByRoundSelector);
  const rounds = useSelector(stageRoundsSelector);
  const dispatch = useDispatch<AppDispatch>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [changedBrackets, setChangedBrackets] = useState<ChangedBracketsMap>({});

  const roundIds = Object
    .keys(bracketsByRound).map(Number)
    .sort((a, b) => rounds.findIndex(findRoundById(a)) - rounds.findIndex(findRoundById(b)));

  const bracketTeamsMap: ChangedBracketsMap = brackets.reduce((result, bracket) => {
    const teamMatches = bracket.match?.teamMatches || [];
    const firstTeamId = teamMatches[0]?.team?.id || null;
    const secondTeamId = teamMatches[1]?.team?.id || null;

    return {
      ...result,
      [bracket.id]: [firstTeamId, secondTeamId],
    };
  }, {});

  const initialValues: StageBracketValues = {
    rounds: {},
  };

  brackets.forEach((bracket) => {
    const { id, roundId, match } = bracket;
    if (!initialValues.rounds[roundId]) {
      initialValues.rounds[roundId] = { brackets: {} };
    }

    const data = [];
    if (!match) {
      data.push(null, null);
    } else {
      const firstTeamOption = (match.teamMatches[0] && match.teamMatches[0].team)
        ? teamToTeamOption(match.teamMatches[0].team)
        : null;

      const secondTeamOption = (match.teamMatches[1] && match.teamMatches[1].team)
        ? teamToTeamOption(match.teamMatches[1].team)
        : null;

      data.push(firstTeamOption, secondTeamOption);
    }

    initialValues.rounds[roundId].brackets[id] = data;
  });

  const isSubmitButtonDisabled = useMemo(() => {
    return isLoading || Object.keys(changedBrackets).length === 0
  }, [changedBrackets, isLoading]);

  const handleBracketChanged = useCallback((bracketId: number, teamsIds: [number | null, number | null]) => {
    const bracketChanged = !bracketTeamsMap[bracketId] || bracketTeamsMap[bracketId].join('_') !== teamsIds.join('_');

    if (!bracketChanged) {
      setChangedBrackets((prevState) => {
        const newState = {...prevState};
        delete newState[bracketId];
        return newState;
      });
    } else {
      setChangedBrackets((prevState) => ({
        ...prevState,
        [bracketId]: teamsIds,
      }));
    }
  }, [bracketTeamsMap]);

  const handleSubmit = useCallback(() => {
    setIsLoading(true);

    const stageId = brackets[0].stageId;
    const bracketsToUpdate: SetTournamentBracketParams[] = Object.keys(changedBrackets).map((bracketId) => {
      const tournamentBracketId = Number(bracketId);
      return{
        tournamentBracketId,
        team1Id: changedBrackets[tournamentBracketId][0],
        team2Id: changedBrackets[tournamentBracketId][1],
      };
    });

    dispatch(setStageBracketsTeams({ stageId, brackets: bracketsToUpdate })).finally(() => {
      setChangedBrackets({});
      setIsLoading(false);
    });
  }, [brackets, changedBrackets, dispatch]);

  if (!brackets.length) {
    return (
      <div>
        <UDText
          className="mt-10 center"
          type="subhead"
        >Сетка еще не создана</UDText>
      </div>
    );
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={handleSubmit}
    >
      {(formProps) => (
        <form onSubmit={formProps.handleSubmit}>
          <RoundHeaders
            className="mb-4"
            rounds={rounds.length}
          >
            {rounds.map((round) => (
              <UDText className="round-title" type="headline">{round.name}</UDText>
            ))}
          </RoundHeaders>

          <RoundBracketsContainer>
            {roundIds.map((roundId, index) => (
              <RoundBrackets
                key={roundId}
                className={`round round-${index + 1}`}
                teams={teams}
                bracketPairs={bracketsByRound[roundId]}
                onBracketChanged={handleBracketChanged}
              />
            ))}
          </RoundBracketsContainer>

          <div className="mt-4 mb-8 mr-4 d-flex justify-content-end">
            <UDButton
              type="submit"
              variant="primary"
              disabled={isSubmitButtonDisabled}
              loading={isLoading}
            >Сохранить</UDButton>
          </div>
        </form>
      )}
    </Formik>
  );
}
