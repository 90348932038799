import React from 'react';

type CircleCheckIconProps = {
  width: number;
  height: number;
  color?: string;
};

const CircleCheckIcon = ({ width, height, color = '#F5D956' }: CircleCheckIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_3614_23741"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width={width}
        height={height}
      >
        <rect width={width} height={height} fill="white"/>
      </mask>
      <g mask="url(#mask0_3614_23741)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 12 2 6.48 2 12ZM10 14.17L16.59 7.58002L18 9.00002L10 17L6 13L7.41 11.59L10 14.17Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default CircleCheckIcon;
