import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

const authStoreSelector = (store: RootState) => store.auth;

export const authStoreLoadingSelector = createSelector(
  authStoreSelector,
  (store) => store.loading,
);

export const authStoreAuthedSelector = createSelector(
  authStoreSelector,
  (store) => store.authed,
);

export const authStoreUserTournamentId = createSelector(
  authStoreSelector,
  (store) => store.activeTournamentId,
);

export const authStoreAvailableTournaments = createSelector(
  authStoreSelector,
  (store) => store.availableTournaments,
);

export const authStoreCheckingSelector = createSelector(
  authStoreSelector,
  (store) => store.authChecking,
);

export const authStoreCheckedSelector = createSelector(
  authStoreSelector,
  (store) => store.authChecked,
);

export const authStoreCodeRequestingSelector = createSelector(
  authStoreSelector,
  (store) => store.codeRequesting,
);

export const authStoreCodeRequestedSelector = createSelector(
  authStoreSelector,
  (store) => store.codeRequested,
);

export const authStoreErrorsSelector = createSelector(
  authStoreSelector,
  (store) => store.error,
);

export const authStoreUserSelector = createSelector(
  authStoreSelector,
  (store) => store.user,
);
