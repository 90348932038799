import styled from '@emotion/styled';
import { SymbolicTeamFormation } from '../../../../../domain';
import playerDefaultAvatar from './player-default-avatar.svg';

function getTopValue(rank: number, size: number): string {
  const halfSize = size / 2;

  switch (rank) {
    case 2:
    case 3:
      return `${24 - halfSize}%`;
    case 4:
    case 5:
      return `${48 - halfSize}%`;
    default:
      return `${-halfSize}%`
  }
}

function getLeftValue(rank: number, size: number): string {
  const halfSize = size / 2;

  switch (rank) {
    case 2:
      return `${halfSize - 4}%`;
    case 3:
      return '';
    case 4:
      return `${halfSize}%`;
    case 5:
      return '';
    default:
      return `${50 - halfSize}%`
  }
}

function getRightValue(rank: number, size: number): string {
  const halfSize = size / 2;

  switch (rank) {
    case 2:
      return '';
    case 3:
      return `${halfSize - 4}%`;
    case 4:
      return '';
    case 5:
      return `${halfSize}%`;
    default:
      return ''
  }
}

type PlayerCardWrapperProps = {
  rank: number;
  formation: SymbolicTeamFormation;
};

export const PlayerCardWrapper = styled.div<PlayerCardWrapperProps>`
  position: absolute;
  width: 32%;
  height: 18%;
  top: ${props => getTopValue(props.rank, 18)};
  left: ${props => getLeftValue(props.rank, 32)};
  right: ${props => getRightValue(props.rank, 32)};
`;

export const PlayerCardContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

type PlayerCardAvatarProps = {
  avatar?: string;
};

export const PlayerCardAvatar = styled.div<PlayerCardAvatarProps>`
  width: 40%;
  height: 90%;
  margin: 0 auto;
  background-image: url(${props => props.avatar || playerDefaultAvatar});
  background-color: #EAEAEC;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 8px 8px 0 0;
`;
