export default function templateNameValidator(templateName?: string, templateList?: string[]) {
  if (!templateName) {
    return 'Название табло не может быть пустым'
  }
  if (templateName.length > 30) {
    return 'Название табло слишком длинное'
  }
  if (templateName.length <= 0) {
    return 'Название табло не может быть пустым'
  }
  if (templateList && templateList.includes(templateName)) {
    return 'Такое название уже существует, придумайте новое.'
  }
  return undefined
}
