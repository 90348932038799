export const birthDateFormatter = (value = ''): string => {
  return value.replace(/^(\d{2})(\D?)(\d{0,2})(\D?)(\d{0,4})(.?)/, (value, ...parts) => {
    if (parts.length < 3) return '';

    const results = [];
    if (parts[0]) {
      results.push(parts[0]);
    }
    if (parts[2]) {
      results.push(parts[2]);
    }
    if (parts[4]) {
      results.push(parts[4]);
    }

    return results.join('.');
  });
}
