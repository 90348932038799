import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { Formik } from 'formik';
// import * as yup from 'yup';
import UDText from 'modules/ud-ui/components/text';
import UDFormDateInput from 'modules/ud-form/components/datetime';
import UDFormTimeInput from 'modules/ud-form/components/datetime/time';
import UDFormSelect, { UDFormCreatableSelect } from 'modules/ud-form/components/select';
import UDButton from 'modules/ud-ui/components/button';
import { Team } from 'modules/teams/domain/interfaces/Team';
import { Game, GameTeam } from 'modules/games/domain/interfaces/Game';
import TeamLogo from '../../../../ud-ui/components/team-logo';
import { Round } from '../../../../tourneys/domain/interfaces/Round';
import { CreatableSelectOption, SelectOption } from '../../../../ud-form/components/select/component';
import { SportType } from '../../../../core/domain/enums/SportType';
import { useSelector } from 'react-redux';
import { championshipIsMatchSavingSelector } from '../../../../tourneys/store/championship/selectors';
import { format } from 'date-fns';

const TeamSelectOption = styled.div`
  display: flex;
  align-items: center;
  
  .team-select-logo {
    margin-right: 8px;
  }
`;

type UpsertMatchCommonParams = {
  date: string;
  time: string;
  firstTeam: GameTeam;
  secondTeam: GameTeam;
  sportType: SportType;
};

type UpsertMatchValues = {
  date: string;
  time: string;
  firstTeam: SelectOption | null;
  secondTeam: SelectOption | null;
  sportType: SportType;
  round: CreatableSelectOption | null;
};

export type UpsertMatchParams = UpsertMatchCommonParams & ({
  roundId: number;
  round?: never;
} | {
  roundId?: never;
  round: {
    name: string;
    stageId: number;
  };
} | {
  roundId?: never;
  round?: never;
});

type CreateGameFormProps = {
  stageId?: number;
  roundId?: number;
  teams: Team[];
  game?: Game;
  rounds?: Round[];
  onSubmit: (values: UpsertMatchParams) => void;
  requiredRound?: boolean;
};
//
// const matchValidator: yup.SchemaOf<UpsertMatchValues> = yup
//   .object()
//   .shape({
//     date: yup.string().required(),
//     time: yup.string().required(),
//     firstTeam: yup.object().shape({
//       label: yup.mixed().required(),
//       value: yup.number().required(),
//     }).default(undefined).required(),
//     secondTeam: yup.object().shape({
//       label: yup.mixed().required(),
//       value: yup.number().required(),
//     }).default(undefined).required(),
//     sportType: yup.mixed<SportType>().oneOf(Object.values(SportType)).required(),
//     round: yup.object().shape({
//       label: yup.string().required(),
//       value: yup.string().required(),
//       __isNew__: yup.boolean().optional(),
//     }).default(undefined).optional(),
//   });

const teamToTeamOption = (team: Team): SelectOption => ({
  label: (
    <TeamSelectOption>
      <TeamLogo
        className="team-select-logo"
        url={team.logo?.url}
        size={32}
      />
      {team.name}
    </TeamSelectOption>
  ),
  value: team.id,
});

const CreateGameForm = (props: CreateGameFormProps) => {
  const isMatchSaving = useSelector(championshipIsMatchSavingSelector);

  const {
    stageId,
    teams,
    game,
    rounds,
    onSubmit,
    requiredRound,
  } = props;

  const teamsOptions = teams.map(teamToTeamOption);

  const roundsOptions: SelectOption[] = (rounds || []).map((round) => ({
    label: round.name,
    value: round.id,
  }));

  const initialValues: UpsertMatchValues = {
    date: game?.date.toISOString() || '',
    time: game?.date ? format(game?.date, 'HH:mm') : '',
    firstTeam: game?.teams[0] ? teamToTeamOption(game?.teams[0]) : null,
    secondTeam: game?.teams[1] ? teamToTeamOption(game?.teams[1]) : null,
    sportType: game?.sportType || SportType.football,
    round: (rounds && rounds?.length && game?.round)
      ? {
        label: game.round.name,
        value: game.round.id,
      }
      : null,
  };

  const handleSubmit = useCallback((values: UpsertMatchValues) => {
    if (isMatchSaving) {
      return;
    }

    const data = {
      date: values.date,
      time: values.time,
      firstTeam: values.firstTeam!.value,
      secondTeam: values.secondTeam!.value,
      sportType: values.sportType,
    } as UpsertMatchParams;

    if (values.round) {
      if (values.round!.__isNew__ && stageId) {
        data.round = {
          name: values.round!.value,
          stageId,

        };
      } else if (!values.round!.__isNew__) {
        data.roundId = values.round!.value;
      }
    }

    if (requiredRound && !data.round && !data.roundId) {
      return;
    }

    onSubmit(data);
  }, [onSubmit, stageId, isMatchSaving]);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      // validationSchema={matchValidator}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {(formProps) => (
        <form onSubmit={formProps.handleSubmit}>
          <input type="hidden" value={formProps.values.sportType} />

          <div className='d-flex flex-row'>
            <div className=''>
              <UDText type="subhead" className="mr-2 color-SurfaceRomanSilver30">
                Дата матча
              </UDText>
              <UDFormDateInput autoComplete="off" name="date" />
            </div>
            <div className='ml-2'>
              <UDText type="subhead" className="mr-2 color-SurfaceRomanSilver30">
                Время начала
              </UDText>
              <UDFormTimeInput
                name="time"
                autoComplete="off"
                timeIntervals={15}
             />
            </div>
          </div>

          {rounds && (
            <div className='mt-2'>
              <UDFormCreatableSelect
                name="round"
                label="Тур"
                placeholder="Выбрать или ввести новый"
                isMulti={false}
                options={roundsOptions}
                formatCreateLabel={(inputValue) => `Добавить: "${inputValue}"`}
              />
            </div>
          )}

          <div className='mt-2'>
            <UDFormSelect
                name='firstTeam'
                label="Команда 1"
                placeholder="Выберите команду"
                isMulti={false}
                options={teamsOptions}
            />
          </div>
          <div className='mt-2'>
            <UDFormSelect
                name='secondTeam'
                label="Команда 2"
                placeholder="Выберите команду"
                isMulti={false}
                options={teamsOptions}
            />
          </div>
          <div className='mt-10'>
            <UDButton
              variant="primary"
              className="w-100 mb-3"
              disabled={isMatchSaving}
              loading={isMatchSaving}
              type="submit"
            >
              Сохранить
            </UDButton>
          </div>
        </form>
      )}
    </Formik>
  );
}

export default CreateGameForm;
