import React from 'react';
import styled from '@emotion/styled';
import { ListContainer } from '../tourneys-list/styles';

const TeamCardSkeleton = styled.div`
  border-radius: 8px;
  height: 128px;
`;

const PaginationContentSkeleton = styled.div`
  border-radius: 8px;
  width: 216px;
  height: 24px;
`;

type TeamListSkeletonProps = {
  resultsOnPage?: number;
};

const TeamListSkeleton = ({ resultsOnPage = 5 }: TeamListSkeletonProps) => {
  const items = new Array(resultsOnPage).fill('');

  return (
    <ListContainer className="container">
      <div className="row g-1">
        {items.map((item, index) => (
          <div key={index} className="col-4">
            <TeamCardSkeleton className="skeleton-block" />
          </div>
        ))}
      </div>
    </ListContainer>
  );
};

const PaginationSkeleton = () => {
  return (
    <div className="d-flex justify-content-end px-6 my-8">
      <PaginationContentSkeleton className="skeleton-block" />
    </div>
  );
};

type TeamBodySkeletonProps = TeamListSkeletonProps;

export const TeamBodySkeleton = ({ resultsOnPage }: TeamBodySkeletonProps) => {
  return (
    <>
      <TeamListSkeleton resultsOnPage={resultsOnPage} />
      <PaginationSkeleton />
    </>
  );
};
