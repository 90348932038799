import { Canvas } from "fabric/fabric-impl";
import Interactive from "../../canvases/interactive";

export class GroupAlignment {
  canvas!: Canvas
  manager!: Interactive
  
  init(manager: Interactive) {
    this.canvas = manager.canvas
    this.manager = manager
  }
  
  left = () => {
    const activeObjects = this.canvas.getActiveObjects()
    if (activeObjects[ 0 ].left === undefined) return
    
    const minLeft = activeObjects.reduce((minLeft: number, object) => {
      if (object.left === undefined) return minLeft
      if (object.left < minLeft) minLeft = object.left
      return minLeft
    }, activeObjects[ 0 ].left)
    
    activeObjects.forEach((object) => {
      object.set({ left: minLeft })
    })
    
    this.manager.reselectActiveObjects()
  }
  
  right = () => {
    const activeObjects = this.canvas.getActiveObjects()
    if (activeObjects[ 0 ].left === undefined) return
    
    const maxLeft = activeObjects.reduce((maxLeft: number, object) => {
      if (object.left === undefined) return maxLeft
      if (object.left > maxLeft) maxLeft = object.left
      return maxLeft
    }, activeObjects[ 0 ].left)
    
    activeObjects.forEach((object) => {
      object.set({ left: maxLeft })
    })
    
    this.manager.reselectActiveObjects()
  }
  
  top = () => {
    const activeObjects = this.canvas.getActiveObjects()
    if (activeObjects[ 0 ].top === undefined) return
    
    const minTop = activeObjects.reduce((minTop: number, object) => {
      if (object.top === undefined) return minTop
      if (object.top < minTop) minTop = object.top
      return minTop
    }, activeObjects[ 0 ].top)
    
    activeObjects.forEach((object) => {
      object.set({ top: minTop })
    })
    
    this.manager.reselectActiveObjects()
  }
  
  bottom = () => {
    const activeObjects = this.canvas.getActiveObjects()
    if (activeObjects[ 0 ].top === undefined) return
    
    const maxTop = activeObjects.reduce((maxTop: number, object) => {
      if (object.top === undefined) return maxTop
      if (object.top > maxTop) maxTop = object.top
      return maxTop
    }, activeObjects[ 0 ].top)
    
    activeObjects.forEach((object) => {
      object.set({ top: maxTop })
    })
    
    this.manager.reselectActiveObjects()
  }
  
  centerVertical = () => {
    this.manager.reselectActiveObjects()
    
    const activeObject = this.canvas.getActiveObject()
    const width = this.manager.resolution.value.width
    if (!activeObject || !width || !activeObject.width) return
    activeObject.set({
      left: width / 2 - activeObject.width / 2
    });
    
    this.canvas.requestRenderAll()
  }
  
  centerHorizontal = () => {
    this.manager.reselectActiveObjects()
    
    const activeObject = this.canvas.getActiveObject()
    const height = this.manager.resolution.value.height
    if (!activeObject || !height || !activeObject.height) return
    activeObject.set({
      top: height / 2 - activeObject.height / 2
    });
    
    this.canvas.requestRenderAll()
  }
}

const groupAlignment = new GroupAlignment()
export default groupAlignment
