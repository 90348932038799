import { useCallback, useMemo } from "react";
import { useOrganizer } from "../../../../../organizer/ui/hooks/useOrganizer";
import { useDispatch, useSelector } from "react-redux";
import { selectActiveCategory } from "../../../../store/selectors";
import useUniversalModal, { IUNModalProps } from "../useUniversalModal";
import _ from "lodash";
import templateNameValidator from "../../../../domain/validators/templateName.validator";
import supabase from "../../../../../core/supabase/supabase";
import { updateCategory } from "../../../../store/templateEditor";

function useCreateModal() {
  const dispatch = useDispatch();
  const { info } = useOrganizer();
  const category = useSelector(selectActiveCategory);
  const templates = useMemo(() => {
    if (!category) return;
    return _(category.templates).toPairs().orderBy((value) => {return value[ 1 ].id}).value().map(value => value[ 0 ]);
  }, [category]);
  
  const validator = useCallback((values: { [ p: string ]: string }) => {
    const errors: { [ p: string ]: string | undefined } = {}
    
    const nameError = templateNameValidator(values.name?.trim(), templates)
    if (nameError) {errors.name = nameError}
    
    return errors
  }, [templates]);
  
  const onSubmit = useCallback((values: { [ p: string ]: string }) => {
    if (!info) {
      console.warn({ event: 'failed to create category: info is undefined', info });
      return
    }
    if (!category) {
      console.warn({ event: 'failed to create category: category is undefined', category });
      return
    }
    
    const { name } = values
    
    const newTemplates = {
      ...category.templates,
      [ name.trim() ]: {
        "version": "5.3.0",
        "objects": [],
        "id": Date.now().toString(),
        createdAt: new Date().toISOString()
      }
    }
    
    supabase.overlayTemplates.updateRow({ rowId: category.id }, { templates: newTemplates })
    dispatch(updateCategory({ name: category.name, templates: newTemplates, resolution: category.resolution }))
  }, [category, dispatch, info]);
  
  const data: IUNModalProps = useMemo(() => {
    return {
      headerText: 'Новое табло',
      initialValue: {
        name: '',
      },
      validator,
      buttonText: 'Создать',
      input: {
        placeholder: 'Название табло'
      },
      onSubmit,
    }
  }, [onSubmit, validator]);
  
  const { modal, close, open } = useUniversalModal(data)
  
  return { modal, close, open }
}

export default useCreateModal;
