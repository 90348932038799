import { Organizer } from "../interfaces/Organizer";

export function sortingByDate(organizers: Organizer[] | undefined, sortType: string | undefined) {
  if (!sortType || sortType === "none") return organizers
  if (!organizers) return organizers
  
  if (sortType === "asc") {
    return [...organizers]?.sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime())
  } else if (sortType === "desc") {
    return [...organizers]?.sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())
  }
}
