/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useState } from "react";
import BaseModal from "../../components/elements/modals/baseModal";
import UDIcon from "../../../../ud-ui/components/icon";
import { css } from "@emotion/react";
import Portal from "../../../../ud-ui/components/portal";
import { DivProps } from "../../../../../typings";

export default function useModal(props: IProps) {
  const { children, onClose } = props;
  const [state, setState] = useState<ModalState>({
    isOpen: false,
    modalStyle: {},
    iconProps: {},
    containerProps: {},
    overlayProps: {}
  });
  
  const isOpen = () => {
    return state.isOpen;
  }
  
  const close = useCallback(() => {
    setState({ isOpen: false, modalStyle: {} });
    onClose?.();
  }, [onClose]);
  
  const open = (styles: styles) => {
    setState({ isOpen: true, ...styles });
  }
  
  useEffect(() => {
    const onPress = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        close();
      }
    }
    
    document.addEventListener('keydown', onPress);
    return () => {
      document.removeEventListener('keydown', onPress);
    }
  }, [close]);
  
  const modal = (
    <Portal>
      <BaseModal isOpen={state.isOpen} style={{ ...state.modalStyle }}>
        <div
          style={{ position: 'absolute', top: 0, right: 0, width: '100vw', height: '100vh' }}
          onClick={close}
          {...state.overlayProps}
        />
        <div css={modalStyle} {...state.containerProps}>
          <div css={iconModalCss} {...state.iconProps} onClick={close}>
            <UDIcon
              name={'close'}
              componentProps={{
                style: { width: 12, height: 12, cursor: 'pointer' },
              }}
            />
          </div>
          <div>{children}</div>
        </div>
      </BaseModal>
    </Portal>
  )
  
  
  return { modal, close, open, isOpen };
}

interface IProps {
  children: React.ReactNode
  onClose?: () => void
}

interface ModalState extends styles {
  isOpen: boolean;
}

interface styles {
  modalStyle: Partial<React.CSSProperties>;
  containerProps?: DivProps
  overlayProps?: DivProps
  iconProps?: DivProps
}

const modalStyle = css`
  z-index: 1001;
  
  background-color: #FFF;
  border: 1px solid #EDEDED;
  box-shadow: 4px 4px 4px 0 #00000040;
  width: 500px;
  min-height: 200px;
  border-radius: 8px;
  
  font-family: SF Pro Text, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  
  display: flex;
  flex-direction: column;
  
  padding: 34px 24px;
  position: relative;
`

const iconModalCss = css`
  position: absolute !important;
  top: 0;
  right: 0;
  padding: 22px;
  cursor: pointer;
`
