import styled from "@emotion/styled";

export const MainBlock = styled.div`
  display: flex;
  flex-direction: row;
  background: white;
  
  width: 100%;
  height: 72px;
  border-radius: 8px;
  box-shadow: 0 8px 16px 0 #1A1A2914, 0 0 1px 0 #1A1A2914;
  padding: 16px 20px;
  
  &:hover {
    cursor: pointer;
    background-color: #FFFBEB;
  }
  
  transition: background-color 0.2s ease;
`

export const ImageBlock = styled.div`
  width: 40px;
  height: 40px;
  min-height: 40px;
  min-width: 40px;
  border-radius: 8px;
  border: 1px solid #F4F4F5;
  overflow: clip;
`

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`

export const InfoContainer = styled.div<{gap?: number, width?: number}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${props => props.gap || 0}px;
  width: ${props => props.width || 'unset'}px;
  min-width: ${props => props.width || 'unset'}px;
  white-space: nowrap; /* Текст не переносится */
  overflow: hidden; /* Обрезаем всё за пределами блока */
  text-overflow: ellipsis; /* Добавляем многоточие */
`

export const Divider = styled.div`
  &::after {
    content: "";
    display: block;
    width: 1px;
    height: 40px;
    background: #E2E3E4;
    margin-left: 25px;
    margin-right: 25px;
  }
`

export const SportType = styled.div`
  background: #F2F8FE;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 7px 14px;
  border-radius: 10px;
`
