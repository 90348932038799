import React from 'react';
import { GroupBase } from 'react-select';
import CreatableSelect, { CreatableProps } from 'react-select/creatable';
import overriddenSelectComponents from './override';
import { getSelectStyles } from './styles';
import { SelectOption, CreatableSelectOption } from './types';

export type UDCreatableSelectProps<
  isMulti extends boolean = boolean,
  Group extends GroupBase<SelectOption> = GroupBase<SelectOption>
> = Omit<
  CreatableProps<SelectOption, isMulti, Group>,
  'hideSelectedOptions' | 'styles'
>;

export const UDCreatableSelect = <
  isMulti extends boolean = false,
  Group extends GroupBase<CreatableSelectOption> = GroupBase<CreatableSelectOption>
>(props: UDCreatableSelectProps<isMulti, Group>) => {
  const {
    components,
    ...restProps
  } = props;

  const styles = getSelectStyles<isMulti, Group>();
  const selectComponents = components || overriddenSelectComponents;

  return (
    <CreatableSelect
      {...restProps}
      hideSelectedOptions={false}
      placeholder={props.placeholder || ''}
      closeMenuOnSelect={!props.isMulti}
      styles={styles}
      components={selectComponents}
    />
  );
};
