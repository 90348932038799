import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UDIcon from '../../../../ud-ui/components/icon';
import { setFouls } from '../../../store/overlay';
import { selectFouls } from '../../../store/selectors';
import Block from '../components/block';
import Counter from '../components/counter';

export function Fouls() {
  const dispatch = useDispatch()
  const fouls = useSelector(selectFouls)
  
  const setScoreFirstTeam = (num: number) => {
    dispatch(setFouls([num, fouls[1]]))
  }
  
  const setScoreSecondTeam = (num: number) => {
    dispatch(setFouls([fouls[0], num]))
  }
  
  const onClickReset = () => {
    dispatch(setFouls([0, 0]))
  }
  
  return <Block className={'flex-center flex-column'} headerText={'Фолы'}>
    <div className={'d-flex'} style={{width: '100%', justifyContent: 'space-between'}}>
      <Counter value={fouls[0]} setValue={setScoreFirstTeam}/>
      <UDIcon componentProps={{onClick: onClickReset}} name={'cyclic-arrow'}/>
      <Counter value={fouls[1]} setValue={setScoreSecondTeam}/>
    </div>
  </Block>;
}
