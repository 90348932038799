import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const MainBlock = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 24px;
  background-color: white;
`

export const disableButtonCSS = css`
  outline: unset !important;
  border-width: 0;

  &:disabled {
    background-color: #ACACAC !important;
    border: 1px #ACACAC !important;
    outline: 1px #ACACAC !important;
    color: #686A79 !important;
  }
`

export const modalContainerCss = css`
  padding: 34px 24px;
  width: 500px;
`

export const Button = styled.button`
  cursor: pointer;
  background: #FFFFFF;
  
  width: 140px;
  height: 36px;
  padding: 8px 16px 8px 16px;
  gap: 10px;
  border-radius: 8px;
  border: 1px;
  
  font-family: SF Pro Display, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  
  &:hover {
    background: #FFF8D9;
  }
  
  &:active {
    background: #F5D956;
  }
`
