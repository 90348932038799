/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { css, SerializedStyles } from "@emotion/react";
import { EmotionJSX } from "@emotion/react/types/jsx-namespace";

type IProps = {
  idleColor?: string
  activeColor?: string
  disableColor?: string
  isActive?: boolean
  child: (props: {
    css: SerializedStyles;
    color: string;
    onMouseEnter: () => void;
    onMouseUp: () => void;
    onMouseLeave: () => void;
    onMouseDown: () => void
  }) => EmotionJSX.Element
};

const InteractiveClickableIcon = (props: IProps) => {
  const {
    idleColor: initColor = '#242424',
    activeColor = '#F5D956',
    disableColor = '#C4C4C4',
    isActive: condition = true,
    child
  } = props;
  
  const [color, setColor] = React.useState<string>(initColor);
  const [isHover, setHover] = React.useState<boolean>(false);
  const [isActive, setActive] = React.useState<boolean>(false);
  
  function select() {
    if (!condition) return
    setHover(true)
    setColor(activeColor)
  }
  
  function unSelect() {
    setHover(false)
    if (!condition) return
    if (isActive) return
    setColor(initColor)
  }
  
  function activate() {
    if (!condition) return
    if (!isHover) return
    setColor(activeColor)
  }
  
  function deactivate() {
    if (!condition) return
    setActive(false)
    setColor(initColor)
  }
  
  useEffect(() => {
    if (condition) {
      if (isHover) setColor(activeColor)
      else setColor(initColor)
    } else {
      setColor(disableColor)
    }
  }, [activeColor, condition, disableColor, initColor, isHover])
  
  return child({
    onMouseEnter: select,
    onMouseLeave: unSelect,
    onMouseDown: deactivate,
    onMouseUp: activate,
    color: color,
    css: svgCss,
  });
};

export default InteractiveClickableIcon;

const svgCss = css`
  &:focus {
    outline: unset;
  }
`
